import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MenuItem } from 'primeng/api';
import { Subject, Subscription, takeUntil } from 'rxjs';
import { RoleEnum, User } from 'src/app/common/models/user/user';
import { IdentityService } from 'src/app/core/services/identity.service';
import { environment } from 'src/environments/environment';
import { AppLayoutService, SideBarState } from '../service/app.layout.service';

@Component({
  selector: 'app-menu',
  templateUrl: './app.menu.component.html',
  styleUrls: ['./app.menu.component.scss'],
  host: {
    class: 'flex flex-column w-full justify-content-between align-items-center',
  },
})
export class AppMenuComponent implements OnInit, OnDestroy {
  menuItems: MenuItem[] = [];
  user$: Subscription | null;
  isLoggedIn: boolean;
  user: User | null;

  sideBarState: SideBarState;

  private readonly _destroying$ = new Subject<void>();

  get isExpanded(): boolean {
    return this.sideBarState === SideBarState.Expanded;
  }

  get isCollapsed(): boolean {
    return this.sideBarState === SideBarState.Collapsed;
  }

  constructor(
    private identityService: IdentityService,
    private appLayoutService: AppLayoutService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.setMenuItems();

    this.appLayoutService.sidebarState$
      .pipe(takeUntil(this._destroying$))
      .subscribe((state) => {
        this.sideBarState = state;
      });

    this.user$ = this.identityService.activeUserChanged$
      .pipe(takeUntil(this._destroying$))
      .subscribe((user: User | null) => {
        this.isLoggedIn = true;
        this.user = user;
        this.setMenuItems();
      });
  }

  ngOnDestroy(): void {
    this._destroying$.unsubscribe();
  }

  setMenuItems() {
    this.menuItems = [
      {
        label: 'Dashboard',
        icon: 'pi pi-home',
        routerLink: ['/'],
        command: this.navigate.bind(this, '/'),
      },
      {
        label: 'User Management',
        icon: 'pi pi-users',
        routerLink: ['/'],
        visible:
          this.user?.hasAnyRole([RoleEnum.SuperAdmin, RoleEnum.Admin]) ?? false,
        command: this.navigate.bind(this, 'user-management'),
      },
      {
        label: 'Projects',
        icon: 'pi pi-pencil',
        url: '/assets/images/svg/track-icon.svg',
        routerLink: ['/'],
        visible:
          this.user?.hasAnyRole([
            RoleEnum.SuperAdmin,
            RoleEnum.Admin,
            RoleEnum.BasicUser,
            RoleEnum.SuperTrakSales,
          ]) ?? false,
        command: this.navigate.bind(this, 'projects'),
      },
      {
        label: 'Configuration Management',
        icon: 'pi pi-file-edit',
        routerLink: ['/'],
        visible:
          this.user?.hasAnyRole([
            RoleEnum.SuperAdmin,
            RoleEnum.Admin,
            RoleEnum.SuperTrakSales,
          ]) ?? false,
        command: this.navigate.bind(this, 'configuration-management'),
      },
      {
        label: 'Concept a System',
        icon: 'pi pi-eye',
        routerLink: ['/'],
        command: this.navigate.bind(this, 'creator'),
        visible:
          !environment.production &&
          (this.user?.hasRole(RoleEnum.Admin) ?? false),
      },
    ];
  }

  navigate(path: string) {
    this.router.navigate([path]);
  }

  showHelp(): void {
    this.appLayoutService.showHelp();
  }
}
