import { ElementRef } from '@angular/core';
import { Application } from '@pixi/app';
import { Assets, ResolverManifest } from '@pixi/assets';
import { EventSystem } from '@pixi/events';
import { Graphics } from '@pixi/graphics';
import { Point } from '@pixi/math';
import { Viewport } from 'pixi-viewport';
import { assets } from '../assets.manifest';

import { TrackConceptDefinition } from '../services/track-creator/track-creator.service';
import { Track } from './track';

const MAX_TRACK_SIZE = 6200;
const MAX_STATION_SIZE = 1000;
const ZOOMING = 0.2;
const TRANSLATE_STEP = 20;

export class TrackViewer {
  private track: Track;

  private manifest: ResolverManifest = {
    bundles: [
      {
        name: 'track-assets',
        assets,
      },
    ],
  };
  private assets: any;

  private container: ElementRef;

  private app: Application;
  private viewport: Viewport;

  private grid: Graphics;

  private trackWidth: number | null;
  private trackLength: number;

  private eventSystem: any;

  get worldLength(): number {
    return this.trackLength * 100 + MAX_STATION_SIZE * 2 + 200; // 200 - gap between theoretical station and viewport edge
  }

  get worldWidth(): number {
    if (this.trackWidth === 0 || this.trackWidth === null)
      return this.app.view.height;

    return this.trackWidth * 100 + MAX_STATION_SIZE * 2 + 200; // 200 - gap between theoretical station and viewport edge
  }

  constructor(
    container: ElementRef,
    trackSpecs: {
      length: number;
      width?: number;
    }
  ) {
    this.initAssets();

    this.container = container;
    this.trackWidth = trackSpecs.width || 0;
    this.trackLength = trackSpecs.length;
  }

  async initAssets(): Promise<void> {
    await Assets.init({
      manifest: this.manifest,
    });

    this.assets = Assets.loadBundle('track-assets', (loader) => {
      console.log('Assets have been loaded');
    });
  }

  async initApplication(): Promise<void> {
    await this.initAssets();
    this.eventSystem = EventSystem;

    this.app = new Application({
      background: 'white',
      resizeTo: this.container.nativeElement,
      resolution: 1,
    });

    const { renderer } = this.app;

    if (!('events' in this.app.renderer)) {
      renderer.addSystem(this.eventSystem, 'events');
    }

    this.app.stage.eventMode = 'dynamic';
    this.app.stage.hitArea = this.app.renderer.screen;

    this.app.stage.addEventListener('wheel', (event) => {
      event?.preventDefault();
    });

    this.container.nativeElement.appendChild(this.app.view);

    this.app.resize();

    this.initViewport();
  }

  private initViewport(): void {
    // Setting viewport
    this.viewport = new Viewport({
      screenWidth: this.app.screen.width,
      screenHeight: this.app.screen.height,
      worldWidth: Math.max(this.worldLength, this.worldWidth),
      worldHeight: Math.max(this.worldLength, this.worldWidth),
      events: this.app.renderer.events,
    });

    this.viewport.center = new Point(0, 0);

    this.setZoomRestrictions();
    this.setViewportBoundaries();

    // Adding dragging ability excluding wheel
    this.viewport.drag({
      wheel: false,
    });

    // Adding zooming via mouse wheel
    this.viewport.wheel({
      wheelZoom: true,
    });

    this.app.stage.addChild(this.viewport);
  }

  private updateViewport(): void {
    this.viewport.worldWidth = Math.max(this.worldLength, this.worldWidth);
    this.viewport.worldHeight = Math.max(this.worldLength, this.worldWidth);

    // Resize the viewport to apply the changes
    this.viewport.resize(this.viewport.screenWidth, this.viewport.screenHeight);

    this.setZoomRestrictions();
    this.setViewportBoundaries();
    this.initBackgroundGrid();
  }

  resize(): void {
    this.app.resize();
    this.viewport.resize(
      this.app.screen.width,
      this.app.screen.height,
      Math.max(this.worldLength, this.worldWidth),
      Math.max(this.worldLength, this.worldWidth)
    );

    this.setZoomRestrictions();
  }

  private setZoomRestrictions(): void {
    const minScale =
      (Math.min(this.app.screen.width, this.app.screen.height) / 100) * 0.9;

    const maxScale = Math.max(
      this.app.screen.width / Math.max(this.worldWidth, this.worldLength),
      this.app.screen.height / Math.max(this.worldWidth, this.worldLength)
    );

    this.viewport.clampZoom({
      minScale: maxScale,
      maxScale: minScale,
    });
  }

  private setViewportBoundaries(): void {
    const halfGridWidth =
      Math.max(this.viewport.worldWidth, this.viewport.worldHeight) / 2;
    const halfGridHeight =
      Math.max(this.viewport.worldWidth, this.viewport.worldHeight) / 2;

    this.viewport.clamp({
      left: -halfGridWidth,
      right: halfGridWidth,
      top: -halfGridHeight,
      bottom: halfGridHeight,
    });
  }

  private initBackgroundGrid(): void {
    if (!this.grid) {
      this.grid = new Graphics();
      this.viewport.addChild(this.grid);
    }
  }

  private drawBackgroundGrid(): void {
    if (this.grid) {
      this.grid.clear();
    }

    const originPoint = this.track.originPoint;

    this.grid.lineStyle(2, 0xdee2e6, 1);

    const offset =
      Math.max(this.trackLength, this.trackWidth || 0) % 2 === 0 ? 0 : 50;
    const positionX =
      -(this.viewport.worldWidth / 2) + (originPoint.x % 100) + offset;
    const positionY =
      -(this.viewport.worldHeight / 2) + (originPoint.y % 100) + offset;

    for (let i = 0; i <= this.viewport.worldWidth; i += 100) {
      // Drawing horizontal lines
      this.grid.moveTo(positionX + i, -(this.viewport.worldHeight / 2));
      this.grid.lineTo(positionX + i, this.viewport.worldHeight / 2);

      // Drawing vertical lines
      this.grid.moveTo(-(this.viewport.worldWidth / 2), positionY + i);
      this.grid.lineTo(this.viewport.worldWidth / 2, positionY + i);
    }
  }

  destroy(): void {
    this.app.destroy();
  }

  zoomViewport(direction: 'in' | 'out'): void {
    const zoomDif = direction === 'in' ? ZOOMING : -ZOOMING;

    this.viewport.setZoom((this.viewport.scaled += zoomDif));
  }

  moveViewport(direction: 'up' | 'left' | 'right' | 'down'): void {
    const viewportPosition = new Point(
      this.viewport.center.x,
      this.viewport.center.y
    );

    switch (direction) {
      case 'up':
        viewportPosition.y = this.viewport.center.y - TRANSLATE_STEP;
        break;
      case 'down':
        viewportPosition.y = this.viewport.center.y + TRANSLATE_STEP;
        break;
      case 'left':
        viewportPosition.x = this.viewport.center.x - TRANSLATE_STEP;
        break;
      case 'right':
        viewportPosition.x = this.viewport.center.x + TRANSLATE_STEP;
        break;
    }

    this.viewport.center = viewportPosition;
  }

  resetZoom(): void {
    if (this.track.trackWidth) {
      this.track.trackLength > this.track.trackWidth
        ? this.viewport.fitWidth(this.track.trackLength * 100 + 200)
        : this.viewport.fitHeight(this.track.trackWidth * 100 + 200);
      return;
    }

    this.viewport.fitWidth(this.track.trackLength * 100 + 200);
  }

  resetPosition(): void {
    this.viewport.center = new Point(0, 0);
  }

  async renderTrack(trackSpecs: TrackConceptDefinition): Promise<Track> {
    this.trackLength = trackSpecs.length;
    this.trackWidth = trackSpecs.width;

    this.updateViewport();

    if (!this.track) {
      this.track = new Track(this.viewport);
    }

    await this.track.renderTrack(trackSpecs);

    this.resetZoom();
    this.resetPosition();
    this.drawBackgroundGrid();

    return this.track;
  }
}
