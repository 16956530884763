<div *ngIf="projects; else loading">
  <div class="flex justify-content-between">
    <h1>My Projects</h1>
    <p-button label="Create"
              icon="pi pi-plus"
              iconPos="left"
              (onClick)="onShowModal()"></p-button>
  </div>

  <div *ngIf="projects.length; else noProjects"
       class="grid">

    <!-- This can be moved to a project grid component -->
    <ng-container *ngFor="let project of projects; trackBy: trackById">
      <p-card (click)="openProject(project)"
              class="col-12 sm:col-6 lg:col-4"
              styleClass="cursor-pointer shadow-4">
        <ng-template pTemplate="header">
          <div class="flex justify-content-between px-3 pt-3">
            <div class="p-card-title">{{project.name}}</div>
          </div>
          <div class="w-full mx-auto max-w-30rem h-12rem flex justify-content-center align-items-center px-3 sm:px-6">
            <ng-container *ngIf="project.image; else noImage">
              <p-image src="{{project.image}}"
                       alt="Image"
                       class="flex align-items-center"
                       [preview]="true"></p-image>
            </ng-container>
            <ng-template #noImage>
              <img alt="app logo"
                   class="w-full"
                   src="/assets/images/SuperTrakConveyance.png" />
            </ng-template>
          </div>
          <div class="px-3">
            <hr>
          </div>
          <div class="p-card-subheader px-3 pb-3 truncate">
            <div class="pb-2"><span pTooltip="{{project.description}}"
                    tooltipPosition="bottom"
                    [showDelay]="100"
                    [hideDelay]="300">{{project.description}}</span></div>
            <!-- <span *ngIf="project.configurations.length">Configurations: {{project.configurations.length}}</span> -->
          </div>
        </ng-template>
      </p-card>
    </ng-container>

  </div>

  <ng-template #noProjects>
    <p>You have not created any projects yet.</p>
  </ng-template>

</div>

<ng-template #loading>
  <div class="loading-overlay">
    <p-progressSpinner></p-progressSpinner>
  </div>
</ng-template>

<p-toast position="bottom-center"
         key="deleteKey"
         [baseZIndex]="5000">
</p-toast>

<p-menu [showTransitionOptions]="'100ms'"
        [hideTransitionOptions]="'100ms'"
        #menu
        [popup]="true"
        [model]="items">
</p-menu>

<p-dialog [(visible)]="isCreateProjectModalShown"
          [modal]="true"
          [style]="{ width: '50vw' }"
          [draggable]="false"
          [resizable]="false"
          position="top">


  <ng-template pTemplate="header">
    <span class="text-xl font-bold">Create a New Project</span>
  </ng-template>

  <app-create-project #projectCreateModal
                      [isCreating]="isProjectCreating"></app-create-project>

  <ng-template pTemplate="footer">
    <p-button label="Cancel"
              icon="pi pi-times"
              styleClass="p-button-outlined p-button-secondary"
              [disabled]="isProjectCreating"
              (click)="closeModal()"></p-button>
    <p-button icon="pi pi-check"
              [loading]="isProjectCreating"
              label="Create"
              (click)="createProject()"></p-button>
  </ng-template>
</p-dialog>
