import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { Subject, takeUntil } from 'rxjs';
import { Track } from 'src/app/common/models/track/track';
import { ETurn } from '../../../common/enums/eTurn.enum';
import { ShortETurnFrameCount } from '../../enums/short-eturn-frame-count.enum';
import { Question } from '../../track-designer-questions';

@Component({
  selector: 'app-track-frames',
  templateUrl: './track-frames.component.html',
  styleUrls: ['./track-frames.component.scss'],
})
export class TrackFramesComponent implements OnInit, OnDestroy {
  @Input() track: Track;
  @Input() questions: Record<string, Question | Question[]> | undefined;
  @Input() form: FormGroup;
  @Input() trackType: ETurn;
  @Input() isInternalUser: boolean;

  @Output() onSelected = new EventEmitter();

  frameQuestion: Question[];
  eTurnNumberQuestion: Question;
  trackStandsAndLevellingKitQuestion: Question;

  selectedValue: boolean;
  framesFieldName: string;

  trackImageSrc: string;

  descriptionVisible = false;

  private readonly _destroying$ = new Subject<void>();

  get isOverUnder(): boolean {
    return this.form.get('eTurn')?.value === ETurn.Curve500Low;
  }

  get isFrameIncluded(): boolean {
    return this.form.get(this.framesFieldName)?.value;
  }

  get isFrameDeclined(): boolean {
    return this.form.get(this.framesFieldName)?.value === false;
  }

  get areShortETurnsAvailable(): boolean {
    return (
      this.form.get(this.framesFieldName)?.value &&
      (this.trackType === ETurn.Curve500 || this.trackType === ETurn.Curve800)
    );
  }

  get isSymphoniProject(): boolean {
    return this.form.get('isSymphoni')?.value;
  }

  constructor() {}

  ngOnInit(): void {
    this.frameQuestion = this.questions?.['frame'] as Question[];
    this.eTurnNumberQuestion = this.questions?.['shortTurnFrames'] as Question;
    this.trackStandsAndLevellingKitQuestion = this.questions?.[
      'trackStandsAndLevellingKit'
    ] as Question;

    this.framesFieldName = this.isOverUnder
      ? 'includeStandardWallMounts'
      : 'includeStandardBaseFrames';

    if (
      this.form.get('eTurn')?.touched &&
      !this.form.get(this.framesFieldName)?.touched
    ) {
      this.form
        .get('includeStandardBaseFrames')
        ?.setValue(this.isOverUnder ? null : true);
      this.form
        .get('includeStandardWallMounts')
        ?.setValue(this.isOverUnder ? true : null);
      this.setRequiredControls();
    }

    if (this.isOverUnder) {
      if (this.form.get('includeStandardWallMounts')?.value === null) {
        this.form.get('includeStandardWallMounts')?.setValue(true);
      }
      this.form
        .get('includeStandardWallMounts')
        ?.setValidators(Validators.required);
      this.form.get('includeStandardBaseFrames')?.clearValidators();
    } else {
      if (this.form.get('includeStandardBaseFrames')?.value === null) {
        this.form.get('includeStandardBaseFrames')?.setValue(true);
      }
      this.form.get('includeStandardWallMounts')?.clearValidators();
      this.form
        .get('includeStandardBaseFrames')
        ?.setValidators(Validators.required);
    }

    this.form.get('includeStandardWallMounts')?.updateValueAndValidity;
    this.form.get('includeStandardBaseFrames')?.updateValueAndValidity;

    this.setRequiredValidators();

    this.form
      .get(this.framesFieldName)
      ?.valueChanges.pipe(takeUntil(this._destroying$))
      .subscribe(() => {
        this.setRequiredValidators();
        this.setRequiredControls();
        this.updateImage();
      });

    if (
      this.isInternalUser &&
      this.trackType !== ETurn.Curve500Low &&
      !this.form.get('includeStandardBaseFrames')?.value
    ) {
      if (this.form.get('includeTrackSectionStands')?.value === null) {
        this.form.get('includeTrackSectionStands')?.setValue(true);
      }
    } else {
      this.form.get('includeTrackSectionStands')?.setValue(null);
    }

    this.form
      .get('shortETurnFrameCount')
      ?.valueChanges.pipe(takeUntil(this._destroying$))
      .subscribe(() => {
        this.updateImage();
      });

    if (this.isSymphoniProject) {
      if (!this.form.get('includeStandardBaseFrames')?.value) {
        this.form.get('includeStandardBaseFrames')?.setValue(true);
      }

      if (
        !this.form.get('shortETurnFrameCount')?.value ||
        this.form.get('shortETurnFrameCount')?.value !==
          ShortETurnFrameCount.None
      ) {
        this.form
          .get('shortETurnFrameCount')
          ?.setValue(ShortETurnFrameCount.None);
      }
    }

    this.updateImage();
  }

  ngOnDestroy(): void {
    this._destroying$.unsubscribe();
  }

  showDescription(): void {
    this.descriptionVisible = true;
  }

  setRequiredValidators(): void {
    if (
      this.isInternalUser &&
      this.trackType !== ETurn.Curve500Low &&
      !this.form.get('includeStandardBaseFrames')?.value
    ) {
      this.form
        .get('includeTrackSectionStands')
        ?.setValidators(Validators.required);
    } else {
      this.form.get('includeTrackSectionStands')?.clearValidators();
    }
    if (!this.form.get(this.framesFieldName)?.value) {
      this.form.get('shortETurnFrameCount')?.clearValidators();
    } else {
      if (
        this.trackType === ETurn.Curve500 ||
        this.trackType === ETurn.Curve800
      ) {
        this.form
          .get('shortETurnFrameCount')
          ?.setValidators(Validators.required);
      }

      this.form.get('includeTrackSectionStands')?.clearValidators();
    }

    this.form.get('shortETurnFrameCount')?.updateValueAndValidity;
    this.form.get('includeTrackSectionStands')?.updateValueAndValidity;
  }

  setRequiredControls(): void {
    if (
      this.isInternalUser &&
      this.trackType !== ETurn.Curve500Low &&
      !this.form.get('includeStandardBaseFrames')?.value
    ) {
      if (this.form.get('includeTrackSectionStands')?.value === null) {
        this.form.get('includeTrackSectionStands')?.setValue(true);
      }
    } else {
      this.form.get('includeTrackSectionStands')?.setValue(null);
    }
    if (!this.form.get(this.framesFieldName)?.value) {
      this.form.get('shortETurnFrameCount')?.setValue(null);
    } else {
      if (
        this.trackType === ETurn.Curve500 ||
        this.trackType === ETurn.Curve800
      ) {
        this.form
          .get('shortETurnFrameCount')
          ?.setValue(ShortETurnFrameCount.BothSides);
      }

      this.form.get('includeTrackSectionStands')?.setValue(null);
    }
  }

  setPlaceholderImage(): void {
    this.trackImageSrc = 'https://plchldr.co/i/500x250?text=Track%20Image';
  }

  updateImage(): void {
    const trackType = this.form.get('eTurn')?.value;
    const isFrameIncluded = this.form.get(this.framesFieldName)?.value;
    const areStandsIncluded = this.form.get('includeTrackSectionStands')?.value;
    const eTurnFrameCount = this.form.get('shortETurnFrameCount')?.value;

    if (this.isSymphoniProject && this.trackType === ETurn.Curve500) {
      this.trackImageSrc = `/assets/images/track-designer/Curve500-symphoni.png`;
      return;
    }

    let imageName = `${trackType}${isFrameIncluded ? '-frame' : ''}-stands`;

    if (
      this.trackType === ETurn.Curve500 ||
      this.trackType === ETurn.Curve800
    ) {
      imageName += eTurnFrameCount || '';
    }

    this.trackImageSrc = `/assets/images/track-designer/${imageName}-pallets.png`;
  }

  disableCheckboxFormValueChange(event: Event): void {
    event.preventDefault();
  }

  isBaseFrameIncludedOpt(basframeIncluded: boolean): boolean {
    return basframeIncluded;
  }

  isShortEturnsNone(shortEturnNumber: ShortETurnFrameCount): boolean {
    return shortEturnNumber === ShortETurnFrameCount.None;
  }
}
