<div class="maintenance-page relative w-screen h-screen flex overflow-hidden">
  <div class="flex w-7 justify-content-center align-items-center">
    <img src="/assets/images/logos/supertrak-logo-slogan.png"
         alt="SuperTrak Conveyance logo and slogan" />
  </div>
  <div class="flex w-5 justify-content-center align-items-center">
    <div class="maintenance-page__content text-white text-center">
      <p class="text-6xl font-bold">Maintenance</p>
      <p class="text-2xl line-height-1 mb-0">Scheduled maintenance is being performed.</p>
      <p class="text-2xl line-height-1">The service will be available shortly.</p>
      <p class="text-2xl">Thank you for your patience!</p>
    </div>
  </div>

  <button pButton
          pRipple
          type="button"
          icon="pi pi-user"
          *ngIf="isDevModeEnabled"
          class="dev-button absolute p-button-rounded p-button-outlined"
          (click)="devSignIn()"></button>
</div>
