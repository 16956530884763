import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiUrls, FormatApiUrlParam } from 'src/app/core/api-helper';
import { ApiUrlParams } from 'src/app/core/api-url-params';
import { AccessScope } from '../../enums/access-scope.enum';

@Injectable({
  providedIn: 'root',
})
export class ConfigurationVersionsDataService {
  constructor(private httpClient: HttpClient) {}

  exportConfigurationVersionParameters(
    versionId: string
  ): Observable<HttpResponse<Blob>> {
    const url = ApiUrls.configVersionParameters.replace(
      FormatApiUrlParam(ApiUrlParams.VersionId),
      versionId
    );

    return this.httpClient.get(url, {
      observe: 'response',
      responseType: 'blob',
      headers: new HttpHeaders({
        'Content-Type': 'application/vnd.ms-excel',
        Accept: 'application/vnd.ms-excel',
      }),
      params: {
        accessScope: AccessScope.Sales,
      },
    });
  }
}
