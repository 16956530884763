import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MenuItem, MessageService } from 'primeng/api';
import { Observable, Subject, take, takeUntil, tap } from 'rxjs';
import { AppRoutes } from 'src/app/app.routes.const';
import { AppLoadingService } from 'src/app/core/services/app-loading.service';
import {
  AppConfirmationDialogRequest,
  AppConfirmationDialogService,
} from 'src/app/layout/service/app-confirmation-dialog.service';
import { CreateProjectComponent } from './create-project/create-project.component';
import { Project } from './project';
import { ProjectService } from './project.service';

@Component({
  selector: 'app-projects',
  templateUrl: './projects.component.html',
  styleUrls: ['./projects.component.scss'],
})
export class ProjectsComponent implements OnInit, OnDestroy {
  @ViewChild('projectCreateModal') projectCreateModal: CreateProjectComponent;

  projects: Project[];
  items: MenuItem[];
  currentProject: Project;

  isCreateProjectModalShown = false;
  isProjectCreating = false;

  private readonly _destroying$ = new Subject<void>();

  constructor(
    private projectService: ProjectService,
    private router: Router,
    private route: ActivatedRoute,
    private messageService: MessageService,
    private confirmationService: AppConfirmationDialogService,
    private appLoadingService: AppLoadingService
  ) {}

  ngOnInit(): void {
    this.items = [
      {
        label: 'Delete',
        icon: 'pi pi-trash',
        iconClass: 'text-red-600',
        command: (event) => {
          this.onDelete(event.originalEvent);
        },
      },
    ];

    this.projects = this.route.snapshot.data[0];

    if (!this.projectService.projects.length) {
      this.isCreateProjectModalShown = true;
    }

    this.route.queryParamMap
      .pipe(takeUntil(this._destroying$))
      .subscribe((params) => {
        const shouldModalOpen = params.get('newProject');

        if (shouldModalOpen && !this.projects.length) {
          this.isCreateProjectModalShown = true;
        }
      });
  }

  ngOnDestroy(): void {
    this._destroying$.unsubscribe();
  }

  onMenuClick(project: Project, event: Event): void {
    event.stopPropagation();
    this.currentProject = project;
  }

  openProject(project: Project): void {
    this.router.navigate([AppRoutes.ProjectDetails.fullPath(project.id)]);
  }

  onShowModal(): void {
    this.isCreateProjectModalShown = true;
  }

  createProject(): void {
    const newProjectForm = this.projectCreateModal.createProjectForm;
    const formValues = newProjectForm.value;

    if (!newProjectForm.valid) {
      newProjectForm.markAsDirty();
      return;
    }

    this.isProjectCreating = true;

    if (!formValues.name || !formValues.description) {
      return;
    }

    this.appLoadingService.startLoadingBar();
    this.projectService
      .createProject(
        formValues.name,
        formValues.description,
        formValues.referenceNumber || null
      )
      .pipe(take(1))
      .subscribe({
        next: (res: Project) => {
          if (res)
            this.router.navigate([AppRoutes.ProjectDetails.fullPath(res.id)], {
              state: { project: res },
            });
        },
        error: (error) => {
          this.appLoadingService.stopLoadingBar();
          this.isProjectCreating = false;

          this.messageService.add({
            severity: 'error',
            summary: 'Error creating project.',
            detail: error.status + ': ' + error.title,
          });
        },
        complete: () => {
          this.isProjectCreating = false;
          this.appLoadingService.stopLoadingBar();
        },
      });
  }

  closeModal(): void {
    this.isCreateProjectModalShown = false;
  }

  onDelete(event: Event | undefined) {
    event?.stopPropagation();
    this.confirmationService.open(
      new AppConfirmationDialogRequest({
        header: `Are you sure that you want to delete ${this.currentProject.name}?`,
        onBeforeClose: this.handleDeleteProject(),
      })
    );
  }

  handleDeleteProject(): Observable<boolean> {
    return this.projectService.deleteProject(this.currentProject.id).pipe(
      tap({
        next: () => {
          this.projects = this.projects.filter(
            (p) => p.id != this.currentProject.id
          );
        },
        error: () => {
          this.messageService.add({
            key: 'deleteKey',
            severity: 'error',
            summary: 'Delete Error',
            detail: 'Failed to delete project: ' + this.currentProject.name,
          });
        },
      })
    );
  }

  trackById(index: number, project: Project): string {
    return project.id;
  }
}
