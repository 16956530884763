<div class="process-map-creator flex flex-column text-center">
  <h2>Start concepting your application with SuperTrak CONVEYANCE&trade;</h2>
  <p class="mb-0">We recommend you start from a template process - you will be able to edit your process steps, add your
    own input and save your new concept.</p>
  <ul class="templates-list text-left flex justify-content-center gap-4">
    <li class="templates-list__item"
        *ngFor="let template of processMaps">
      <app-process-map-template [template]="template"></app-process-map-template>
    </li>
  </ul>
  <p-divider layout="horizontal"
             align="center">
    <b>OR</b>
  </p-divider>
  <div class="flex gap-2 justify-content-center">
    <p-button icon="pi pi-file-o"
              severity="warning"
              (onClick)="closeProcessMapCreator()"
              label="Create from scratch"></p-button>
    <p-button icon="pi pi-file-import"
              label="Open existing"></p-button>
  </div>
</div>
