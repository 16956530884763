<div class="process-item"
     appClickOutside
     (clickOutside)="hideProcessDetails()"
     (click)="showActions()">
  <div class="process-item__content p-card">

    <span class="process-item__color"
          [style]="'background-color: ' + process.color">
    </span>

    <div class="process-item__info-wrapper flex flex-column flex-grow-1 overflow-hidden">
      <div class="process-item__info">

        <p class="process-item__name mb-0"
           [pTooltip]="process.name"
           [showDelay]="100"
           [tooltipPosition]="'bottom'">{{ process.name }}</p>
        <div class="flex w-5rem flex-column justify-content-center align-items-center">
          <img [src]="'/assets/icons/' + getIconName(process.function) + '.svg'"
               class="w-1rem process-item__img"
               [alt]="process.function" />
          <span class="inline-block mt-1 text-xs text-color-secondary">{{process.function}}</span>
        </div>
      </div>

      <div class="process-item__footer flex overflow-hidden"
           [ngClass]="{
              '_visible': actionsVisible
            }">
        <div class="process-item__footer-inner flex w-full align-items-center justify-content-between p-2">
          <ul class="process-item__spec-list flex-grow-1 px-3 my-0">
            <li class="process-item__spec-item flex-grow-1">
              <p class="process-item__spec-name text-left flex-grow-1 mb-0"><span class="font-bold">Takt
                  time</span></p>
              <p class="process-item__spec-value mb-0 ml-3">{{ process.taktTime }}&nbsp;<span
                      class="inline-block text-left w-2rem">ms</span>
              </p>
            </li>
            <li class="process-item__spec-item flex-grow-1">
              <p class="process-item__spec-name text-left flex-grow-1 mb-0"><span class="font-bold">Distance to next
                  process</span>
              </p>
              <p class="process-item__spec-value mb-0 ml-3">{{ process.distanceToNext }}&nbsp;<span
                      class="inline-block text-left w-2rem">mm</span></p>
            </li>
          </ul>
          <div class="process-item__actions">
            <p-button (onClick)="deleteProcess()"
                      [outlined]="true"
                      severity="danger"
                      [text]="true"
                      class="ml-2"
                      icon="pi pi-trash"></p-button>

            <p-button (onClick)="showProcessDetails()"
                      [outlined]="true"
                      [text]="true"
                      icon="pi pi-eye"></p-button>
          </div>
        </div>
      </div>

    </div>
  </div>
</div>
