<div class="error-page relative w-screen h-screen flex overflow-hidden">
  <div class="flex w-7 justify-content-center align-items-center">
    <img src="/assets/images/logos/supertrak-logo-slogan.png"
         alt="SuperTrak Conveyance logo and slogan" />
  </div>
  <div class="flex w-5 justify-content-center align-items-center">
    <div class="error-page__content text-white text-center">
      <p class="error-page__error mb-4 font-bold">
        <span class="error-page__error-number _blue">4</span>
        <span class="error-page__error-number _light-blue">0</span>
        <span class="error-page__error-number _blue">4</span>
      </p>
      <p class="text-6xl mb-1 font-bold">
        Error
      </p>
      <p class="text-2xl">It seems the page you were looking for does not exist.</p>
      <button pButton
              type="button"
              form="ngForm"
              label="HOME"
              icon="pi pi-home"
              (click)="navigateHome()"
              iconPos="left">
      </button>
    </div>
  </div>
</div>
