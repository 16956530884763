import { MenuItem } from 'primeng/api';
import { TRACK_TYPES } from '../common/constants/track-types.const';
import { ETurn } from '../common/enums/eTurn.enum';
import { RoleEnum } from '../common/models/user/user';
import { IdentityService } from '../core/services/identity.service';
import { UserService } from '../core/services/user.service';
import { ControlPanelInputVoltage } from './enums/control-panel-input-voltage.enum';
import { ControlPanelInterconnectLength } from './enums/control-panel-interconnect-length.enum';
import { ControlPanelType } from './enums/control-panel-type.enum';
import { PlcCommunicationType } from './enums/plc-communication-type.enum';
import { ShortETurnFrameCount } from './enums/short-eturn-frame-count.enum';
import { ShuttleMagnetCount } from './enums/shuttle-magnet-count.enum';
import { UserCompanyName } from './enums/user-company-name.enum';

export interface QuestionOption {
  label: string;
  value?: any;
  checkbox?: boolean;
  inputNumber?: boolean;
  description?: string;
  imgUrl?: string;
  fieldName?: string;
  isEnabled?: (...args: any[]) => boolean;
}

export interface Question {
  questionText: string;
  fieldName: string;
  options: QuestionOption[];
  isEnabled?: (...args: any[]) => boolean;
}

export interface SpecFormStep extends MenuItem {
  label: string;
  question?: Question;
  questions?: Record<string, Question | Question[]>;
}

export const steps: MenuItem[] = [
  {
    label: 'Track Type',
  },
  {
    label: 'Dimensions',
  },
  {
    label: 'Frames/Mounts',
  },
  {
    label: 'Controller',
  },
  {
    label: 'Controller Options',
  },
  {
    label: 'Control Panel Interconnect',
  },
  {
    label: 'Shuttles',
  },
  {
    label: 'Accessories',
  },
  {
    label: 'Additional Options',
  },
];

export const specSteps: SpecFormStep[] = [
  {
    label: 'Track Type',
    question: {
      questionText: 'Please choose a track type:',
      fieldName: 'eTurn',
      options: [
        {
          label: TRACK_TYPES[ETurn.Curve90].trackDesignerLabel,
          value: ETurn.Curve90,
        },
        {
          label: TRACK_TYPES[ETurn.Curve500].trackDesignerLabel,
          value: ETurn.Curve500,
        },
        {
          label: TRACK_TYPES[ETurn.Curve800].trackDesignerLabel,
          value: ETurn.Curve800,
        },
        {
          label: TRACK_TYPES[ETurn.None].trackDesignerLabel,
          value: ETurn.None,
        },
        {
          label: TRACK_TYPES[ETurn.Curve500Low].trackDesignerLabel,
          value: ETurn.Curve500Low,
        },
      ],
    },
  },
  {
    label: 'Dimensions',
    question: {
      questionText: 'Define your track dimensions:',
      fieldName: 'size',
      options: [
        {
          label: 'Width (meters):',
          fieldName: 'width',
          isEnabled: (eTurnType: ETurn) => {
            return eTurnType === ETurn.Curve90;
          },
        },
        {
          label: 'Length (meters):',
          fieldName: 'length',
          isEnabled: () => true,
        },
      ],
    },
  },
  {
    label: 'Frames/Mounts',
    questions: {
      frame: [
        {
          questionText: 'Do you want to include standard base plate?',
          fieldName: 'includeStandardWallMounts',
          options: [
            {
              label: 'Yes',
              fieldName: 'includeStandardWallMounts',
              value: true,
            },
            {
              label: 'No',
              fieldName: 'includeStandardWallMounts',
              value: false,
            },
          ],
          isEnabled: (isOverUnder: boolean) => {
            return isOverUnder;
          },
        },
        {
          questionText: 'What type of track assembly option do you want?',
          fieldName: 'includeStandardBaseFrames',
          options: [
            {
              label: 'Deliver an assembled track on standard base frames',
              fieldName: 'includeStandardBaseFrames',
              value: true,
            },
            {
              label: 'Deliver single modules without standard base frames',
              fieldName: 'includeStandardBaseFrames',
              value: false,
            },
          ],
          isEnabled: (isOverUnder: boolean) => {
            return !isOverUnder;
          },
        },
      ],
      shortTurnFrames: {
        questionText: 'Please choose number of short e-turn frames',
        fieldName: 'shortETurnFrameCount',
        options: [
          {
            label: '2 Sides',
            value: ShortETurnFrameCount.BothSides,
          },
          {
            label: '1 Side',
            value: ShortETurnFrameCount.OneSide,
          },
          {
            label: 'None',
            value: ShortETurnFrameCount.None,
          },
        ],
        isEnabled: (isFrameIncluded: boolean, eTurnType: ETurn) => {
          return (
            isFrameIncluded &&
            (eTurnType === ETurn.Curve500 || eTurnType === ETurn.Curve800)
          );
        },
      },
      trackStandsAndLevellingKit: {
        questionText: 'Track Section Stands',
        fieldName: 'includeTrackSectionStands',
        options: [
          {
            label:
              'Include track section stands and levelling kit for the straight sections',
            fieldName: '',
            value: true,
          },
        ],
        isEnabled: (
          isFrameIncluded: boolean,
          eTurnType: ETurn,
          isInternalUser: boolean
        ) => {
          return (
            !isFrameIncluded &&
            eTurnType !== ETurn.Curve500Low &&
            isInternalUser
          );
        },
      },
    },
  },
  {
    label: 'Controller',
    question: {
      questionText: 'Please choose a track controller:',
      fieldName: 'panelType',
      options: [
        {
          label: 'Standard Control Panel',
          value: ControlPanelType.Standard,
          description:
            '<b>Standard Control Panel</b> - Our standard control panel is a fully-integrated and certified solution that reduces machine design time and allows for faster machine integration. It includes our standard SuperTrak GEN3™ controller, an electrical enclosure for the controller, a PLC interface, a 24VDC logic power supply, a UPS, and safety contactors. This control panel requires a five-wire power supply (L1/L2/L3 +N+PE). The control panel cabinet dimensions are 540mm x 910mm x 330mm.',
        },
        {
          label: 'Controller & PLC Communication Card',
          value: ControlPanelType.ControllerOnly,
          description:
            '<b>Controller & PLC Communication Card</b> - This option includes only the standard SuperTrak GEN3™ controller and the necessary PLC communication card (which is selected on the next page). Integrators who select this option will need to design and manufacture an electrical solution that incorporates a 24VDC logic power supply, a UPS, safety contactors, and motor power supply breakers.',
        },
        {
          label: 'Compact',
          value: ControlPanelType.Compact,
          description:
            '<b>Compact Control Panel</b> - For ATS Inter-divisions only. This option is available for applications where space is limited or where electrical enclosures are to be placed overhead. It includes our standard SuperTrak GEN3™ controller, an electrical enclosure for the controller, a PLC interface, a 24VDC logic power supply, a UPS, and safety contactors. A five-wire power supply (L1/L2/L3 +N+PE) is required for its operation. Dimensions of the compact control panel cabinet are 356mm x 910mm x 305mm. This product has been tested but has not been independently certified. Certification of this unit should be included with the overall machine certification.',
          isEnabled: (
            userService: UserService,
            identityService: IdentityService
          ) =>
            userService.isUserFromCompany(UserCompanyName.LifeScience) ||
            !!identityService.currentUser?.hasRole(RoleEnum.SuperTrakSales),
        },
      ],
    },
  },
  {
    label: 'Controller Options',
    questions: {
      powerSupply: {
        questionText: 'Control panel input voltage:',
        fieldName: 'controlPanelInputVoltage',
        options: [
          {
            label: '208Y120VAC electrical standard',
            value: ControlPanelInputVoltage.Vac208Y120,
          },
          {
            label: '400Y230VAC electrical standard',
            value: ControlPanelInputVoltage.Vac400Y230,
          },
        ],
        isEnabled: (panelType: ControlPanelType) => {
          return (
            panelType === ControlPanelType.Standard ||
            panelType === ControlPanelType.Compact
          );
        },
      },
      plcInterface: {
        questionText: 'PLC interface:',
        fieldName: 'communicationType',
        options: [
          {
            label: 'Ethernet/IP™',
            value: PlcCommunicationType.EthernetIp,
          },
          {
            label: 'PROFINET™',
            value: PlcCommunicationType.Profinet,
          },
          {
            label: 'EtherCAT™',
            value: PlcCommunicationType.EtherCat,
          },
          {
            label: 'POWERLINK™',
            value: PlcCommunicationType.PowerLink,
          },
        ],
      },
      controllerKits: {
        questionText: 'Digital IO Kits:',
        fieldName: '',
        options: [
          {
            label: 'Additional kit for external HighSpeed IO',
            fieldName: 'includeExternalHighSpeedIo',
            description:
              'Using the high speed IO slice, the position trigger is accurate within tens of microseconds. This is a high-speed output card which is purchased when needed.  It is preconfigured in the SuperTrak project and therefore no hardware configuration is required to use it. The standard IO slice inside our Standard Control Panel outputs the position trigger which is accurate to within roughly 4ms.\n\nExample Use Case - Triggering Vision System.',
            checkbox: true,
          },
          {
            label: 'Additional kit for 1 external encoder signal',
            fieldName: 'includeExternalEncoderSignal',
            description:
              'The encoder output functionality provides an ability to output a real-time quadrature signal for a SuperTrak shuttle’s position relative to a specific point (target) on the SuperTrak. This enables using a SuperTrak shuttle as an axis of motion. The encoder output signal is generally used to synchronize external tooling to the SuperTrak shuttle making the shuttle the master and the external tooling the slave.\n\nExample Use Case - Scanning the profile of an object on the shuttle using a profilometer.',
            checkbox: true,
          },
          {
            label: 'Additional kit for up to 3 additional encoder signals',
            fieldName: 'additionalEncoderSignalCount',
          },
        ],
      },
    },
  },
  {
    label: 'Interconnect & Cabling',
    question: {
      questionText:
        'Please choose an e-turn to control panel interconnect length:',
      fieldName: 'controlPanelInterconnectLength',
      options: [
        {
          label: '1.2 meters',
          value: ControlPanelInterconnectLength.Interconnect1200,
          isEnabled: (eTurnType: any) => {
            return eTurnType === ETurn.None;
          },
        },
        {
          label: '2 meters',
          value: ControlPanelInterconnectLength.Interconnect2000,
          isEnabled: (eTurnType: any) => {
            return eTurnType !== ETurn.None;
          },
        },
        {
          label: '6.5 meters',
          value: ControlPanelInterconnectLength.Interconnect6500,
          isEnabled: (eTurnType: any) => {
            return eTurnType !== ETurn.None;
          },
        },
      ],
    },
  },
  {
    label: 'Shuttles',
    questions: {
      magnetsCount: {
        questionText: 'Select the number of magnets for each shuttle:',
        fieldName: 'shuttleMagnetCount',
        options: [
          {
            label: '2 magnets',
            value: ShuttleMagnetCount.Two,
          },
          {
            label: '3 magnets',
            value: ShuttleMagnetCount.Three,
          },
        ],
      },
      shuttlesCount: {
        questionText: 'Number of shuttles:',
        fieldName: 'shuttleCount',
        options: [
          {
            label: 'Number of shuttles:',
            inputNumber: true,
          },
        ],
      },
      irPallet: {
        questionText: 'Infrared (IR) Shuttle Identification (ID) System',
        fieldName: 'includeStandardIrPalletId',
        options: [
          {
            label: 'Include Shuttle IRID System',
            description:
              'The shuttle IRID system provides a method of assigning a fixed ID to each shuttle.  The system comprises one(1) IR reader, typically mounted at a section-to-section boundary, and one(1) IR read-only tag on each shuttle.  This system seamlessly integrates into the SuperTrak™ conveyor system without external PLC programming and does not require shuttles to stop in order to read the shuttle tags (on-the-fly reading).',
          },
        ],
      },
    },
  },
  {
    label: 'Accessories',
    question: {
      questionText: 'Please choose accessories',
      fieldName: 'accessories',
      options: [
        {
          label: 'Station Setup - Removable Locate',
          checkbox: true,
          fieldName: 'stationSetupRemovableLocate',
          description:
            'The station setup tool provides a convenient and repeatable mechanism for safely locking a shuttle in position, while motor power is not present, for the purpose of aligning station tooling to the application-specific shuttle tooling/product fixture. The removable locate is a part of the station setup tool which works together with stationary mount. The removable locate can be installed on any stationary mount and is used to physically lock the shuttle into a repeatable position for station alignment. <b><i>Only one removable locate is required. Note - You also need to select stationary mounts if you are selecting removable locate</i></b>.',
        },
        {
          label: 'Station Setup - Stationary Mount',
          checkbox: true,
          fieldName: 'stationSetupStationaryLocate',
          description:
            'The stationary mount is a part of the station setup tool which works together with removable locate. It is installed in the T-slot on the top of any straight track section. Once installed, the stationary mount will generally remain on the track for the life of the system. <b><i>One stationary mount is required for each station where this type of alignment is needed</i></b>.',
        },
        {
          label: 'Shuttle Setup Tool Kit',
          checkbox: true,
          fieldName: 'palletSetupTool',
          description:
            'The shuttle setup tool kit provides a convenient and repeatable mechanism for calibrating the encoder strips on the shuttles back to the original factory-set zero position. <b><i>Typically, only one shuttle setup tool kit is required per customer site</i></b>.',
        },
        {
          label: 'Alignment Verification Tool',
          checkbox: true,
          fieldName: 'alignmentVerificationTool',
          description:
            'The alignment verification tool is required to validate that the track rails are properly aligned during system assembly. Of course, customers may be able to fabricate their own method of validating this alignment if they prefer. Our tool provides a readily available and convenient method and is one familiar to our team in the event they need to verify alignment while at a customer facility. <b><i>Typically, only one alignment verification tool is required per customer site</i></b>.',
        },
      ],
    },
  },
  {
    label: 'Additional Options',
    questions: {
      superTrakSync: {
        questionText: 'SuperTrak GEN3™ Sync software option',
        fieldName: 'includeOmniTrakLicense',
        options: [
          {
            label: 'Include SuperTrak GEN3™ Sync software option for Omnitrak™',
          },
        ],
        isEnabled: (
          userService: UserService,
          identityService: IdentityService
        ) => {
          return (
            userService.isUserFromCompany(UserCompanyName.LifeScience) ||
            !!identityService.currentUser?.hasRole(RoleEnum.SuperTrakSales)
          );
        },
      },
      academyCurricula: {
        questionText: 'SuperTrak Academy',
        fieldName: 'includeSuperTrakAcademy',
        options: [
          {
            label: 'Include SuperTrak Academy online curricula for 1 year',
          },
        ],
      },
      peopleToTrain: {
        questionText: 'Number of people to train',
        fieldName: 'superTrakAcademyTrainingCount',
        options: [
          {
            label: 'Number of people to train',
          },
        ],
      },
    },
  },
];
