<div class="flex h-28rem login-gateway">
  <div class="flex align-items-center w-5">
    <img alt="SuperTrak Conveyance logo"
         class="max-w-full"
         src="/assets/images/SuperTrakConveyance.png" />
  </div>
  <div class="w-1 h-full">
    <p-divider layout="vertical"></p-divider>
  </div>
  <div class="flex flex-column justify-content-center w-6">
    <form [formGroup]="loginForm"
          class="py-2">
      <div class="flex flex-column justify-content-center">
        <p class="text-l text-center">Please enter your email to begin.</p>
        <input id="email"
               formControlName="email"
               type="email"
               placeholder="Enter your email address..."
               class="email text-l w-full"
               pInputText>
        <div class="h-2rem py-1 text-base text-red-500">
          <ng-container *ngIf="loginForm.invalid && loginForm.dirty">
            *Please enter a valid email address.
          </ng-container>
        </div>
      </div>
      <div class="text-center"
           [ngClass]="{
              'flex': isExternalUser,
              'justify-content-between': isExternalUser
            }
      ">
        <button pButton
                *ngIf="!isExternalUser; else externalAuthTpl"
                class="login-button w-full text-l justify-content-center"
                [ngClass]="{
                  'brand-button': isInternalDomain
                }"
                type="button"
                [disabled]="loginForm.invalid || !loginForm.dirty"
                (click)="startSignIn()">
          {{isInternalDomain ?
          'Sign in with' :
          'Continue'}}
          <img *ngIf="isInternalDomain"
               class="w-3rem ml-2"
               src="/assets/images/ATS-Corp-white.png"
               alt="ATS Logo">
        </button>
        <ng-template #externalAuthTpl>
          <button pButton
                  class="login-button w-5 text-l"
                  type="button"
                  [disabled]="loginForm.invalid || !loginForm.dirty"
                  label="Sign In"
                  (click)="onLoginClick()"></button>
          <button pButton
                  class="login-button p-button-outlined w-5 text-l"
                  type="button"
                  [disabled]="loginForm.invalid || !loginForm.dirty"
                  label="Sign Up"
                  (click)="onNewUserClick()"></button>
        </ng-template>
      </div>
    </form>
  </div>

</div>
