import { FormControl, Validators } from '@angular/forms';
import { ProcessFunctionName } from '../enums/process-function.enum';
import { ProcessMap } from '../types/process-form.interface';

export const processMap: ProcessMap = {
  name: {
    title: 'Name',
    control: new FormControl<string>('', {
      nonNullable: true,
      validators: [Validators.required],
    }),
  },
  function: {
    title: 'Process icon',
    imgFormat: 'tall',
    description:
      '<i>Process icon</i> is an icon for graphical use only. It provides each process step with an icon that helps to visualize them on the track. This input does not impact the calculation of size nor of the performance of the system.',
    imgSrc: '/assets/images/process-map/function-icon.png',
    control: new FormControl<ProcessFunctionName>(
      ProcessFunctionName.AddInsert,
      {
        nonNullable: true,
        validators: [Validators.required],
      }
    ),
  },
  color: {
    title: 'Process color',
    control: new FormControl<string>('#1B4279', {
      nonNullable: true,
      validators: [Validators.required, Validators.min(0)],
    }),
  },
  width: {
    title: 'Width of process box',
    imgFormat: 'tall',
    description:
      '<i>Width of process box</i> is for graphical use only. It provides each process step with a box of the specified width (mm), that helps to visualize the process on the track. This input does not impact the calculation of size nor performance of the system, because each process step happens at a single point. The takt time of the process step and the transfer distance to the next process step are what matters for the calculation of system size and performance.',
    imgSrc: '/assets/images/process-map/width.png',
    control: new FormControl<number>(0, {
      nonNullable: true,
      validators: [
        Validators.required,
        Validators.min(0),
        Validators.max(10000),
      ],
    }),
  },
  distanceToNext: {
    title: 'Distance to next process',
    imgFormat: 'wide',
    description:
      'This is the distance (mm) between this process step and the next process step in your process map. This tool will use the distance between all process steps not only to calculate the size of the track, but also to calculate the time for shuttles to move from one process step to another and the system performance.',
    imgSrc: '/assets/images/process-map/distance-to-next.png',
    control: new FormControl<number>(0, {
      nonNullable: true,
      validators: [
        Validators.required,
        Validators.min(0),
        Validators.max(10000),
      ],
    }),
  },
  taktTime: {
    title: 'Process takt time',
    description:
      '<i>Process takt time</i> is the time it takes for a single process step to be executed, in ms. This tool will use the process takt time to calculate the system performance.<br><br>Important: process takt time is different from process cycle time, which is the time separating each shuttle arrival at this process. Process takt time is inherent to the process itself, but the same process may see shuttles arrive at slower cycle times than it could deliver based on other process takt times present on the system (case of under-utilization of the station). This tool uses process takt times as an input to calculate system performance and cycle times.<br><br>Note: You can setup parallel processes by dividing the process takt time by the number of parallel stations, and setting-up a larger travel distance to and from this process step.',
    control: new FormControl<number>(0, {
      nonNullable: true,
      validators: [
        Validators.required,
        Validators.min(0),
        Validators.max(100000),
      ],
    }),
  },
  placeProcessInsideTheTrack: {
    title: 'Place process inside the trak',
    control: new FormControl<boolean>(false, {
      nonNullable: true,
      validators: [Validators.required],
    }),
  },
  notes: {
    title: 'Notes',
    control: new FormControl<string | null>(null),
  },
};
