import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, catchError, map, Observable, throwError } from 'rxjs';
import { AccessScope } from 'src/app/configuration-management/enums/access-scope.enum';
import {
  TrackComment,
  TrackComponentInfo,
  TrackDetails,
} from 'src/app/configuration/models/track-details.interface';
import { TrackRequest } from 'src/app/configuration/models/track-request.model';
import { ApiUrls, FormatApiUrlParam } from 'src/app/core/api-helper';
import { ApiUrlParams } from 'src/app/core/api-url-params';
import { IApiResponse } from 'src/app/core/iapi-response';
import { IdentityService } from 'src/app/core/services/identity.service';
import { Track } from '../../models/track/track';

@Injectable({
  providedIn: 'root',
})
export class TrackService {
  private _track: TrackDetails;
  private tracks: Track[] = [];
  private _trackComments: BehaviorSubject<TrackComment[]> = new BehaviorSubject<
    TrackComment[]
  >([]);
  private tracksLoading$: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(true);
  tracks$ = new BehaviorSubject<Track[]>([]);
  tracksLoading = this.tracksLoading$.asObservable();
  trackComments: Observable<TrackComment[]> =
    this._trackComments.asObservable();

  get track(): TrackDetails {
    return this._track;
  }

  get isEmptyTrackPresent(): boolean {
    if (!this.tracks.length) {
      return true;
    }

    return !!this.tracks.find((track: Track) => !track.components.length);
  }

  constructor(
    private httpClient: HttpClient,
    private identityService: IdentityService
  ) {}

  getTracksForConfigVersion(versionId: string, accessScope = AccessScope.User) {
    const url = ApiUrls.allTracksByConfigurationVersionIdUrl.replace(
      FormatApiUrlParam(ApiUrlParams.VersionId),
      versionId
    );

    this.clearTracks();
    this.tracksLoading$.next(true);
    return this.httpClient
      .get<IApiResponse<Track[]>>(url, {
        params: {
          accessScope,
        },
      })
      .pipe(
        map((response: IApiResponse<Track[]>) => {
          const tracks = response.value.map((track) => {
            return new Track(
              track.id,
              track.name,
              track.productType,
              track.components,
              track.specification
            );
          });
          this.tracks = tracks;
          this.tracks$.next(this.tracks.slice());
          this.tracksLoading$.next(false);
        }),
        catchError((error) => {
          console.error(error);
          this.tracksLoading$.next(false);
          return throwError(() => error);
        })
      );
  }

  clearTracks(): void {
    this.tracks = [];
    this.tracks$.next([]);
  }

  createNewTrack(configurationVersionId: string, newTrack: TrackRequest) {
    const url = ApiUrls.createNewTrack.replace(
      FormatApiUrlParam(ApiUrlParams.VersionId),
      configurationVersionId
    );

    return this.httpClient.post<IApiResponse<Track>>(url, newTrack).pipe(
      map((response) => {
        const trackResponse = response.value;
        const track = new Track(
          trackResponse.id,
          trackResponse.name,
          trackResponse.productType,
          trackResponse.components,
          trackResponse.specification || null
        );
        this.tracks.push(track);
        this.tracks$.next(this.tracks.slice());
        return response.value;
      }),
      catchError((error) => {
        console.error(error);
        return throwError(() => error);
      })
    );
  }

  removeTrack(trackId: string, index: number) {
    const url = ApiUrls.removeTrack.replace(
      FormatApiUrlParam(ApiUrlParams.TrackId),
      trackId
    );

    return this.httpClient.delete<IApiResponse<Track>>(url).pipe(
      map(() => {
        this.tracks.splice(index, 1);
        this.tracks$.next(this.tracks.slice());
        return true;
      }),
      catchError((error) => {
        console.error(error);
        return throwError(() => error);
      })
    );
  }

  getTracks() {
    return this.tracks;
  }

  getTrackDetails(
    trackId: string,
    accessScope = AccessScope.User
  ): Observable<TrackDetails> {
    const url = ApiUrls.getTrackById.replace(
      FormatApiUrlParam(ApiUrlParams.TrackId),
      trackId
    );

    return this.httpClient
      .get<IApiResponse<TrackDetails>>(url, {
        params: {
          accessScope: accessScope,
        },
      })
      .pipe(
        map((response: IApiResponse<TrackDetails>) => {
          const trackDetails = response.value;
          // FIXME: Done for demo. Should be romved once corresponding functionality is implemented on the BE
          const componentsOrder = [
            'section',
            'shuttle',
            'power supply',
            'control panel',
            'interconnect',
            'controller',
            'base plate',
            'base frame',
          ];

          let sortedComponents: TrackComponentInfo[] = [];

          componentsOrder.forEach((componentKey) => {
            const restKeys = [...componentsOrder];
            const componentsToFilter = [...trackDetails.components];
            restKeys.splice(
              0,
              componentsOrder.findIndex((name) => name === componentKey) + 1
            );
            const filteredComponents: TrackComponentInfo[] =
              componentsToFilter.filter((component: TrackComponentInfo) => {
                let includesOtherNames = false;
                restKeys.forEach((key) => {
                  if (component.name.toLowerCase().includes(key)) {
                    includesOtherNames = true;
                    return;
                  }
                });
                return (
                  component.name.toLowerCase().includes(componentKey) &&
                  !includesOtherNames
                );
              });
            sortedComponents = [...sortedComponents, ...filteredComponents];
          });

          // adding missed components to already sorted | Should be removed eventually
          trackDetails.components.forEach((component) => {
            const isComponentSorted = sortedComponents.find(
              (sortedComponent) => {
                return sortedComponent.name === component.name;
              }
            );

            if (!isComponentSorted) {
              sortedComponents.push(component);
            }
          });

          trackDetails.components = sortedComponents;
          this._track = trackDetails;
          this._trackComments.next(this._track.comments);
          return trackDetails; // should return initial response from the server
        })
      );
  }

  getTrackById(
    trackId: string,
    accessScope = AccessScope.User
  ): Observable<Track> {
    const url = ApiUrls.trackById.replace(
      FormatApiUrlParam(ApiUrlParams.TrackId),
      trackId
    );

    return this.httpClient
      .get<IApiResponse<Track>>(url, {
        params: {
          accessScope,
        },
      })
      .pipe(
        map((response) => {
          const trackResponse = response.value;
          const index = this.tracks.findIndex((track) => track.id === trackId);

          if (index < 0) {
            this.tracks.push(trackResponse);
          } else {
            this.tracks[index] = trackResponse;
          }
          this.tracks$.next(this.tracks);

          return new Track(
            trackResponse.id,
            trackResponse.name,
            trackResponse.productType,
            trackResponse.components,
            trackResponse.specification || null
          );
        }),
        catchError((error) => {
          console.error(error);
          return throwError(() => error);
        })
      );
  }

  postTrackComment(trackId: string, text: string): Observable<TrackComment> {
    const url = ApiUrls.postTrackComment.replace(
      FormatApiUrlParam(ApiUrlParams.TrackId),
      trackId
    );

    return this.httpClient
      .post<IApiResponse<TrackComment>>(url, {
        text,
      })
      .pipe(
        map((response: IApiResponse<TrackComment>) => {
          this._trackComments.next([
            ...this._trackComments.value,
            response.value,
          ]);

          return response.value;
        })
      );
  }

  updateTrackComment(
    trackId: string,
    commentId: string,
    content: string
  ): Observable<TrackComment> {
    const url = ApiUrls.updateTrackCommentById
      .replace(FormatApiUrlParam(ApiUrlParams.TrackId), trackId)
      .replace(FormatApiUrlParam(ApiUrlParams.CommentId), commentId);

    return this.httpClient
      .put<IApiResponse<TrackComment>>(url, {
        text: content,
      })
      .pipe(
        map((response: IApiResponse<TrackComment>) => {
          const comments = this._trackComments.value;
          const indexToReplace = comments.findIndex(
            (comment) => comment.id === commentId
          );
          const replaceWith = response.value;

          comments.splice(indexToReplace, 1, replaceWith);
          this._trackComments.next(comments);

          return response.value;
        })
      );
  }

  deleteTrackComment(trackId: string, commentId: string): Observable<any> {
    const url = ApiUrls.deleteTrackCommentById
      .replace(FormatApiUrlParam(ApiUrlParams.TrackId), trackId)
      .replace(FormatApiUrlParam(ApiUrlParams.CommentId), commentId);

    return this.httpClient.delete(url).pipe(
      map((_) => {
        let comments = this._trackComments.value;
        comments = comments.filter((comment) => comment.id !== commentId);

        this._trackComments.next(comments);
      }),
      catchError((error) => {
        console.error(error);
        return throwError(() => error);
      })
    );
  }

  getTrackComments(trackId: string, text: string): Observable<TrackComment> {
    const url = ApiUrls.getTrackComments.replace(
      FormatApiUrlParam(ApiUrlParams.TrackId),
      trackId
    );

    return this.httpClient.get<TrackComment>(url);
  }
}
