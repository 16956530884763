export class Project {
  name: string;
  id: string;
  description: string;
  ownerId: string;
  image?: string;
  referenceNumber?: string;

  constructor(
    id: string,
    name: string,
    description: string,
    ownerId: string,
    image?: string,
    referenceNumber?: string
  ) {
    this.name = name;
    this.id = id;
    this.image = image;
    this.referenceNumber = referenceNumber;
    this.ownerId = ownerId;
    this.description = description;
  }
}
