import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AvatarModule } from 'primeng/avatar';
import { ButtonModule } from 'primeng/button';
import { DialogModule } from 'primeng/dialog';
import { DialogService, DynamicDialogModule } from 'primeng/dynamicdialog';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { TieredMenuModule } from 'primeng/tieredmenu';
import { AppMenuComponent } from '../layout/app.menu/app.menu.component';
import { AppMenuItemComponent } from '../layout/app.menuitem/app.menuitem.component';
import { AppSidebarComponent } from '../layout/app.sidebar/app.sidebar.component';
import { AppTopbarComponent } from '../layout/app.topbar/app.topbar.component';

@NgModule({
  declarations: [
    AppTopbarComponent,
    AppSidebarComponent,
    AppMenuComponent,
    AppMenuItemComponent,
  ],
  imports: [
    CommonModule,
    ProgressSpinnerModule,
    DialogModule,
    DynamicDialogModule,
    TieredMenuModule,
    AvatarModule,
    ButtonModule,
  ],
  providers: [DialogService],
  exports: [AppSidebarComponent, AppTopbarComponent],
})
export class SharedModule {}
