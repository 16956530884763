import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { AccordionModule } from 'primeng/accordion';
import { ConfirmationService } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { CheckboxModule } from 'primeng/checkbox';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { DialogModule } from 'primeng/dialog';
import { DividerModule } from 'primeng/divider';
import { DropdownModule } from 'primeng/dropdown';
import { FieldsetModule } from 'primeng/fieldset';
import { ImageModule } from 'primeng/image';
import { InputNumberModule } from 'primeng/inputnumber';
import { RadioButtonModule } from 'primeng/radiobutton';
import { RippleModule } from 'primeng/ripple';
import { StepsModule } from 'primeng/steps';
import { TrackAccessoriesComponent } from './components/track-accessories/track-accessories.component';
import { TrackAdditionalsComponent } from './components/track-additionals/track-additionals.component';
import { TrackControllerOptionsComponent } from './components/track-controller-options/track-controller-options.component';
import { TrackControllerComponent } from './components/track-controller/track-controller.component';
import { TrackFramesComponent } from './components/track-frames/track-frames.component';
import { TrackInterconnectComponent } from './components/track-interconnect/track-interconnect.component';
import { TrackShuttlesComponent } from './components/track-shuttles/track-shuttles.component';
import { TrackSizeComponent } from './components/track-size/track-size.component';
import { TrackTypeComponent } from './components/track-type/track-type.component';
import { TrackDesignerComponent } from './track-designer.component';

@NgModule({
  declarations: [
    TrackDesignerComponent,
    TrackTypeComponent,
    TrackSizeComponent,
    TrackFramesComponent,
    TrackControllerComponent,
    TrackShuttlesComponent,
    TrackInterconnectComponent,
    TrackAdditionalsComponent,
    TrackControllerOptionsComponent,
    TrackAccessoriesComponent,
  ],
  imports: [
    FieldsetModule,
    AccordionModule,
    DropdownModule,
    DividerModule,
    InputNumberModule,
    FormsModule,
    ReactiveFormsModule,
    RadioButtonModule,
    CommonModule,
    ButtonModule,
    RouterModule,
    RippleModule,
    StepsModule,
    ImageModule,
    CheckboxModule,
    ConfirmDialogModule,
    DialogModule,
  ],
  providers: [ConfirmationService],
})
export class TrackDesignerModule {}
