import { Container } from '@pixi/display';
import { Point } from '@pixi/math';
import { Sprite } from '@pixi/sprite';
import { Viewport } from 'pixi-viewport';
import { Observable, Subject } from 'rxjs';
import { ETurn } from 'src/app/common/enums/eTurn.enum';
import { TrackConceptDefinition } from '../services/track-creator/track-creator.service';
import { TrackRenderer } from './track-renderer';
export class Track {
  private viewport: Viewport;

  private trackType: ETurn;
  trackLength: number;
  trackWidth: number | null;

  private trackContainer: Container;
  private trackRenderer: TrackRenderer;

  private onSelect: Subject<boolean> = new Subject();
  onSelect$: Observable<boolean> = this.onSelect.asObservable();

  get trackChildren(): Container[] | undefined {
    return this.trackContainer.children as Container[];
  }

  get originPoint(): Point {
    const originpart = (this.trackContainer.children as Container[]).find(
      (container) => container.name === 'straight'
    )?.children[0] as Sprite;

    return originpart
      ? new Point(
          originpart.x - originpart.height / 2,
          originpart.y + originpart.width / 2
        )
      : new Point(0, 0);
  }

  constructor(viewport: Viewport) {
    this.viewport = viewport;
    this.createTrackContainer();
    this.trackRenderer = new TrackRenderer(this.trackContainer);
  }

  private createTrackContainer(): void {
    this.trackContainer = new Container();
    this.trackContainer.eventMode = 'dynamic';

    this.trackContainer.cursor = 'pointer';
    this.trackContainer.on('click', () => {
      this.onSelect.next(true);
    });

    this.viewport.addChild(this.trackContainer);
  }

  renderTrack(trackSpecs?: TrackConceptDefinition): Promise<void> {
    if (trackSpecs) {
      this.trackType = trackSpecs.eTurn;
      this.trackLength = trackSpecs.length;
      this.trackWidth = trackSpecs.width;
    }

    return this.trackRenderer.render(
      this.trackType,
      this.trackLength,
      this.trackWidth
    );
  }
}
