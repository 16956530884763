import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Question, QuestionOption } from '../../track-designer-questions';

@Component({
  selector: 'app-track-accessories',
  templateUrl: './track-accessories.component.html',
  styleUrls: ['./track-accessories.component.scss'],
})
export class TrackAccessoriesComponent implements OnInit {
  @Input() form: FormGroup;
  @Input() question: Question | undefined;

  currentOption: QuestionOption;
  descriptionVisible = false;

  constructor() {}

  ngOnInit(): void {
    this.question?.options.forEach((option) => {
      const checked = !!this.form.get(option.fieldName + 'Count')?.value;

      this.form.get(option.fieldName || '')?.setValue(checked);
    });
  }

  isOptionDisabeld(fieldName: string): boolean {
    return !!!this.form.get(fieldName)?.value;
  }

  toggleInputNumber(value: boolean, fieldName: string): void {
    this.form.get(`${fieldName + 'Count'}`)?.setValue(value ? 1 : 0);
  }

  isMultipleAccessory(accessoryName: string | undefined): boolean {
    return accessoryName === 'stationSetupStationaryLocate';
  }

  getAccessoryImage(imgName: string | undefined): string {
    if (!imgName) {
      return 'https://plchldr.co/i/500x250?text=Accessory%20Image';
    }
    return `/assets/images/track-designer/accessories/${imgName}.png`;
  }

  disableCheckboxFormValueChange(event: Event): void {
    event.preventDefault();
  }

  showDescription(option: QuestionOption): void {
    this.currentOption = option;
    this.descriptionVisible = true;
  }
}
