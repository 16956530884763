<h2>Tracks</h2>
<div class="flex flex-column flex-grow-1 overflow-auto">

  <ng-container *ngIf="!loadingTracks; else loadingTpl">
    <div class="flex flex-column p-card gap-3 p-3 bg-white"
         *ngIf="tracksList.length; else emptyTpl">
      <p-card styleClass="p-0"
              *ngFor="let track of tracksList">
        <div class="flex flex-row flex-wrap gap-4 align-items-center">
          <img [src]="track.imageUrl"
               class="w-10rem"
               alt="SuperTrak GEN3™ Logo">
          <img src="/assets/images/SuperTrakWithShuttle-2.PNG"
               class="w-10rem hidden md:block"
               alt="SuperTrak">
          <p class="flex-grow-1 text-lg m-0"> {{track.name}}</p>
          <div class="flex gap-2">
            <button pButton
                    type="button"
                    icon="pi pi-eye"
                    class="p-button-outlined p-ripple"
                    [pTooltip]="!track.components.length && !isOwner ? 'Track is empty' : 'View track'"
                    tooltipPosition="bottom"
                    [disabled]="!track.components.length && !isOwner"
                    (click)="viewTrack(track.id)"></button>
          </div>
        </div>
      </p-card>
    </div>
    <ng-template #emptyTpl>
      <div class="flex p-card p-3 bg-white">
        <p>There are no tracks in the configuration version</p>
      </div>
    </ng-template>
  </ng-container>
  <ng-template #loadingTpl>
    <div class="flex flex-grow-1 flex-column justify-content-center align-items-center">
      <p-progressSpinner></p-progressSpinner>
    </div>
  </ng-template>
</div>
