import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, map, Observable, throwError } from 'rxjs';
// import { Configuration } from 'src/app/configuration/models/configuration.model';
import { Configuration } from 'src/app/configuration/models/configuration.model';
import { IApiResponse } from 'src/app/core/iapi-response';
import { environment } from 'src/environments/environment';
import { AccessScope } from '../../enums/access-scope.enum';
import { ConfigurationsRequestParameters } from '../../interfaces/configurations-request-parameters.interface';
import { ConfigurationsSales } from '../../interfaces/configurations-sales.interface';

@Injectable({
  providedIn: 'root',
})
export class ConfigurationsApiService {
  private allConfigurations =
    'https://' + environment.potApiBaseUrl + '/api/configurations';

  constructor(private httpClient: HttpClient) {}

  getAllConfigurations(
    accessScope = AccessScope.User,
    parameters?: ConfigurationsRequestParameters
  ): Observable<ConfigurationsSales> {
    const url = this.allConfigurations;

    return this.httpClient
      .get<IApiResponse<ConfigurationsSales>>(url, {
        params: {
          accessScope,
          ...parameters,
        },
      })
      .pipe(
        map((response: IApiResponse<ConfigurationsSales>) => {
          response.value.items.map(
            ({
              id,
              name,
              description,
              productType,
              projectName,
              projectId,
              configurationVersions,
              owner,
              createdTime,
              updatedTime,
              ownerId,
            }) =>
              new Configuration(
                id,
                name,
                description,
                productType,
                projectName,
                projectId,
                configurationVersions,
                owner,
                createdTime,
                updatedTime,
                ownerId
              )
          );

          return response.value;
        }),
        catchError((error) => {
          console.error(error);
          return throwError(() => error);
        })
      );
  }
}
