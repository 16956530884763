import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { AppRoutes } from 'src/app/app.routes.const';
import { ETurn } from 'src/app/common/enums/eTurn.enum';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
  host: {
    class: 'flex flex-column flex-grow-1',
  },
})
export class DashboardComponent implements OnInit {
  trakSlides: ETurn[] = [
    ETurn.Curve500,
    ETurn.Curve500Low,
    ETurn.Curve800,
    ETurn.Curve90,
    ETurn.None,
  ];

  private readonly _destroying$ = new Subject<void>();

  constructor(private router: Router) {}

  ngOnInit(): void {}

  ngOnDestroy(): void {
    this._destroying$.unsubscribe();
  }

  openProject() {
    this.router.navigate([AppRoutes.Projects.fullPath()], {
      queryParams: {
        newProject: 'true',
      },
    });
  }
}
