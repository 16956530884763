<div class="layout-topbar">
  <button class="menu-button mr-3"
          type="button"
          (click)="toggleSidebar()"
          [ngClass]="{
    '_open': isSidebarExpanded
  }">
    <span class="menu-button__bar _bar-1"></span>
    <span class="menu-button__bar _bar-2"></span>
    <span class="menu-button__bar _bar-3"></span>
  </button>

  <a class="app-logo"
     routerLink="/">
    <img alt="app logo"
         [src]="logo" />
  </a>

  <div class="flex-1"></div>
  <div *ngIf="!isLoading">
    <div *ngIf="!isLoggedIn"
         class="topbar-profile">
      <button class="topbar-profile-button p-link"
              type="button"
              (click)="show()">
        <span class="profile-details">
          <span class="profile-name">Login or Register</span>
        </span>
      </button>
    </div>

    <div *ngIf="isLoggedIn && user != null"
         class="topbar-profile">
      <button type="button"
              pButton
              class="topbar-profile-button"
              (click)="menu.toggle($event)"
              autoZIndex>
        <p-avatar label="{{ accountInfo?.name?.charAt(0) || 'N'}}"
                  shape="square"
                  size="large">
        </p-avatar>
        <div class="profile-details">
          <div class="profile-name">
            {{ accountInfo?.name || "-- No Name --"}}
          </div>
          <div class="profile-job">{{ accountEmail }}</div>
        </div>
        <i class="pi pi-angle-down"></i>
      </button>
      <p-tieredMenu #menu
                    [popup]="true"
                    [model]="items">
      </p-tieredMenu>
    </div>
    <p-dialog [(visible)]="showBuildModal"
              [modal]="true"
              [draggable]="false">
      <div *ngIf="!loadingBuildModal"
           class="flex align-items-center justify-content-between flex-column"
           style="height: 420px">
        <div class="flex justify-content-center align-items-center"
             style="padding: 0px 0px 15px 0px;">
          <img src="{{logo}}">
        </div>
        <div class="modal-section flex align-items-center justify-content-center flex-grow-1">
          <h2 style="margin: 0px">Configuration Tool</h2>
        </div>
        <div class="modal-section-alignment flex align-items-center justify-content-center flex-column flex-grow-1">
          <div style="width: 100%;"
               class="flex justify-content-around">
            <div style="width: 45%"
                 class="flex justify-content-end"><b>Environment:</b></div>
            <div style="width: 45%"
                 class="flex justify-content-start">
              <p>{{env.appTitle}}</p>
            </div>
          </div>
          <div style="width: 100%;"
               class="flex justify-content-around">
            <div style="width: 45%"
                 class="flex justify-content-end"><b>Client Hash:</b></div>
            <div style="width: 45%"
                 class="flex justify-content-start">
              <p>{{version.hash}}</p>
            </div>
          </div>
          <div style="width: 100%;"
               class="flex justify-content-around">
            <div style="width: 45%"
                 class="flex justify-content-end"><b>Client Version:</b></div>
            <div style="width: 45%"
                 class="flex justify-content-start">
              <p>{{version.version}}</p>
            </div>
          </div>
          <div style="width: 100%;"
               class="flex justify-content-around">
            <div style="width: 45%"
                 class="flex justify-content-end"><b>Service Hash:</b></div>
            <div style="width: 45%"
                 class="flex justify-content-start">
              <p>Not Applicable</p>
            </div>
          </div>
          <div style="width: 100%;"
               class="flex justify-content-around">
            <div style="width: 45%"
                 class="flex justify-content-end"><b>Service Version:</b></div>
            <div style="width: 45%"
                 class="flex justify-content-start">
              <p>Not Applicable</p>
            </div>
          </div>
        </div>
        <div id="footer"
             class="flex flex-grow-1 justify-content-center flex-column"
             style="text-align: center;">
          Copyright: 2023, Other Information, Disclaimers, Terms...
        </div>
      </div>

      <div *ngIf="loadingBuildModal"
           style="height: 420px; width: 345px"
           class="flex align-items-center justify-content-center">
        <p-progressSpinner styleClass="w-4rem h-4rem"
                           strokeWidth="8"
                           fill="var(--surface-ground)"
                           animationDuration=".5s"></p-progressSpinner>
      </div>
    </p-dialog>
  </div>
