/* eslint-disable @typescript-eslint/no-unused-vars */

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { AppRoutes } from 'src/app/app.routes.const';
import { Role } from 'src/app/common/models/user/user';
import { UserDataService } from 'src/app/core/services/user-data.service';
import { UserService } from 'src/app/core/services/user.service';

@Injectable({
  providedIn: 'root',
})
export class EditUserResolver  {
  constructor(
    private userService: UserService,
    private userDataService: UserDataService,
    private router: Router
  ) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<Role[]> {
    if (!this.userService.currentUser) {
      this.router.navigate([AppRoutes.UserManagement.fullPath()]);
      return of([]);
    }

    // Resolve roles.
    const roles = this.userService.getRoles();

    if (roles.length === 0) {
      return this.userDataService.loadRoles();
    }

    return of(roles);
  }
}
