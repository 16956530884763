import { ProductType } from 'src/app/configuration/models/product-type';
import { Specification } from '../specification/Specification';
import { TrackComponent } from '../track-component/track-component';

export class Track {
  id: string;
  name: string;
  productType: ProductType;
  components: TrackComponent[];
  specification: Specification | null;
  imageUrl: string;

  constructor(
    id: string,
    name: string,
    productType: ProductType,
    components: TrackComponent[],
    specification: Specification | null
  ) {
    this.name = name;
    this.id = id;
    this.productType = productType;
    this.imageUrl = 'assets/images/logos/supertrak-logo.svg';
    this.components = components.map(
      (component) =>
        new TrackComponent(
          component.quantity,
          component.componentNumber,
          component.erpPartNumber,
          component.name,
          component.description,
          component.imageUrl,
          component.deliveryOptionsEnum
        )
    );
    this.specification = specification
      ? new Specification(
          specification.productType,
          specification.eTurn,
          specification.length,
          specification.width,
          specification.rmeSectionCount,
          specification.lowPowerSectionCount,
          specification.includeTrackSectionStands,
          specification.includeStandardWallMounts,
          specification.includeStandardBaseFrames,
          specification.shortETurnFrameCount,
          specification.shuttleCount,
          specification.shuttleMagnetCount,
          specification.additionalPowerSupplies,
          specification.additionalLowPowerSupplies,
          specification.includePowerSupplyWiring,
          specification.controlPanelInterconnectLength,
          specification.panelType,
          specification.controllerType,
          specification.controlPanelInputVoltage,
          specification.plcOptions,
          specification.includeExternalHighSpeedIo,
          specification.includeExternalEncoderSignal,
          specification.additionalEncoderSignalCount,
          specification.stationSetupRemovableLocateCount,
          specification.stationSetupStationaryLocateCount,
          specification.palletSetupToolCount,
          specification.alignmentVerificationToolCount,
          specification.includeSuperTrakAcademy,
          specification.superTrakAcademyTrainingCount,
          specification.includeOmniTrakLicense,
          specification.includeStandardIrPalletId,
          specification.isSymphoni
        )
      : null;
  }
}
