import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TableLazyLoadEvent } from 'primeng/table';
import { AppRoutes } from 'src/app/app.routes.const';
import { Role, RoleEnum, User } from 'src/app/common/models/user/user';
import { IdentityService } from 'src/app/core/services/identity.service';
import { UserDataService } from 'src/app/core/services/user-data.service';
import { UserService } from 'src/app/core/services/user.service';

@Component({
  selector: 'app-user-management',
  templateUrl: './user-management.component.html',
  styleUrls: ['./user-management.component.scss'],
})
export class UserManagementComponent implements OnInit {
  users: User[];
  roleEnum = RoleEnum;
  loading = true;
  displayUserEdit = false;
  userToEditIndex: number;

  constructor(
    private userService: UserService,
    private userDataService: UserDataService,
    private identityService: IdentityService,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    console.log('Init');
  }

  loadUsers(event: TableLazyLoadEvent) {
    this.loading = true;
    console.log('Lazy load event: ', event);

    this.userDataService.loadUsers().subscribe({
      next: (users) => {
        this.users = users;
      },
      error: (error) => {
        console.error(error);
      },
      complete: () => {
        this.loading = false;
      },
    });
  }

  editUser(index: number) {
    this.userService.currentUser = this.users[index];
    this.router.navigate([
      AppRoutes.EditUser.fullPath(this.userService.currentUser.id),
    ]);
  }

  getRoleName(role: Role): string {
    return role.name;
  }

  getRoleSeverity(
    name: string
  ): 'success' | 'info' | 'warning' | 'danger' | null | undefined {
    let res: 'success' | 'info' | 'warning' | 'danger' | null | undefined =
      'info';
    if (name === this.roleEnum.Admin) res = 'danger';
    else if (name === this.roleEnum.SuperAdmin) res = 'warning';

    return res;
  }

  isEditAvailable(user: User): boolean {
    return (
      !user.hasRole(this.roleEnum.SuperAdmin) ||
      this.identityService.isCurrentUser(user)
    );
  }
}
