import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { CardModule } from 'primeng/card';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextModule } from 'primeng/inputtext';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { RippleModule } from 'primeng/ripple';
import { SidebarModule } from 'primeng/sidebar';
import { TableModule } from 'primeng/table';
import { TagModule } from 'primeng/tag';
import { TooltipModule } from 'primeng/tooltip';
import { ProductTypePipe } from '../common/pipes/product-type/product-type.pipe';
import { ConfigurationVersionInfoComponent } from './components/configuration-version-info/configuration-version-info.component';
import { ConfigurationsListComponent } from './components/configurations-list/configurations-list.component';
import { TracksListComponent } from './components/tracks-list/tracks-list.component';

@NgModule({
  declarations: [
    ConfigurationsListComponent,
    TracksListComponent,
    ConfigurationVersionInfoComponent,
    ProductTypePipe,
  ],
  imports: [
    CommonModule,
    RippleModule,
    ButtonModule,
    TableModule,
    DropdownModule,
    TagModule,
    SidebarModule,
    CardModule,
    ProgressSpinnerModule,
    CalendarModule,
    InputTextModule,
    TooltipModule,
  ],
})
export class ConfigurationManagementModule {}
