export enum ProcessFunctionName {
  PnP = 'PnP',
  Vision = 'Vision',
  FillDispense = 'Fill/Dispense',
  Screw = 'Screw',
  Label = 'Label',
  Weld = 'Weld',
  Cure = 'Cure',
  Cut = 'Cut',
  PressCrimp = 'Press/Crimp',
  MoveRotate = 'Move/Rotate',
  TestPowerOn = 'Test/PowerOn',
  WeighScale = 'Weight/Scale',
  Load = 'Load',
  Unload = 'Unload',
  Reject = 'Reject',
  AddInsert = 'Add/Insert',
  Blank = 'Blank',
  Process = 'Process',
}
