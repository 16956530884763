<p-card (click)="selectVersion()"
        [styleClass]="isSelected ? '_selected' : ''"
        class="cursor-pointer shadow-4">
  <ng-template pTemplate="content">
    <div class="flex gap-4 align-items-center">
      <p-tag [value]="'Version ' + configurationVersion.version"
             severity="warning"></p-tag>
      <div class="flex-grow-1 h-3rem">
      </div>
      <!-- <p class="font-bold">{{ getStatusLabel(configurationVersion.rfqStatus) }}</p> -->
      <p-tag *ngIf="!isDraft"
             [styleClass]="getStatusColor(configurationVersion.rfqStatus).class"
             [value]="getStatusLabel(configurationVersion.rfqStatus)"></p-tag>
      <p-progressSpinner *ngIf="isLoading"
                         [style]="{width: '34px', height: '34px'}"
                         styleClass="custom-spinner"
                         strokeWidth="4"
                         animationDuration=".5s"></p-progressSpinner>
    </div>
  </ng-template>
</p-card>
