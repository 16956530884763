const ApiScopes = {
  local: {
    read: 'https://devsupertrakpot.onmicrosoft.com/a5d3a690-e279-4d4f-b379-5714b1987fc8/api.read',
    write:
      'https://devsupertrakpot.onmicrosoft.com/a5d3a690-e279-4d4f-b379-5714b1987fc8/api.write',
  },
  development: {
    read: 'https://devsupertrakpot.onmicrosoft.com/a5d3a690-e279-4d4f-b379-5714b1987fc8/api.read',
    write:
      'https://devsupertrakpot.onmicrosoft.com/a5d3a690-e279-4d4f-b379-5714b1987fc8/api.write',
  },
  preview: {
    read: 'https://supertrakpot.onmicrosoft.com/c42612d4-fb94-486a-8e64-6fed210d5fe4/api.read',
    write:
      'https://supertrakpot.onmicrosoft.com/c42612d4-fb94-486a-8e64-6fed210d5fe4/api.write',
  },
  production: {
    read: 'https://supertrakpot.onmicrosoft.com/a7ee0240-4bae-4c2d-92db-3c2b4154d3ba/api.read',
    write:
      'https://supertrakpot.onmicrosoft.com/a7ee0240-4bae-4c2d-92db-3c2b4154d3ba/api.write',
  },
};

export default ApiScopes;
