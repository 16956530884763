import ApiScopes from 'src/app/core/config/api-scopes';

export const environment = {
  production: true,
  appTitle: 'SuperTrak Config - Preview',
  potApiBaseUrl: 'prev-stservices.supertrakconveyance.com',
  msalConfig: {
    system: {
      tokenRenewalOffset: undefined,
    },
    auth: {
      clientId: 'a3911401-9a53-482f-944f-1a295642dcff',
    },
    b2cConfig: {
      policies: {
        signIn: {
          name: 'B2C_1A_Local_Idp_SignIn',
          authority:
            'https://supertrakpot.b2clogin.com/supertrakpot.onmicrosoft.com/B2C_1A_Local_Idp_SignIn',
        },
        editProfile: {
          name: 'b2c_1_profileeditingclientapp',
          authority:
            'https://supertrakpot.b2clogin.com/supertrakpot.onmicrosoft.com/b2c_1_profileeditingclientapp',
        },
        signUp: {
          name: 'b2c_1a_signup',
          authority:
            'https://supertrakpot.b2clogin.com/supertrakpot.onmicrosoft.com/b2c_1a_signup',
        },
      },
      authorityDomain: 'supertrakpot.b2clogin.com',
    },
  },
  apiConfig: {
    scopes: [ApiScopes.preview.read, ApiScopes.preview.write],
    uri: '',
  },
};
