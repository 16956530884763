import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import {
  ConfirmationService,
  MessageService,
  PrimeNGConfig,
} from 'primeng/api';
import { Subject, takeUntil } from 'rxjs';
import { AppRoutes } from '../app.routes.const';
import { ETurn } from '../common/enums/eTurn.enum';
import { Specification } from '../common/models/specification/Specification';
import { Track } from '../common/models/track/track';
import { TrackService } from '../common/services/track-services/track.service';
import {
  Configuration,
  ConfigurationVersion,
} from '../configuration/models/configuration.model';
import { ProductType } from '../configuration/models/product-type';
import { EditorModeService } from '../configuration/services/editor-mode/editor-mode.service';
import { ProjectConfigurationService } from '../configuration/services/project-configuration/project-configuration.service';
import { IdentityService } from '../core/services/identity.service';
import { NavigationHistoryService } from '../core/services/navigation-history/navigation-history.service';
import { ControlPanelInterconnectLength } from './enums/control-panel-interconnect-length.enum';
import { ControlPanelType } from './enums/control-panel-type.enum';
import { ControllerType } from './enums/controller-type.enum';
import { ShuttleMagnetCount } from './enums/shuttle-magnet-count.enum';
import { TrackDesignerService } from './services/track-designer/track-designer.service';
import { specSteps } from './track-designer-questions';

@Component({
  selector: 'app-track-designer',
  templateUrl: './track-designer.component.html',
  styleUrls: ['./track-designer.component.scss'],
  host: {
    class: 'flex flex-column flex-grow-1 h-full',
  },
})
export class TrackDesignerComponent implements OnInit, OnDestroy {
  track: Track;

  versionId: string;
  configId: string;
  projectId: string;

  currentConfiguration: Configuration;
  currentConfigVersion: ConfigurationVersion;

  eTurns = ETurn;
  controlPanel = ControlPanelType;
  controllerType = ControllerType;

  activeStep = 0;
  isNextVisible = true;

  loading = false;

  specQuestions = specSteps;

  specForm: FormGroup = new FormGroup({
    productType: new FormControl(
      ProductType.SuperTrakGen3,
      Validators.required
    ),
    eTurn: new FormControl(null, Validators.required),
    length: new FormControl(0, Validators.required),
    width: new FormControl(0, Validators.required),
    // rmeSectionCount: new FormControl(0, Validators.required), // Temprorary not used. Might be used later
    // lowPowerSectionCount: new FormControl(0, Validators.required), // Temprorary not used. Might be used later
    includeStandardBaseFrames: new FormControl(false, Validators.required),
    includeStandardWallMounts: new FormControl(false, Validators.required),
    includeTrackSectionStands: new FormControl(true, Validators.required),
    shortETurnFrameCount: new FormControl(null),
    panelType: new FormControl(this.controlPanel.Standard, Validators.required),
    controlPanelInputVoltage: new FormControl(null),
    // plcInterface: new FormControl(null),
    plcOptions: new FormGroup({
      communicationType: new FormControl(null),
      integratedProfinetSlave: new FormControl(true),
      plcCommInterfaceCount: new FormControl(0),
    }),
    shuttleMagnetCount: new FormControl(
      ShuttleMagnetCount.Two,
      Validators.required
    ),
    shuttleCount: new FormControl(1, Validators.required),
    controlPanelInterconnectLength: new FormControl(
      ControlPanelInterconnectLength.Interconnect2000,
      Validators.required
    ),
    includeExternalHighSpeedIo: new FormControl(false, Validators.required),
    includeExternalEncoderSignal: new FormControl(false),
    additionalEncoderSignalCount: new FormControl(0),
    // additionalPowerSupplies: new FormControl(0), // Temprorary not used. Might be used later
    // additionalLowPowerSupplies: new FormControl(0), // Temprorary not used. Might be used later
    // includePowerSupplyWiring: new FormControl(true), // Temprorary not used. Might be used later
    // controllerType: new FormControl(this.controllerType.I3), // Temprorary not used. Might be used later
    stationSetupRemovableLocateCount: new FormControl(0),
    stationSetupRemovableLocate: new FormControl(false),
    stationSetupStationaryLocateCount: new FormControl(0),
    stationSetupStationaryLocate: new FormControl(false),
    palletSetupToolCount: new FormControl(0),
    palletSetupTool: new FormControl(false),
    alignmentVerificationToolCount: new FormControl(0),
    alignmentVerificationTool: new FormControl(false),
    includeSuperTrakAcademy: new FormControl(false),
    includePowerSupplyWiring: new FormControl(null),
    superTrakAcademyTrainingCount: new FormControl(0),
    includeOmniTrakLicense: new FormControl(false),
    includeStandardIrPalletId: new FormControl(false),
    isSymphoni: new FormControl(false),
  });

  get isOverUnder(): boolean {
    return this.specForm.get('eTurn')?.value === ETurn.Curve500Low;
  }

  get trackType(): ETurn {
    return this.specForm.get('eTurn')?.value;
  }

  get isLoading(): boolean {
    return this.loading;
  }

  set isLoading(value: boolean) {
    this.loading = value;
  }

  get isInternalUser(): boolean {
    return this.identityService.currentUser?.isAts || false;
  }

  private readonly _destroying$ = new Subject<void>();

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private primengConfig: PrimeNGConfig,
    private trackService: TrackService,
    private projectConfigService: ProjectConfigurationService,
    private trackDesignerService: TrackDesignerService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private editorModeService: EditorModeService,
    private navHistoryService: NavigationHistoryService,
    private identityService: IdentityService
  ) {
    this.activatedRoute.queryParams
      .pipe(takeUntil(this._destroying$))
      .subscribe(({ versionId }) => {
        this.versionId = versionId;
      });

    this.activatedRoute.params
      .pipe(takeUntil(this._destroying$))
      .subscribe(({ trackId, configId }) => {
        this.configId = configId;

        const configuration =
          this.projectConfigService.configurations$.value.find(
            (configuration) => configuration.id === this.configId
          );

        if (configuration) {
          this.currentConfiguration = configuration;
        }

        const version = this.currentConfiguration.configurationVersions.find(
          (version) => version.id === this.versionId
        );

        if (version) {
          this.currentConfigVersion = version;
        }

        const projectId = this.currentConfiguration?.projectId;

        if (projectId) {
          this.projectId = projectId;
        }

        const track = this.trackService
          .getTracks()
          .find((item) => item.id === trackId);

        if (track) {
          this.track = track;
          if (this.track.specification) {
            this.populateTrack(this.track.specification);
          }
        }
      });

    this.editorModeService.onBehalfOfUser$
      .pipe(takeUntil(this._destroying$))
      .subscribe((user) => {
        if (!user) return;

        if (user && this.editorModeService.isEditorMode) {
          if (
            this.currentConfigVersion.createdById ===
            this.currentConfiguration.owner?.id
          ) {
            this.router.navigate(
              [AppRoutes.ProjectDetails.fullPath(this.projectId)],
              {
                queryParams: {
                  versionId: this.versionId,
                },
              }
            );
          }
        }
      });
  }

  ngOnInit(): void {
    this.primengConfig.ripple = true;
  }

  ngOnDestroy(): void {
    this._destroying$.unsubscribe();
  }

  populateTrack(specification: Specification): void {
    this.specForm.patchValue(specification);
  }

  prevQuestion(): void {
    this.activeStep--;
    this.updateButtonsVisibility();
  }

  updateButtonsVisibility(): void {
    this.isNextVisible = this.activeStep !== this.specQuestions.length - 1;
  }

  nextQuestion(): void {
    if (this.isNextDisabled()) return;
    this.activeStep++;
    this.updateButtonsVisibility();
  }

  isNextDisabled(): boolean {
    if (this.specQuestions[this.activeStep].question?.fieldName === 'size') {
      return !!(
        !this.specForm.get('width')?.valid &&
        !this.specForm.get('length')?.valid
      );
    }

    if (this.specQuestions[this.activeStep].label === 'Frames/Mounts') {
      return !!!this.specForm.get('includeTrackSectionStands')?.valid;
    }

    if (this.specQuestions[this.activeStep].label === 'Controller Options') {
      return !(
        !!this.specForm.get('controlPanelInputVoltage')?.valid &&
        this.specForm.get('plcOptions')?.get('communicationType')?.valid
      );
    }

    if (this.specQuestions[this.activeStep].label === 'Accessories') {
      return false;
    }

    let isValid = true;
    let fieldName;

    if (this.specQuestions[this.activeStep].question) {
      fieldName = this.specQuestions[this.activeStep].question?.fieldName;
      isValid = !this.specForm.get(fieldName || '')?.valid;
    }

    if (this.specQuestions[this.activeStep].questions) {
      for (const key in this.specQuestions[this.activeStep].questions) {
        if (!this.specForm.get(key)?.valid) {
          isValid = false;
          break;
        }
      }
    }

    return isValid;
  }

  isSubmitDisabled(): boolean {
    return this.specForm.invalid;
  }

  submitSpec(): void {
    if (this.isLoading) return;

    const specification = new Specification(
      this.specForm.value.productType,
      this.specForm.value.eTurn,
      this.specForm.value.length,
      this.specForm.value.width,
      this.specForm.value.rmeSectionCount,
      this.specForm.value.lowPowerSectionCount,
      this.specForm.value.includeTrackSectionStands,
      this.specForm.value.includeStandardWallMounts,
      this.specForm.value.includeStandardBaseFrames,
      this.specForm.value.shortETurnFrameCount,
      this.specForm.value.shuttleCount,
      this.specForm.value.shuttleMagnetCount,
      this.specForm.value.additionalPowerSupplies,
      this.specForm.value.additionalLowPowerSupplies,
      this.specForm.value.includePowerSupplyWiring,
      this.specForm.value.controlPanelInterconnectLength,
      this.specForm.value.panelType,
      this.specForm.value.controllerType,
      this.specForm.value.controlPanelInputVoltage,
      this.specForm.value.plcOptions,
      this.specForm.value.includeExternalHighSpeedIo,
      this.specForm.value.includeExternalEncoderSignal,
      this.specForm.value.additionalEncoderSignalCount,
      this.specForm.value.stationSetupRemovableLocateCount,
      this.specForm.value.stationSetupStationaryLocateCount,
      this.specForm.value.palletSetupToolCount,
      this.specForm.value.alignmentVerificationToolCount,
      this.specForm.value.includeSuperTrakAcademy,
      this.specForm.value.superTrakAcademyTrainingCount,
      this.specForm.value.includeOmniTrakLicense,
      this.specForm.value.includeStandardIrPalletId,
      this.specForm.value.isSymphoni
    );
    this.isLoading = true;
    this.trackDesignerService
      .submitSpecification(this.track.id, specification)
      .pipe(takeUntil(this._destroying$))
      .subscribe({
        next: (response) => {
          this.router.navigate(
            [AppRoutes.TrackDrilldown.fullPath(this.configId, this.track.id)],
            {
              queryParams: {
                projectId: this.projectId,
                versionId: this.versionId,
              },
            }
          );
          console.log('Created new track: ', response);
        },
        error: (error) => {
          this.isLoading = false;
          const errorMessage =
            error.status && (error.detail || error.title)
              ? error.status + ': ' + (error.detail || error.title)
              : 'Failed to submit the specification.';
          this.messageService.add({
            severity: 'error',
            summary: 'Error creating a new track',
            detail: errorMessage,
          });
          console.error(error);
        },
      });
  }

  onBack(): void {
    this.confirmationService.confirm({
      message:
        'Are you sure you want to leave the track designer? All the data you entered will be lost.',
      header: 'Leaving Track Designer',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.navHistoryService.back(
          AppRoutes.ProjectDetails.fullPath(this.projectId),
          {
            versionId: this.versionId,
          }
        );
      },
    });
  }
}
