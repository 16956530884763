import { ETurn } from 'src/app/common/enums/eTurn.enum';
import { ProductType } from 'src/app/configuration/models/product-type';
import { ControlPanelInputVoltage } from 'src/app/track-designer/enums/control-panel-input-voltage.enum';
import { ControlPanelInterconnectLength } from 'src/app/track-designer/enums/control-panel-interconnect-length.enum';
import { ControlPanelType } from 'src/app/track-designer/enums/control-panel-type.enum';
import { ControllerType } from 'src/app/track-designer/enums/controller-type.enum';
import { PlcCommunicationType } from 'src/app/track-designer/enums/plc-communication-type.enum';
import { ShortETurnFrameCount } from 'src/app/track-designer/enums/short-eturn-frame-count.enum';
import { ShuttleMagnetCount } from 'src/app/track-designer/enums/shuttle-magnet-count.enum';

interface PlcOptions {
  communicationType: PlcCommunicationType;
  integratedProfinetSlave: boolean;
  plcCommInterfaceCount: number;
}

export class Specification {
  productType: ProductType;
  eTurn: ETurn;
  length: number;
  width: number;
  rmeSectionCount: number;
  lowPowerSectionCount: number;
  includeTrackSectionStands: boolean;
  includeStandardWallMounts: boolean;
  includeStandardBaseFrames: boolean;
  shortETurnFrameCount: ShortETurnFrameCount;
  shuttleCount: number;
  shuttleMagnetCount: ShuttleMagnetCount;
  additionalPowerSupplies: number;
  additionalLowPowerSupplies: number;
  includePowerSupplyWiring: boolean; // Temporary not in use. Might be used later
  controlPanelInterconnectLength: ControlPanelInterconnectLength;
  panelType: ControlPanelType;
  controllerType: ControllerType;
  controlPanelInputVoltage: ControlPanelInputVoltage;
  plcOptions: PlcOptions;
  includeExternalHighSpeedIo: boolean;
  includeExternalEncoderSignal: boolean;
  additionalEncoderSignalCount: number;
  stationSetupRemovableLocateCount: number;
  stationSetupStationaryLocateCount: number;
  palletSetupToolCount: number;
  alignmentVerificationToolCount: number;
  includeSuperTrakAcademy: boolean;
  superTrakAcademyTrainingCount: number;
  includeOmniTrakLicense: boolean;
  includeStandardIrPalletId: boolean;
  isSymphoni: boolean;

  constructor(
    productType: ProductType,
    eTurn: ETurn,
    length: number,
    width: number,
    rmeSectionCount = 0,
    lowPowerSectionCount = 0,
    includeTrackSectionStands: boolean,
    includeStandardWallMounts: boolean,
    includeStandardBaseFrames: boolean,
    shortETurnFrameCount: ShortETurnFrameCount,
    shuttleCount: number,
    shuttleMagnetCount: ShuttleMagnetCount,
    additionalPowerSupplies = 0,
    additionalLowPowerSupplies = 0,
    includePowerSupplyWiring = true,
    controlPanelInterconnectLength: ControlPanelInterconnectLength,
    panelType: ControlPanelType,
    controllerType = ControllerType.I3,
    controlPanelInputVoltage: ControlPanelInputVoltage,
    plcOptions: PlcOptions,
    includeExternalHighSpeedIo: boolean,
    includeExternalEncoderSignal: boolean,
    additionalEncoderSignalCount: number,
    stationSetupRemovableLocateCount: number,
    stationSetupStationaryLocateCount: number,
    palletSetupToolCount: number,
    alignmentVerificationToolCount: number,
    includeSuperTrakAcademy: boolean,
    superTrakAcademyTrainingCount: number,
    includeOmniTrakLicense: boolean,
    includeStandardIrPalletId: boolean,
    isSymphoni: boolean
  ) {
    this.productType = productType;
    this.eTurn = eTurn;
    this.length = length;
    this.width = width;
    this.rmeSectionCount = rmeSectionCount;
    this.lowPowerSectionCount = lowPowerSectionCount;
    this.includeTrackSectionStands = includeTrackSectionStands;
    this.includeStandardWallMounts = includeStandardWallMounts;
    this.includeStandardBaseFrames = includeStandardBaseFrames;
    this.shortETurnFrameCount = shortETurnFrameCount;
    this.shuttleCount = shuttleCount;
    this.shuttleMagnetCount = shuttleMagnetCount;
    this.additionalPowerSupplies = additionalPowerSupplies;
    this.additionalLowPowerSupplies = additionalLowPowerSupplies;
    this.includePowerSupplyWiring = includePowerSupplyWiring;
    this.controlPanelInterconnectLength = controlPanelInterconnectLength;
    this.panelType = panelType;
    this.controllerType = controllerType;
    this.controlPanelInputVoltage = controlPanelInputVoltage;
    this.plcOptions = plcOptions;
    this.includeExternalHighSpeedIo = includeExternalHighSpeedIo;
    this.includeExternalEncoderSignal = includeExternalEncoderSignal;
    this.additionalEncoderSignalCount = additionalEncoderSignalCount;
    this.stationSetupRemovableLocateCount = stationSetupRemovableLocateCount;
    this.stationSetupStationaryLocateCount = stationSetupStationaryLocateCount;
    this.palletSetupToolCount = palletSetupToolCount;
    this.alignmentVerificationToolCount = alignmentVerificationToolCount;
    this.includeSuperTrakAcademy = includeSuperTrakAcademy;
    this.superTrakAcademyTrainingCount = superTrakAcademyTrainingCount;
    this.includeOmniTrakLicense = includeOmniTrakLicense;
    this.includeStandardIrPalletId = includeStandardIrPalletId;
    this.isSymphoni = isSymphoni;
  }
}
