import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MsalService } from '@azure/msal-angular';
import { AccountInfo } from '@azure/msal-browser';
import { MessageService } from 'primeng/api';
import { AppRoutes } from 'src/app/app.routes.const';
import { Role, User } from 'src/app/common/models/user/user';
import { AppLoadingService } from 'src/app/core/services/app-loading.service';
import { IdentityService } from 'src/app/core/services/identity.service';
import { UserDataService } from 'src/app/core/services/user-data.service';
import { UserService } from 'src/app/core/services/user.service';
import { environment } from 'src/environments/environment';
import { TestingService } from '../../services/testing/testing.service';
import { CompaniesList } from '../constants/companies';

@Component({
  selector: 'app-edit-user',
  templateUrl: './edit-user.component.html',
  styleUrls: ['./edit-user.component.scss'],
})
export class EditUserComponent implements OnInit {
  isLoading = true;
  roles: Role[] = [];
  selectedRoles: Role[] = [];
  user: User;
  accountInfo: AccountInfo;
  isSaving: boolean;

  companiesList = CompaniesList;
  selectedCompany: any;

  get isCurrentUser(): boolean {
    return this.identityService.isCurrentUser(this.user);
  }

  get isProduction(): boolean {
    return environment.production;
  }

  get isInternalUser(): boolean {
    return this.user.isAts;
  }

  constructor(
    private msalService: MsalService,
    private userService: UserService,
    private userDataService: UserDataService,
    private identityService: IdentityService,
    private testingService: TestingService,
    private messageService: MessageService,
    private router: Router,
    private appLoadingService: AppLoadingService
  ) {
    if (this.userService.currentUser == null) {
      throw new Error('User not defined.');
    }

    this.user = this.userService.currentUser;
    this.roles = this.userService.getRoles();
    this.selectedRoles = this.user?.roles ?? [];
  }

  ngOnInit(): void {
    const accountInfo = this.msalService.instance.getActiveAccount();
    if (accountInfo !== null) {
      this.accountInfo = accountInfo;
    }

    this.getCurrentUserCompany();
  }

  submitRoles() {
    this.isSaving = true;
    this.appLoadingService.startLoadingBar();
    this.userDataService
      .updateUserRoles(this.user, this.selectedRoles)
      .subscribe({
        next: () => {
          this.router.navigate([AppRoutes.UserManagement.fullPath()]);
        },
        error: (error) => {
          this.messageService.add({
            severity: 'error',
            summary: 'Error submitting roles.',
            detail: error.status + ': ' + error.title,
          });
          this.appLoadingService.stopLoadingBar();
        },
        complete: () => {
          this.appLoadingService.stopLoadingBar();
        },
      });
  }

  cancel() {
    this.router.navigate([AppRoutes.UserManagement.fullPath()]);
  }

  getCurrentUserCompany(): void {
    if (this.testingService.companyName) {
      this.selectedCompany = this.testingService.companyName;
      return;
    }

    if (this.accountInfo.idTokenClaims) {
      this.selectedCompany = this.accountInfo.idTokenClaims['companyName'];
      return;
    }
  }

  resetCompanyName(): void {
    this.testingService.resetCompanyName();
    this.selectedCompany = this.accountInfo.idTokenClaims
      ? this.accountInfo?.idTokenClaims['companyName']
      : null;

    this.messageService.add({
      severity: 'info',
      summary: 'Company name was reseted to default value',
    });
  }

  saveCompanyName(): void {
    this.testingService.companyName = this.selectedCompany;

    this.messageService.add({
      severity: 'info',
      summary: 'Company name was overriden',
    });
  }
}
