<ng-container *ngIf="!isLoadingTracks">
  <div *ngIf="currentConfigurationVersion"
       class="flex flex-column p-card bg-white p-3">

    <div class="flex align-items-center">

      <h5 class="flex flex-grow-1 m-0">
        Version Summary
      </h5>
      <div class="flex gap-2">
        <button *ngIf="isDraft && !editingMode"
                pButton
                pRipple
                type="button"
                label="Request a quote"
                (click)="requestQuote()"></button>
        <button pButton
                pRipple
                type="button"
                class="p-button-outlined"
                icon="pi pi-clone"
                pTooltip="Make a copy"
                (click)="cloneConfiguration()"></button>
        <button pButton
                pRipple
                type="button"
                class="p-button-outlined p-button-danger"
                icon="pi pi-trash"
                pTooltip="Delete"
                *ngIf="canDelete"
                (click)="onDeleteConfigurationVersion()"></button>
      </div>
    </div>
    <ul class="list-none p-0">
      <li class="configuration-info__item flex justify-content-between align-items-center py-2 border-bottom-1">
        <span>Id:</span>
        <span>{{ currentConfigurationVersion.id }}</span>
      </li>
      <li class="configuration-info__item flex justify-content-between align-items-center py-2 border-bottom-1">
        <span>Version Number:</span>
        <p-tag [value]="'Version ' + currentConfigurationVersion.version"
               severity="warning"></p-tag>
      </li>
      <li class="configuration-info__item flex justify-content-between align-items-center py-2 border-bottom-1">
        <span>Created By:</span>
        <ng-container *ngIf="!editingMode; else userDetailsTmpl">
          <span *ngIf="isVersionCreatedByOwner; else createdBySalesTmpl">Owner</span>
          <ng-template #createdBySalesTmpl>
            <span class="inline-flex align-items-center">SuperTrak&trade; Sales
            </span>
          </ng-template>
        </ng-container>
        <ng-template #userDetailsTmpl>
          <span *ngIf="isVersionCreatedByOwner; else createdBySalesTmpl">Owner</span>
          <ng-template #createdBySalesTmpl>
            <span class="inline-flex align-items-center">SuperTrak&trade; Sales
              <ng-container *ngIf="configVersionOwnerDetails; else ownerDetailsLoader">
                - {{ configVersionOwnerDetails.fullName}}
              </ng-container>
              <ng-template #ownerDetailsLoader>
                <p-progressSpinner [style]="{width: '18px', height: '18px'}"
                                   styleClass="custom-spinner ml-3"
                                   strokeWidth="4"
                                   animationDuration=".5s"></p-progressSpinner>
              </ng-template>
            </span>
          </ng-template>
        </ng-template>
      </li>
      <li class="configuration-info__item flex justify-content-between align-items-center py-2 border-bottom-1">
        <span>Product Type:</span>
        <span>{{ transformProductTypeName(currentConfigurationVersion.productType) }}</span>
      </li>
      <li class="configuration-info__item flex justify-content-between align-items-center py-2 border-bottom-1">
        <span>Status:</span>
        <div class="inline-flex align-items-center">
          <p-tag [style]="{ 'background-color': getStatusColor(selectedVersionStatus).color }"
                 *ngIf="!isVersionStatusEditable; else versionStatusEditorTmpl"
                 [value]="getStatusLabel(selectedVersionStatus)"></p-tag>
          <ng-template #versionStatusEditorTmpl>
            <p-dropdown [options]="versionStatusList"
                        optionLabel="label"
                        optionValue="value"
                        [disabled]="updatingVersionStatus"
                        (onChange)="changeVersionRfqStatus($event)"
                        [styleClass]="getStatusColor(selectedVersionStatus).class"
                        class="configuration-info__status-selector">
              <ng-template pTemplate="selectedItem">
                <div class="flex flex-column justify-content-center">
                  <p-progressSpinner *ngIf="updatingVersionStatus"
                                     [style]="{width: '1.2rem', height: '1.2rem'}"
                                     styleClass="custom-spinner"
                                     class="inline-flex align-items-center"
                                     strokeWidth="4"
                                     animationDuration=".5s">
                  </p-progressSpinner>
                </div>
                <span *ngIf="!updatingVersionStatus">{{ getStatusLabel(selectedVersionStatus) }}</span>
              </ng-template>
              <ng-template let-item
                           pTemplate="item">
                <p-tag [style]="{ 'background-color': getStatusColor(item.value).color }"
                       [value]="item.label"></p-tag>
              </ng-template>
            </p-dropdown>
          </ng-template>
        </div>
      </li>
      <li *ngIf="currentConfigurationVersion.requestedByDate && !isDraft"
          class="configuration-info__item flex justify-content-between align-items-center py-2 border-bottom-1">
        <span>Requested By Date:</span>
        <span class="inline-flex align-items-center"
              *ngIf="!isEditingDueDate; else dueDateEditingTpl">
          <p-button icon="pi pi-pencil"
                    styleClass="mr-1"
                    *ngIf="editingMode"
                    [text]="true"
                    (onClick)="isEditingDueDate = true"
                    [outlined]="true">
          </p-button>
          {{ currentConfigurationVersion.requestedByDate | date : 'dd-MMM-yyyy' }}

        </span>
        <ng-template #dueDateEditingTpl>
          <div class="inline-flex align-items-center">
            <ng-container *ngIf="isDateChanged()">
              <ng-container *ngIf="!isProcessingDueDate; else loadingTpl">
                <p-button icon="pi pi-check"
                          severity="success"
                          (onClick)="updateRequestDate()"
                          [text]="true"
                          [loading]="isProcessingDueDate"
                          [outlined]="true">
                </p-button>
                <p-button icon="pi pi-times"
                          *ngIf="!isProcessingDueDate"
                          severity="danger"
                          (onClick)="cancelRequestDateChange()"
                          [text]="true"
                          [outlined]="true">
                </p-button>
              </ng-container>
              <ng-template #loadingTpl>
                <p-progressSpinner [style]="{width: '18px', height: '18px'}"
                                   styleClass="custom-spinner mr-2"
                                   strokeWidth="4"
                                   animationDuration=".5s">
                </p-progressSpinner>
              </ng-template>
            </ng-container>
            <p-calendar id="requestedByDate"
                        [minDate]="minDate"
                        [maxDate]="maxDate"
                        dateFormat="dd-M-yy"
                        [(ngModel)]="selectedDate">
            </p-calendar>
          </div>
        </ng-template>
      </li>
    </ul>
    <p-accordion class="w-full"
                 *ngIf="!isDraft">
      <p-accordionTab>
        <ng-template pTemplate="header">
          <div class="flex align-items-center">
            <span class="vertical-align-middle">Comments</span>
          </div>
        </ng-template>
        <ng-template pTemplate="content">
          <div class="flex flex-column">
            <div class="col-12 pb-2 mb-2"
                 *ngIf="currentConfigurationVersion.rfqComment">
              <p class="font-bold mb-2">User Comment:</p>
              <p class="inline-flex align-items-center"
                 [innerText]="currentConfigurationVersion.rfqComment">
              </p>
            </div>

            <div class="col-12 flex flex-column">
              <div class="flex w-full">
                <div class="flex flex-grow-1 flex-column">
                  <p class="font-bold mb-2">Sales Comment:</p>
                  <p *ngIf="!isSalesUser || (isSalesUser && !isEditingSalesComment)"
                     [innerText]="currentConfigurationVersion.salesComment || 'None'"></p>
                </div>
                <p-button icon="pi pi-pencil"
                          *ngIf="isSalesUser && !isEditingSalesComment"
                          (onClick)="isEditingSalesComment = true"
                          [text]="true"></p-button>
              </div>

              <ng-container *ngIf="isEditingSalesComment">
                <textarea pInputTextarea
                          class="w-full mb-2"
                          [(ngModel)]="salesComment"
                          [rows]="3"
                          [disabled]="isSavingSalesComment"
                          [maxLength]="10000"
                          [autoResize]="true"></textarea>
                <div *ngIf="isSalesCommentEdited"
                     class="flex justify-content-end">
                  <p-button icon="pi pi-check"
                            severity="success"
                            [loading]="isSavingSalesComment"
                            (onClick)="saveComment()"
                            ariaLabel="Save changes"
                            [text]="true"></p-button>
                  <p-button icon="pi pi-times"
                            *ngIf="!isSavingSalesComment"
                            severity="danger"
                            (onClick)="declineComment()"
                            ariaLabel="Decline changes"
                            [text]="true"></p-button>
                </div>
              </ng-container>
            </div>
          </div>

        </ng-template>
      </p-accordionTab>
    </p-accordion>
  </div>

  <ng-container *ngIf="versionId; else noSelectedVersionTmpl">
    <div class="flex align-items-center">
      <h2 class="flex flex-grow-1 m-0">
        Tracks
      </h2>
      <p-button label="Create Track"
                *ngIf="isVersionEditable && (!editingMode || editableForSales)"
                icon="pi pi-plus"
                iconPos="left"
                styleClass="p-button-outlined _light-blue"
                (onClick)="onShowCreateTrack()">
      </p-button>
    </div>

    <div class="flex flex-column p-card gap-3 bg-white p-3">
      <ng-container *ngIf="tracks.length; else emptyBlock">
        <p-card *ngFor="let track of tracks; let i = index"
                styleClass="p-0">
          <div class="flex flex-row flex-wrap gap-4 align-items-center">
            <img [src]="track.imageUrl"
                 class="w-10rem"
                 alt="SuperTrak GEN3™ Logo">
            <img src="/assets/images/SuperTrakWithShuttle-2.PNG"
                 class="w-10rem hidden md:block"
                 alt="SuperTrak">
            <p class="flex-grow-1 text-lg m-0"> {{track.name}}</p>
            <div class="flex gap-2">
              <ng-container *ngIf="isTrackEditAvailable; else viewTrackTmpl">
                <button pButton
                        type="button"
                        icon="pi pi-pencil"
                        class="p-button-outlined p-ripple"
                        pTooltip="Edit track"
                        tooltipPosition="bottom"
                        (click)="onViewTrack(track)"></button>
                <button pButton
                        type="button"
                        icon="pi pi-trash"
                        class="p-button-outlined p-button-danger p-ripple"
                        pTooltip="Delete track"
                        tooltipPosition="bottom"
                        *ngIf="canDelete"
                        (click)="onDeleteTrack(track, i)"></button>
              </ng-container>
              <ng-template #viewTrackTmpl>
                <button pButton
                        type="button"
                        icon="pi pi-eye"
                        class="p-button-outlined p-ripple"
                        [pTooltip]="isViewTrackAvailable(track) ? 'Track is empty' : 'View track'"
                        tooltipPosition="bottom"
                        [disabled]="isViewTrackAvailable(track)"
                        (click)="onViewTrack(track)"></button>
              </ng-template>
            </div>
          </div>
        </p-card>
      </ng-container>

      <ng-template #emptyBlock>
        <div class="flex flex-column align-content-center align-items-center ">
          <p class="font-semibold">There are no tracks in your configuration.</p>
        </div>
      </ng-template>
    </div>

  </ng-container>

  <ng-template #noSelectedVersionTmpl>
    <div class="flex flex-column p-card gap-3 bg-white p-3">
      <div class="flex flex-column align-content-center align-items-center ">
        <p class="font-semibold">Create or select a version to see your tracks.</p>
      </div>
    </div>
  </ng-template>
</ng-container>

<!-- Create track modal -->
<p-dialog header="Header"
          [(visible)]="isNewTrackFormVisible"
          [modal]="true"
          [style]="{ width: '50vw' }"
          [draggable]="false"
          [resizable]="false"
          position="top"
          (onHide)="onCancelNewTrack()">
  <ng-template pTemplate="header">
    <span class="text-xl font-bold">Create a New Track</span>
  </ng-template>
  <!-- Name Input -->
  <form [formGroup]="createTrackForm"
        autoComplete="off"
        class="m-auto w-6">
    <div class="flex flex-column gap-2 mb-4">
      <label htmlFor="name">Name</label>
      <input pInputText
             id="name"
             name="name"
             formControlName="name"
             type="text"
             autoComplete="off"
             [class.ng-invalid]="createTrackForm.controls.name.invalid"
             [class.ng-dirty]="createTrackForm.controls.name.dirty">
      <div *ngIf="createTrackForm.controls.name.invalid && createTrackForm.touched"
           class="text-red-500">
        <p *ngIf="createTrackForm.controls.name.errors?.['required']">* A name is required.</p>
        <p *ngIf="createTrackForm.controls.name.errors?.['maxlength']">* The name is too long. Max 200.
          characters.
        </p>
        <p *ngIf="createTrackForm.controls.name.errors?.['minlength']">* The name is too short. Min 5.
          characters.
        </p>
        <p *ngIf="createTrackForm.controls.name.errors?.['duplicateName']">* The track name already
          exists. Please use a different name.</p>
      </div>
    </div>
    <!-- Description Input -->
    <div class="flex flex-column gap-2">
      <label htmlFor="description">Description</label>
      <input pInputText
             id="description"
             formControlName="description"
             name="description"
             type="text"
             autoComplete="off"
             [class.ng-invalid]="createTrackForm.controls.description.invalid"
             [class.ng-dirty]="createTrackForm.controls.description.dirty">
      <div *ngIf="createTrackForm.controls.description.invalid && createTrackForm.touched"
           class="h-1rem text-red-500">
        <p *ngIf="createTrackForm.controls.description.errors?.['required']">* A description is required.</p>
        <p *ngIf="createTrackForm.controls.description.errors?.['maxlength']">* The name is too long. Max 200.
          characters.
        </p>
      </div>
    </div>
  </form>
  <ng-template pTemplate="footer">
    <p-button label="Cancel"
              icon="pi pi-times"
              styleClass="p-button-outlined p-button-secondary"
              (click)="onCancelNewTrack()"></p-button>
    <p-button icon="pi pi-check"
              [loading]="isLoading"
              label="Create"
              (click)="onSubmitNewTrack()"></p-button>
  </ng-template>
</p-dialog>
