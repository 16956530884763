import { Component, Input, OnInit } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { AppLoadingService } from 'src/app/core/services/app-loading.service';
import { AppLayoutService, SideBarState } from '../service/app.layout.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './app.sidebar.component.html',
  styleUrls: ['./app.sidebar.component.scss'],
})
export class AppSidebarComponent implements OnInit {
  @Input() collapsed: boolean;

  isLoading = false;

  private sideBarState: SideBarState;
  private readonly _destroying$ = new Subject<void>();

  get isExpanded(): boolean {
    return this.sideBarState === SideBarState.Expanded;
  }

  get isCollapsed(): boolean {
    return this.sideBarState === SideBarState.Collapsed;
  }

  constructor(
    private appLoadingService: AppLoadingService,
    private layoutService: AppLayoutService
  ) {
    this.appLoadingService.isLoading$
      .pipe(takeUntil(this._destroying$))
      .subscribe((res) => {
        this.isLoading = res;
      });

    this.layoutService.sidebarState$
      .pipe(takeUntil(this._destroying$))
      .subscribe((state) => {
        this.sideBarState = state;
      });
  }

  ngOnInit(): void {
    if (this.collapsed && !this.isCollapsed) {
      this.layoutService.setSidebarState(SideBarState.Collapsed);
    }

    if (!this.collapsed && this.isCollapsed) {
      this.layoutService.setSidebarState(SideBarState.Slim);
    }
  }

  ngOnDestroy(): void {
    this._destroying$.unsubscribe();
  }
}
