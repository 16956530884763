<!-- <div class="layout-container" [ngClass]="containerClass"> -->
<div class="layout-container layout-slim layout-light">
  <app-topbar (onSidebarToggle)="toggleSideBar()"></app-topbar>
  <div class="flex">
    <app-sidebar></app-sidebar>
    <div class="layout-content-wrapper flex-grow-1">
      <app-progress-bar class="fixed w-full"></app-progress-bar>

      <div class="layout-content"
           #layoutWrapper
           [ngClass]="{
          'editor-mode': editorMode,
        }">
        <app-editor-panel *ngIf="editorMode"></app-editor-panel>
        <div *ngIf="!isLoading"
             class="layout-content-inner"
             [ngClass]="{
              'max-w-full': wideScreen
              }">
          <!-- <app-breadcrumb></app-breadcrumb> -->
          <router-outlet></router-outlet>
          <!-- <app-footer></app-footer> -->
        </div>
        <div *ngIf="isLoading"
             class="layout-content-inner-spinner">
          <p-progressSpinner></p-progressSpinner>
        </div>
      </div>
    </div>
  </div>
  <!-- <app-config></app-config> -->
</div>
<p-toast position="top-right"></p-toast>

<p-dialog class="help-dialog text-center"
          [(visible)]="isHelpModalVisible"
          [resizable]="false"
          [draggable]="false"
          [modal]="true">
  <p class="mt-3 mb-0">If you need help with the application or anything regarding SuperTrak CONVEYANCE™, please send us
    your comments and questions to</p>
  <p class="block mb-3">
    <a href="mailto:team@supertrakconveyance.com">team@supertrakconveyance.com</a>.
  </p>
  <p class="mb-0">Your feedback is greatly appreciated.</p>
</p-dialog>
