export class TrackComponent {
  quantity: number;
  componentNumber: string;
  erpPartNumber: string;
  name: string;
  description: string;
  imageUrl: string;
  deliveryOptionsEnum: number;

  constructor(
    quantity: number,
    componentNumber: string,
    erpPartNumber: string,
    name: string,
    description: string,
    imageUrl: string,
    deliveryOptionsEnum: number
  ) {
    this.quantity = quantity;
    this.componentNumber = componentNumber;
    this.erpPartNumber = erpPartNumber;
    this.name = name;
    this.description = description;
    this.imageUrl = imageUrl;
    this.deliveryOptionsEnum = deliveryOptionsEnum;
  }
}
