import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Specification } from 'src/app/common/models/specification/Specification';
import { ApiUrls, FormatApiUrlParam } from 'src/app/core/api-helper';
import { ApiUrlParams } from 'src/app/core/api-url-params';

@Injectable({
  providedIn: 'root',
})
export class TrackDesignerService {
  constructor(private http: HttpClient) {}

  submitSpecification(
    trackId: string,
    specification: Specification
  ): Observable<any> {
    const url = ApiUrls.createSpecification.replace(
      FormatApiUrlParam(ApiUrlParams.TrackId),
      trackId
    );

    return this.http.put(url, specification);
  }
}
