<div class="editor-panel flex flex-row align-items-center justify-content-center bg-blue-500 py-2 text-white font-bold">
  <ng-container *ngIf="user && !isLoading; else retrievingTpl">
    <p class="editor-panel__text m-0">Editing for {{ user.fullName || '(empty)' }}</p>
    <button pButton
            class="editor-panel__btn p-button-outlined p-button-rounded text-white ml-3"
            type="button"
            icon="pi pi-info"
            pTooltip="User info"
            (click)="showUserInfo()">
    </button>
  </ng-container>
  <ng-template #retrievingTpl>
    <p-progressSpinner [style]="{width: '18px', height: '18px'}"
                       styleClass="custom-spinner"
                       strokeWidth="4"
                       animationDuration=".5s"></p-progressSpinner>
  </ng-template>
</div>

<p-dialog [(visible)]="userInfoShown"
          [modal]="true"
          [resizable]="false"
          [draggable]="false"
          position="bottom">
  <ng-template pTemplate="content">
    <div class="flex">
      <p class="flex align-items-center text-3xl font-bold m-0">{{ user.fullName || '(empty)' }}</p>
      <p-divider layout="vertical">
      </p-divider>
      <ul class="m-0 p-0 w-22rem min-w-max">
        <li class="flex">
          <p class="flex-grow-1 m-0">First Name:</p>
          <p class="m-0">{{ user.firstName || '(empty)' }}</p>
        </li>
        <li class="flex">
          <p class="flex-grow-1 m-0">Last Name:</p>
          <p class="m-0">{{ user.lastName || '(empty)' }}</p>
        </li>
        <li class="flex">
          <p class="flex-grow-1 m-0">Email:</p>
          <a [href]="'mailto:' + user.email"
             class="m-0">{{ user.email || '(empty)' }}</a>
        </li>
        <li class="flex">
          <p class="flex-grow-1 m-0">Country:</p>
          <p class="m-0">{{ user.country || '(empty)' }}</p>
        </li>
        <li class="flex">
          <p class="flex-grow-1 m-0">Company Name:</p>
          <p class="m-0">{{ user.companyName || '(empty)' }}</p>
        </li>
      </ul>
    </div>
  </ng-template>

</p-dialog>
