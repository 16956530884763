import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  BehaviorSubject,
  catchError,
  map,
  Observable,
  of,
  throwError,
} from 'rxjs';
import { AccessScope } from 'src/app/configuration-management/enums/access-scope.enum';
import { ApiUrls, FormatApiUrlParam } from 'src/app/core/api-helper';
import { ApiUrlParams } from 'src/app/core/api-url-params';
import { IApiErrorResponse, IApiResponse } from 'src/app/core/iapi-response';
import { Project } from './project';

@Injectable({
  providedIn: 'root',
})
export class ProjectService {
  private _projects: BehaviorSubject<Project[]> = new BehaviorSubject<
    Project[]
  >([]);

  projects$: Observable<Project[]> = this._projects.asObservable();

  constructor(private httpClient: HttpClient) {}

  get projects(): Project[] {
    return this._projects.value;
  }

  getProjects() {
    return this.httpClient
      .get<IApiResponse<Project[]>>(ApiUrls.getProjects)
      .pipe(
        map((response: IApiResponse<Project[]>) => {
          const projects: Project[] = [];

          response.value.forEach((p) => {
            const project = new Project(
              p.id,
              p.name,
              p.description,
              p.ownerId,
              p.image,
              p.referenceNumber
            );
            projects.push(project);
          });

          this._projects.next(projects);
          return projects;
        }),
        catchError((error) => {
          return throwError(() => error);
        })
      );
  }

  getProjectById(
    projectId: string,
    accessScope: AccessScope = AccessScope.User
  ): Observable<Project> {
    const existingProject = this._projects.value.find(
      (project) => project.id === projectId
    );

    if (existingProject) {
      return of(existingProject);
    }

    const url = ApiUrls.getProjectByIdUrl.replace(
      FormatApiUrlParam(ApiUrlParams.ProjectId),
      projectId
    );

    return this.httpClient
      .get<IApiResponse<Project>>(url, {
        params: {
          accessScope,
        },
      })
      .pipe(
        map((response: IApiResponse<Project>) => {
          const projects = [...this._projects.value];
          const model = response.value;

          const project = new Project(
            model.id,
            model.name,
            model.description,
            model.ownerId,
            model.image,
            model.referenceNumber
          );

          projects.push(project);
          this._projects.next(projects);

          return project;
        })
      );
  }

  createProject(
    name: string,
    description: string,
    referenceNumber: string | null
  ) {
    const body = { name, description, referenceNumber };
    return this.httpClient
      .post<IApiResponse<Project>>(ApiUrls.createProject, body)
      .pipe(
        map((response: IApiResponse<Project>) => {
          const projects = [...this._projects.value];
          const model = response.value;
          const project = new Project(
            model.id,
            model.name,
            model.description,
            model.ownerId
          );
          this._projects.next(projects);
          return project;
        }),
        catchError((error: HttpErrorResponse) => {
          const errorResponse = error.error as IApiErrorResponse;
          console.log(errorResponse);
          return throwError(() => error);
        })
      );
  }

  deleteProject(projectId: string) {
    const url = ApiUrls.removeProject.replace(
      FormatApiUrlParam(ApiUrlParams.ProjectId),
      projectId
    );

    return this.httpClient.delete(url).pipe(
      map(() => {
        this._projects.value.splice(
          this._projects.value.findIndex((project) => project.id === projectId),
          1
        );
        return true;
      }),
      catchError((error) => {
        console.log('Delete Failed', error);
        console.error(error);
        return throwError(() => error);
      })
    );
  }

  updateProject(project: Project): Observable<Project> {
    const url = ApiUrls.updateProject.replace(
      FormatApiUrlParam(ApiUrlParams.ProjectId),
      project.id
    );

    return this.httpClient.put<IApiResponse<Project>>(url, project).pipe(
      map((response: IApiResponse<Project>) => {
        const projects = [...this._projects.value];
        const model = response.value;
        const project = new Project(
          model.id,
          model.name,
          model.description,
          model.ownerId,
          model.image,
          model.referenceNumber
        );

        const indexToReplace = projects.findIndex(
          (item) => item.id === project.id
        );

        if (indexToReplace) {
          projects[indexToReplace] = project;
        } else {
          projects.push(project);
        }

        this._projects.next(projects);
        return project;
      }),
      catchError((error) => {
        console.log('Project update failed', error);
        console.error(error);
        return throwError(() => error);
      })
    );
  }
}
