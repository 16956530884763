import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Process } from '../../types/process.interface';

@Injectable({
  providedIn: 'root',
})
export class ProcessesStorageService {
  private processes: BehaviorSubject<Process[]> = new BehaviorSubject<
    Process[]
  >([]);

  processes$ = this.processes.asObservable();

  constructor() {}

  addProcess(newProcess: Process): void {
    const processes = this.processes.value;
    processes.push(newProcess);

    this.processes.next(processes);
  }

  updateProcess(process: Process, index: number): void {
    const processList = this.processes.value;
    processList[index] = process;

    this.processes.next(processList);
  }

  clearProcesses(): void {
    this.processes.next([]);
  }

  deleteProcess(processId: number): void {
    const processList = this.processes.value;
    processList.splice(processId, 1);

    this.processes.next(processList);
  }

  updateProcessListOrder(processList: Process[]): void {
    this.processes.next(processList);
  }
}
