import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import { Track } from 'src/app/common/models/track/track';

@Component({
  selector: 'app-tracks-list',
  templateUrl: './tracks-list.component.html',
  styleUrls: ['./tracks-list.component.scss'],
  host: {
    class: 'flex flex-column flex-grow-1',
  },
})
export class TracksListComponent {
  @Input() loadingTracks = false;
  @Input() tracksList: Track[];
  @Input() isOwner = false;

  @Output() onViewTrack: EventEmitter<string> = new EventEmitter<string>();

  constructor(private router: Router) {}

  viewTrack(trackId: string): void {
    this.onViewTrack.emit(trackId);
  }
}
