import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AccordionModule } from 'primeng/accordion';
import { ConfirmationService, MessageService } from 'primeng/api';
import { BadgeModule } from 'primeng/badge';
import { CardModule } from 'primeng/card';
import { CarouselModule } from 'primeng/carousel';
import { CheckboxModule } from 'primeng/checkbox';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { DividerModule } from 'primeng/divider';
import { DropdownModule } from 'primeng/dropdown';
import { ImageModule } from 'primeng/image';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { MenuModule } from 'primeng/menu';
import { MenubarModule } from 'primeng/menubar';
import { PanelModule } from 'primeng/panel';
import { PasswordModule } from 'primeng/password';
import { ProgressBarModule } from 'primeng/progressbar';
import { SidebarModule } from 'primeng/sidebar';
import { SkeletonModule } from 'primeng/skeleton';
import { StyleClassModule } from 'primeng/styleclass';
import { TableModule } from 'primeng/table';
import { TabViewModule } from 'primeng/tabview';
import { TagModule } from 'primeng/tag';
import { ToastModule } from 'primeng/toast';
import { TooltipModule } from 'primeng/tooltip';

import {
  InteractionType,
  IPublicClientApplication,
  LogLevel,
  PublicClientApplication,
} from '@azure/msal-browser';

import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import {
  MsalBroadcastService,
  MsalGuard,
  MsalGuardConfiguration,
  MsalInterceptor,
  MsalInterceptorConfiguration,
  MsalRedirectComponent,
  MsalService,
  MSAL_GUARD_CONFIG,
  MSAL_INSTANCE,
  MSAL_INTERCEPTOR_CONFIG,
  ProtectedResourceScopes,
} from '@azure/msal-angular';
import { environment } from 'src/environments/environment';

import { EditUserComponent } from './admin/user-management/edit-user/edit-user.component';
import { UserManagementComponent } from './admin/user-management/user-management.component';
import { AppComponent } from './app.component';
import { ConfigurationInfoComponent } from './configuration/configuration-info/configuration-info.component';
import { ConfigurationsCardComponent } from './configuration/configurations/configurations-card/configurations-card.component';
import { ConfigurationsListComponent } from './configuration/configurations/configurations-list/configurations-list.component';
import { ConfigurationsComponent } from './configuration/configurations/configurations.component';
import { DashboardComponent } from './configuration/dashboard/dashboard.component';
import { LoginGatewayComponent } from './configuration/login-gateway/login-gateway.component';
import { CreateProjectComponent } from './configuration/projects/create-project/create-project.component';
import { ProjectsComponent } from './configuration/projects/projects.component';
import { TracksCardComponent } from './configuration/track-library/tracks-list/tracks-card/tracks-card.component';
import { TracksListComponent } from './configuration/track-library/tracks-list/tracks-list.component';
import { TrackComponent } from './configuration/track/track.component';
import { msalConfig, protectedResources } from './core/config/auth-config';
import { AppLayoutComponent } from './layout/app.layout/app.layout.component';

import { AutoFocusModule } from 'primeng/autofocus';
import { CalendarModule } from 'primeng/calendar';
import { DialogModule } from 'primeng/dialog';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { OrderByPipe } from './admin/user-management/order-by.pipe';
import { ConfigurationManagementModule } from './configuration-management/configuration-management.module';
import { EditorPanelComponent } from './configuration/editor-panel/editor-panel.component';
import { QuoteRequestComponent } from './configuration/quote-request/quote-request.component';
import { TrackCommentComponent } from './configuration/track/components/track-comments/track-comment/track-comment.component';
import { TrackCommentsComponent } from './configuration/track/components/track-comments/track-comments.component';
import { ErrorPageComponent } from './core/error-page/error-page.component';
import { initializeAuth } from './core/factories/auth-initialization.factory';
import { EditorModeInterceptor } from './core/interceptors/editor-interceptor/editor-mode.interceptor';
import { AppErrorInterceptor } from './core/interceptors/error-interceptor';
import { MaintenanceInterceptor } from './core/interceptors/maintenance-interceptor/maintenance.interceptor';
import { TokenInterceptor } from './core/interceptors/token-interceptor';
import { MaintenancePageComponent } from './core/maintenance-page/maintenance-page.component';
import { AuthInitializationService } from './core/services/auth-initialization.app/auth-initialization.service';
import { AppConfirmationDialogComponent } from './layout/app-confirmation-dialog/app-confirmation-dialog/app-confirmation-dialog.component';
import { ProgressBarComponent } from './layout/app.progress-bar/progress-bar/progress-bar.component';
import { TrackCreatorModule } from './modules/track-creator/track-creator.module';
import { SharedModule } from './shared/shared.module';
import { TrackDesignerModule } from './track-designer/track-designer.module';

export function loggerCallback(logLevel: LogLevel, message: string) {
  console.log(message);
}

export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication(msalConfig);
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<
    string,
    Array<string | ProtectedResourceScopes> | null
  >();

  protectedResourceMap.set(protectedResources.heartbeat.endpoint, [
    {
      httpMethod: 'GET',
      scopes: null,
    },
  ]);

  protectedResourceMap.set(protectedResources.api.endpoint, [
    {
      httpMethod: 'GET',
      scopes: [...protectedResources.api.scopes.read],
    },
    {
      httpMethod: 'POST',
      scopes: [...protectedResources.api.scopes.write],
    },
    {
      httpMethod: 'PUT',
      scopes: [...protectedResources.api.scopes.write],
    },
    {
      httpMethod: 'DELETE',
      scopes: [...protectedResources.api.scopes.write],
    },
  ]);

  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap,

    // The auth request that is sent in the interceptor.
    // If we have logged out or the refresh token has expired, we need to make sure the domain_hint
    // is passed to B2C properly based on the user that was logged in.
    authRequest: (msalSerivce, httpRequest, originalAuthRequest) => {
      type IdTokenClaims = {
        idp_tenantId: string;
      };

      const claims = originalAuthRequest.account
        ?.idTokenClaims as IdTokenClaims;
      let domainHint = '';

      if (claims?.idp_tenantId == 'f5b2e964-6123-4a27-9a8c-8c1dfbf93791') {
        domainHint = 'atsautomation.com';
      }
      return {
        domainHint: domainHint,
        // Enable this if you need to test refresh tokens.
        //cacheLookupPolicy: CacheLookupPolicy.Skip,
      };
    },
  };
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Redirect,
    authRequest: {
      scopes: [...environment.apiConfig.scopes],
    },
    loginFailedRoute: '/login-failed',
  };
}

@NgModule({
  declarations: [
    AppComponent,
    AppLayoutComponent,
    UserManagementComponent,
    EditUserComponent,
    ProjectsComponent,
    ConfigurationsComponent,
    ConfigurationsListComponent,
    ConfigurationsCardComponent,
    ConfigurationInfoComponent,
    TracksListComponent,
    TracksCardComponent,
    TrackComponent,
    CreateProjectComponent,
    LoginGatewayComponent,
    DashboardComponent,
    OrderByPipe,
    AppConfirmationDialogComponent,
    ProgressBarComponent,
    EditorPanelComponent,
    ErrorPageComponent,
    MaintenancePageComponent,
    TrackCommentsComponent,
    TrackCommentComponent,
    QuoteRequestComponent,
  ],
  imports: [
    BrowserAnimationsModule,
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    BadgeModule,
    StyleClassModule,
    HttpClientModule,
    TooltipModule,
    MenubarModule,
    MenuModule,
    CardModule,
    TableModule,
    DialogModule,
    ProgressSpinnerModule,
    PanelModule,
    CheckboxModule,
    ImageModule,
    InputTextModule,
    AccordionModule,
    DropdownModule,
    ConfirmDialogModule,
    ToastModule,
    InputTextareaModule,
    PasswordModule,
    DividerModule,
    ProgressBarModule,
    SidebarModule,
    TrackDesignerModule,
    ConfigurationManagementModule,
    TagModule,
    AutoFocusModule,
    SkeletonModule,
    TrackCreatorModule,
    CalendarModule,
    SharedModule,
    CarouselModule,
    TabViewModule,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MaintenanceInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: EditorModeInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AppErrorInterceptor,
      multi: true,
    },
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory,
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory,
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSALInterceptorConfigFactory,
    },
    MsalService,
    MsalGuard,
    MsalBroadcastService,
    AuthInitializationService,
    {
      provide: APP_INITIALIZER,
      useFactory: initializeAuth,
      deps: [AuthInitializationService],
      multi: true,
    },
    ConfirmationService,
    MessageService,
  ],
  bootstrap: [AppComponent, MsalRedirectComponent],
})
export class AppModule {}
