import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ETurn } from '../../../common/enums/eTurn.enum';
import { ShuttleMagnetCount } from '../../enums/shuttle-magnet-count.enum';
import { Question } from '../../track-designer-questions';

enum MagnetsRecommendations {
  twoMagnets = '2 magnet shuttle',
  threeMagnets = '3 magnet shuttle',
  largerPitch = '3 magnet shuttle and a pitch larger than 200mm',
}

@Component({
  selector: 'app-track-shuttles',
  templateUrl: './track-shuttles.component.html',
  styleUrls: ['./track-shuttles.component.scss'],
  host: {
    class: 'h-full',
  },
})
export class TrackShuttlesComponent implements OnInit {
  @Input() form: FormGroup;
  @Input() questions: Record<string, Question | Question[]> | undefined;

  shuttlesCountQuestions: Question;
  magnetsCountQuestion: Question;
  irPalletQuestion: Question;

  fixtureLength: number;
  payload: number;

  isDescriptionVisible = false;

  magnetsOptions = [
    {
      label: '2 magnets',
      value: ShuttleMagnetCount.Two,
    },
    {
      label: '3 magnets',
      value: ShuttleMagnetCount.Three,
    },
  ];

  get recommendedMagnets(): string {
    if (!this.fixtureLength || !this.payload) {
      return '';
    }

    if (this.fixtureLength <= 200 && this.payload <= 5) {
      return MagnetsRecommendations.twoMagnets;
    }

    if (this.fixtureLength > 200) {
      return MagnetsRecommendations.threeMagnets;
    }

    return MagnetsRecommendations.largerPitch;
  }

  get isFixtureLengthExceeds(): boolean {
    return this.fixtureLength > 1000;
  }

  get isFixtureLengthBelowMin(): boolean {
    return this.fixtureLength < 152;
  }

  get isPayloadExceeds10kg(): boolean {
    return this.payload > 10;
  }

  get isPayloadExceeds4kg(): boolean {
    return this.payload > 4;
  }

  get isOverUnder(): boolean {
    return this.form.get('eTurn')?.value === ETurn.Curve500Low;
  }

  constructor() {}

  ngOnInit(): void {
    this.magnetsCountQuestion = this.questions?.['magnetsCount'] as Question;
    this.shuttlesCountQuestions = this.questions?.['shuttlesCount'] as Question;
    this.irPalletQuestion = this.questions?.['irPallet'] as Question;
  }

  setMagnets(magnetsNumber: number): void {
    this.form.get('shuttleMagnetCount')?.setValue(magnetsNumber);
  }

  disableCheckboxFormValueChange(event: Event): void {
    event.preventDefault();
  }
}
