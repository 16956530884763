import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { IdentityService } from 'src/app/core/services/identity.service';
import { UserService } from 'src/app/core/services/user.service';
import { UserCompanyName } from '../../enums/user-company-name.enum';
import { Question } from '../../track-designer-questions';

@Component({
  selector: 'app-track-additionals',
  templateUrl: './track-additionals.component.html',
  styleUrls: ['./track-additionals.component.scss'],
  host: {
    class: 'h-full',
  },
})
export class TrackAdditionalsComponent implements OnInit {
  @Input() form: FormGroup;
  @Input() questions: Record<string, Question | Question[]> | undefined;

  superTrakSyncQuestion: Question;
  academyCurriculaQuestion: Question;
  peopleToTrainQuestion: Question;

  get isCurriculaDisabled(): boolean {
    return !this.form.get('includeSuperTrakAcademy')?.value;
  }

  constructor(
    public userService: UserService,
    public identityService: IdentityService
  ) {}

  ngOnInit(): void {
    this.checkDefaultOption();
    this.superTrakSyncQuestion = this.questions?.['superTrakSync'] as Question;
    this.academyCurriculaQuestion = this.questions?.[
      'academyCurricula'
    ] as Question;
    this.peopleToTrainQuestion = this.questions?.['peopleToTrain'] as Question;
  }

  isLSUSer(): boolean {
    return this.userService.isUserFromCompany(UserCompanyName.LifeScience);
  }

  handleAcademyCurriculaCHange(isCurriculaIncluded: boolean): void {
    this.form
      .get('superTrakAcademyTrainingCount')
      ?.setValue(isCurriculaIncluded ? 1 : 0);
  }

  checkDefaultOption(): void {
    const selectedController = this.form.get('includeOmniTrakLicense');

    let isOptionAvailableForCurrentUser: boolean;

    if (this.superTrakSyncQuestion?.isEnabled) {
      isOptionAvailableForCurrentUser = this.superTrakSyncQuestion.isEnabled(
        this.userService,
        this.identityService
      );
    } else {
      isOptionAvailableForCurrentUser = true;
    }

    if (!isOptionAvailableForCurrentUser) {
      selectedController?.setValue('null');
    }
  }

  disableCheckboxFormValueChange(event: Event): void {
    event.preventDefault();
  }
}
