import { Component } from '@angular/core';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { TrackCreatorService } from '../../services/track-creator/track-creator.service';

@Component({
  selector: 'app-process-map-creator',
  templateUrl: './process-map-creator.component.html',
  styleUrls: ['./process-map-creator.component.scss'],
})
export class ProcessMapCreatorComponent {
  // Should be changed to dynamic data
  processMaps = [
    {
      id: 1,
      title: 'Assembly application (syringes)',
      subtitle: 'Template Subtitle 1',
      imgSrc: 'https://plchldr.co/i/500x250?text=Template%201',
      description:
        'An easy to understand eight-step application showing how to setup parallel processes and offsets in this concepting tool.',
    },
  ];

  constructor(
    public dialogRef: DynamicDialogRef,
    private trackCreatorService: TrackCreatorService
  ) {}

  closeProcessMapCreator(): void {
    this.trackCreatorService.createDefaultTrack();
    this.dialogRef.close();
  }
}
