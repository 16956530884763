<div class="mb-6">
  <div class="flex col-12">
    <h3 class="my-2">Comments</h3>
  </div>

  <div class="comments-section p-card p-3 w-full">
    <div class="py-3">
      <ng-container *ngIf="!trackComments.length; else commentsListTpl">
        <h3 class="comments-section__empty-message text-xl mb-0">Do you have a question or want to leave a comment?</h3>
        <button pButton
                pRipple
                type="button"
                icon="pi pi-comment"
                class="p-button-outlined mt-3"
                *ngIf="!this.isCommentFieldVisible"
                (click)="showCommentField()"
                label="Leave a comment">
        </button>
      </ng-container>
      <ng-template #commentsListTpl>
        <ul class="list-none m-0 pl-0 w-full">
          <li class="w-full border-300 py-3"
              *ngFor="let comment of trackComments; trackBy: trackCommentById; let last = last"
              [ngClass]="{
                'border-bottom-1': !last
              }">
            <app-track-comment [comment]="comment"
                               [isCommentOwner]="isCommentOwner(comment)"
                               [isATSUser]="isATSUser(comment.createdBy.id)"
                               [isVersionSubmitted]="isVersionSubmitted"
                               [isDeleting]="deletingId === comment.id"
                               [isPosting]="editingId === comment.id ? postingChanges : false"
                               [isEditing]="editingId === comment.id"
                               (onEditComment)="editComment($event)"
                               (onSubmitCommentChanges)="submitCommentChanges($event)"
                               (onDeleteComment)="deleteComment($event)">

            </app-track-comment>
          </li>
        </ul>
      </ng-template>
    </div>
    <ng-container *ngIf="isCommentFieldVisible || trackComments.length">
      <p-divider></p-divider>
      <ng-container [formGroup]="commentForm">
        <textarea pInputTextarea
                  class="w-full mb-3"
                  [rows]="6"
                  [cols]="60"
                  formControlName="text"
                  [autoResize]="true">
        </textarea>
        <div class="flex justify-content-end">
          <button pButton
                  pRipple
                  type="submit"
                  icon="pi pi-comment"
                  class="p-button-outlined"
                  [loading]="isPosting"
                  [disabled]="!commentForm.get('text')?.value.length"
                  (click)="submitComment()"
                  label="Add comment">
          </button>
        </div>
      </ng-container>
    </ng-container>
  </div>
</div>
