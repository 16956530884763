import { FormControl, FormGroup } from '@angular/forms';
import { processMap } from '../constants/process-map.const';
import { ProcessFormGroup, ProcessMap } from '../types/process-form.interface';
import { Process } from '../types/process.interface';

const createFormGroupFromProcessMap = (
  processMap: ProcessMap
): FormGroup<ProcessFormGroup> => {
  const controls: Partial<Record<keyof ProcessMap, FormControl>> = {};
  for (const key in processMap) {
    if (processMap.hasOwnProperty(key)) {
      const controlKey = key as keyof ProcessMap;
      controls[controlKey] = processMap[controlKey].control;
    }
  }
  const formGroup = new FormGroup<ProcessFormGroup>(
    controls as ProcessFormGroup
  );
  return formGroup;
};

export const createFormGroupFromProcess = (
  process: Process
): FormGroup<ProcessFormGroup> => {
  const controls: Partial<Record<keyof ProcessMap, FormControl>> = {};
  for (const key in processMap) {
    if (processMap.hasOwnProperty(key)) {
      const controlKey = key as keyof ProcessMap;
      controls[controlKey] = processMap[controlKey].control;
      controls[controlKey]?.setValue(process[controlKey]);
    }
  }
  const formGroup = new FormGroup<ProcessFormGroup>(
    controls as ProcessFormGroup
  );
  formGroup.markAsUntouched();
  formGroup.markAsPristine();
  return formGroup;
};

export const processMapFormGroup = createFormGroupFromProcessMap(processMap);
