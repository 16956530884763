import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { EditorModeService } from 'src/app/configuration/services/editor-mode/editor-mode.service';
import { ProjectConfigurationService } from 'src/app/configuration/services/project-configuration/project-configuration.service';
import { IdentityService } from '../../services/identity.service';

@Injectable()
export class EditorModeInterceptor implements HttpInterceptor {
  constructor(
    private identityService: IdentityService,
    private projectConfigurationService: ProjectConfigurationService,
    private editorModeService: EditorModeService
  ) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    if (
      !this.isEditableRequest(request) ||
      !this.editorModeService.isEditorMode
    ) {
      return next.handle(request);
    }

    let extendedRequest;

    if (
      request.method === 'POST' ||
      request.method === 'PUT' ||
      request.method === 'DELETE'
    ) {
      const configurationOwner = this.editorModeService.currentUser;

      if (configurationOwner) {
        extendedRequest = request.clone({
          setHeaders: {
            'X-On-Behalf-Of': configurationOwner.id,
          },
        });
      }
    }

    return next.handle(extendedRequest || request);
  }

  isEditableRequest(request: HttpRequest<unknown>): boolean {
    const editableEndpoints = [
      'projects',
      'configurations',
      'configurationVersions',
      'tracks',
    ];

    return !!editableEndpoints.some(
      (endpoint) => request.url.indexOf(`/api/${endpoint}`) > -1
    );
  }
}
