import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MessageService } from 'primeng/api';
import { RoleEnum } from 'src/app/common/models/user/user';
import { TrackService } from 'src/app/common/services/track-services/track.service';
import {
  Configuration,
  ConfigurationVersion,
} from 'src/app/configuration/models/configuration.model';
import { TrackComment } from 'src/app/configuration/models/track-details.interface';
import { EditorModeService } from 'src/app/configuration/services/editor-mode/editor-mode.service';
import { IdentityService } from 'src/app/core/services/identity.service';

@Component({
  selector: 'app-track-comments',
  templateUrl: './track-comments.component.html',
  styleUrls: ['./track-comments.component.scss'],
})
export class TrackCommentsComponent implements OnInit {
  @Input() trackId: string;
  @Input() currentVersion: ConfigurationVersion;
  @Input() currentConfiguration: Configuration;
  @Input() isVersionSubmitted: boolean;

  commentForm: FormGroup;
  trackComments: TrackComment[];
  isPosting = false;

  deletingId: string | null = null;
  editingId: string | null = null;

  postingChanges = false;

  isCommentFieldVisible = false;

  constructor(
    private trackService: TrackService,
    private identityService: IdentityService,
    private editorModeService: EditorModeService,
    private messageService: MessageService
  ) {}

  ngOnInit(): void {
    this.commentForm = new FormGroup({
      text: new FormControl('', [
        Validators.required,
        Validators.maxLength(5000),
      ]),
    });

    this.trackService.trackComments.subscribe((trackComments) => {
      this.trackComments = trackComments;
    });
  }

  isCommentOwner(comment: TrackComment): boolean {
    return (
      comment.createdBy.id === this.identityService.currentUser?.id || false
    );
  }

  submitComment(): void {
    if (this.isPosting) {
      return;
    }

    if (this.commentForm.invalid) {
      this.commentForm.markAsTouched;
      return;
    }
    this.isPosting = true;

    const commentText = this.commentForm.get('text')?.value;
    this.trackService.postTrackComment(this.trackId, commentText).subscribe({
      next: () => {
        this.commentForm.get('text')?.setValue('');
        this.isPosting = false;
      },
      error: (error) => {
        this.commentForm.get('text')?.setValue('');
        this.isPosting = false;
        console.error('error: ', error);
        this.messageService.add({
          severity: 'error',
          summary: 'Error submitting comment.',
          detail: error ? error.status + ': ' + error.title : '',
        });
      },
    });
  }

  deleteComment(commentId: string): void {
    this.deletingId = commentId;

    this.trackService.deleteTrackComment(this.trackId, commentId).subscribe({
      next: (res) => {
        this.deletingId = null;
      },
      error: (error) => {
        this.deletingId = null;
        console.error('error: ', error);
        this.messageService.add({
          severity: 'error',
          summary: 'Error deleting comment.',
          detail: error ? error.status + ': ' + error.title : '',
        });
      },
    });
  }

  submitCommentChanges(comment: { id: string; text: string } | null): void {
    if (!comment) {
      this.editingId = null;
      return;
    }

    this.postingChanges = true;
    this.trackService
      .updateTrackComment(this.trackId, comment.id, comment.text)
      .subscribe({
        next: () => {
          this.editingId = null;
          this.postingChanges = false;
        },
        error: (error) => {
          console.error('error: ', error);
          this.editingId = null;
          this.postingChanges = false;
          this.messageService.add({
            severity: 'error',
            summary: 'Error updating comment.',
            detail: error ? error.status + ': ' + error.title : '',
          });
        },
      });
  }

  isATSUser(commentOwnerId: string): boolean {
    if (this.editorModeService.isEditorMode) {
      return commentOwnerId !== this.currentConfiguration.ownerId;
    }

    return (
      commentOwnerId !== this.currentConfiguration.ownerId ||
      !!this.identityService.currentUser?.hasAnyRole([
        RoleEnum.SuperTrakSales,
        RoleEnum.SuperAdmin,
        RoleEnum.Admin,
      ])
    );
  }

  editComment(commentId: string): void {
    this.editingId = commentId;
  }

  trackCommentById(index: number, comment: TrackComment): string {
    return comment.id;
  }

  showCommentField(): void {
    this.isCommentFieldVisible = true;
  }
}
