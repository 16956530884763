import { ETurn } from '../enums/eTurn.enum';

export const TRACK_TYPES: Record<
  ETurn,
  {
    fullLabel: string;
    brandName: string;
    label: string;
    trackDesignerLabel: string;
  }
> = {
  [ETurn.None]: {
    fullLabel: 'Straight SuperTrak only',
    brandName: 'Straight SuperTrak only',
    label: 'Straight SuperTrak only',
    trackDesignerLabel: 'Straight SuperTrak only',
  },
  [ETurn.Curve500]: {
    fullLabel: 'SuperTrak HORIZON10™ 500mm wide loop',
    brandName: 'SuperTrak HORIZON10™',
    label: '500mm wide loop',
    trackDesignerLabel: '500mm wide loop',
  },
  [ETurn.Curve800]: {
    fullLabel: 'SuperTrak HORIZON10™ 800mm wide loop',
    brandName: 'SuperTrak HORIZON10™',
    label: '800mm wide loop',
    trackDesignerLabel: '800mm wide loop',
  },
  [ETurn.Curve90]: {
    fullLabel: 'SuperTrak HORIZON10™ 1200mm+ wide loop with 90° curves',
    brandName: 'SuperTrak HORIZON10™',
    label: '1200mm+ wide loop with 90° curves',
    trackDesignerLabel: '1200mm+ wide loop with 90deg',
  },
  [ETurn.Curve500Low]: {
    fullLabel: 'SuperTrak VERTICAL10™ 500mm wide loop',
    brandName: 'SuperTrak VERTICAL10™',
    label: '500mm wide loop',
    trackDesignerLabel: 'Vertical 500mm wide loop',
  },
};

export function getTrackTypesList(): {
  fullLabel: string;
  value: ETurn;
}[] {
  const trackTypesList: {
    fullLabel: string;
    value: ETurn;
  }[] = [];

  for (const key in TRACK_TYPES) {
    const trackType = {
      value: key as ETurn,
      ...TRACK_TYPES[key as ETurn],
    };

    trackTypesList.push(trackType);
  }

  return trackTypesList;
}
