import { ConfigurationsRequestParameters } from '../interfaces/configurations-request-parameters.interface';
import { TableParameters } from '../interfaces/table-parameters.interface';

const parameterNames: {
  [key: string]: string;
} = {
  rows: 'pageSize',
  first: 'pageNumber',
};

export function convertTableParameters(originalParameters: TableParameters): {
  [key: string]: string | number;
} {
  const { filters, ...rest } = originalParameters;
  const convertedParameters: ConfigurationsRequestParameters = {};

  for (const filterKey in filters) {
    const filter = filters[filterKey];

    if (!Array.isArray(filter) && !!filter?.value) {
      const parameterName = convertFieldName(filterKey);
      convertedParameters[parameterName] = filter.value;
    }
  }

  Object.entries(rest).forEach((param) => {
    const key = param[0];
    const value = param[1];
    if (!!value && !Array.isArray(value)) {
      const parameterName = convertFieldName(key);
      if (key === 'first' && rest.rows) {
        convertedParameters[parameterName] =
          Math.ceil(Number(value) / rest.rows) + 1;
        return;
      }
      if (key === 'sortField' && rest['sortOrder']) {
        const fieldName = convertFieldName(value.toString());
        const sortBy = `${rest['sortOrder'] > 0 ? '-' : ''}${fieldName}`;
        convertedParameters['sortBy'] = sortBy;
        return;
      }
      if (key === 'sortOrder') return;

      convertedParameters[parameterName] = value;
    }
  });

  return convertedParameters;
}

function convertFieldName(fieldName: string): string {
  return parameterNames[fieldName] || fieldName;
}
