import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

export enum SideBarState {
  Collapsed,
  Slim,
  Expanded,
}

interface LayoutState {
  staticMenuDesktopInactive: boolean;
  overlayMenuActive: boolean;
  staticMenuMobileActive: boolean;
  menuHoverActive: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class AppLayoutService {
  private scrollTop: BehaviorSubject<number | null> = new BehaviorSubject<
    number | null
  >(null);

  private sidebarState: BehaviorSubject<SideBarState> =
    new BehaviorSubject<SideBarState>(SideBarState.Slim);

  private showHelpModal: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);

  scrollTop$ = this.scrollTop.asObservable();
  showHelpModal$ = this.showHelpModal.asObservable();
  sidebarState$ = this.sidebarState.asObservable();

  state: LayoutState = {
    staticMenuDesktopInactive: false,
    overlayMenuActive: false,
    staticMenuMobileActive: false,
    menuHoverActive: false,
  };

  isDesktop() {
    return window.innerWidth > 991;
  }

  setScrollTop(yPos: number): void {
    this.scrollTop.next(yPos);
  }

  showHelp(): void {
    this.showHelpModal.next(true);
  }

  setSidebarState(state: SideBarState): void {
    this.sidebarState.next(state);
  }
}
