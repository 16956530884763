import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { DropdownChangeEvent } from 'primeng/dropdown';
import { OverlayPanel } from 'primeng/overlaypanel';
import { ETurn } from 'src/app/common/enums/eTurn.enum';
import { processFunctionsList } from '../../constants/process-function-list.const';
import { ProcessFunctionName } from '../../enums/process-function.enum';
// import { processMapDescriptions } from '../../helpers/process-map-descriptions';
import { processMap } from '../../constants/process-map.const';
import { processMapFormGroup } from '../../helpers/process-map-form';
import { ProcessesStorageService } from '../../services/processes-storage/processes-storage.service';
import {
  ProcessFormGroup,
  ProcessMapKeys,
} from '../../types/process-form.interface';
import { ProcessFunction } from '../../types/process-function.interface';
import { Process } from '../../types/process.interface';

@Component({
  selector: 'app-new-process',
  templateUrl: './new-process.component.html',
  styleUrls: ['./new-process.component.scss'],
  host: {
    class: 'flex flex-column flex-grow-1 h-full',
  },
})
export class NewProcessComponent implements OnInit {
  @ViewChild('propertyDescriptionOverlay')
  propertyDescriptionOverlay: OverlayPanel;

  @Input() eTurn: ETurn | undefined;
  @Output() onClose: EventEmitter<boolean> = new EventEmitter();

  processForm: FormGroup<ProcessFormGroup>;
  // processMapDescriptions: ProcessMapDescriptions;
  processMap = processMap;

  propertyDescriptionToShow: ProcessMapKeys;

  processFunctionList: ProcessFunction[];

  selectedProcessFunction: ProcessFunction | null;

  get isOverUnder(): boolean {
    return this.eTurn === ETurn.Curve500Low;
  }

  get isCurve500(): boolean {
    return this.eTurn === ETurn.Curve500;
  }

  constructor(private processesStorageService: ProcessesStorageService) {
    this.processForm = processMapFormGroup;

    this.processFunctionList = processFunctionsList;
    this.setSelectedFunctionByName(this.processForm.controls.function.value);
  }

  ngOnInit(): void {
    this.validateFormFields();
  }

  close(): void {
    this.onClose.emit(true);
  }

  setSelectedFunctionByName(functionName: ProcessFunctionName): void {
    this.selectedProcessFunction =
      this.processFunctionList.find((item) => item.value === functionName) ||
      null;
  }

  updateSelectedFunction(event: DropdownChangeEvent): void {
    const selectedFunctionName = event.value;

    this.selectedProcessFunction =
      this.processFunctionList.find(
        (item) => item.value === selectedFunctionName
      ) || null;
  }

  createProcess(): void {
    const newProcess: Process = this.processForm.value as Process;
    this.processesStorageService.addProcess(newProcess);
    this.close();
  }

  showPropertyDescription(
    event: MouseEvent,
    propertyName: ProcessMapKeys
  ): void {
    this.propertyDescriptionToShow = propertyName;

    this.propertyDescriptionOverlay.toggle(event);
  }

  private validateFormFields(): void {
    if (this.isOverUnder || this.isCurve500) {
      this.processForm.controls.placeProcessInsideTheTrack.disable();
    }
  }
}
