import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, tap } from 'rxjs';
import { AppRoutes } from 'src/app/app.routes.const';
import { IdentityService } from '../../services/identity.service';

@Injectable()
export class MaintenanceInterceptor implements HttpInterceptor {
  constructor(
    private router: Router,
    private identityService: IdentityService
  ) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      tap((event) => {
        if (
          event instanceof HttpResponse &&
          event.headers.has('X-Server-Mode')
        ) {
          if (
            !this.identityService.isInternalUser() ||
            localStorage.getItem('developer-mode') !== 'true'
          ) {
            this.router.navigate([AppRoutes.Maintenance.fullPath()]);
          }
        }

        if (
          this.router.url === '/' + AppRoutes.Maintenance.fullPath() &&
          event instanceof HttpResponse &&
          !event.headers.has('X-Server-Mode')
        ) {
          this.router.navigate([AppRoutes.Dashboard.fullPath()]);
        }
      })
    );
  }
}
