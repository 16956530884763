import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { AccountInfo } from '@azure/msal-browser';
import { MenuItem } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { Subject, Subscription, takeUntil } from 'rxjs';
import { User } from 'src/app/common/models/user/user';
import { LoginGatewayComponent } from 'src/app/configuration/login-gateway/login-gateway.component';
import { AppLoadingService } from 'src/app/core/services/app-loading.service';
import { IdentityService } from 'src/app/core/services/identity.service';
import { environment } from 'src/environments/environment';
import { VERSION } from 'src/environments/version';
import { AppLayoutService, SideBarState } from '../service/app.layout.service';

@Component({
  selector: 'app-topbar',
  templateUrl: './app.topbar.component.html',
  styleUrls: ['./app.topbar.component.scss'],
  providers: [DialogService],
})
export class AppTopbarComponent implements OnInit {
  @Output() onSidebarToggle: EventEmitter<boolean> =
    new EventEmitter<boolean>();

  isLoggedIn = false;
  showBuildModal = false;
  loadingBuildModal = true;
  isLoading = false;
  items: MenuItem[];
  user: User | null;
  accountInfo: AccountInfo | null;
  accountEmail: string;
  version = VERSION;
  env = environment;

  user$: Subscription | undefined;

  logo =
    'https://supertrakconveyance.com/wp-content/themes/theme/images/supertrak_logo.png';

  private sidebarState: SideBarState;
  private readonly _destroying$ = new Subject<void>();

  get isSidebarExpanded(): boolean {
    return this.sidebarState === SideBarState.Expanded;
  }

  constructor(
    private identityService: IdentityService,
    private dialogService: DialogService,
    private msalService: MsalService,
    private appLoadingService: AppLoadingService,
    private appLayoutService: AppLayoutService
  ) {
    this.appLoadingService.isLoading$
      .pipe(takeUntil(this._destroying$))
      .subscribe((res) => {
        this.isLoading = res;
      });

    this.appLayoutService.sidebarState$
      .pipe(takeUntil(this._destroying$))
      .subscribe((state) => {
        this.sidebarState = state;
      });
  }

  ngOnInit(): void {
    this.items = [
      {
        label: 'About',
        icon: 'pi pi-info-circle',
        command: () => {
          this.showBuildModal = true;
          this.getData();
        },
      },
      {
        label: 'Help',
        icon: 'pi pi-question-circle',
        command: () => {
          this.appLayoutService.showHelp();
        },
      },
      {
        label: 'Logout',
        icon: 'pi pi-power-off',
        command: this.logout.bind(this),
      },
    ];

    this.user$ = this.identityService.activeUserChanged$
      .pipe(takeUntil(this._destroying$))
      .subscribe((user: User | null) => {
        if (user == null) {
          this.isLoggedIn = false;
        } else {
          this.isLoggedIn = true;
        }

        this.accountInfo = this.msalService.instance.getActiveAccount();
        this.setAccountEmail();

        if (!environment.production) {
          console.log('TopBar.AccountInfo: ', this.accountInfo);
        }

        this.user = user;
      });
  }

  ngOnDestroy(): void {
    this._destroying$.unsubscribe();
  }

  logout() {
    this.identityService.logout();
  }

  show() {
    this.dialogService.open(LoginGatewayComponent, {
      header: 'Login or Register',
    });
  }

  private setAccountEmail() {
    const claims = this.accountInfo?.idTokenClaims;

    if (claims === undefined) {
      this.accountEmail = '';
      return;
    }

    this.accountEmail =
      (claims['email'] as string) ?? (claims['upn'] as string) ?? '';
  }

  // WIP until API for backend build
  getData() {
    this.loadingBuildModal = true;
    setTimeout(() => {
      this.loadingBuildModal = false;
    }, 1000);
  }

  toggleSidebar(): void {
    this.onSidebarToggle.next(true);
  }
}
