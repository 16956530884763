<div class="flex flex-column flex-grow-1 layout-container layout-slim w-full h-full">

  <app-topbar (onSidebarToggle)="toggleSideBar()"></app-topbar>

  <div class="content-container flex relative flex-grow-1">
    <div class="flex flex-column flex-grow-1 absolute h-full z-5">
      <app-sidebar class="flex flex-grow-1 _shadow"
                   [collapsed]="true">
      </app-sidebar>
    </div>

    <app-process-map [collapsed]="!processMapExpanded"
                     [originPoint]="processMapOriginPoint"
                     (onOriginPointChange)="setOriginPoint($event)">
    </app-process-map>

    <div class="track-concept flex relative w-full overflow-hidden"
         [ngClass]="{
          '_full-screen': !processMapExpanded,
        }">

      <button class="process-map-btn py-3"
              [ngClass]="{
                '_active': processMapExpanded
              }"
              (click)="toggleProcessMap()"
              type="button">
        <span class="process-map-btn__bar _bar-1"></span>
        <span class="process-map-btn__bar _bar-2"></span>
      </button>

      <div #trackContainer
           (keydown)="moveViewport($event)"
           class="flex flex-grow-1 w-full h-full"></div>

      <button pButton
              pRipple
              pTooltip="Settings"
              class="track-concept__settings-btn absolute"
              aria-label="Concept Tool Settings"
              (click)="showSettings()"
              icon="pi pi-cog">
      </button>

      <button pButton
              pRipple
              pTooltip="Controls"
              class="track-concept__controls-btn absolute"
              aria-label="Concept Tool Navigation"
              (click)="showControls()"
              icon="pi pi-globe">
      </button>

      <div class="track-concept__controls flex absolute py-5 px-5 surface-card shadow-2 border-ground"
           [ngClass]="{
          '_visible': controlsVisible
        }">

        <button class="track-concept__close-btn absolute"
                (click)="hideControls()">
          <i class="pi pi-times"></i>
        </button>

        <div class="flex flex-column align-items-center">

          <div class="track-concept__controls-group">

            <p-button class="track-concept__control-btn _up"
                      [text]="true"
                      aria-label="Up"
                      pTooltip="Move Up"
                      (click)="changePosition('up')"
                      icon="pi pi-arrow-up"></p-button>

            <p-button [text]="true"
                      class="track-concept__control-btn _left"
                      aria-label="Left"
                      pTooltip="Move Left"
                      tooltipPosition="left"
                      (click)="changePosition('left')"
                      icon="pi pi-arrow-left"></p-button>

            <p-button [text]="true"
                      class="track-concept__control-btn _right"
                      pTooltip="Move Right"
                      aria-label="Right"
                      (click)="changePosition('right')"
                      icon="pi pi-arrow-right"></p-button>

            <p-button [text]="true"
                      class="track-concept__control-btn _down"
                      pTooltip="Move Down"
                      aria-label="Down"
                      (click)="changePosition('down')"
                      icon="pi pi-arrow-down"></p-button>
          </div>

          <div class="flex justify-content-between mt-3">

            <span class="p-buttonset">
              <p-button aria-label="Zoom In"
                        tooltipPosition="left"
                        (click)="zoom('in')"
                        pTooltip="Zoom In"
                        icon="pi pi-search-plus"></p-button>
              <p-button pRipple
                        label="Reset"
                        [outlined]="true"
                        pTooltip="Reset Viewport"
                        (click)="resetZoom()"
                        icon="pi pi-refresh"></p-button>
              <p-button aria-label="Zoom Out"
                        tooltipPosition="right"
                        pTooltip="Zoom Out"
                        (click)="zoom('out')"
                        icon="pi pi-search-minus"></p-button>
            </span>
          </div>
        </div>

      </div>
    </div>
  </div>
</div>

<p-sidebar class="track-concept-settings"
           [(visible)]="settingsVisible">

  <ng-template pTemplate="header">
    <h3 class="mb-0">Settings</h3>
  </ng-template>

  <ng-template pTemplate="content">
    <form class="pt-3"
          [formGroup]="trackConceptForm">
      <div class="flex flex-column gap-2">
        <label htmlFor="eTurn">Trak Type</label>
        <p-dropdown placeholder="Select a Trak Type"
                    inputId="eTurn"
                    formControlName="eTurn"
                    optionLabel="label"
                    optionValue="value"
                    [options]="trackTypes">
        </p-dropdown>

        <label htmlFor="eTurn">Trak Length</label>
        <p-inputNumber formControlName="length"
                       [showButtons]="true"
                       [min]="minLength"
                       [max]="maxLength">
        </p-inputNumber>

        <label htmlFor="eTurn">Trak Width</label>
        <p-inputNumber formControlName="width"
                       [showButtons]="true"
                       [min]="minWidth"
                       [max]="maxWidth">
        </p-inputNumber>
      </div>

      <p-button label="Apply changes"
                styleClass="mt-3"
                type="button"
                (click)="updateTrack()"
                icon="pi pi-check"></p-button>
    </form>
  </ng-template>

</p-sidebar>

<p-sidebar position="right"
           class="track-info"
           styleClass="w-30rem"
           [(visible)]="trackInfoVisible">
  <ng-template pTemplate="header">
    <h3 class="mb-0">Track Info</h3>
  </ng-template>
  <ng-template pTemplate="content">
    <div class="p-card p-3 bg-white mb-4">
      <ul class="track-info__list flex flex-column list-none p-0 m-0">
        <li class="track-info__list-item flex justify-content-between align-items-center py-2 border-bottom-1">
          <span>Type:</span>
          <span>{{ trackTypeName }}</span>
        </li>
        <li class="track-info__list-item flex justify-content-between align-items-center py-2">
          <span>Length:</span>
          <span>{{ trackLength }}</span>
        </li>
      </ul>
    </div>
  </ng-template>
</p-sidebar>
