<div class="col-12 flex flex-column justify-content-center"
     [formGroup]="form">
  <ul class="options-list _accessories justify-content-center">
    <li class="option-item _bordered _inline"
        *ngFor="let option of question?.options;">
      <div class="flex justify-content-between">
        <p-checkbox [binary]="true"
                    [name]="option.fieldName"
                    [inputId]="option.fieldName"
                    [name]="option.fieldName"
                    [inputId]="option.fieldName"
                    (click)="disableCheckboxFormValueChange($event)"
                    [formControlName]="option.fieldName || ''"
                    (onChange)="toggleInputNumber($event.checked, option.fieldName || '')"
                    class="mr-3">
        </p-checkbox>
        <p-button [rounded]="true"
                  styleClass="w-2rem h-2rem"
                  ariaLabel="Description"
                  (click)="showDescription(option)"
                  icon="pi pi-info"></p-button>
      </div>
      <label class="cursor-pointer"
             for="{{ option.fieldName }}">
        <p-image styleClass="w-3rem"
                 [src]="getAccessoryImage(option.fieldName)"></p-image>
        <p class="option-item__label text-base w-full text-center mb-2">{{ option.label }}</p>
      </label>
      <div class="text-center">
        <p-inputNumber mode="decimal"
                       class="mx-auto"
                       *ngIf="isMultipleAccessory(option.fieldName)"
                       [disabled]="isOptionDisabeld(option.fieldName || '')"
                       [formControlName]="option.fieldName + 'Count'"
                       [showButtons]="true"
                       [min]="1">
        </p-inputNumber>
      </div>
    </li>
  </ul>
</div>

<p-dialog [(visible)]="descriptionVisible"
          [modal]="true"
          [closeOnEscape]="false"
          [draggable]="false"
          class="description-dialog"
          [resizable]="false">
  <ng-template pTemplate="header">
    <h3 class="m-0">{{ currentOption.label }}</h3>
  </ng-template>

  <ng-template pTemplate="content">
    <p-image [preview]="true"
             [appendTo]="'body'"
             src="/assets/images/track-designer/accessories/{{ currentOption.fieldName }}.png"></p-image>
    <p class="m-0 text-base text-justify"
       [innerHTML]="currentOption.description"></p>
  </ng-template>
</p-dialog>
