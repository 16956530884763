<div class="flex align-items-center gap-3 mb-3">
  <p-button icon="pi pi-arrow-left"
            ariaLabel="Back"
            (click)="onBack()"
            styleClass="w-5rem">
  </p-button>
</div>
<p-steps [model]="specQuestions"
         [readonly]="true"
         [activeIndex]="activeStep">
</p-steps>
<form [formGroup]="specForm"
      class="flex flex-column flex-grow-1 overflow-hidden">
  <ng-container [ngSwitch]="activeStep">
    <ng-container *ngSwitchCase="0">
      <app-track-type class="flex flex-grow-1 py-4"
                      [form]="specForm"
                      [question]="specQuestions[0].question">
      </app-track-type>
    </ng-container>
    <ng-container *ngSwitchCase="1">
      <app-track-size class="flex flex-grow-1"
                      [question]="specQuestions[1].question"
                      [form]="specForm">
      </app-track-size>
    </ng-container>
    <ng-container *ngSwitchCase="2">
      <app-track-frames class="flex flex-grow-1"
                        [questions]="specQuestions[2].questions"
                        [form]="specForm"
                        [isInternalUser]="isInternalUser"
                        [trackType]="trackType">
      </app-track-frames>
    </ng-container>
    <ng-container *ngSwitchCase="3">
      <app-track-controller class="flex flex-grow-1 py-4 h-full"
                            [form]="specForm"
                            [question]="specQuestions[3].question">
      </app-track-controller>
    </ng-container>
    <ng-container *ngSwitchCase="4">
      <app-track-controller-options class="flex flex-column flex-grow-1 py-4 justify-content-center"
                                    [formPrefilled]="!!track.specification"
                                    [questions]="specQuestions[4].questions"
                                    [form]="specForm">
      </app-track-controller-options>
    </ng-container>
    <ng-container *ngSwitchCase="5">
      <app-track-interconnect class="flex flex-grow-1 py-4"
                              [question]="specQuestions[5].question"
                              [form]="specForm">

      </app-track-interconnect>
    </ng-container>
    <ng-container *ngSwitchCase="6">
      <app-track-shuttles class="flex flex-grow-1 py-4"
                          [questions]="specQuestions[6].questions"
                          [form]="specForm">

      </app-track-shuttles>
    </ng-container>
    <ng-container *ngSwitchCase="7">
      <app-track-accessories class="flex flex-grow-1 py-4"
                             [question]="specQuestions[7].question"
                             [form]="specForm">

      </app-track-accessories>
    </ng-container>
    <ng-container *ngSwitchCase="8">
      <app-track-additionals class="flex flex-column flex-grow-1 py-4 justify-content-center"
                             [questions]="specQuestions[8].questions"
                             [form]="specForm">

      </app-track-additionals>
    </ng-container>
  </ng-container>
</form>
<div class="flex justify-content-between">
  <div>
    <p-button icon="pi pi-arrow-left"
              *ngIf="activeStep"
              (click)="prevQuestion()"
              label="Back">
    </p-button>
  </div>
  <div>
    <p-button icon="pi pi-arrow-right"
              *ngIf="isNextVisible; else submitButton"
              [disabled]="isNextDisabled()"
              (click)="nextQuestion()"
              label="Next"
              iconPos="right">
    </p-button>
    <ng-template #submitButton>
      <p-button icon="pi pi-check"
                class="p-button-success"
                [disabled]="isSubmitDisabled()"
                (click)="submitSpec()"
                [loading]="isLoading"
                label="Submit"
                iconPos="right">
      </p-button>
    </ng-template>
  </div>
</div>
<p-confirmDialog [style]="{width: '50vw'}"
                 [baseZIndex]="10000"
                 rejectButtonStyleClass="p-button-text"></p-confirmDialog>
