<form class="flex flex-column flex-grow-1 h-full overflow-auto"
      [formGroup]="form">
  <h2 class="text-center mt-6">Select the following controller options:</h2>
  <div class="flex flex-column flex-grow-1 justify-content-center">
    <ng-container *ngIf="isPowerSupplyAvailable">
      <p-divider align="center">
        <h5 class="question m-0">{{ powerSupplyQuestion.questionText }}</h5>
      </p-divider>
      <ul class="options-list _center">
        <ng-container *ngFor="let item of powerSupplyQuestion.options">
          <li class="option-item _bordered w-3">
            <label class="option-item__inner _inline">
              <p-radioButton formControlName="controlPanelInputVoltage"
                             [value]="item.value"></p-radioButton>
              <p class="option-item__label text-base w-full text-center">{{ item.label }}</p>
            </label>
          </li>
        </ng-container>
      </ul>
    </ng-container>

    <p-divider align="center">
      <h5 class="question m-0">{{ plcInterfaceQuestion.questionText }}</h5>
    </p-divider>
    <ul class="options-list _center"
        formGroupName="plcOptions">
      <ng-container *ngFor="let item of plcInterfaceQuestion.options">
        <div class="flex flex-column w-2 mx-3">
          <li class="option-item w-full m-0 _bordered"
              [ngClass]="{
        '_disabled': isSymphoniProject
      }">
            <label class="option-item__inner align-items-center _inline">
              <p-radioButton formControlName="communicationType"
                             [value]="item.value"></p-radioButton>
              <p class="option-item__label text-base w-full mb-0 text-center">{{ item.label }}</p>
              <i *ngIf="isSymphoniProject && !isPowerlink(item.value)"
                 class="pi pi-info-circle ml-3"
                 style="color: #f7941d"></i>
            </label>
          </li>
        </div>
      </ng-container>
    </ul>

    <p class="text-center font-italic"
       *ngIf="isSymphoniProject"><i class="pi pi-info-circle"
         style="color: #f7941d"></i> This option is not available for Symphoni
      enabled systems.</p>

    <ng-container *ngIf="isProfinetSelected">
      <p-divider align="center">
        <h5 class="question m-0">Controller type:</h5>
      </p-divider>
      <ul class="options-list _center"
          formGroupName="plcOptions">
        <li class="option-item flex _bordered w-4">
          <label class="option-item__inner align-items-center _inline">
            <p-radioButton [value]="true"
                           [formControlName]="'integratedProfinetSlave'"></p-radioButton>
            <p class="option-item__label text-base w-full m-0 text-center ml-3">Integrated i3 SuperTrak™ Controller and
              PROFINET™ Slave</p>
          </label>
        </li>
        <li class="option-item flex _bordered w-4">
          <label class="option-item__inner align-items-center _inline">
            <p-radioButton [value]="false"
                           [formControlName]="'integratedProfinetSlave'"></p-radioButton>
            <p class="option-item__label text-base w-full m-0 text-center ml-3">High Performance i3 SuperTrak™
              Controller</p>
          </label>
        </li>
      </ul>
    </ng-container>

    <ng-container formGroupName="plcOptions"
                  *ngIf="isSlaveInterfacesNumberVisible">
      <p-divider align="center">
        <h5 class="question m-0">Slave Interfaces</h5>
      </p-divider>
      <div class="flex justify-content-center">
        <div class="option-item flex justify-content-center _bordered w-3">
          <label class="option-item__inner align-items-center">
            <p class="option-item__label text-base w-full m-0 mb-2 text-center">Number of Slave Interfaces</p>
            <p-inputNumber mode="decimal"
                           [formControlName]="'plcCommInterfaceCount'"
                           [showButtons]="true"
                           [min]="1"
                           [max]="4">
            </p-inputNumber>
          </label>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="isKitAvailable">
      <p-divider align="center">
        <h5 class="question m-0">{{ controllerKitsQuestion.questionText }}</h5>
      </p-divider>
      <ul class="options-list _center">
        <li class="option-item flex relative overflow-hidden _bordered w-3">
          <label class="option-item__inner align-items-center _inline">
            <p-checkbox [binary]="true"
                        (click)="disableCheckboxFormValueChange($event)"
                        [formControlName]="controllerKitsQuestion.options[0].fieldName || ''"></p-checkbox>
            <p class="option-item__label text-base w-full m-0 text-center ml-3">{{
              controllerKitsQuestion.options[0].label }}
            </p>
          </label>
          <div class="hidden-info flex align-items-center justify-content-end w-5rem">
            <p-button [rounded]="true"
                      severity="warning"
                      ariaLabel="Description"
                      (click)="showDescription(controllerKitsQuestion.options[0])"
                      icon="pi pi-info">
            </p-button>
          </div>
        </li>
        <li class="option-item flex relative overflow-hidden _bordered w-3">
          <label class="option-item__inner align-items-center _inline">
            <p-checkbox [binary]="true"
                        (click)="disableCheckboxFormValueChange($event)"
                        [formControlName]="controllerKitsQuestion.options[1].fieldName || ''"></p-checkbox>
            <p class="option-item__label text-base w-full m-0 text-center ml-3">{{
              controllerKitsQuestion.options[1].label }}
            </p>
          </label>
          <div class="hidden-info flex align-items-center justify-content-end w-5rem">
            <p-button [rounded]="true"
                      severity="warning"
                      ariaLabel="Description"
                      (click)="showDescription(controllerKitsQuestion.options[1])"
                      icon="pi pi-info">
            </p-button>
          </div>
        </li>
        <li class="option-item flex _bordered w-3">
          <label class="option-item__inner align-items-center">
            <p class="option-item__label text-base w-full m-0 mb-2 text-center"
               [ngClass]="{
              'text-color-secondary': isKitDisabled()
            }">{{ controllerKitsQuestion.options[2].label }}</p>
            <p-inputNumber mode="decimal"
                           [disabled]="isKitDisabled()"
                           [formControlName]="controllerKitsQuestion.options[2].fieldName || ''"
                           [showButtons]="true"
                           [min]="0"
                           [max]="3">
            </p-inputNumber>
          </label>
        </li>
      </ul>
    </ng-container>
  </div>
</form>

<p-dialog [(visible)]="descriptionVisible"
          [modal]="true"
          [closeOnEscape]="false"
          [draggable]="false"
          class="description-dialog"
          (onHide)="clearDescription()"
          [resizable]="false">
  <ng-template pTemplate="header">
    <h3 class="m-0">{{ selectedQuestion?.label }}</h3>
  </ng-template>

  <ng-template pTemplate="content">
    <p class="m-0 text-base text-justify"
       [innerText]="selectedQuestion?.description"></p>
  </ng-template>
</p-dialog>
