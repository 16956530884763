<form class="flex flex-column flex-grow-1 h-full overflow-auto"
      [formGroup]="form">
  <h2 class="text-center mt-6">Select the following additional options:</h2>

  <div class="flex flex-column flex-grow-1 justify-content-center">
    <ng-container *ngIf="
        superTrakSyncQuestion.isEnabled
        ?
        superTrakSyncQuestion.isEnabled(userService, identityService)
        :
        true">
      <p-divider align="center">
        <h5 class="question m-0">{{ superTrakSyncQuestion.questionText }}</h5>
      </p-divider>
      <ul class="options-list _center">
        <li>
          <div class="option-item _bordered">
            <label class="option-item__inner _inline">
              <p-checkbox [formControlName]="superTrakSyncQuestion.fieldName"
                          class="mr-2"
                          (click)="disableCheckboxFormValueChange($event)"
                          [binary]="true"></p-checkbox>
              <p class="option-item__label text-base w-full text-center">{{ superTrakSyncQuestion.options[0].label }}
              </p>
            </label>
          </div>
          <p class="font-italic">*SuperTrak GEN3™ Sync software is required to operate ATS Omnitrak™ and ATS Symphoni™
            systems
          </p>
        </li>
      </ul>
    </ng-container>

    <p-divider align="center">
      <h5 class="question m-0">{{ academyCurriculaQuestion.questionText }}</h5>
    </p-divider>
    <ul class="options-list _center _column align-items-center">
      <li class="
        option-item
        _bordered
        w-3">
        <label class="option-item__inner _inline">
          <p-checkbox [formControlName]="academyCurriculaQuestion.fieldName"
                      (onChange)="handleAcademyCurriculaCHange($event.checked)"
                      (click)="disableCheckboxFormValueChange($event)"
                      class="mr-2"
                      [binary]="true"></p-checkbox>
          <p class="option-item__label text-base w-full text-center">{{ academyCurriculaQuestion.options[0].label
            }}
          </p>
        </label>
      </li>
      <li class="
           option-item
           text-center
           _bordered
           py-3
           w-4">
        <p class="option-item__label text-base w-full text-center">{{ peopleToTrainQuestion.questionText }}</p>

        <p-inputNumber mode="decimal"
                       [disabled]="isCurriculaDisabled"
                       [formControlName]="peopleToTrainQuestion.fieldName"
                       [showButtons]="true"
                       [min]="1">
        </p-inputNumber>
      </li>
    </ul>
  </div>
</form>
