import {
  AfterViewInit,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import {
  AuthenticationResult,
  EventMessage,
  EventType,
  InteractionStatus,
} from '@azure/msal-browser';
import { IdTokenClaims } from '@azure/msal-common';
import { MessageService } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { filter, Subject, take, takeUntil } from 'rxjs';
import { User } from 'src/app/common/models/user/user';
import { LoginGatewayComponent } from 'src/app/configuration/login-gateway/login-gateway.component';
import { EditorModeService } from 'src/app/configuration/services/editor-mode/editor-mode.service';
import { AppLoadingService } from 'src/app/core/services/app-loading.service';
import { IdentityService } from 'src/app/core/services/identity.service';
import { MaintenanceService } from 'src/app/core/services/maintenance/maintenance.service';
import { environment } from 'src/environments/environment';
import { AppLayoutService, SideBarState } from '../service/app.layout.service';

type IdTokenClaimsWithPolicyId = IdTokenClaims & {
  acr?: string;
  tfp?: string;
};

@Component({
  selector: 'app-layout',
  templateUrl: './app.layout.component.html',
  styleUrls: ['./app.layout.component.scss'],
})
export class AppLayoutComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('layoutWrapper') layoutWrapper: ElementRef;

  isLoading = false;
  isHelpModalVisible = false;
  preventLogin = false;
  editorMode = false;

  wideScreen = false;

  private targetRoute: string | null;
  private sideBarState: SideBarState;

  private readonly _destroying$ = new Subject<void>();

  get isSidebarExpanded(): boolean {
    return this.sideBarState === SideBarState.Expanded;
  }

  constructor(
    private msalBroadcastService: MsalBroadcastService,
    private authService: MsalService,
    private identityService: IdentityService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private dialogService: DialogService,
    private appLoadingService: AppLoadingService,
    private messageService: MessageService,
    private editorModeService: EditorModeService,
    private layoutService: AppLayoutService,
    private maintenanceService: MaintenanceService
  ) {
    this.maintenanceService
      .checkMaintenanceStatus()
      .pipe(take(1))
      .subscribe(() => {
        if (!environment.production) {
          console.log('Maintenance checked');
        }
      });
    this.identityService.logInRequired$
      .pipe(
        filter((showGateway: boolean) => showGateway),
        takeUntil(this._destroying$)
      )
      .subscribe(() => {
        this.dialogService.open(LoginGatewayComponent, {
          header: 'Login or Register',
        });
      });

    // TODO: Do a server check if under maintenance.
    this.appLoadingService.isLoading$
      .pipe(takeUntil(this._destroying$))
      .subscribe((res) => {
        this.isLoading = res;
      });

    this.layoutService.sidebarState$
      .pipe(takeUntil(this._destroying$))
      .subscribe((state) => {
        this.sideBarState = state;
      });

    this.router.events.pipe(takeUntil(this._destroying$)).subscribe((event) => {
      if (event instanceof NavigationEnd) {
        const url = event.url;

        this.wideScreen = url === '/dashboard' || url === '/';
      }
    });
  }

  ngOnInit(): void {
    this.setActiveAccount();
    this.initializeAuth();
  }

  ngAfterViewInit(): void {
    this.editorModeService.editorMode$
      .pipe(takeUntil(this._destroying$))
      .subscribe((res) => {
        this.editorMode = res;
      });

    this.layoutService.scrollTop$
      .pipe(takeUntil(this._destroying$))
      .subscribe((offsetTop) => {
        if (offsetTop) {
          const wrapperOffsetTop = this.layoutWrapper.nativeElement.offsetTop;
          this.layoutWrapper.nativeElement.scrollTo({
            behavior: 'smooth',
            top: offsetTop - wrapperOffsetTop,
          });
        }
      });

    this.layoutService.showHelpModal$
      .pipe(takeUntil(this._destroying$))
      .pipe(filter((showHelpModal: boolean) => showHelpModal))
      .subscribe(() => {
        this.isHelpModalVisible = true;
      });
  }

  ngOnDestroy(): void {
    this._destroying$.unsubscribe();
  }

  setActiveUser() {
    const users = this.authService.instance.getAllAccounts();
    const user = this.authService.instance.getActiveAccount();
    const currentUser = this.identityService.currentUser;

    if (!environment.production) {
      console.log(users);
    }

    if (this.preventLogin) {
      this.authService.logout();
      console.error('error failed to acquire token');
    }

    if (user != null && !currentUser && !this.preventLogin) {
      this.appLoadingService.startLoading();
      this.identityService.updateUser().subscribe({
        next: (user: User) => {
          console.log('Logged in as: ', user);
          this.appLoadingService.stopLoading();
        },
        error: (error) => {
          console.error(error);
          this.appLoadingService.stopLoading();
          this.showError();
        },
      });
    }
    // What if user is null?
    // Show as logged out.
  }

  initializeAuth() {
    // Check if interaction is finished.
    this.appLoadingService.startLoading();
    this.msalBroadcastService.inProgress$
      .pipe(
        filter((status: InteractionStatus) => {
          return (
            status === InteractionStatus.None ||
            status === InteractionStatus.Startup
          );
        }),
        takeUntil(this._destroying$)
      )
      .subscribe({
        next: () => {
          this.appLoadingService.stopLoading();
          this.setActiveAccount();
          this.setActiveUser();
        },
        error: (error) => {
          console.error(error);
          this.appLoadingService.stopLoading();
          this.showError();
        },
      });

    this.msalBroadcastService.msalSubject$
      .pipe(
        filter(
          (msg: EventMessage) =>
            msg.eventType === EventType.ACQUIRE_TOKEN_FAILURE
        ),
        takeUntil(this._destroying$)
      )
      .subscribe((_result: EventMessage) => {
        // Failed to acquire token. This could be that we have returned from the login page.
        this.preventLogin = true;
      });

    this.msalBroadcastService.msalSubject$
      .pipe(
        filter(
          (msg: EventMessage) =>
            msg.eventType === EventType.LOGIN_SUCCESS ||
            msg.eventType === EventType.ACQUIRE_TOKEN_SUCCESS ||
            msg.eventType === EventType.SSO_SILENT_SUCCESS
        ),
        takeUntil(this._destroying$)
      )
      .subscribe((result: EventMessage) => {
        const payload = result.payload as AuthenticationResult;
        const idtoken = payload.idTokenClaims as IdTokenClaimsWithPolicyId;

        if (
          idtoken.acr ===
            environment.msalConfig.b2cConfig.policies.signIn.name ||
          idtoken.tfp === environment.msalConfig.b2cConfig.policies.signIn.name
        ) {
          this.authService.instance.setActiveAccount(payload.account);
        }

        this.targetRoute = localStorage.getItem('ATS-location');

        if (this.targetRoute) {
          const url = this.targetRoute.split('?')[0];
          const queryParams = this.parseQueryParameters(
            this.targetRoute.split('?')[1]
          );
          this.router.navigate([url], {
            queryParams,
            queryParamsHandling: 'merge',
          });
          localStorage.removeItem('ATS-location');
        }

        this.preventLogin = false;

        /**
         * For the purpose of setting an active account for UI update, we want to consider only the auth response resulting
         * from SUSI flow. "acr" claim in the id token tells us the policy (NOTE: newer policies may use the "tfp" claim instead).
         * To learn more about B2C tokens, visit https://docs.microsoft.com/en-us/azure/active-directory-b2c/tokens-overview
         */
        // if (
        //   idtoken.acr === environment.b2cPolicies.names.editProfile ||
        //   idtoken.tfp === environment.b2cPolicies.names.editProfile
        // ) {
        //   // retrieve the account from initial sing-in to the app
        //   const originalSignInAccount = this.authService.instance
        //     .getAllAccounts()
        //     .find(
        //       (account: AccountInfo) =>
        //         account.idTokenClaims?.oid === idtoken.oid &&
        //         account.idTokenClaims?.sub === idtoken.sub &&
        //         ((account.idTokenClaims as IdTokenClaimsWithPolicyId).acr ===
        //           environment.b2cPolicies.names.signUpSignIn ||
        //           (account.idTokenClaims as IdTokenClaimsWithPolicyId).tfp ===
        //             environment.b2cPolicies.names.signUpSignIn)
        //     );

        //   const signUpSignInFlowRequest: SsoSilentRequest = {
        //     authority:
        //       environment.b2cPolicies.authorities.signUpSignIn.authority,
        //     account: originalSignInAccount,
        //   };

        //   // silently login again with the signUpSignIn policy
        //   this.authService.ssoSilent(signUpSignInFlowRequest);
        // }

        /**
         * Below we are checking if the user is returning from the reset password flow.
         * If so, we will ask the user to reauthenticate with their new password.
         * If you do not want this behavior and prefer your users to stay signed in instead,
         * you can replace the code below with the same pattern used for handling the return from
         * profile edit flow (see above ln. 74-92).
         */
        // if (
        //   idtoken.acr === environment.b2cPolicies.names.resetPassword ||
        //   idtoken.tfp === environment.b2cPolicies.names.resetPassword
        // ) {
        //   const signUpSignInFlowRequest: RedirectRequest | PopupRequest = {
        //     authority:
        //       environment.b2cPolicies.authorities.signUpSignIn.authority,
        //     scopes: [...environment.apiConfig.scopes],
        //     prompt: PromptValue.LOGIN, // force user to reauthenticate with their new password
        //   };

        //   this.login(signUpSignInFlowRequest);
        // }

        return result;
      });
  }

  setActiveAccount() {
    const activeAccount = this.authService.instance.getActiveAccount();

    if (
      !activeAccount &&
      this.authService.instance.getAllAccounts().length > 0
    ) {
      const accounts = this.authService.instance.getAllAccounts();
      this.authService.instance.setActiveAccount(accounts[0]);
    }
  }

  showError() {
    this.messageService.add({
      severity: 'error',
      summary: 'Error',
      detail: 'There was an issue communicating with the server',
    });
  }

  parseQueryParameters(queryString: string): Record<string, string> {
    const params: { [key: string]: string } = {};
    if (queryString) {
      const pairs = queryString.split('&');
      pairs.forEach((pair) => {
        const [key, value] = pair.split('=');
        params[key] = value;
      });
    }
    return params;
  }

  toggleSideBar(): void {
    const sidebarState = this.isSidebarExpanded
      ? SideBarState.Slim
      : SideBarState.Expanded;

    this.layoutService.setSidebarState(sidebarState);
  }
}
