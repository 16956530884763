<section class="dashboard-banner flex align-items-center">
  <div class="col-6">
    <h1 class="dashboard-banner__title text-white">Let's Build a New System and Request a Quote.</h1>
    <div class="inline-flex">

      <button class="dashboard-banner__btn inline-flex align-items-center p-card p-button p-button-warning"
              (click)="openProject()"
              aria-label="Start Project"
              type="button">
        <i class="pi pi-file text-2xl mr-3"></i>
        <p class="my-0">Start a new project</p>
      </button>
    </div>
  </div>
  <div class="col-6">
    <p-carousel [value]="trakSlides"
                [numVisible]="1"
                [numScroll]="1"
                [autoplayInterval]="3000"
                [showNavigators]="false"
                [circular]="false">
      <ng-template let-trak
                   pTemplate="item">
        <div class="text-center">
          <img src="/assets/images/track-designer/{{ trak }}-stands-pallets.png"
               [alt]="trak"
               class="w-8" />
        </div>
      </ng-template>
    </p-carousel>

  </div>
</section>
<section class="dashboard-help flex flex-grow-1 sm:flex-column lg:flex-row">
  <div class="flex flex-column flex-grow-1 col-12 lg:col-6 text-center">
    <div class="my-auto">
      <h3 class="dashboard-help__title">Don't Know How to Start? Watch the Video!</h3>
      <p>To start designing your system now, create a new project and a system within that project. You will be able to
        register to save your system, connect with our team and get pricing information.</p>
    </div>
    <p>For any assistance, please contact SuperTrak Support <a
         href="mailto:team@supertrakconveyance.com?subject=Online Configuration Tool - Need Assistance">team@supertrakconveyance.com</a>.
    </p>
    <p class="mb-auto">Do not hesitate to visit <a href="https://supertrakconveyance.com"
         target="_blank">supertrakconveyance.com</a> for product information.</p>
    <p class="text-sm font-italic">All images provided are for reference only.</p>
  </div>
  <div class="col-12 lg:col-6 flex justify-content-center align-items-center">
    <div class="dashboard-help__video w-9">
      <iframe width="100%"
              height="100%"
              class="mx-auto"
              src="https://www.youtube.com/embed/ctJjcQixdJQ?si=qIz-rPoiqV1ADLqS"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerpolicy="strict-origin-when-cross-origin"
              allowfullscreen></iframe>
    </div>
  </div>
</section>
