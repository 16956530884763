import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { ButtonModule } from 'primeng/button';
import { CheckboxModule } from 'primeng/checkbox';
import { ColorPickerModule } from 'primeng/colorpicker';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { DividerModule } from 'primeng/divider';
import { DropdownModule } from 'primeng/dropdown';
import { DynamicDialogModule } from 'primeng/dynamicdialog';
import { ImageModule } from 'primeng/image';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { OrderListModule } from 'primeng/orderlist';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { RadioButtonModule } from 'primeng/radiobutton';
import { SidebarModule } from 'primeng/sidebar';
import { TooltipModule } from 'primeng/tooltip';
import { ClickOutsideDirective } from 'src/app/core/directives/click-outside/click-outside.directive';
import { SharedModule } from 'src/app/shared/shared.module';
import { NewProcessComponent } from './components/new-process/new-process.component';
import { ProcessDetailsComponent } from './components/process-details/process-details.component';
import { ProcessItemComponent } from './components/process-item/process-item.component';
import { ProcessListComponent } from './components/process-list/process-list.component';
import { ProcessMapCreatorComponent } from './components/process-map-creator/process-map-creator.component';
import { ProcessMapTemplateComponent } from './components/process-map-template/process-map-template.component';
import { ProcessMapComponent } from './components/process-map/process-map.component';
import { TrackCreatorComponent } from './components/track-creator/track-creator.component';
import { TrackTypeModalComponent } from './components/track-type-modal/track-type-modal.component';
import trackCreatorRoutes from './track-creator.routing';

@NgModule({
  declarations: [
    TrackCreatorComponent,
    ProcessMapComponent,
    ProcessMapCreatorComponent,
    ProcessMapTemplateComponent,
    NewProcessComponent,
    ProcessListComponent,
    ProcessDetailsComponent,
    ProcessItemComponent,
    ClickOutsideDirective,
    TrackTypeModalComponent,
  ],
  imports: [
    CommonModule,
    ButtonModule,
    TooltipModule,
    SidebarModule,
    DropdownModule,
    FormsModule,
    ReactiveFormsModule,
    InputNumberModule,
    InputTextareaModule,
    InputTextModule,
    CheckboxModule,
    SharedModule,
    DividerModule,
    ColorPickerModule,
    RadioButtonModule,
    ConfirmDialogModule,
    OrderListModule,
    OverlayPanelModule,
    DynamicDialogModule,
    ImageModule,
    RouterModule.forChild(trackCreatorRoutes),
  ],
  providers: [],
})
export class TrackCreatorModule {}
