import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import {
  InteractionRequiredAuthError,
  SilentRequest,
} from '@azure/msal-browser';
import { catchError, Observable, of, switchMap, throwError } from 'rxjs';
import { protectedResources } from '../config/auth-config';
import { IdentityService } from '../services/identity.service';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor(
    private msalService: MsalService,
    private identityService: IdentityService
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status && error.status === 401) {
          return this.refreshTokenAndRetry(request, next);
        }
        return throwError(() => error);
      })
    );
  }

  refreshTokenAndRetry(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const tokenRequest: SilentRequest = {
      scopes: [
        ...protectedResources.api.scopes.read,
        ...protectedResources.api.scopes.write,
      ],
      forceRefresh: true,
    };
    return this.msalService.acquireTokenSilent(tokenRequest).pipe(
      switchMap((response: any) => {
        const updatedRequest = request.clone({
          setHeaders: {
            Authorization: `Bearer ${response.accessToken}`,
          },
        });

        return next.handle(updatedRequest);
      }),
      catchError((error) => {
        if (!(error instanceof InteractionRequiredAuthError)) {
          this.handleError();
          return throwError(() => error);
        }

        const userAccount = this.msalService.instance.getActiveAccount();

        let email;

        if (userAccount) {
          email = userAccount.idTokenClaims?.['upn'] as string;

          const authenticationSource =
            (userAccount.idTokenClaims?.['authenticationSource'] as string) ||
            null;
          const idp_tenantId =
            (userAccount.idTokenClaims?.['idp_tenantId'] as string) || null;
          if (
            authenticationSource &&
            idp_tenantId &&
            this.isInternalUser(authenticationSource, idp_tenantId)
          ) {
            const domainHint = email.split('@')[1] as
              | 'atsautomation.com'
              | 'supertrakconveyance.com';
            return this.identityService.ATSSignUpLogin(email, domainHint).pipe(
              catchError((error) => {
                this.handleError();
                return throwError(() => error);
              })
            );
          }

          if (email) {
            return this.identityService.login(email).pipe(
              catchError((error) => {
                this.handleError();
                return throwError(() => error);
              })
            );
          }
        }

        return of();
      })
    );
  }

  handleError() {
    catchError((error) => {
      console.error(error);
      return of(false);
    });
  }

  isInternalUser(authenticationSource: string, idp_tenantId: string): boolean {
    return (
      authenticationSource === 'socialIdpAuthentication' &&
      !!idp_tenantId &&
      idp_tenantId === 'f5b2e964-6123-4a27-9a8c-8c1dfbf93791'
    );
  }
}
