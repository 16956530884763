import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Subject, takeUntil } from 'rxjs';
import { UserService } from 'src/app/core/services/user.service';
import { ETurn } from '../../../common/enums/eTurn.enum';
import { UserCompanyName } from '../../enums/user-company-name.enum';
import { Question } from '../../track-designer-questions';

@Component({
  selector: 'app-track-type',
  templateUrl: './track-type.component.html',
  styleUrls: ['./track-type.component.scss'],
  host: {
    class: 'h-full',
  },
})
export class TrackTypeComponent implements OnInit, OnDestroy {
  @Input() question: Question | undefined;
  @Input() form: FormGroup;

  trackTypeImg: string;

  isWelcomeMessageVisible = true;
  eTurns = ETurn;

  private readonly _destroying$ = new Subject<void>();

  get trackType(): ETurn {
    return this.form.get('eTurn')?.value;
  }

  get is500Selected(): boolean {
    return this.form.get('eTurn')?.value === ETurn.Curve500;
  }

  get isSymphoniAvailable(): boolean {
    return (
      (this.userService.isUserFromCompany(UserCompanyName.LSCambridge) ||
        this.userService.isUserFromCompany(UserCompanyName.LSChicago) ||
        this.userService.isUserFromCompany(UserCompanyName.LSRemCambridge) ||
        this.userService.isUserFromCompany(UserCompanyName.Munich) ||
        this.userService.isUserFromCompany(UserCompanyName.LifeScience)) &&
      this.is500Selected
    );
  }

  constructor(private userService: UserService) {}

  ngOnInit(): void {
    this.form
      .get('eTurn')
      ?.valueChanges.pipe(takeUntil(this._destroying$))
      .subscribe((trackType) => {
        this.isWelcomeMessageVisible = false;
        this.trackTypeImg = this.getImageUrl(trackType);

        this.validateSymphoniOption(trackType);
      });
  }

  ngOnDestroy(): void {
    this._destroying$.unsubscribe();
  }

  showImage(imgName: string): void {
    this.trackTypeImg = `/assets/images/track-designer/${imgName}-stands-pallets.png`;
    this.isWelcomeMessageVisible = false;
  }

  setPlaceholderImage(): void {
    this.trackTypeImg = 'https://plchldr.co/i/500x250?text=Track%20Type';
  }

  getImageUrl(trackType: ETurn): string {
    return `/assets/images/track-designer/${trackType}-stands-pallets.png`;
  }

  validateSymphoniOption(trackType: ETurn): void {
    const isSymphoniControl = this.form.get('isSymphoni');

    if (trackType !== ETurn.Curve500 && isSymphoniControl?.value) {
      isSymphoniControl?.setValue(false);
    }
  }
}
