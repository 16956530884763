<div class="flex flex-column gap-3">
  <div class="flex flex-column xl:flex-row">
    <div class="flex col-12 xl:col-6 align-items-center">
      <p-image [src]="getTrackImage()"
               class="max-w-full"
               [preview]="true"
               appendTo="body"
               styleClass="max-w-full"
               alt="SuperTrak graphics">
      </p-image>
    </div>
    <p-divider class="col-1 hidden xl:block"
               layout="vertical"></p-divider>
    <div class="flex col-12 xl:col-5">
      <ul class="flex flex-column justify-content-center w-full gap-3 list-none p-0">
        <ng-container *ngFor="let track of trackTypeList">
          <li *ngIf="isOptionAvailable(track.value)">
            <button type="button"
                    class="track-selection-btn _brand"
                    [ngClass]="{
                    '_selected': selectedTrack === track.value
                  }"
                    (click)="selectType(track.value)">
              <p class="relative font-bold">{{ track.fullLabel }}</p>
            </button>
          </li>
        </ng-container>
      </ul>
    </div>
  </div>
  <div class="flex justify-content-end">
    <p-button severity="secondary"
              styleClass="font-bold"
              (onClick)="closeDialog()"
              label="Cancel"></p-button>
    <p-button class="ml-2"
              styleClass="font-bold"
              (onClick)="changeTrackType()"
              label="Confirm"></p-button>
  </div>
</div>
