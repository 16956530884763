import { Routes } from '@angular/router';
import { AppRoutes } from '../app.routes.const';
import { AuthGuard } from '../guards/authGuard.guard';
import { ConfigurationsListComponent } from './components/configurations-list/configurations-list.component';
import { ConfigurationsListResolver } from './components/configurations-list/configurations-list.resolver';

const configurationManagementRoutes: Routes = [
  {
    path: AppRoutes.ConfigurationManagement.path,
    children: [
      {
        path: '',
        component: ConfigurationsListComponent,
        resolve: [ConfigurationsListResolver],
        canActivate: [AuthGuard],
      },
    ],
  },
];

export default configurationManagementRoutes;
