<p-orderList #orderList
             class="process-list"
             [value]="processList"
             (onReorder)="reorderProcessMap(orderList.value)"
             [dragdrop]="true">
  <ng-template #itemTpl
               let-process
               let-i="index"
               pTemplate="item">
    <app-process-item [process]="process"
                      (onDeleteProcess)="deleteProcess(i)"
                      (onShowDetails)="showProcessDetails(i)">
    </app-process-item>
  </ng-template>
</p-orderList>

<p-confirmDialog #cd
                 [style]="{width: '400px'}">
  <ng-template pTemplate="footer">
    <p-button type="button"
              label="No"
              [text]="true"
              (click)="cd.reject()"></p-button>
    <p-button type="button"
              severity="danger"
              label="Yes"
              (click)="cd.accept()"></p-button>
  </ng-template>
</p-confirmDialog>
