import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  ValidationErrors,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationService, MessageService } from 'primeng/api';
import { DropdownChangeEvent } from 'primeng/dropdown';
import { DialogService } from 'primeng/dynamicdialog';
import {
  BehaviorSubject,
  Observable,
  Subject,
  Subscription,
  takeUntil,
  tap,
} from 'rxjs';
import { AppRoutes } from 'src/app/app.routes.const';
import {
  getVersionStatusColor,
  getVersionStatusLabel,
} from 'src/app/common/helpers/configuration-version-status';
import { Track } from 'src/app/common/models/track/track';
import { RoleEnum, User } from 'src/app/common/models/user/user';
import { ProductTypePipe } from 'src/app/common/pipes/product-type/product-type.pipe';
import { TrackService } from 'src/app/common/services/track-services/track.service';
import { AccessScope } from 'src/app/configuration-management/enums/access-scope.enum';
import { ApiUrlParams } from 'src/app/core/api-url-params';
import { AppLoadingService } from 'src/app/core/services/app-loading.service';
import { IdentityService } from 'src/app/core/services/identity.service';
import { UserDataService } from 'src/app/core/services/user-data.service';
import {
  AppConfirmationDialogRequest,
  AppConfirmationDialogService,
} from 'src/app/layout/service/app-confirmation-dialog.service';
import {
  ConfigurationVersion,
  VersionStatus,
} from '../models/configuration.model';
import { TrackRequest } from '../models/track-request.model';
import { QuoteRequestComponent } from '../quote-request/quote-request.component';
import { ProjectConfigurationService } from '../services/project-configuration/project-configuration.service';

@Component({
  selector: 'app-configuration-info',
  templateUrl: './configuration-info.component.html',
  styleUrls: ['./configuration-info.component.scss'],
  host: {
    class: 'flex flex-column gap-3',
  },
  providers: [ProductTypePipe],
})
export class ConfigurationInfoComponent
  implements OnChanges, OnInit, OnDestroy
{
  @Output() onSelectVersion = new EventEmitter<string>();
  @Output() onDeleteVersion = new EventEmitter<string>();

  @Input() versionId: string;
  @Input() configId: string;
  @Input() projectId: string;

  @Input() editingMode: boolean;
  @Input() editableForSales: boolean;

  isLoading: boolean;
  isNewTrackFormVisible: boolean;

  tracks: Track[] = [];
  isLoadingTracks = false;
  currentConfigurationVersion: ConfigurationVersion | undefined;
  isVersionEditable = true;
  isQuoteRequestDisabled = true;
  isEditingDueDate = false;
  isProcessingDueDate = false;

  updatingVersionStatus = false;

  minDate = this.getStartDate();
  maxDate = this.getMaxDate();
  selectedDate: Date;

  selectedVersionStatus: VersionStatus;
  versionStatusList: {
    label: string;
    value: VersionStatus;
  }[] = [
    {
      label: 'In Progress',
      value: VersionStatus.InProgress,
    },
    {
      label: 'Proposal Sent',
      value: VersionStatus.ProposalSent,
    },
    {
      label: 'Cancelled',
      value: VersionStatus.Cancelled,
    },
  ];

  configVersionOwnerDetails: User | null;

  tracksRequestSub$: Subscription;

  isEditingSalesComment = false;
  isSavingSalesComment = false;
  salesComment: string;

  createTrackForm = this.formBuilder.group({
    name: [
      '',
      [
        Validators.required,
        Validators.maxLength(200),
        Validators.minLength(5),
        this.duplicateNameValidator.bind(this),
      ],
    ],
    description: ['', [Validators.required, Validators.maxLength(200)]],
  });

  private versionOwnerId: BehaviorSubject<string | null> = new BehaviorSubject<
    string | null
  >(null);
  private readonly _destroying$ = new Subject<void>();

  get isSubmitted(): boolean {
    return (
      this.currentConfigurationVersion?.rfqStatus === VersionStatus.Submitted
    );
  }

  get isDraft(): boolean {
    return this.currentConfigurationVersion?.rfqStatus === VersionStatus.Draft;
  }

  get isVersionStatusEditable(): boolean {
    return (
      (this.identityService.currentUser?.hasRole(RoleEnum.SuperTrakSales) ||
        false) &&
      this.currentConfigurationVersion?.rfqStatus !== VersionStatus.Draft
    );
  }

  get canDelete(): boolean {
    if (!this.isDraft) return false;

    return !this.editingMode
      ? this.isVersionCreatedByOwner
      : !this.isVersionCreatedByOwner;
  }

  get isVersionCreatedByOwner(): boolean {
    return (
      this.projectConfigService.getConfiguration(this.configId)?.ownerId ===
      this.currentConfigurationVersion?.createdById
    );
  }

  get isTrackEditAvailable(): boolean {
    if (!this.editingMode) {
      return this.isDraft && this.isVersionCreatedByOwner;
    }
    return this.isDraft && !this.isVersionCreatedByOwner;
  }

  get isSalesUser(): boolean {
    return (
      this.identityService.currentUser?.hasRole(RoleEnum.SuperTrakSales) ||
      false
    );
  }

  get isSalesCommentEdited(): boolean {
    return (
      (this.currentConfigurationVersion?.salesComment || '') !==
      this.salesComment
    );
  }

  constructor(
    private trackService: TrackService,
    private projectConfigService: ProjectConfigurationService,
    private formBuilder: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private messageService: MessageService,
    private appConfirmationService: AppConfirmationDialogService,
    private appLoadingService: AppLoadingService,
    private identityService: IdentityService,
    private userDataService: UserDataService,
    private confirmationService: ConfirmationService,
    private productTypePipe: ProductTypePipe,
    private dialogService: DialogService
  ) {}

  ngOnInit(): void {
    this.trackService.tracks$
      .pipe(takeUntil(this._destroying$))
      .subscribe((tracks) => {
        this.tracks = tracks;
        this.isQuoteRequestDisabled = this.trackService.isEmptyTrackPresent;
      });

    this.projectConfigService.configurations$
      .pipe(takeUntil(this._destroying$))
      .subscribe(() => {
        if (this.versionId) {
          this.currentConfigurationVersion =
            this.projectConfigService.getConfigurationVersion(this.versionId);

          this.salesComment =
            this.currentConfigurationVersion?.salesComment || '';
          this.isVersionEditable = this.checkVersionEditability();
          this.selectedVersionStatus =
            this.currentConfigurationVersion?.rfqStatus || VersionStatus.Draft;
          this.selectedDate = this.currentConfigurationVersion?.requestedByDate
            ? new Date(this.currentConfigurationVersion?.requestedByDate)
            : this.minDate;
        }
      });

    this.trackService.tracksLoading
      .pipe(takeUntil(this._destroying$))
      .subscribe((isLoading) => {
        this.isLoadingTracks = isLoading;
      });

    this.versionOwnerId
      .asObservable()
      .pipe(takeUntil(this._destroying$))
      .subscribe((ownerId) => {
        if (ownerId) {
          this.retreiveUserInfo(ownerId);
        }
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    const versionId = changes['versionId'].currentValue;

    if (!!versionId) {
      this.currentConfigurationVersion =
        this.projectConfigService.getConfigurationVersion(versionId);
      this.selectedVersionStatus =
        this.currentConfigurationVersion?.rfqStatus || VersionStatus.Draft;
      if (this.editingMode) {
        this.versionOwnerId.next(
          this.currentConfigurationVersion?.createdById || null
        );
      }
      this.isVersionEditable = this.checkVersionEditability();
      this.updateTracks();
    }
  }

  ngOnDestroy(): void {
    this._destroying$.unsubscribe();
  }

  updateTracks(): void {
    if (!this.versionId) return;

    if (this.tracksRequestSub$) {
      this.tracksRequestSub$.unsubscribe();
    }

    this.appLoadingService.startLoadingBar();

    const accessScope = this.identityService.currentUser?.hasAccessScope(
      AccessScope.Sales
    )
      ? AccessScope.Sales
      : AccessScope.User;

    this.tracksRequestSub$ = this.trackService
      .getTracksForConfigVersion(this.versionId, accessScope)
      .pipe(takeUntil(this._destroying$))
      .subscribe({
        next: () => {
          this.tracks = this.trackService.getTracks();
        },
        error: (error) => {
          this.appLoadingService.stopLoadingBar();
          this.messageService.add({
            severity: 'error',
            summary: 'Error requesting tracks.',
            detail: error.status + ': ' + error.title,
          });
        },
        complete: () => {
          this.appLoadingService.stopLoadingBar();
        },
      });
  }

  isConfigurationEditable(): boolean {
    return (
      this.currentConfigurationVersion?.rfqStatus !== VersionStatus.Submitted
    );
  }

  isViewAvailableForSales(track: Track): boolean {
    return !!track.components.length;
  }

  onShowCreateTrack() {
    this.isNewTrackFormVisible = true;
  }

  onSubmitNewTrack() {
    this.createTrackForm.markAsTouched();

    if (!this.createTrackForm.valid) {
      return;
    }

    this.isLoading = true;
    const formValues = this.createTrackForm.getRawValue();
    const versionId =
      this.route.snapshot.queryParamMap.get(ApiUrlParams.VersionId) ?? '';

    this.trackService
      .createNewTrack(
        versionId,
        new TrackRequest(formValues.name, formValues.description)
      )
      .subscribe({
        next: (response) => {
          this.isNewTrackFormVisible = false;
          this.isLoading = false;
          console.log('Created new track: ', response);
        },
        error: (error) => {
          this.isNewTrackFormVisible = false;
          this.isLoading = false;
          this.messageService.add({
            severity: 'error',
            summary: 'Error creating a new track',
            detail: error.status + ': ' + error.title,
          });
          console.error(error);
        },
      });
  }

  onCancelNewTrack() {
    this.createTrackForm.reset();
    this.isNewTrackFormVisible = false;
  }

  onViewTrack(track: Track) {
    const url = !track.components.length
      ? AppRoutes.TrackDesigner.fullPath(this.configId, track.id)
      : AppRoutes.TrackDrilldown.fullPath(this.configId, track.id);
    this.router.navigate([url], {
      queryParams: {
        versionId: this.versionId,
      },
    });
  }

  onDeleteTrack(track: Track, index: number) {
    this.appConfirmationService.open(
      new AppConfirmationDialogRequest({
        header: 'Delete this track?',
        onBeforeClose: this.handleDeleteTrack(track, index),
      })
    );
  }

  private handleDeleteTrack(track: Track, index: number): Observable<boolean> {
    return this.trackService.removeTrack(track.id, index).pipe(
      tap({
        next: () => {
          console.log('Deleted track:', track.name);
        },
        error: (error) => {
          this.messageService.add({
            severity: 'error',
            summary: 'Error deleting the track.',
            detail: error.status + ': ' + error.title,
          });
          console.error(error);
        },
      })
    );
  }

  getStatusColor(rfqStatus: VersionStatus): {
    class: string;
    color: string;
  } {
    return getVersionStatusColor(rfqStatus);
  }

  getStatusLabel(rfqStatus: VersionStatus): string {
    return getVersionStatusLabel(rfqStatus);
  }

  duplicateNameValidator(control: AbstractControl): ValidationErrors | null {
    const trackExists = this.tracks.find(
      (track) => track.name === (control.value as string)
    );

    return trackExists
      ? {
          duplicateName: {
            value: 'Track name already exists. Please use a different name.',
          },
        }
      : null;
  }

  selectVersion(versionId: string): void {
    this.onSelectVersion.emit(versionId);
  }

  deleteVersion(versionId: string | undefined): void {
    this.onDeleteVersion.emit(versionId);
  }

  requestQuote(): void {
    if (this.isQuoteRequestDisabled) {
      this.messageService.add({
        severity: 'info',
        summary: 'Unable to request a quote.',
        detail: 'Please make sure you have configured your tracks.',
      });
      return;
    }

    this.dialogService.open(QuoteRequestComponent, {
      header: 'Quote Request',
      contentStyle: { overflow: 'unset' },
    });
  }

  cloneConfiguration(): void {
    if (!this.versionId) return;

    this.appLoadingService.startLoadingBar();
    this.projectConfigService
      .createNewVersion(this.configId, this.versionId)
      .pipe(takeUntil(this._destroying$))
      .subscribe({
        next: (newVersion: ConfigurationVersion) => {
          this.selectVersion(newVersion.id);
          console.log('The version was successfully copied.');
        },
        error: (error) => {
          this.appLoadingService.stopLoadingBar();
          this.messageService.add({
            severity: 'error',
            summary: 'Error copying the configuration version.',
            detail: error.status + ': ' + error.title,
          });
        },
        complete: () => {
          this.appLoadingService.stopLoadingBar();
        },
      });
  }

  onDeleteConfigurationVersion(): void {
    this.confirmationService.confirm({
      header: 'Delete Version',
      icon: 'pi pi-exclamation-triangle',
      message:
        'Are you sure you want to delete the configuration version permanently?',
      accept: () => {
        this.deleteConfigurationVersion();
      },
    });
  }

  deleteConfigurationVersion(): void {
    if (!this.versionId) return;

    this.appLoadingService.startLoadingBar();
    this.projectConfigService
      .deleteConfigVersionById(this.versionId)
      .subscribe({
        next: (versionId) => {
          this.deleteVersion(versionId);
        },
        error: (error) => {
          this.appLoadingService.stopLoadingBar();
          this.messageService.add({
            severity: 'error',
            summary: 'Failed deleting configuration version.',
            detail: error.status + ': ' + error.title,
          });
        },
        complete: () => {
          this.appLoadingService.stopLoadingBar();
        },
      });
  }

  checkVersionEditability(): boolean {
    const isDraft =
      this.currentConfigurationVersion?.rfqStatus === VersionStatus.Draft;

    return isDraft && (!this.editingMode || this.editableForSales);
  }

  isViewTrackAvailable(track: Track): boolean {
    return (
      (this.editingMode
        ? this.isVersionCreatedByOwner
        : !this.isVersionCreatedByOwner) && !!!track.components.length
    );
  }

  retreiveUserInfo(ownerId: string): void {
    if (!this.editingMode || this.isVersionCreatedByOwner) return;

    if (ownerId) {
      this.configVersionOwnerDetails = null;
      this.userDataService
        .getUserById(ownerId)
        .pipe(takeUntil(this._destroying$))
        .subscribe({
          next: (owner: User) => {
            this.configVersionOwnerDetails = owner;
          },
          error: (error) => {
            this.messageService.add({
              severity: 'error',
              summary: 'Failed deleting configuration version.',
              detail: error.status + ': ' + error.title,
            });
          },
        });
    }
  }

  transformProductTypeName(productType: string): string {
    return this.productTypePipe.transform(productType);
  }

  getStartDate(): Date {
    const currentDate = new Date();
    currentDate.setDate(currentDate.getDate() + 1);

    return currentDate;
  }

  getMaxDate(): Date {
    const date = this.getStartDate();
    date.setFullYear(this.getStartDate().getFullYear() + 1);
    return date;
  }

  isDateChanged(): boolean {
    if (!this.currentConfigurationVersion?.requestedByDate) return false;

    const initialDate = new Date(
      this.currentConfigurationVersion?.requestedByDate
    );

    return (
      this.selectedDate.getFullYear() !== initialDate.getFullYear() ||
      this.selectedDate.getMonth() !== initialDate.getMonth() ||
      this.selectedDate.getDate() !== initialDate.getDate()
    );
  }

  cancelRequestDateChange(): void {
    if (!this.currentConfigurationVersion?.requestedByDate) return;

    this.selectedDate = new Date(
      this.currentConfigurationVersion?.requestedByDate
    );

    this.isEditingDueDate = false;
  }

  updateRequestDate(): void {
    const date = this.selectedDate.toISOString();
    this.isProcessingDueDate = true;

    this.projectConfigService
      .updateConfigurationQuoteByDate(this.versionId, date)
      .pipe(takeUntil(this._destroying$))
      .subscribe({
        next: () => {},
        error: (error) => {
          this.appLoadingService.stopLoadingBar();
          this.messageService.add({
            severity: 'error',
            summary: 'Failed deleting configuration version.',
            detail: error.status + ': ' + error.title,
          });
        },
        complete: () => {
          this.isEditingDueDate = false;
          this.isProcessingDueDate = false;
        },
      });
  }

  changeVersionRfqStatus(event: DropdownChangeEvent): void {
    const selectedStatus = event.value as VersionStatus;

    const quote: {
      rfqStatus: VersionStatus;
      requestedByDate: string | undefined;
      rfqComment: null | string;
      configUrl: string;
    } = {
      rfqStatus: selectedStatus,
      requestedByDate: this.currentConfigurationVersion?.requestedByDate,
      rfqComment: this.currentConfigurationVersion?.rfqComment || null,
      configUrl: this.router.url,
    };

    this.updatingVersionStatus = true;
    this.projectConfigService
      .changeVersionRfqStatus(this.versionId, quote)
      .pipe(takeUntil(this._destroying$))
      .subscribe({
        next: () => {
          this.updatingVersionStatus = false;
        },
        error: (error) => {
          this.updatingVersionStatus = false;
          this.selectedVersionStatus =
            this.currentConfigurationVersion?.rfqStatus ||
            VersionStatus.Submitted;
          this.messageService.add({
            severity: 'error',
            summary: 'Failed to update.',
            detail: error.status + ': ' + error.title,
          });
        },
      });
  }

  saveComment(): void {
    this.isSavingSalesComment = true;

    this.projectConfigService
      .updateConfigurationVersion(this.versionId, {
        salesComment: this.salesComment,
      })
      .pipe(takeUntil(this._destroying$))
      .subscribe({
        next: () => {
          this.isSavingSalesComment = false;
          this.isEditingSalesComment = false;
        },
        error: (error) => {
          this.isSavingSalesComment = false;
          this.isEditingSalesComment = false;
          this.messageService.add({
            severity: 'error',
            summary: 'Failed to save comment.',
            detail: error.status + ': ' + error.title,
          });
        },
      });
  }

  declineComment(): void {
    this.salesComment = this.currentConfigurationVersion?.salesComment || '';
    this.isEditingSalesComment = false;
  }
}
