import { VersionStatus } from './configuration.model';
import { ProductType } from './product-type';

export class ConfigurationVersionRequest {
  productType: ProductType;
  id: string;
  name: string;
  version: number;
  rfqStatus: VersionStatus;

  constructor(
    productType: ProductType,
    id?: string,
    name?: string,
    version?: number,
    rfqStatus?: VersionStatus
  ) {
    this.productType = productType;
    if (id) {
      this.id = id;
    }
    if (name) {
      this.name = name;
    }
    if (version) {
      this.version = version;
    }
    if (rfqStatus) {
      this.rfqStatus = rfqStatus;
    }
  }
}
