import { Injectable } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AuthInitializationService {
  constructor(private msalService: MsalService) {}

  initializeAuth(): Observable<void> {
    return this.msalService.initialize();
  }
}
