import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { Observable, tap } from 'rxjs';
import { AppRoutes } from 'src/app/app.routes.const';
import { EditorModeService } from '../services/editor-mode/editor-mode.service';
import { Project } from './project';
import { ProjectService } from './project.service';

@Injectable({
  providedIn: 'root',
})
export class ProjectsResolver  {
  constructor(
    private projectService: ProjectService,
    private router: Router,
    private messageService: MessageService,
    private editorModeService: EditorModeService
  ) {}

  resolve(): Observable<Project[]> {
    return this.projectService.getProjects().pipe(
      tap((projects: Project[]) => {
        if (projects.length) {
          this.editorModeService.initEditorModeByProject(projects[0]);
        }
      }),
      tap({
        error: () => {
          this.handleError();
        },
      })
    );
  }

  handleError() {
    this.messageService.add({
      severity: 'error',
      summary: 'Error',
      detail: 'Failed to resolve projects',
    });
    this.router.navigate([AppRoutes.Dashboard.fullPath()]);
  }
}
