/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, HostBinding, Input, OnDestroy } from '@angular/core';
import { Subject, Subscription, takeUntil } from 'rxjs';
import { AppLayoutService, SideBarState } from '../service/app.layout.service';
import { AppMenuService } from '../service/app.menu.service';

@Component({
  selector: '[app-menuitem]',
  templateUrl: './app.menuitem.component.html',
  styleUrls: ['./app.menuitem.component.scss'],
})
export class AppMenuItemComponent implements OnDestroy {
  @Input() item: any;

  @Input() index!: number;

  @Input() @HostBinding('class.layout-root-menuitem') root!: boolean;

  @Input() parentKey!: string;

  @Input() sidebarState: SideBarState;

  active = true;

  key = '';

  isSlim = true;

  menuSourceSubscription: Subscription;

  menuResetSubscription: Subscription;

  private readonly _destroying$ = new Subject<void>();

  get isTooltipVisible(): boolean {
    return this.sidebarState === SideBarState.Slim;
  }

  constructor(
    private layoutService: AppLayoutService,
    private menuService: AppMenuService
  ) {
    this.menuSourceSubscription = this.menuService.menuSource$
      .pipe(takeUntil(this._destroying$))
      .subscribe((value) => {
        Promise.resolve(null).then(() => {
          if (value.routeEvent) {
            this.active =
              value.key === this.key || value.key.startsWith(this.key + '-')
                ? true
                : false;
          } else {
            if (
              value.key !== this.key &&
              !value.key.startsWith(this.key + '-')
            ) {
              this.active = false;
            }
          }
        });
      });

    this.menuResetSubscription = this.menuService.resetSource$
      .pipe(takeUntil(this._destroying$))
      .subscribe(() => {
        this.active = false;
      });
  }

  ngOnDestroy(): void {
    this._destroying$.unsubscribe();
  }

  itemClick(event: MouseEvent) {
    // avoid processing disabled items
    if (this.item.disabled) {
      event.preventDefault();
      return;
    }

    // navigate with hover
    if (this.root && this.isSlim) {
      this.layoutService.state.menuHoverActive =
        !this.layoutService.state.menuHoverActive;
    }

    // execute command
    if (this.item.command) {
      this.item.command({ originalEvent: event, item: this.item });
    }

    // toggle active state
    if (this.item.items) {
      this.active = !this.active;

      // if (this.root && this.active && this.isSlim) {
      //   this.layoutService.onOverlaySubmenuOpen();
      // }
    }

    this.menuService.onMenuStateChange({ key: this.key });
  }

  onMouseEnter() {
    // activate item on hover
    if (this.root && this.isSlim && this.layoutService.isDesktop()) {
      if (this.layoutService.state.menuHoverActive) {
        this.active = true;
        this.menuService.onMenuStateChange({ key: this.key });
      }
    }
  }

  @HostBinding('class.active-menuitem')
  get activeClass() {
    return this.active && !this.root;
  }
}
