import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { Subject, Subscription, takeUntil } from 'rxjs';
import { ControlPanelInputVoltage } from '../../enums/control-panel-input-voltage.enum';
import { ControlPanelType } from '../../enums/control-panel-type.enum';
import { PlcCommunicationType } from '../../enums/plc-communication-type.enum';
import { Question, QuestionOption } from '../../track-designer-questions';

@Component({
  selector: 'app-track-controller-options',
  templateUrl: './track-controller-options.component.html',
  styleUrls: ['./track-controller-options.component.scss'],
  host: {
    class: 'h-full',
  },
})
export class TrackControllerOptionsComponent implements OnInit, OnDestroy {
  @Input() formPrefilled = false;
  @Input() questions: Record<string, Question | Question[]> | undefined;
  @Input() form: FormGroup;

  powerSupplyVoltage = ControlPanelInputVoltage;
  plcInterface = PlcCommunicationType;

  powerSupplyQuestion: Question;
  plcInterfaceQuestion: Question;
  controllerKitsQuestion: Question;

  selectedQuestion: QuestionOption | null;
  descriptionVisible = false;

  isSlaveInterfacesNumberVisible = false;
  isProfinetSelected = false;

  integratedProfinetSlaveSub$: Subscription;

  private readonly _destroying$ = new Subject<void>();

  get controllerType(): ControlPanelType {
    return this.form.get('panelType')?.value;
  }

  get isKitAvailable(): boolean {
    return (
      (this.form.get('panelType')?.value === ControlPanelType.ControllerOnly ||
        this.form.get('panelType')?.value === ControlPanelType.Standard) &&
      !this.isSlaveIntegrated
    );
  }

  get isPowerSupplyAvailable(): boolean {
    return (
      this.form.get('panelType')?.value === ControlPanelType.Standard ||
      this.form.get('panelType')?.value === ControlPanelType.Compact
    );
  }

  get communicationType(): PlcCommunicationType {
    return this.form.get('plcOptions')?.get('communicationType')?.value;
  }

  get isSlaveIntegrated(): boolean | null {
    return this.form.get('plcOptions')?.get('integratedProfinetSlave')?.value;
  }

  get isSymphoniProject(): boolean {
    return this.form.get('isSymphoni')?.value;
  }

  constructor() {}

  ngOnInit(): void {
    this.powerSupplyQuestion = this.questions?.['powerSupply'] as Question;
    this.plcInterfaceQuestion = this.questions?.['plcInterface'] as Question;
    this.controllerKitsQuestion = this.questions?.[
      'controllerKits'
    ] as Question;

    if (!this.formPrefilled) {
      this.updateFormControllers();
    }

    this.form
      .get('panelType')
      ?.valueChanges.pipe(takeUntil(this._destroying$))
      .subscribe(() => {
        this.updateFormControllers();
      });

    const controllerType = this.form.get('panelType')?.value;

    const communicationType = this.form
      .get('plcOptions')
      ?.get('communicationType')?.value;

    if (controllerType === ControlPanelType.ControllerOnly) {
      this.setControllerOnlyOptionsVisibility(
        communicationType,
        this.isSlaveIntegrated
      );

      const communicationTypeControl = this.form
        .get('plcOptions')
        ?.get('communicationType');

      const integratedProfinetSlaveControl = this.form
        .get('plcOptions')
        ?.get('integratedProfinetSlave');

      integratedProfinetSlaveControl?.valueChanges
        .pipe(takeUntil(this._destroying$))
        .subscribe((integratedProfinetSlave) => {
          this.setControllerOnlyOptionsVisibility(
            this.communicationType,
            integratedProfinetSlave
          );
        });

      communicationTypeControl?.valueChanges
        .pipe(takeUntil(this._destroying$))
        .subscribe((communicationType) => {
          this.setControllerOnlyOptionsVisibility(
            communicationType,
            this.isSlaveIntegrated
          );
          if (communicationType === PlcCommunicationType.Profinet) {
            const integratedProfinetSlave = this.form
              .get('plcOptions')
              ?.get('integratedProfinetSlave');

            this.integratedProfinetSlaveSub$ =
              integratedProfinetSlave!.valueChanges.subscribe(
                (isSlaveIntegrated) => {
                  this.setControllerOnlyOptionsVisibility(
                    communicationType,
                    isSlaveIntegrated
                  );
                }
              );
          } else {
            this.integratedProfinetSlaveSub$?.unsubscribe();
          }
        });
    } else {
      this.form
        .get('controlPanelInputVoltage')
        ?.setValue(
          this.form.get('controlPanelInputVoltage')?.value ||
            this.powerSupplyVoltage.Vac208Y120
        );
    }

    if (this.isSymphoniProject) {
      if (
        communicationType &&
        communicationType !== PlcCommunicationType.PowerLink
      ) {
        this.form
          .get('plcOptions')
          ?.get('communicationType')
          ?.setValue(PlcCommunicationType.PowerLink);
      }

      if (
        this.form.get('plcOptions')?.get('integratedProfinetSlave') &&
        this.form.get('plcOptions')?.get('integratedProfinetSlave')?.value !==
          null
      ) {
        this.form
          .get('plcOptions')
          ?.get('integratedProfinetSlave')
          ?.setValue(null);
      }
    }

    this.form
      .get('includeExternalEncoderSignal')
      ?.valueChanges.pipe(takeUntil(this._destroying$))
      .subscribe((value) => {
        if (!value) {
          this.form.get('additionalEncoderSignalCount')?.setValue(0);
        }
      });
  }

  ngOnDestroy(): void {
    if (
      (!this.isProfinetSelected &&
        this.form.get('panelType')?.value !==
          ControlPanelType.ControllerOnly) ||
      !this.isProfinetSelected
    ) {
      this.form
        .get('plcOptions')
        ?.get('integratedProfinetSlave')
        ?.setValue(null);
    }

    if (!this.isSlaveInterfacesNumberVisible || this.isSlaveIntegrated) {
      this.form.get('plcOptions')?.get('plcCommInterfaceCount')?.setValue(null);
    }

    this._destroying$.unsubscribe();
  }

  setControllerOnlyOptionsVisibility(
    communicationType: PlcCommunicationType,
    isSlaveIntegrated: boolean | null
  ): void {
    this.isProfinetSelected =
      communicationType === PlcCommunicationType.Profinet;

    if (this.isProfinetSelected && this.isSlaveIntegrated === null) {
      this.form
        .get('plcOptions')
        ?.get('integratedProfinetSlave')
        ?.setValue(this.isSlaveIntegrated || false);
    }

    this.isSlaveInterfacesNumberVisible =
      communicationType === PlcCommunicationType.EthernetIp ||
      communicationType === PlcCommunicationType.EtherCat ||
      (communicationType === PlcCommunicationType.Profinet &&
        !isSlaveIntegrated);

    const slaveInterfacesNumber = this.form
      .get('plcOptions')
      ?.get('plcCommInterfaceCount');

    if (this.isSlaveInterfacesNumberVisible) {
      if (!!slaveInterfacesNumber?.value) return;
      slaveInterfacesNumber?.setValue(1);
    } else {
      slaveInterfacesNumber?.setValue(null);
    }
  }

  updateFormControllers(): void {
    if (this.controllerType === ControlPanelType.Standard) {
      if (this.form.get('controlPanelInputVoltage')?.value === null) {
        this.form
          .get('controlPanelInputVoltage')
          ?.setValue(this.powerSupplyVoltage.Vac208Y120);
      }

      if (
        this.form.get('plcOptions')?.get('communicationType')?.value === null
      ) {
        this.form
          .get('plcOptions')
          ?.get('communicationType')
          ?.setValue(this.plcInterface.EthernetIp);
      }
      this.form
        .get('controlPanelInputVoltage')
        ?.setValidators(Validators.required);
      this.form
        .get('plcOptions')
        ?.get('communicationType')
        ?.setValidators(Validators.required);
    }

    if (this.controllerType === ControlPanelType.ControllerOnly) {
      if (
        this.form.get('plcOptions')?.get('communicationType')?.value === null
      ) {
        this.form
          .get('plcOptions')
          ?.get('communicationType')
          ?.setValue(this.plcInterface.EthernetIp);
      }

      this.form
        .get('plcOptions')
        ?.get('communicationType')
        ?.setValidators(Validators.required);
      this.form.get('controlPanelInputVoltage')?.clearValidators();
      this.form.get('controlPanelInputVoltage')?.setValue(null);
    }

    if (this.controllerType !== ControlPanelType.ControllerOnly) {
      this.form.get('plcOptions')?.get('plcCommInterfaceCount')?.setValue(null);
      this.form
        .get('plcOptions')
        ?.get('integratedProfinetSlave')
        ?.setValue(null);
    }

    if (this.controllerType === ControlPanelType.Compact) {
      if (
        this.form.get('plcOptions')?.get('communicationType')?.value === null
      ) {
        this.form
          .get('plcOptions')
          ?.get('communicationType')
          ?.setValue(this.plcInterface.EthernetIp);
      }

      if (this.form.get('controlPanelInputVoltage')?.value === null) {
        this.form
          .get('controlPanelInputVoltage')
          ?.setValue(this.powerSupplyVoltage.Vac208Y120);
      }

      this.form
        .get('plcOptions')
        ?.get('communicationType')
        ?.setValidators(Validators.required);

      this.form.get('controlPanelInputVoltage')?.clearValidators();

      this.form.get('includeExternalHighSpeedIo')?.setValue(false);
      this.form.get('includeExternalEncoderSignal')?.setValue(null);
      this.form.get('additionalEncoderSignalCount')?.setValue(null);
    }

    this.form.get('controlPanelInputVoltage')?.updateValueAndValidity;
    this.form.get('plcOptions')?.get('communicationType')
      ?.updateValueAndValidity;
  }

  isKitDisabled(): boolean {
    return !this.form.get('includeExternalEncoderSignal')?.value;
  }

  disableCheckboxFormValueChange(event: Event): void {
    event.preventDefault();
  }

  isPowerlink(communicationType: PlcCommunicationType): boolean {
    return communicationType === PlcCommunicationType.PowerLink;
  }

  showDescription(question: QuestionOption): void {
    this.selectedQuestion = question;
    this.descriptionVisible = true;
  }

  clearDescription(): void {
    this.selectedQuestion = null;
  }
}
