import { environment } from 'src/environments/environment';

/**
 * This file contains authentication parameters. Contents of this file
 * is roughly the same across other MSAL.js libraries. These parameters
 * are used to initialize Angular and MSAL Angular configurations in
 * in app.module.ts file.
 */

import {
  BrowserCacheLocation,
  Configuration,
  LogLevel,
} from '@azure/msal-browser';
const isIE =
  window.navigator.userAgent.indexOf('MSIE ') > -1 ||
  window.navigator.userAgent.indexOf('Trident/') > -1;
/**
 * Configuration object to be passed to MSAL instance on creation.
 * For a full list of MSAL.js configuration parameters, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/configuration.md
 */
export const msalConfig: Configuration = {
  auth: {
    authority: environment.msalConfig.b2cConfig.policies.signIn.authority,
    clientId: environment.msalConfig.auth.clientId, // This is the ONLY mandatory field that you need to supply.
    redirectUri: '/', // Points to window.location.origin by default. You must register this URI on Azure portal/App Registration.
    postLogoutRedirectUri: '/', // Points to window.location.origin by default.
    clientCapabilities: ['CP1'], // This lets the resource server know that this client can handle claim challenges.
    knownAuthorities: [
      'devsupertrakpot.onmicrosoft.com',
      'devsupertrakpot.b2clogin.com',
      'supertrakpot.onmicrosoft.com',
      'supertrakpot.b2clogin.com',
    ],
  },
  cache: {
    cacheLocation: BrowserCacheLocation.LocalStorage, // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO between tabs.
    storeAuthStateInCookie: isIE, // Set this to "true" if you are having issues on IE11 or Edge. Remove this line to use Angular Universal
  },
  system: {
    /**
     * Below you can configure MSAL.js logs. For more information, visit:
     * https://docs.microsoft.com/azure/active-directory/develop/msal-logging-js
     */
    loggerOptions: {
      loggerCallback(logLevel: LogLevel, message: string) {
        console.log(message);
      },
      logLevel: LogLevel.Verbose,
      piiLoggingEnabled: false,
    },
    tokenRenewalOffsetSeconds: environment.msalConfig.system.tokenRenewalOffset,
  },
};

/**
 * Add here the endpoints and scopes when obtaining an access token for protected web APIs. For more information, see:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/resources-and-scopes.md
 */
export const protectedResources = {
  api: {
    endpoint: 'https://' + environment.potApiBaseUrl + '/api/*',
    scopes: {
      read: [environment.apiConfig.scopes[0]],
      write: [environment.apiConfig.scopes[1]],
    },
  },
  heartbeat: {
    endpoint: 'https://' + environment.potApiBaseUrl + '/api/heartbeat',
    scopes: null,
  },
};

/**
 * Scopes you add here will be prompted for user consent during sign-in.
 * By default, MSAL.js will add OIDC scopes (openid, profile, email) to any login request.
 * For more information about OIDC scopes, visit:
 * https://docs.microsoft.com/en-us/azure/active-directory/develop/v2-permissions-and-consent#openid-connect-scopes
 */
// export const loginRequest = {
//   scopes: [
//     'openid',
//     'https://devsupertrakpot.onmicrosoft.com/api/supertrak.read',
//   ],
// };

// export const tokenRequest = {
//   scopes: ['https://devsupertrakpot.onmicrosoft.com/api/supertrak.read'], // e.g. ["https://fabrikamb2c.onmicrosoft.com/helloapi/demo.read"]
//   forceRefresh: false, // Set this to "true" to skip a cached token and go to the server to get a new token
// };
