<a *ngIf="(!item.routerLink || item.items) && item.visible !== false"
   [attr.href]="item.url"
   (click)="itemClick($event)"
   (mouseenter)="onMouseEnter()"
   [ngClass]="item.class"
   [attr.target]="item.target"
   tabindex="0"
   pRipple>
  <i [ngClass]="item.icon"
     *ngIf="!item.url; else customIcon"
     class="layout-menuitem-icon"></i>
  <span class="layout-menuitem-text">{{ item.label }}</span>
  <i class="pi pi-fw pi-angle-down layout-submenu-toggler"
     *ngIf="item.items"></i>
</a>

<a *ngIf="item.routerLink && !item.items && item.visible !== false"
   (click)="itemClick($event)"
   (mouseenter)="onMouseEnter()"
   [ngClass]="item.class"
   [routerLink]="item.routerLink"
   routerLinkActive="active-route"
   [routerLinkActiveOptions]="
    item.routerLinkActiveOptions || {
      paths: 'exact',
      queryParams: 'ignored',
      matrixParams: 'ignored',
      fragment: 'ignored'
    }
  "
   [fragment]="item.fragment"
   [queryParamsHandling]="item.queryParamsHandling"
   [preserveFragment]="item.preserveFragment!"
   [skipLocationChange]="item.skipLocationChange!"
   [replaceUrl]="item.replaceUrl!"
   [state]="item.state"
   [queryParams]="item.queryParams"
   [attr.target]="item.target"
   [pTooltip]="item.label"
   [tooltipDisabled]="!isTooltipVisible"
   tabindex="0"
   pRipple>
  <span class="layout-menuitem-icon-wrapper">
    <i [ngClass]="item.icon"
       *ngIf="!item.url; else customIcon"
       class="layout-menuitem-icon">
    </i>
  </span>
  <span class="layout-menuitem-text">{{ item.label }}</span>
  <i class="pi pi-fw pi-angle-down layout-submenu-toggler"
     *ngIf="item.items"></i>
</a>

<ng-template #customIcon>
  <img class="layout-menuitem-icon _img"
       [src]="item.url"
       [alt]="item.label">
</ng-template>
