import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { ConfigurationOwner } from 'src/app/configuration-management/interfaces/configuration-owner.interface';
import { IdentityService } from 'src/app/core/services/identity.service';
import { Configuration } from '../../models/configuration.model';
import { Project } from '../../projects/project';

@Injectable({
  providedIn: 'root',
})
export class EditorModeService {
  private editorMode: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );
  private onBehalfOfUserId: BehaviorSubject<string | null> =
    new BehaviorSubject<string | null>(null);
  private onBehalfOfUser: BehaviorSubject<ConfigurationOwner | null> =
    new BehaviorSubject<ConfigurationOwner | null>(null);

  editorMode$: Observable<boolean> = this.editorMode.asObservable();
  onBehalfOfUserId$: Observable<string | null> =
    this.onBehalfOfUserId.asObservable();
  onBehalfOfUser$: Observable<ConfigurationOwner | null> =
    this.onBehalfOfUser.asObservable();

  get currentUser(): ConfigurationOwner | null {
    return this.onBehalfOfUser.value;
  }

  set currentUser(owner: ConfigurationOwner | null) {
    this.onBehalfOfUser.next(owner);
  }

  get isEditorMode(): boolean {
    return this.editorMode.value;
  }

  set userId(id: string | null) {
    this.onBehalfOfUserId.next(id);
  }

  get userId(): string | null {
    return this.onBehalfOfUserId.value;
  }

  constructor(private identityService: IdentityService) {}

  setEditorMode(isVisible: boolean): void {
    this.editorMode.next(isVisible);
  }

  initEditorModeByConfig(configuration: Configuration): void {
    const currentUser = this.identityService.currentUser;
    if (!configuration.ownerId || !currentUser) return;

    const editingMode = configuration.ownerId !== currentUser.id;

    if (editingMode) {
      this.userId = configuration.ownerId || null;
    }

    this.setEditorMode(editingMode);
  }

  initEditorModeByProject(project: Project): void {
    const currentUser = this.identityService.currentUser;

    const editingMode = project.ownerId !== currentUser?.id;

    if (editingMode) {
      this.userId = project.ownerId;
    }

    this.setEditorMode(editingMode);
  }
}
