<div class="flex flex-column justify-content-center align-items-center py-4 col-6">
  <p-image class="option-item__image my-auto"
           (onImageError)="setPlaceholderImage()"
           [src]="controllerImg"></p-image>
  <p class="my-3 text-justify"
     [innerHtml]="controllerDescription"></p>
</div>

<div class="col-1">
  <p-divider [layout]="'vertical'"></p-divider>
</div>

<div class="flex flex-column justify-content-center col-5"
     [formGroup]="form">
  <h5 class="question mt-4 text-center">{{ question?.questionText }}</h5>
  <ul class="options-list _column align-items-center">
    <ng-container *ngFor="let option of question?.options">
      <li *ngIf="option.isEnabled ? option.isEnabled(userService, identityService) : true"
          class="option-item _bordered w-8"
          [ngClass]="{
            '_disabled': isSymphoniProject
          }">
        <label class="option-item__inner align-items-center _inline"
               (click)="showController(option)">

          <p-radioButton [formControlName]="question?.fieldName || ''"
                         [value]="option.value">
          </p-radioButton>
          <p class="option-item__label text-base w-full text-center ml-3 mb-0">{{ option.label }}</p>

          <i *ngIf="isSymphoniProject && !isControllerOnly(option.value)"
             class="pi pi-info-circle ml-3"
             style="color: #f7941d"></i>
        </label>
      </li>
    </ng-container>
  </ul>
  <p class="text-center font-italic"
     *ngIf="isSymphoniProject"><i class="pi pi-info-circle"
       style="color: #f7941d"></i> This option is not available for Symphoni
    enabled systems.
  </p>
</div>
