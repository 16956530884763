import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { ETurn } from '../../../common/enums/eTurn.enum';
import { Question } from '../../track-designer-questions';

@Component({
  selector: 'app-track-size',
  templateUrl: './track-size.component.html',
  styleUrls: ['./track-size.component.scss'],
})
export class TrackSizeComponent implements OnInit {
  @Input() question: Question | undefined;
  @Input() form: FormGroup;

  dimensionsImage: string;

  get length(): number {
    return this.form.get('length')?.value;
  }

  set length(value: number) {
    this.form.get('length')?.setValue(value);
  }

  get width(): number {
    return this.form.get('width')?.value;
  }

  set width(value: number) {
    this.form.get('width')?.setValue(value);
  }

  get is90Deg(): boolean {
    return this.form.get('eTurn')?.value === ETurn.Curve90;
  }

  get isStraightSection(): boolean {
    return this.form.get('eTurn')?.value === ETurn.None;
  }

  get is800mm(): boolean {
    return this.form.get('eTurn')?.value === ETurn.Curve800;
  }

  get maxWidth(): number {
    return 30 - this.length;
  }

  get minLength(): number {
    return this.is90Deg ? 0 : 1;
  }

  get maxLength(): number {
    if (this.is90Deg || this.is800mm) {
      return 30 - this.width;
    }

    if (this.isStraightSection) {
      return 64;
    }

    return 31;
  }

  get sizeLimit(): number {
    if (this.is90Deg || this.is800mm) {
      return 30;
    }

    if (this.isStraightSection) {
      return 64;
    }

    return 31;
  }

  get eTurnType(): ETurn {
    return this.form.get('eTurn')?.value;
  }

  constructor() {}

  ngOnInit(): void {
    this.resetWidth();
    this.resetLength();

    this.length = this.form.get('length')?.value || this.minLength;

    this.dimensionsImage = `/assets/images/track-designer/dimensions/${this.eTurnType}.png`;
  }

  minSize(dimension: string): number {
    return dimension === 'width' ? 0 : this.minLength;
  }

  maxSize(dimension: string): number {
    return dimension === 'width' ? this.maxWidth : this.maxLength;
  }

  resetWidth(): void {
    if (this.is90Deg) {
      this.form.get('width')?.setValidators(Validators.required);
    } else {
      this.form.get('width')?.clearValidators();
    }

    this.form
      .get('width')
      ?.setValue(this.is90Deg ? this.form.get('width')?.value || 0 : null);
    this.width = this.form.get('width')?.value;

    this.form.get('width')?.updateValueAndValidity;
  }

  resetLength(): void {
    const curLength = this.form.get('length')?.value;

    if (curLength > this.maxLength) {
      this.form.get('length')?.setValue(this.maxLength);
    }
  }

  setPlaceholderImage(): void {
    this.dimensionsImage =
      'https://plchldr.co/i/500x250?text=Measures%20Schema';
  }
}
