import { Component, OnDestroy, OnInit } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { take } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AppConfirmationDialogRequest } from '../../service/app-confirmation-dialog.service';

@Component({
  selector: 'app-app-confirmation-dialog',
  templateUrl: './app-confirmation-dialog.component.html',
  styleUrls: ['./app-confirmation-dialog.component.scss'],
})
export class AppConfirmationDialogComponent implements OnInit, OnDestroy {
  isLoading = false;

  constructor(
    public dialogRef: DynamicDialogRef,
    public dialogConfig: DynamicDialogConfig
  ) {}

  ngOnInit(): void {}

  ngOnDestroy(): void {
    if (!environment.production) {
      console.log('AppConfirmationDialogComponent destroyed.');
    }
  }

  onConfirm() {
    const data = this.dialogConfig.data as AppConfirmationDialogRequest;
    this.isLoading = true;

    data.onBeforeClose.pipe(take(1)).subscribe({
      next: () => {
        this.isLoading = false;
        this.dialogRef.close();
        this.dialogRef.destroy();
      },
      error: () => {
        this.isLoading = false;
        this.dialogRef.close();
        this.dialogRef.destroy();
      },
    });
  }

  onCancel() {
    this.dialogRef.close();
    this.dialogRef.destroy();
  }
}
