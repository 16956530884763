<div class="process-map flex flex-grow-1 h-full flex-column overflow-hidden"
     [ngClass]="{
    '_collapsed': collapsed
  }">
  <div
       class="process-map__inner flex h-full align-items-center justify-content-center flex-grow-1 flex-column gap-3 p-2">
    <div class="flex w-full flex-column px-2"
         *ngIf="!trackSpecs; else processMapTpl">
      <h5 class="text-center">Start building your own SuperTrak&trade;</h5>
      <p-button class="w-full"
                type="button"
                aria-label="Start process creation"
                (onClick)="openProcessMapCreation()"
                styleClass="w-full">
        <p class="w-full text-center font-semibold mb-0">Start</p>
      </p-button>
    </div>

    <ng-template #processMapTpl>
      <div class="flex flex-column w-full border-bottom-1 border-300 pb-2 px-2"
           *ngIf="screenState === processMapState.ProcessList">
        <p-button [outlined]="true"
                  aria-label="Trak type select"
                  class="w-full mb-3"
                  (onClick)="openTrackTypeModal()"
                  styleClass="track-type-btn w-full">
          <span class="flex w-full align-items-center">
            <span class="flex flex-grow-1 flex-column">
              <p class="mb-0 text-center"><b>{{ getTrackBrandName(trackSpecs!.eTurn) }}</b></p>
              <p class="mb-0 text-center text-gray-500"><b>{{ getTrackLabel(trackSpecs!.eTurn) }}</b></p>
            </span>
          </span>
        </p-button>

        <ng-container *ngIf="processList.length">
          <div class="flex align-items-center">
            <div class="flex flex-grow-1 align-items-center">
              <p class="mb-0"><span class="font-semibold">Start process at position</span>&nbsp;<i>(mm)</i></p>
              <p-button [outlined]="true"
                        [text]="true"
                        class="ml-2"
                        (onClick)="originPointOverlay.toggle($event)"
                        icon="pi pi-info-circle">

              </p-button>
            </div>
            <div class="inline-flex align-items-center"
                 *ngIf="!originPointEditing">
              <p class="mb-0">{{ originPoint }}</p>
              <p-button [outlined]="true"
                        [text]="true"
                        class="ml-2"
                        (onClick)="originPointEditing = true"
                        icon="pi pi-pencil">

              </p-button>
            </div>
          </div>

          <div class="flex align-items-center w-full"
               *ngIf="originPointEditing">
            <p-inputNumber type="text"
                           class="w-full"
                           [min]="0"
                           [(ngModel)]="originPointModel"
                           styleClass="w-full">
            </p-inputNumber>
            <div class="inline-flex p-buttonset align-items-center ml-2">
              <p-button [text]="true"
                        [severity]="'success'"
                        (onClick)="saveOriginPoint()"
                        icon="pi pi-check"></p-button>
              <p-button [text]="true"
                        [severity]="'danger'"
                        (onClick)="resetOriginPoint()"
                        icon="pi pi-times"></p-button>
            </div>

          </div>
        </ng-container>
      </div>
      <div class="flex w-full flex-column flex-grow-1 justify-content-center overflow-auto px-2">

        <app-new-process *ngIf="screenState === processMapState.NewProcess"
                         [eTurn]="trackSpecs?.eTurn"
                         (onClose)="showProcessMap()"></app-new-process>

        <ng-container *ngIf="screenState === processMapState.ProcessList">
          <div *ngIf="!processList.length; else processListTpl"
               class="flex flex-column text-center">
            <h5>Start by adding a process to your SuperTrak&trade;</h5>
            <p-button class="w-full"
                      aria-label="Add process"
                      styleClass="w-full text-center"
                      (onClick)="startNewProcess()">
              <span class="flex w-full justify-content-center">
                <i class="pi pi-plus mr-2"></i>
                <span><b>Add process</b></span>
              </span>
            </p-button>
          </div>
          <ng-template #processListTpl>
            <div class="flex flex-column text-center w-full h-full flex-grow-1 gap-3">
              <app-process-list class="flex flex-column flex-grow-1 overflow-auto"
                                (onShowDetails)="showDetails($event)"
                                [processList]="processList">
              </app-process-list>
              <p-button class="w-full"
                        aria-label="Add process"
                        styleClass="w-full text-center"
                        (onClick)="startNewProcess()">
                <span class="flex w-full justify-content-center">
                  <i class="pi pi-plus mr-2"></i>
                  <span><b>Add process</b></span>
                </span>
              </p-button>
            </div>
          </ng-template>
        </ng-container>

        <app-process-details class="flex flex-column w-full flex-grow-1"
                             *ngIf="screenState === processMapState.ProcessDetails"
                             [process]="processToShow"
                             [processStorageIndex]="processIndexToShow"
                             (onClose)="showProcessMap()">
        </app-process-details>
      </div>

    </ng-template>
  </div>
</div>

<p-overlayPanel #originPointOverlay>
  <ng-template pTemplate="content">
    <div [style]="{
      maxWidth: '350px'
    }">
      <img class="max-w-full mb-3"
           src="/assets/images/track-concept/origin-point-graphics.png"
           alt="Origin oint example">
      <p> The <i>start process at position</i> value, in mm, allows moving the position of the first process step to a
        different position on the track. Note that this value does not impact the overall track length.
      </p>
    </div>
  </ng-template>
</p-overlayPanel>
