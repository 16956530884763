import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import {
  MsalBroadcastService,
  MsalGuardConfiguration,
  MsalService,
  MSAL_GUARD_CONFIG,
} from '@azure/msal-angular';
import { AccountInfo, RedirectRequest } from '@azure/msal-browser';
import {
  BehaviorSubject,
  catchError,
  from,
  map,
  Observable,
  of,
  switchMap,
} from 'rxjs';
import { Role, User } from 'src/app/common/models/user/user';
import { environment } from 'src/environments/environment';
import { UserResponse } from '../../common/models/user-response/user-response';
import { protectedResources } from '../config/auth-config';
import { AppLoadingService } from './app-loading.service';

@Injectable({
  providedIn: 'root',
})
export class IdentityService {
  private userUrl = 'https://' + environment.potApiBaseUrl + '/api/user';
  private activeUser: User | null = null;

  private _logInRequired = new BehaviorSubject<boolean>(false);

  public activeUserChanged$ = new BehaviorSubject<User | null>(null);

  get logInRequired$(): Observable<boolean> {
    return this._logInRequired.asObservable();
  }

  get currentUser(): User | null {
    return this.activeUser;
  }

  constructor(
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private msalBroadcastService: MsalBroadcastService,
    private msalService: MsalService,
    private httpClient: HttpClient,
    private appLoadingService: AppLoadingService
  ) {}

  /*
   * Check if token exists. Also, automatically updates the token in case it has expired, but refreshToken is still actual
   */
  public checkToken(): Observable<boolean> {
    return this.msalService
      .acquireTokenSilent({
        scopes: [
          ...protectedResources.api.scopes.read,
          ...protectedResources.api.scopes.write,
        ],
      })
      .pipe(
        switchMap(() => this.updateUser()),
        map(() => {
          return true;
        }),
        catchError(() => of(false))
      );
  }

  /*
   * Forced refresh token silently. Automatically updates the token in case it has expired, but refreshToken is still actual
   */
  public refreshToken(): Observable<boolean> {
    return from(
      this.msalService.acquireTokenSilent({
        scopes: [
          ...protectedResources.api.scopes.read,
          ...protectedResources.api.scopes.write,
        ],
        forceRefresh: true,
      })
    ).pipe(
      switchMap(() => this.updateUser()),
      map(() => {
        return true;
      }),
      catchError(() => of(false))
    );
  }

  loginRequired(): void {
    this._logInRequired.next(true);
  }

  isCurrentUser(user: User): boolean {
    return user.id === this.activeUser?.id;
  }

  updateUser() {
    return this.httpClient.get<UserResponse>(this.userUrl).pipe(
      map((response: UserResponse) => {
        const user = new User(
          response.id,
          response.externalId,
          response.firstName,
          response.lastName,
          response.email,
          response.isActive,
          response.isAts
        );

        response.roles.forEach((role) => {
          user.addRole(new Role(role.id, role.name, role.permissions));
        });

        this.activeUser = user;
        this.activeUserChanged$.next(user);

        return user;
      })
    );
  }

  clearUser() {
    this.activeUser = null;
    this.activeUserChanged$.next(null);
  }

  checkActiveAccount(): AccountInfo | null {
    let activeAccount = this.msalService.instance.getActiveAccount();

    if (
      !activeAccount &&
      this.msalService.instance.getAllAccounts().length > 0
    ) {
      const accounts = this.msalService.instance.getAllAccounts();
      activeAccount = accounts[0];
      this.msalService.instance.setActiveAccount(activeAccount);
    }

    return activeAccount;
  }

  login(email: string): Observable<any> {
    const request = {
      ...this.msalGuardConfig.authRequest,
      loginHint: email,
    } as RedirectRequest;

    return this.msalService.loginRedirect(request);
  }

  signup(email: string): void {
    const request = {
      ...this.msalGuardConfig.authRequest,
      authority: environment.msalConfig.b2cConfig.policies.signUp.authority,
      loginHint: email,
    } as RedirectRequest;

    this.msalService.loginRedirect(request);
  }

  ATSSignUpLogin(
    loginHint: string,
    domainHint: 'atsautomation.com' | 'supertrakconveyance.com'
  ): Observable<any> {
    const request = {
      ...this.msalGuardConfig.authRequest,
      authority: environment.msalConfig.b2cConfig.policies.signIn.authority,
      domainHint: 'atsautomation.com', // temporarily static. Later should be changed to dynamic once it is supported
      loginHint,
    } as RedirectRequest;

    return this.msalService.loginRedirect(request);
  }

  isInternalUser(): boolean {
    const userAccount =
      this.msalService.instance.getActiveAccount() ||
      this.msalService.instance.getAllAccounts()[0];

    const authenticationSource =
      (userAccount?.idTokenClaims?.['authenticationSource'] as string) || null;
    const idp_tenantId =
      (userAccount?.idTokenClaims?.['idp_tenantId'] as string) || null;

    return (
      authenticationSource === 'socialIdpAuthentication' &&
      !!idp_tenantId &&
      idp_tenantId === 'f5b2e964-6123-4a27-9a8c-8c1dfbf93791'
    );
  }

  logout(): Observable<void> {
    this.appLoadingService.startLoading();
    return this.msalService.logout();
  }
}
