import { ConfigurationOwner } from 'src/app/configuration-management/interfaces/configuration-owner.interface';
import { ProductType } from './product-type';

export class ConfigurationVersion {
  id: string;
  name: string | null;
  productType: ProductType;
  rfqStatus: VersionStatus;
  version: number;
  dateCreated: string;
  createdById: string;
  requestedByDate?: string;
  rfqComment?: string;
  salesComment?: string;
}

export class Configuration {
  id: string;
  name: string;
  description: string;
  productType: ProductType;
  projectName: string;
  projectId: string;
  configurationVersions: ConfigurationVersion[];
  owner: ConfigurationOwner | null;
  createdTime: Date;
  updatedTime: Date;
  ownerId?: string;
  constructor(
    id: string,
    name: string,
    description: string,
    productType: ProductType,
    projectName: string,
    projectId: string,
    configurationVersions: ConfigurationVersion[],
    owner: ConfigurationOwner | null,
    createdTime: Date,
    updatedTime: Date,
    ownerId?: string
  ) {
    this.id = id;
    this.name = name;
    this.description = description;
    this.productType = productType;
    this.configurationVersions = configurationVersions;
    this.projectName = projectName;
    this.projectId = projectId;
    this.owner = owner;
    this.createdTime = createdTime;
    this.updatedTime = updatedTime;

    if (ownerId) {
      this.ownerId = ownerId;
    }
  }
}

export enum VersionStatus {
  Draft = 'Draft',
  Submitted = 'Submitted',
  Responded = 'Responded',
  InProgress = 'InProgress',
  ProposalSent = 'ProposalSent',
  Cancelled = 'Cancelled',
}
