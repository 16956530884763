import { inject } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivateFn,
  NavigationExtras,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { MsalService } from '@azure/msal-angular';
import { catchError, Observable, of, switchMap, tap } from 'rxjs';
import { AppRoutes } from '../app.routes.const';
import { IdentityService } from '../core/services/identity.service';

export const AuthGuard: CanActivateFn = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
): Observable<boolean> => {
  const identityService = inject(IdentityService);
  const router = inject(Router);
  const msalService = inject(MsalService);

  const navigationExtras: NavigationExtras = {
    state: {
      shouldAuth: true,
    },
  };

  return msalService.initialize().pipe(
    switchMap(() => identityService.checkToken()),
    tap((isAuthenticated: boolean) => {
      // If user is already authenticated, they can proceed to target route
      if (isAuthenticated) {
        localStorage.removeItem('ATS-location');
        return true;
      }
      // Otherwise, user is being navigated to the dashboard with additional data indicating that login popup should appear
      router.navigate([AppRoutes.Dashboard.fullPath()], navigationExtras);
      // Since we use authentication with redirecting user to third-party env, localStorage is being used to memorize the target route user tried to reach
      localStorage.setItem('ATS-location', state.url);
      // Pop up Login Gateway Component
      identityService.loginRequired();
      return false;
    }),
    catchError(() => {
      router.navigate([AppRoutes.Dashboard.fullPath()], navigationExtras);
      localStorage.setItem('ATS-location', state.url);
      // Pop up Login Gateway Component
      identityService.loginRequired();
      return of(false);
    })
  );
};
