import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { Observable, Subject, takeUntil } from 'rxjs';
import {
  getVersionStatusColor,
  getVersionStatusLabel,
} from 'src/app/common/helpers/configuration-version-status';
import { TrackService } from 'src/app/common/services/track-services/track.service';
import {
  ConfigurationVersion,
  VersionStatus,
} from '../../models/configuration.model';
import { ProjectConfigurationService } from '../../services/project-configuration/project-configuration.service';

@Component({
  selector: 'app-configurations-card',
  templateUrl: './configurations-card.component.html',
  styleUrls: ['./configurations-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConfigurationsCardComponent
  implements OnInit, OnChanges, OnDestroy
{
  @Input() configurationVersion: ConfigurationVersion;
  @Input() configId: string;
  @Input() selectedId: string | null;
  @Output() onSelectVersion = new EventEmitter<string>();

  isLoading$: Observable<boolean>;
  isLoading = false;
  isQuoteRequestDisabled = true;
  isDraft = false;

  private readonly _destroying$ = new Subject<void>();

  get isSelected(): boolean {
    return this.selectedId === this.configurationVersion.id;
  }

  constructor(
    private trackService: TrackService,
    private projectConfigurationService: ProjectConfigurationService,
    private changeDetectorRef: ChangeDetectorRef
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    const selectedVersionId = changes['selectedId'].currentValue;

    if (selectedVersionId !== this.configurationVersion.id) {
      this.isLoading = false;
    }
  }

  ngOnInit(): void {
    this.isLoading$ = this.trackService.tracksLoading;
    this.isLoading$
      .pipe(takeUntil(this._destroying$))
      .subscribe((isLoading) => {
        if (!this.isSelected) return;

        this.isLoading = isLoading;
        this.changeDetectorRef.detectChanges();
      });

    this.projectConfigurationService.configurations$
      .pipe(takeUntil(this._destroying$))
      .subscribe((_) => {
        const version =
          this.projectConfigurationService.getConfigurationVersion(
            this.configurationVersion.id
          );

        this.isDraft = version?.rfqStatus === VersionStatus.Draft;
        this.changeDetectorRef.detectChanges();
      });
  }

  ngOnDestroy(): void {
    this._destroying$.unsubscribe();
  }

  getStatusColor(rfqStatus: VersionStatus): {
    class: string;
    color: string;
  } {
    return getVersionStatusColor(rfqStatus);
  }

  getStatusLabel(rfqStatus: VersionStatus): string {
    return getVersionStatusLabel(rfqStatus);
  }

  selectVersion(versionId?: string): void {
    this.onSelectVersion.emit(versionId || this.configurationVersion.id);
  }
}
