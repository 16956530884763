import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { map, Observable, tap } from 'rxjs';
import { AccessScope } from '../../enums/access-scope.enum';
import { ConfigurationsSales } from '../../interfaces/configurations-sales.interface';
import { ConfigurationsApiService } from '../../services/configurations-api/configurations-api.service';
import { ConfigurationsStorageService } from '../../services/configurations-storage/configurations-storage.service';

@Injectable({
  providedIn: 'root',
})
export class ConfigurationsListResolver {
  constructor(
    private router: Router,
    private messageService: MessageService,
    private configurationsApiService: ConfigurationsApiService,
    private configurationsStorageService: ConfigurationsStorageService
  ) {}

  resolve(): Observable<ConfigurationsSales> {
    return this.configurationsApiService
      .getAllConfigurations(AccessScope.Sales)
      .pipe(
        map((response: ConfigurationsSales) => {
          this.configurationsStorageService.updateConfigurations(response);
          return response;
        }),
        tap({
          error: () => {
            this.handleError();
          },
        })
      );
  }

  handleError() {
    this.messageService.add({
      severity: 'error',
      summary: 'Error',
      detail: 'Failed to resolve configurations',
    });
    this.router.navigate(['/']);
  }
}
