import { TRACK_TYPES } from '../constants/track-types.const';
import { ETurn } from '../enums/eTurn.enum';

export function getTrackFullLabel(value: ETurn): string | undefined {
  return TRACK_TYPES[value].fullLabel;
}

export function getTrakLabel(value: ETurn): string | undefined {
  return TRACK_TYPES[value].label;
}

export function getTrakBrandName(value: ETurn): string | undefined {
  return TRACK_TYPES[value].brandName;
}
