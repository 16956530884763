import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  ValidationErrors,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationService, MessageService } from 'primeng/api';
import { Subject, takeUntil } from 'rxjs';
import { AppRoutes } from 'src/app/app.routes.const';
import { Track } from 'src/app/common/models/track/track';
import { AppLoadingService } from 'src/app/core/services/app-loading.service';
import { NavigationHistoryService } from 'src/app/core/services/navigation-history/navigation-history.service';
import { ConfigurationRequest } from '../models/configuration-request.model';
import {
  Configuration,
  ConfigurationVersion,
  VersionStatus,
} from '../models/configuration.model';
import { Project } from '../projects/project';
import { ProjectService } from '../projects/project.service';
import { EditorModeService } from '../services/editor-mode/editor-mode.service';
import { ProjectConfigurationService } from '../services/project-configuration/project-configuration.service';

@Component({
  selector: 'app-configurations',
  templateUrl: './configurations.component.html',
  styleUrls: ['./configurations.component.scss'],
})
export class ConfigurationsComponent implements OnInit, OnDestroy {
  private readonly _destroying$ = new Subject<void>();

  projectId: string;
  projectRefNumberEditing = false;
  isProjectUpdating = false;
  projectRefNumber: string;
  currentProject: Project;
  configurations: Configuration[] = [];
  configurations$: Subject<Configuration[]>;
  isNewConfigurationVisible: boolean;
  isLoading: boolean;
  selectedVersionId: string | null;
  selectedConfigId: string | null;

  editingMode = false;
  versionEditable = true;

  createConfigurationForm = this.formBuilder.group({
    name: [
      '',
      [
        Validators.required,
        Validators.maxLength(200),
        this.duplicateNameValidator.bind(this),
      ],
    ],
    description: ['', [Validators.required, Validators.maxLength(200)]],
  });

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private formBuilder: FormBuilder,
    private projectConfigService: ProjectConfigurationService,
    private projectService: ProjectService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private appLoadingService: AppLoadingService,
    private editorModeService: EditorModeService,
    private navHistoryService: NavigationHistoryService
  ) {}

  ngOnDestroy(): void {
    this._destroying$.unsubscribe();
  }

  ngOnInit(): void {
    this.projectId = this.route.snapshot.paramMap.get('projectId') ?? '';

    this.projectService.projects$
      .pipe(takeUntil(this._destroying$))
      .subscribe((projects) => {
        const project = projects.find(
          (project: Project) => project.id === this.projectId
        );

        if (project) {
          this.setCurrentProject(project);
        }
      });

    this.editorModeService.editorMode$
      .pipe(takeUntil(this._destroying$))
      .subscribe((editingMode) => {
        this.editingMode = editingMode;
      });

    this.projectConfigService.configurations$
      .pipe(takeUntil(this._destroying$))
      .subscribe((configurations) => {
        this.configurations = configurations;
      });

    this.route.queryParamMap
      .pipe(takeUntil(this._destroying$))
      .subscribe((res) => {
        if (res.get('versionId')) {
          this.preSelectConfigurationVersion();
          return;
        }

        this.selectedVersionId = null;
      });
  }

  onBack(): void {
    this.navHistoryService.back(AppRoutes.Projects.fullPath());
  }

  private setCurrentProject(project: Project): void {
    this.currentProject = project;
    this.projectRefNumber = project.referenceNumber || 'empty';
  }

  onNewConfiguration(): void {
    this.isNewConfigurationVisible = true;
  }

  onCancelNewConfiguration(): void {
    this.createConfigurationForm.reset();
    this.isNewConfigurationVisible = false;
  }

  isConfigEditable(configuration: Configuration): boolean {
    return !!!configuration.configurationVersions.find(
      (version) => version.rfqStatus === VersionStatus.Submitted
    );
  }

  onDeleteConfiguration(configId: string): void {
    this.confirmationService.confirm({
      header: 'Delete Configuration',
      icon: 'pi pi-exclamation-triangle',
      message: 'Are you sure you want to delete the configuration permanently?',
      accept: () => {
        this.deleteConfiguration(configId);
      },
    });
  }

  deleteConfiguration(configId: string): void {
    this.appLoadingService.startLoadingBar();

    this.projectConfigService
      .deleteConfigurationById(configId)
      .pipe(takeUntil(this._destroying$))
      .subscribe({
        next: () => {
          this.router.navigate(['.'], {
            relativeTo: this.route,
          });
        },
        error: (error) => {
          this.appLoadingService.stopLoadingBar();
          this.messageService.add({
            severity: 'error',
            summary: 'Error deleting configuration.',
            detail: error.status + ': ' + error.title,
          });
        },
        complete: () => {
          this.appLoadingService.stopLoadingBar();
        },
      });
  }

  onSubmitNewConfiguration(): void {
    this.createConfigurationForm.markAsTouched();

    if (!this.createConfigurationForm.valid) {
      return;
    }

    this.isLoading = true;
    const formValues = this.createConfigurationForm.getRawValue();

    this.appLoadingService.startLoadingBar();
    this.projectConfigService
      .createNewConfiguration(
        this.projectId,
        new ConfigurationRequest(formValues.name, formValues.description)
      )
      .pipe(takeUntil(this._destroying$))
      .subscribe({
        next: (response: Configuration) => {
          this.isNewConfigurationVisible = false;
          const configId = response.configurationVersions[0].id;
          this.selectVersion(configId);
        },
        error: (error) => {
          this.appLoadingService.stopLoadingBar();
          this.isNewConfigurationVisible = false;
          this.messageService.add({
            severity: 'error',
            summary: 'Error creating new configuration.',
            detail: error.status + ': ' + error.title,
          });
          console.error(error);
        },
        complete: () => {
          this.appLoadingService.stopLoadingBar();
          this.isLoading = false;
        },
      });
  }

  onNewVersion(configId: string): void {
    this.appLoadingService.startLoadingBar();

    this.projectConfigService
      .createNewVersion(configId)
      .pipe(takeUntil(this._destroying$))
      .subscribe({
        next: (response) => {
          const configId = response.id;
          this.selectVersion(configId);
        },
        error: (error) => {
          this.appLoadingService.stopLoadingBar();
          this.messageService.add({
            severity: 'error',
            summary: 'Error creating new configuration version.',
            detail: error.status + ': ' + error.title,
          });
          console.error(error);
        },
        complete: () => {
          this.appLoadingService.stopLoadingBar();
        },
      });
  }

  get duplicatedName(): boolean {
    const formName = this.createConfigurationForm.getRawValue().name;
    const configExists = this.configurations.find(
      (config) => config.name == formName
    );

    if (configExists) {
      return true;
    }

    return false;
  }

  onEditTrack(track: Track): void {
    if (!this.selectedConfigId) return;

    this.router.navigate(
      [AppRoutes.TrackDrilldown.fullPath(this.selectedConfigId, track.id)],
      {
        state: { track: track },
      }
    );
  }

  preSelectConfigurationVersion() {
    const configIdParam = this.route.snapshot.queryParams['versionId'];

    if (!configIdParam) {
      return;
    }

    this.selectedVersionId = configIdParam;
    this.selectedConfigId =
      this.configurations.find((config) => {
        return config.configurationVersions.find(
          (version: ConfigurationVersion) => version.id === configIdParam
        );
      })?.id || null;

    if (this.editingMode) {
      this.versionEditable = this.isVersionEditableForSales();
    }
  }

  selectVersion(versionId: string): void {
    if (!versionId) {
      this.router.navigate(['.'], {
        relativeTo: this.route,
      });
    }

    this.selectedVersionId = versionId;

    if (this.editingMode) {
      this.versionEditable = this.isVersionEditableForSales();
    }

    this.router.navigate(['.'], {
      relativeTo: this.route,
      queryParams: {
        versionId,
      },
      queryParamsHandling: 'merge',
    });
  }

  isVersionEditableForSales(): boolean {
    const configuration = this.configurations.find(
      (configuration) => configuration.id === this.selectedConfigId
    );

    const configurationOwnerId = configuration?.owner?.id;

    const configVersion = configuration?.configurationVersions.find(
      (version) => version.id === this.selectedVersionId
    );

    const configVersionCreatorId = configVersion?.createdById;

    return configurationOwnerId !== configVersionCreatorId;
  }

  duplicateNameValidator(control: AbstractControl): ValidationErrors | null {
    const configExists = this.configurations.find(
      (config) => config.name === (control.value as string)
    );

    return configExists
      ? {
          duplicateName: {
            value: 'Configuration already exists. Please use a different name.',
          },
        }
      : null;
  }

  isSelectedConfig(configId: string): boolean {
    return this.selectedVersionId === configId;
  }

  saveProjectRefNumber(): void {
    const updatedProject: Project = this.currentProject;
    updatedProject.referenceNumber = this.projectRefNumber;

    this.isProjectUpdating = true;

    this.projectService.updateProject(updatedProject).subscribe({
      next: (project: Project) => {
        this.setCurrentProject(project);
      },
      error: (error) => {
        this.appLoadingService.stopLoadingBar();
        this.messageService.add({
          severity: 'error',
          summary: 'Error updating project.',
          detail: error.status + ': ' + error.title,
        });
      },
      complete: () => {
        this.isProjectUpdating = false;
        this.resetProjectRefNumberChange();
      },
    });
  }

  resetProjectRefNumberChange(): void {
    this.projectRefNumberEditing = false;
    this.projectRefNumber = this.currentProject.referenceNumber || '(empty)';
  }
}
