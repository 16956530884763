import { environment } from 'src/environments/environment';
import { ApiUrlParams } from './api-url-params';

export class ApiUrls {
  // || ----- || PROJECTS || -----||

  // GET all projects
  static getProjects =
    'https://' + environment.potApiBaseUrl + '/api/projects/';

  // POST new project
  static createProject =
    'https://' + environment.potApiBaseUrl + '/api/projects/';

  // /api/projects/:projectId
  static getProjectByIdUrl: string =
    'https://' +
    environment.potApiBaseUrl +
    '/api/projects/:' +
    ApiUrlParams.ProjectId;

  // /api/projects/:projectId
  static removeProject: string =
    'https://' +
    environment.potApiBaseUrl +
    '/api/projects/:' +
    ApiUrlParams.ProjectId;

  static updateProject: string =
    'https://' +
    environment.potApiBaseUrl +
    '/api/projects/:' +
    ApiUrlParams.ProjectId;

  // || ----- || TRACKS || -----||

  // /api/configurations/:versionId/tracks
  static allTracksByConfigurationVersionIdUrl: string =
    'https://' +
    environment.potApiBaseUrl +
    '/api/configurationVersions/:' +
    ApiUrlParams.VersionId +
    '/tracks';

  // /api/configurations/:versionId/tracks
  static createNewTrack: string =
    'https://' +
    environment.potApiBaseUrl +
    '/api/configurationVersions/:' +
    ApiUrlParams.VersionId +
    '/tracks';

  // /api/configurations/:configurationId/tracks
  static removeTrack: string =
    'https://' +
    environment.potApiBaseUrl +
    '/api/tracks/:' +
    ApiUrlParams.TrackId;

  // /api/tracks/:trackId
  static getTrackById: string =
    'https://' +
    environment.potApiBaseUrl +
    '/api/tracks/:' +
    ApiUrlParams.TrackId;

  // /api/tracks/:trackId/comments
  static getTrackComments: string =
    'https://' +
    environment.potApiBaseUrl +
    '/api/tracks/:' +
    ApiUrlParams.TrackId +
    '/comments';

  // /api/tracks/:trackId/comments
  static postTrackComment: string =
    'https://' +
    environment.potApiBaseUrl +
    '/api/tracks/:' +
    ApiUrlParams.TrackId +
    '/comments';

  // /api/tracks/:trackId/comments/:commentId
  static deleteTrackCommentById: string =
    'https://' +
    environment.potApiBaseUrl +
    '/api/tracks/:' +
    ApiUrlParams.TrackId +
    '/comments/:' +
    ApiUrlParams.CommentId;

  // /api/tracks/:trackId/comments/:commentId
  static updateTrackCommentById: string =
    'https://' +
    environment.potApiBaseUrl +
    '/api/tracks/:' +
    ApiUrlParams.TrackId +
    '/comments/:' +
    ApiUrlParams.CommentId;

  // GET all configurations by project id
  // /api/projects/:projetId/configurations
  static getConfigurationsByProjectId =
    'https://' +
    environment.potApiBaseUrl +
    '/api/projects/:' +
    ApiUrlParams.ProjectId +
    '/configurations';

  // POST new configuration
  // api/projects/:projectId
  static createNewConfigurationUrl =
    'https://' +
    environment.potApiBaseUrl +
    '/api/projects/:' +
    ApiUrlParams.ProjectId +
    '/configurations';

  // GET configuration by id
  // /api/configurations/:configId
  static getConfigurationByIdUrl =
    'https://' +
    environment.potApiBaseUrl +
    '/api/configurations/:' +
    ApiUrlParams.ConfigurationId;

  // DELETE Configuration by id
  // /api/configurations/:configurationId
  static deleteConfiguration: string =
    'https://' +
    environment.potApiBaseUrl +
    '/api/configurations/:' +
    ApiUrlParams.ConfigurationId;

  // POST new configuration version
  // /api/configurations/:configId/configurationVersions
  static createNewVersionUrl =
    'https://' +
    environment.potApiBaseUrl +
    '/api/configurations/:' +
    ApiUrlParams.ConfigurationId +
    '/configurationVersions';

  // GET Configuration version by id
  // /api/configurationVersions/:versionId
  static configVersionById: string =
    'https://' +
    environment.potApiBaseUrl +
    '/api/configurationVersions/:' +
    ApiUrlParams.VersionId;

  // GET Configuration version parameters export by id url
  // /api/configurationVersions/:versionId
  static configVersionParameters: string =
    'https://' +
    environment.potApiBaseUrl +
    '/api/configurationVersions/:' +
    ApiUrlParams.VersionId;

  // POST request a configuration version quote
  // /api/configurationVersions/:versionId/rfq
  static requestConfigQuote: string =
    'https://' +
    environment.potApiBaseUrl +
    '/api/configurationVersions/:' +
    ApiUrlParams.VersionId +
    '/rfq';

  static trackById =
    'https://' + environment.potApiBaseUrl + '/api/tracks/:trackId';

  static createSpecification: string =
    'https://' +
    environment.potApiBaseUrl +
    '/api/tracks/:' +
    ApiUrlParams.TrackId +
    '/specification';

  static maintenanceEndpoint =
    'https://' + environment.potApiBaseUrl + '/api/heartbeat';
}

export function FormatApiUrlParam(param: ApiUrlParams): string {
  return ':' + param;
}
