export const AppRoutes = {
  Dashboard: {
    path: 'dashboard',
    fullPath: () => 'dashboard',
  },
  UserManagement: {
    path: 'user-management',
    fullPath: () => 'user-management',
  },
  EditUser: {
    path: ':userId',
    fullPath: (userId: string) => `/user-management/${userId}`,
  },
  Projects: {
    path: 'projects',
    fullPath: () => 'projects',
  },
  ProjectDetails: {
    path: ':projectId',
    fullPath: (projectId: string) => `projects/${projectId}`,
  },
  TrackDrilldown: {
    path: 'configuration/:configId/track/:trackId',
    fullPath: (configId: string, trackId: string) =>
      `configuration/${configId}/track/${trackId}`,
  },
  TrackDesigner: {
    path: 'configuration/:configId/track/:trackId/designer',
    fullPath: (configId: string, trackId: string) =>
      `configuration/${configId}/track/${trackId}/designer`,
  },
  ConfigurationManagement: {
    path: 'configuration-management',
    fullPath: () => 'configuration-management',
  },
  Maintenance: {
    path: 'maintenance',
    fullPath: () => 'maintenance',
  },
  TrackCreator: {
    path: 'creator',
    fullPath: () => 'creator',
  },
};
