import { Component, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { DialogService } from 'primeng/dynamicdialog';
import { interval, Subscription, switchMap } from 'rxjs';
import { AppRoutes } from 'src/app/app.routes.const';
import { LoginGatewayComponent } from 'src/app/configuration/login-gateway/login-gateway.component';
import { IdentityService } from '../services/identity.service';
import { MaintenanceService } from '../services/maintenance/maintenance.service';

@Component({
  selector: 'app-maintenance-page',
  templateUrl: './maintenance-page.component.html',
  styleUrls: ['./maintenance-page.component.scss'],
})
export class MaintenancePageComponent implements OnDestroy {
  isDevModeEnabled = false;

  private authSub$: Subscription;
  private maintenanceSub$: Subscription;

  constructor(
    private identityService: IdentityService,
    private dialogService: DialogService,
    private maintenanceService: MaintenanceService,
    private router: Router
  ) {
    this.authSub$ = interval(1000).subscribe(() => {
      const devMode = localStorage.getItem('developer-mode');
      this.isDevModeEnabled = (devMode && devMode === 'true') || false;

      if (this.isDevModeEnabled && this.identityService.isInternalUser()) {
        this.router.navigate([AppRoutes.Dashboard.fullPath()]);
      }
    });

    this.maintenanceSub$ = interval(30000)
      .pipe(switchMap(() => this.maintenanceService.checkMaintenanceStatus()))
      .subscribe((_) => {
        console.log('Maintenance mode check');
      });
  }

  ngOnDestroy(): void {
    this.authSub$.unsubscribe();
    this.maintenanceSub$.unsubscribe();
  }

  devSignIn(): void {
    this.dialogService.open(LoginGatewayComponent, {
      header: 'Login or Register',
    });
  }
}
