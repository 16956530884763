import { Injectable } from '@angular/core';
import { NavigationEnd, Params, Router } from '@angular/router';
import { AppRoutes } from 'src/app/app.routes.const';
import { EditorModeService } from 'src/app/configuration/services/editor-mode/editor-mode.service';

@Injectable({
  providedIn: 'root',
})
export class NavigationHistoryService {
  private history: string[] = [];

  constructor(
    private editorModeService: EditorModeService,
    private router: Router
  ) {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.updateNavigationHistory(event.urlAfterRedirects);
      }
    });
  }

  private updateNavigationHistory(url: string): void {
    const currentRoute = url.split('?')[0];

    if (this.isSameRouteWithQuery(currentRoute)) {
      this.history[this.history.length - 1] = url;
      return;
    }

    if (this.shouldExcludeRoute(url)) {
      return;
    }

    this.addToHistory(url);

    if (this.history.length > 20) {
      this.history.shift();
    }
  }

  private isSameRouteWithQuery(currentRoute: string): boolean {
    return (
      this.history.length > 0 &&
      currentRoute === this.history[this.history.length - 1].split('?')[0]
    );
  }

  private shouldExcludeRoute(url: string): boolean {
    const lastItem = url.split('/').pop();
    return (
      lastItem?.includes('designer') ||
      url === this.history[this.history.length - 1]
    );
  }

  private addToHistory(url: string): void {
    this.history.push(url);
  }

  back(defaultUrl?: string, queryParams?: Params): void {
    this.history.pop();

    if (this.history.length > 0) {
      this.router.navigateByUrl(this.history[this.history.length - 1]);
      return;
    }

    if (defaultUrl) {
      this.handleDefaultUrl(defaultUrl, queryParams);
    }
  }

  private handleDefaultUrl(defaultUrl: string, queryParams?: Params): void {
    if (defaultUrl === 'projects' && this.editorModeService.isEditorMode) {
      this.router.navigate([AppRoutes.Projects.fullPath()]);
      return;
    }

    this.router.navigate([defaultUrl], { queryParams });
  }
}
