import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { TrackComment } from 'src/app/configuration/models/track-details.interface';

@Component({
  selector: 'app-track-comment',
  templateUrl: './track-comment.component.html',
  styleUrls: ['./track-comment.component.scss'],
})
export class TrackCommentComponent implements OnInit {
  @Input() comment: TrackComment;
  @Input() isCommentOwner: boolean;
  @Input() isATSUser: boolean;
  @Input() isVersionSubmitted: boolean;
  @Input() isDeleting = false;
  @Input() isEditing = false;
  @Input() isPosting = false;

  @Output() onDeleteComment: EventEmitter<string> = new EventEmitter();
  @Output() onEditComment: EventEmitter<string> = new EventEmitter();
  @Output() onSubmitCommentChanges: EventEmitter<{
    id: string;
    text: string;
  } | null> = new EventEmitter();

  commentForm: FormGroup;

  constructor() {}

  ngOnInit(): void {
    this.commentForm = new FormGroup({
      text: new FormControl(this.comment.text, [
        Validators.required,
        Validators.maxLength(5000),
      ]),
    });
  }

  editComment(): void {
    this.commentForm.get('text')?.setValue(this.comment.text);
    this.onEditComment.emit(this.comment.id);
  }

  deleteComment(commentId: string): void {
    this.onDeleteComment.emit(commentId);
  }

  submitCommentChanges(): void {
    if (!this.commentForm.get('text')?.value.length) {
      this.commentForm.markAsTouched();
      return;
    }

    this.onSubmitCommentChanges.emit({
      ...this.commentForm.value,
      id: this.comment.id,
    });
  }

  declineCommentChanges(): void {
    this.onSubmitCommentChanges.emit(null);
  }
}
