import { Injectable } from '@angular/core';

interface TestingInfo {
  companyName: string | null;
}

@Injectable({
  providedIn: 'root',
})
export class TestingService {
  private _testingInfo: TestingInfo;

  get companyName(): string | null {
    const savedTestingInfo = this.getTestingInfo();

    this._testingInfo = savedTestingInfo;

    return this._testingInfo?.companyName;
  }

  set companyName(companyName: string | null) {
    this._testingInfo.companyName = companyName || null;

    const testingInfoStringified = JSON.stringify(this._testingInfo);

    localStorage.setItem('testingInfo', testingInfoStringified);
  }

  constructor() {}

  getTestingInfo(): TestingInfo {
    const testingInfo = localStorage.getItem('testingInfo');

    if (testingInfo) {
      return JSON.parse(testingInfo) as TestingInfo;
    }

    return {
      companyName: null,
    };
  }

  resetCompanyName(): void {
    this.companyName = null;
  }
}
