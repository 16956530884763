<div class="flex flex-column justify-content-center align-items-center col-6">
  <p-image class="option-item__image"
           [src]="dimensionsImage"
           (onImageError)="setPlaceholderImage()"
           alt='Measures Schema'></p-image>
</div>

<div class="col-1">
  <p-divider [layout]="'vertical'"></p-divider>
</div>

<div class="col-5 flex flex-column justify-content-center align-items-center"
     [formGroup]="form">
  <h5 class="question mt-4 text-center">{{ question?.questionText }}</h5>
  <ul class="options-list _column">
    <ng-container *ngFor="let option of question?.options">
      <li class="option-item"
          *ngIf="option.isEnabled ? option.isEnabled(eTurnType) : true">
        <p class="option-item__label text-base m-0">{{ option.label }}</p>
        <div class="option-item__inner _inline align-items-center">
          <p-inputNumber [showButtons]="true"
                         [formControlName]="option.fieldName || ''"
                         [min]="minSize(option.fieldName || '')"
                         [max]="maxSize(option.fieldName || '')">
          </p-inputNumber>
        </div>
      </li>
    </ng-container>
  </ul>
  <p class="font-italic">*For the chosen track type its length <span *ngIf="is90Deg">and width</span> can not exceed
    more than <b>{{ sizeLimit }} meters</b>
  </p>
</div>
