import { ProcessFunctionName } from '../enums/process-function.enum';
import { ProcessFunction } from '../types/process-function.interface';

export const processFunctionsList: ProcessFunction[] = [
  {
    label: ProcessFunctionName.PnP,
    value: ProcessFunctionName.PnP,
    iconName: 'PnP',
  },
  {
    label: ProcessFunctionName.Vision,
    value: ProcessFunctionName.Vision,
    iconName: 'Vision',
  },
  {
    label: ProcessFunctionName.FillDispense,
    value: ProcessFunctionName.FillDispense,
    iconName: 'FillDispense',
  },
  {
    label: ProcessFunctionName.Screw,
    value: ProcessFunctionName.Screw,
    iconName: 'Screw',
  },
  {
    label: ProcessFunctionName.Label,
    value: ProcessFunctionName.Label,
    iconName: 'Label',
  },
  {
    label: ProcessFunctionName.Weld,
    value: ProcessFunctionName.Weld,
    iconName: 'Weld',
  },
  {
    label: ProcessFunctionName.Cure,
    value: ProcessFunctionName.Cure,
    iconName: 'Cure',
  },
  {
    label: ProcessFunctionName.Cut,
    value: ProcessFunctionName.Cut,
    iconName: 'Cut',
  },
  {
    label: ProcessFunctionName.PressCrimp,
    value: ProcessFunctionName.PressCrimp,
    iconName: 'PressCrimp',
  },
  {
    label: ProcessFunctionName.MoveRotate,
    value: ProcessFunctionName.MoveRotate,
    iconName: 'MoveRotate',
  },
  {
    label: ProcessFunctionName.TestPowerOn,
    value: ProcessFunctionName.TestPowerOn,
    iconName: 'TestPowerOn',
  },
  {
    label: ProcessFunctionName.WeighScale,
    value: ProcessFunctionName.WeighScale,
    iconName: 'WeighScale',
  },
  {
    label: ProcessFunctionName.Load,
    value: ProcessFunctionName.Load,
    iconName: 'Load',
  },
  {
    label: ProcessFunctionName.Unload,
    value: ProcessFunctionName.Unload,
    iconName: 'Unload',
  },
  {
    label: ProcessFunctionName.Reject,
    value: ProcessFunctionName.Reject,
    iconName: 'Reject',
  },
  {
    label: ProcessFunctionName.AddInsert,
    value: ProcessFunctionName.AddInsert,
    iconName: 'AddInsert',
  },
  {
    label: ProcessFunctionName.Blank,
    value: ProcessFunctionName.Blank,
    iconName: 'Blank',
  },
  {
    label: ProcessFunctionName.Process,
    value: ProcessFunctionName.Process,
    iconName: 'Process',
  },
];
