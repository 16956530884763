import { Injectable } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { BehaviorSubject } from 'rxjs';
import { TestingService } from 'src/app/admin/services/testing/testing.service';
import { UserCompanyName } from 'src/app/track-designer/enums/user-company-name.enum';
import { Role, User } from '../../common/models/user/user';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private _users: User[] = [];
  private _roles: Role[] = [];
  private _currentUser: User | null = null;

  usersChanged$ = new BehaviorSubject<User[] | null>(null);
  rolesChanged$ = new BehaviorSubject<Role[] | null>(null);

  constructor(
    private msalService: MsalService,
    private testingServce: TestingService
  ) {}

  get currentUser(): User | null {
    return this._currentUser;
  }

  set currentUser(user: User | null) {
    this._currentUser = user;
  }

  getRoles(): Role[] {
    return this._roles.slice();
  }

  setUsers(users: User[]) {
    this._users = users;
    this.usersChanged$.next(this._users);
  }

  setRoles(roles: Role[]) {
    this._roles = roles;
    this.rolesChanged$.next(this._roles);
  }

  setUserRoles(user: User, roles: Role[]) {
    const oldUser = this._users.find((u) => u.id == user.id);

    if (oldUser) {
      oldUser.roles = roles;
    }

    this.usersChanged$.next(this._users);
  }

  isUserFromCompany(department: UserCompanyName): boolean {
    const accountInfo = this.msalService.instance.getActiveAccount();

    let accountCompanyName;

    if (this.testingServce.companyName) {
      accountCompanyName = this.testingServce.companyName;
    } else {
      accountCompanyName = accountInfo?.idTokenClaims
        ? accountInfo?.idTokenClaims['companyName']
        : null;
    }

    if (!accountCompanyName) return false;

    // Done for testing purposes
    if (accountCompanyName === UserCompanyName.APG) {
      return true;
    }

    if (department === UserCompanyName.LifeScience) {
      return (accountCompanyName as string)
        .toLowerCase()
        .includes(department.toLowerCase());
    }

    return accountCompanyName === department;
  }
}
