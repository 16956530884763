import { ResolverAssetsArray } from 'pixi.js';

export const assets: ResolverAssetsArray = [
  {
    name: 'Curve500VerticalLowProfile',
    srcs: '/assets/images/track-concept/svg/Curve500VerticalLowProfile.svg',
  },
  {
    name: 'Curve500',
    srcs: '/assets/images/track-concept/svg/Curve500.svg',
  },
  {
    name: 'Curve800',
    srcs: '/assets/images/track-concept/svg/Curve800.svg',
  },
  {
    name: 'Curve90',
    srcs: '/assets/images/track-concept/svg/Curve90.svg',
  },
  {
    name: 'straight',
    srcs: '/assets/images/track-concept/svg/straight.svg',
  },
  {
    name: 'shuttle',
    srcs: '/assets/images/track-concept/svg/shuttle.svg',
  },
];
