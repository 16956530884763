import { ProductType } from './product-type';

export class ConfigurationRequest {
  productType: ProductType;
  name: string;
  description: string;

  constructor(
    name: string | null,
    description: string | null,
    productType?: ProductType
  ) {
    // Note: productType has to be the first property sent so the server
    // recognizes it as the typeDiscriminator.
    this.productType = productType ?? ProductType.SuperTrakGen3;
    this.name = name ?? 'Default';
    this.description = description ?? '';
  }
}
