import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ETurn } from 'src/app/common/enums/eTurn.enum';

export interface TrackConceptDefinition {
  eTurn: ETurn;
  length: number;
  width: number | null;
}

@Injectable({
  providedIn: 'root',
})
export class TrackCreatorService {
  private trackSpecs: BehaviorSubject<TrackConceptDefinition | null> =
    new BehaviorSubject<TrackConceptDefinition | null>(null);

  trackSpecs$ = this.trackSpecs.asObservable();

  constructor() {}

  createDefaultTrack(): void {
    this.trackSpecs.next({
      eTurn: ETurn.Curve500,
      length: 1,
      width: 0,
    });
  }

  updateTrack(trackSpecs: Partial<TrackConceptDefinition>): void {
    const newTrakSpecs: TrackConceptDefinition = this.trackSpecs.value
      ? {
          ...this.trackSpecs.value,
          ...trackSpecs,
        }
      : {
          eTurn: ETurn.None,
          length: 1,
          width: null,
          ...trackSpecs,
        };

    this.trackSpecs.next(newTrakSpecs);
  }

  clearTrack(): void {
    this.trackSpecs.next(null);
  }
}
