import { Routes } from '@angular/router';
import { AppRoutes } from '../app.routes.const';
import { AuthGuard } from '../guards/authGuard.guard';
import { TrackDesignerComponent } from './track-designer.component';
import { TrackDesignerResolver } from './track-designer.resolver';

const trackDesignerRoutes: Routes = [
  {
    path: AppRoutes.TrackDesigner.path,
    component: TrackDesignerComponent,
    resolve: [TrackDesignerResolver],
    canActivate: [AuthGuard],
  },
];

export default trackDesignerRoutes;
