<div class="flex flex-column justify-content-center align-items-center col-6">
  <h4>Explanation graphics</h4>
  <p-image class="option-item__image"
           [preview]="true"
           [src]="'/assets/images/track-designer/shuttles/shuttle-magnets.png'"
           alt='Shuttle Magnets graphics'></p-image>
</div>

<div class="col-1">
  <p-divider [layout]="'vertical'"></p-divider>
</div>

<div class="shuttles col-5 flex flex-column h-full overflow-auto"
     [formGroup]="form">
  <p-divider align="center"
             class="mt-4">
    <h5 class="question m-0">{{ magnetsCountQuestion.questionText }}</h5>
  </p-divider>
  <ul class="options-list flex justify-content-center">
    <li class="option-item m-0"
        *ngFor="let option of magnetsCountQuestion.options">
      <label class="option-item__inner _inline p-0 mb-4 mx-4 justify-content-center align-items-center">
        <p-radioButton [formControlName]="magnetsCountQuestion.fieldName"
                       [value]="option.value"
                       class="mr-3"></p-radioButton>
        <p class="option-item__label text-base">{{ option.label }}</p>
      </label>
    </li>
  </ul>
  <p-accordion>
    <p-accordionTab header="How to decide">
      <p>Please enter the following information to calculate the recommended number of magnets:</p>
      <ul class="options-list flex flex-column align-items-start">
        <li class="option-item m-0 w-full">
          <div class="option-item__inner _inline p-0 mt-4 mb-2 align-items-center justify-content-start cursor-auto">
            <p class="option-item__label text-base m-0 mr-4 w-2">Fixture length (mm):</p>
            <p-inputNumber [showButtons]="false"
                           [(ngModel)]="fixtureLength"
                           [ngModelOptions]="{standalone: true}"
                           placeholder="152 - 1000"
                           [min]="0">
            </p-inputNumber>
            <p *ngIf="isFixtureLengthExceeds"
               class="font-italic ml-3 w-6 text-orange-500">*You have exceeded the maximum supported fixture
              length.
              Please
              contact
              sales to
              discuss possible solutions.</p>
            <p *ngIf="isFixtureLengthBelowMin"
               class="font-italic ml-3 w-6 text-orange-500">*Your fixture length is below the minimum supported length.
              Please contact sales to discuss possible solutions.</p>
          </div>
        </li>
        <li class="option-item m-0 w-full">
          <div class="option-item__inner _inline p-0 mt-4 mb-2 align-items-center justify-content-start cursor-auto">
            <p class="option-item__label text-base m-0 mr-4 w-2">Payload (kg):</p>
            <p-inputNumber [showButtons]="false"
                           [(ngModel)]="payload"
                           [ngModelOptions]="{standalone: true}"
                           placeholder="0 - 10"
                           [min]="0">
            </p-inputNumber>
            <p *ngIf="!isOverUnder && isPayloadExceeds10kg"
               class="font-italic ml-3 w-6 text-orange-500">*You have exceeded the maximum supported payload
              weight.
              Please
              contact
              sales to
              discuss possible solutions.</p>
            <p *ngIf="isOverUnder && isPayloadExceeds4kg"
               class="font-italic ml-3 w-6 text-orange-500">*Warning: on a vertical system, the payload of the shuttle
              is not to exceed 4kg in the curves. 10kg payload remains applicable on top of the track.</p>
          </div>
        </li>
      </ul>
      <ng-container *ngIf="!!recommendedMagnets">
        <p-divider></p-divider>
        <p class="font-italic">*Based on the provided information, we recommend a <span
                class="underline font-semibold">{{
            recommendedMagnets }}</span>
        </p>
      </ng-container>
    </p-accordionTab>
  </p-accordion>
  <p-divider align="center"
             class="mt-4">
    <h5 class="question m-0">{{ shuttlesCountQuestions.questionText }}</h5>
  </p-divider>
  <ul class="options-list _left">
    <li class="option-item m-0">
      <div class="option-item__inner _inline p-0 mb-4 justify-content-center align-items-center">
        <p class="option-item__label text-base m-0 mr-4">{{ shuttlesCountQuestions.options[0].label }}</p>
        <p-inputNumber [formControlName]="shuttlesCountQuestions.fieldName"
                       [showButtons]="false"
                       [min]="0">
        </p-inputNumber>
      </div>
    </li>
  </ul>
  <p-divider align="center">
    <h5 class="question m-0">{{ irPalletQuestion.questionText }}</h5>
  </p-divider>
  <ul class="options-list _left">
    <li class="option-item _bordered flex relative overflow-hidden w-6 m-0">
      <label class="option-item__inner _inline">
        <p-checkbox [formControlName]="irPalletQuestion.fieldName"
                    (click)="disableCheckboxFormValueChange($event)"
                    [binary]="true"></p-checkbox>
        <p class="option-item__label text-base w-full text-center">{{ irPalletQuestion.options[0].label }}</p>
      </label>
      <div class="hidden-info flex align-items-center justify-content-end w-5rem">
        <p-button [rounded]="true"
                  severity="warning"
                  ariaLabel="Description"
                  class="description-button"
                  (click)="isDescriptionVisible = true"
                  icon="pi pi-info">
        </p-button>
      </div>
    </li>
  </ul>
</div>

<p-dialog [(visible)]="isDescriptionVisible"
          [modal]="true"
          [closeOnEscape]="false"
          [draggable]="false"
          class="description-dialog"
          [resizable]="false">
  <ng-template pTemplate="header">
    <h3 class="m-0">{{ irPalletQuestion.options[0].label }}</h3>
  </ng-template>

  <ng-template pTemplate="content">

    <p-image [preview]="true"
             [appendTo]="'body'"
             src="/assets/images/track-designer/shuttles/ir-pallet.png"></p-image>
    <p class="m-0 text-base text-justify"
       [innerText]="irPalletQuestion.options[0].description"></p>
  </ng-template>
</p-dialog>
