<div class="flex align-items-center gap-3 mb-3">
  <p-button icon="pi pi-arrow-left"
            (click)="onBack()"
            styleClass="w-5rem">
  </p-button>
</div>

<div class="flex gap-3">
  <!-- No configurations available. -->
  <div class="flex flex-column w-full"
       *ngIf="!configurations.length; else configurationsExist">
    <div class="flex flex-column gap-3 w-6 h-fit-content">
      <ng-container *ngTemplateOutlet="projectInfoTpl"></ng-container>
    </div>
    <p-divider></p-divider>
    <div class="text-center w-full mt-3">
      <span>Create a configuration and start adding your tracks</span>
      <div class="flex justify-content-center gap-5 mt-3">
        <button pButton
                label="New Configuration"
                type="button"
                icon="pi pi-plus"
                (click)="onNewConfiguration()">
        </button>
      </div>
    </div>
  </div>

  <ng-template #configurationsExist>
    <div class="project-info flex flex-column gap-3 w-6 h-fit-content">
      <ng-container *ngTemplateOutlet="projectInfoTpl"></ng-container>

      <div class="flex align-items-center">
        <h3 class="flex flex-grow-1 m-0">Configurations</h3>
        <button pButton
                label="New Configuration"
                icon="pi pi-plus"
                class="p-button-outlined _light-blue"
                *ngIf="!editingMode"
                (click)="onNewConfiguration()">
        </button>
      </div>

      <div *ngFor="let configuration of configurations;"
           class="flex flex-column gap-2 p-card p-3">
        <div class="flex">
          <h4 class="flex-grow-1 text-3xl font-semibold m-0 mb-3">{{ configuration.name }}</h4>
          <div>
            <button pButton
                    pTooltip="New Version"
                    type="button"
                    icon="pi pi-plus"
                    class="p-button-outlined"
                    (click)="onNewVersion(configuration.id)"></button>
            <button pButton
                    pTooltip="Delete Configuration"
                    icon="pi pi-trash"
                    *ngIf="isConfigEditable(configuration) && !editingMode"
                    class="p-button-danger p-button-outlined ml-2"
                    (click)="onDeleteConfiguration(configuration.id)">
            </button>
          </div>
        </div>

        <ng-container *ngFor="let configurationVersion of configuration.configurationVersions">
          <app-configurations-card [configurationVersion]="configurationVersion"
                                   [configId]="configuration.id"
                                   [selectedId]="selectedVersionId"
                                   (click)="selectVersion(configurationVersion.id)">
          </app-configurations-card>
        </ng-container>
      </div>
    </div>
    <div class="w-6 h-fit-content gap-3">
      <app-configuration-info *ngIf="selectedVersionId && selectedConfigId"
                              [versionId]="selectedVersionId"
                              [configId]="selectedConfigId"
                              [projectId]="projectId"
                              [editingMode]="editingMode"
                              [editableForSales]="versionEditable"
                              (onDeleteVersion)="selectVersion($event)"
                              (onSelectVersion)="selectVersion($event)">
      </app-configuration-info>
    </div>
  </ng-template>

</div>


<!-- Create config modal -->
<p-dialog header="Header"
          [(visible)]="isNewConfigurationVisible"
          [modal]="true"
          [style]="{ width: '50vw' }"
          [draggable]="false"
          [resizable]="false"
          position="top"
          (onHide)="onCancelNewConfiguration()">
  <ng-template pTemplate="header">
    <span class="text-xl font-bold">Create a New Configuration</span>
  </ng-template>

  <!-- Name Input -->
  <form [formGroup]="createConfigurationForm"
        autoComplete="off"
        class="m-auto w-6">
    <div class="flex flex-column gap-2 mb-4">
      <label htmlFor="name">Name</label>
      <input pInputText
             id="name"
             name="name"
             formControlName="name"
             type="text"
             autoComplete="off"
             [class.ng-invalid]="createConfigurationForm.controls.name.invalid"
             [class.ng-dirty]="createConfigurationForm.controls.name.dirty">
      <div *ngIf="createConfigurationForm.controls.name.invalid && createConfigurationForm.touched"
           class="text-red-500">
        <p *ngIf="createConfigurationForm.controls.name.errors?.['required']">* A name is required.</p>
        <p *ngIf="createConfigurationForm.controls.name.errors?.['maxlength']">* The name is too long. Max 200.
          characters.
        </p>
        <p *ngIf="createConfigurationForm.controls.name.errors?.['duplicateName']">* The configuration name already
          exists. Please use a different name.</p>
      </div>
    </div>
    <!-- Description Input -->
    <div class="flex flex-column gap-2">
      <label htmlFor="description">Description</label>
      <input pInputText
             id="description"
             formControlName="description"
             name="description"
             type="text"
             autoComplete="off"
             [class.ng-invalid]="createConfigurationForm.controls.description.invalid"
             [class.ng-dirty]="createConfigurationForm.controls.description.dirty">
      <div *ngIf="createConfigurationForm.controls.description.invalid && createConfigurationForm.touched"
           class="h-1rem text-red-500">
        <p *ngIf="createConfigurationForm.controls.description.errors?.['required']">* A description is required.</p>
        <p *ngIf="createConfigurationForm.controls.description.errors?.['maxlength']">* The name is too long. Max 200.
          characters.
        </p>
      </div>
    </div>
  </form>
  <ng-template pTemplate="footer">
    <p-button label="Cancel"
              icon="pi pi-times"
              styleClass="p-button-outlined p-button-secondary"
              (click)="onCancelNewConfiguration()"></p-button>
    <p-button icon="pi pi-check"
              [loading]="isLoading"
              label="Create"
              (click)="onSubmitNewConfiguration()"></p-button>
  </ng-template>
</p-dialog>

<p-confirmDialog rejectButtonStyleClass="p-button-text"></p-confirmDialog>

<ng-template #projectInfoTpl>
  <p-panel [toggleable]="true"
           [collapsed]="configurations.length >= 3"
           (onAfterToggle)="resetProjectRefNumberChange()">
    <ng-template pTemplate="header">
      <h3 class="m-0">Project: {{ currentProject.name }}</h3>
    </ng-template>

    <ul class="project-info__list list-none p-0">
      <li class="project-info__item flex justify-content-between align-items-center py-2 border-bottom-1">
        <span>Description:</span>
        <span>{{ currentProject.description }}</span>
      </li>
      <li class="project-info__item flex justify-content-between align-items-center py-2 border-bottom-1">
        <span>Reference Number:</span>
        <p *ngIf="!projectRefNumberEditing; else projectRefNumberEditingTpl"
           class="inline-flex align-items-center">
          <p-button icon="pi pi-pencil"
                    *ngIf="!editingMode"
                    [text]="true"
                    [size]="'small'"
                    class="mr-2"
                    (click)="projectRefNumberEditing = true">
          </p-button>
          {{ projectRefNumber }}
        </p>

        <ng-template #projectRefNumberEditingTpl>
          <span class="inline-flex align-items-center">
            <span class="p-buttonset ml-3"
                  *ngIf="!isProjectUpdating; else projectUpdatingTpl">
              <p-button icon="pi pi-check"
                        [disabled]="!projectRefNumber.length"
                        [text]="true"
                        [size]="'small'"
                        severity="success"
                        (click)="saveProjectRefNumber()">
              </p-button>
              <p-button icon="pi pi-times"
                        [text]="true"
                        [size]="'small'"
                        severity="danger"
                        (click)="resetProjectRefNumberChange()">
              </p-button>
            </span>
            <input type="text"
                   pInputText
                   [minlength]="1"
                   [disabled]="isProjectUpdating"
                   [maxlength]="64"
                   [(ngModel)]="projectRefNumber" />
            <ng-template #projectUpdatingTpl>
              <p-progressSpinner [style]="{width: '18px', height: '18px'}"
                                 styleClass="custom-spinner ml-3"
                                 strokeWidth="4"
                                 animationDuration=".5s"></p-progressSpinner>
            </ng-template>
          </span>
        </ng-template>
      </li>
    </ul>
  </p-panel>
</ng-template>
