import { UserCompanyName } from 'src/app/track-designer/enums/user-company-name.enum';

export const CompaniesList = [
  {
    name: 'Life Science Chicago',
    value: UserCompanyName.LSChicago,
  },
  {
    name: 'Life Science Cambridge',
    value: UserCompanyName.LSCambridge,
  },
  {
    name: 'Life Science REM Cambridge',
    value: UserCompanyName.LSRemCambridge,
  },
  {
    name: 'Munich',
    value: UserCompanyName.Munich,
  },
  {
    name: 'ATS Products Group',
    value: UserCompanyName.APG,
  },
];
