import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { IdentityService } from 'src/app/core/services/identity.service';
import { UserService } from 'src/app/core/services/user.service';
import { ControlPanelType } from '../../enums/control-panel-type.enum';
import { Question, QuestionOption } from '../../track-designer-questions';
@Component({
  selector: 'app-track-controller',
  templateUrl: './track-controller.component.html',
  styleUrls: ['./track-controller.component.scss'],
})
export class TrackControllerComponent implements OnInit {
  @Input() question: Question | undefined;
  @Input() form: FormGroup;

  controllerImg: string;
  controllerDescription: string;

  get isSymphoniProject(): boolean {
    return this.form.get('isSymphoni')?.value;
  }

  constructor(
    public identityService: IdentityService,
    public userService: UserService
  ) {}

  ngOnInit(): void {
    this.checkDefaultOption();

    const currentController = this.form.get('panelType')?.value;
    const selectedController = this.question?.options.find(
      (option: QuestionOption) => option?.value === currentController
    );

    this.controllerImg = this.getControllerImageSrc(selectedController?.value);

    this.controllerDescription = selectedController?.description || '';
  }

  showController(controller: QuestionOption): void {
    this.controllerImg = this.getControllerImageSrc(controller?.value);
    this.controllerDescription = controller.description || '';
  }

  setPlaceholderImage(): void {
    this.controllerImg =
      'https://plchldr.co/i/1920x1456?text=Controller%20Image';
  }

  getControllerImageSrc(controllerName: string): string {
    return `/assets/images/track-designer/controllers/${controllerName}.png`;
  }

  isControllerOnly(controller: ControlPanelType): boolean {
    return controller === ControlPanelType.ControllerOnly;
  }

  checkDefaultOption(): void {
    const selectedController = this.form.get('panelType');

    const controllerOption = this.question?.options.find(
      (option: QuestionOption) => option?.value === selectedController?.value
    );
    let isOptionAvailableForCurrentUser: boolean;

    if (controllerOption?.isEnabled) {
      isOptionAvailableForCurrentUser = controllerOption.isEnabled(
        this.userService,
        this.identityService
      );
    } else {
      isOptionAvailableForCurrentUser = true;
    }

    if (!isOptionAvailableForCurrentUser) {
      selectedController?.setValue('ControlPanelType.Standard');
    }

    if (this.isSymphoniProject) {
      selectedController?.setValue(ControlPanelType.ControllerOnly);
    }
  }
}
