<div class="flex align-items-center mb-3">
  <p class="w
     font-bold
     flex-grow-1
     mb-0">{{ comment.createdBy.fullName }}
    <img class="max-w-5rem ml-2"
         *ngIf="isATSUser"
         [src]="'assets/images/SuperTrak Gen3 Small.jpg'"
         alt="SuperTrak Conveyance Logo" />
  </p>
  <p class="text-500 mb-0">{{ comment.createdTime | date : 'long' }}</p>
</div>
<p *ngIf="!isEditing; else editingTpl">{{ comment.text }}</p>
<p *ngIf="comment.updatedTime !== comment.createdTime"
   class="text-500 mb-0">Updated on: {{ comment.updatedTime | date : 'long' }}</p>

<ng-template #editingTpl>
  <ng-container [formGroup]="commentForm">
    <textarea pInputTextarea
              class="w-full mb-3"
              [rows]="6"
              [cols]="60"
              formControlName="text"
              pAutoFocus
              [autofocus]="true"
              [autoResize]="true">
    </textarea>
    <div *ngIf="commentForm.invalid && commentForm.touched"
         class="h-1rem text-red-500">
      <p *ngIf="commentForm.get('text')?.errors?.['required']">* Comment content is required.</p>
    </div>
  </ng-container>
</ng-template>
<div class="flex justify-content-end gap-3"
     *ngIf="isCommentOwner && !isVersionSubmitted">
  <ng-container *ngIf="!isEditing; else postBtn">
    <button pButton
            pRipple
            type="button"
            icon="pi pi-pencil"
            [disabled]="isDeleting"
            (click)="editComment()"
            label="Edit">
    </button>
    <button pButton
            pRipple
            type="button"
            icon="pi pi-trash"
            class="p-button-danger"
            [loading]="isDeleting"
            (click)="deleteComment(comment.id)"
            label="Delete">
    </button>
  </ng-container>

  <ng-template #postBtn>
    <button pButton
            pRipple
            type="button"
            icon="pi pi-check"
            [loading]="isPosting"
            (click)="submitCommentChanges()"
            label="Add">
    </button>
    <button pButton
            pRipple
            type="button"
            class="p-button-danger"
            [disabled]="isPosting"
            icon="pi pi-times"
            (click)="declineCommentChanges()"
            label="Cancel">
    </button>
  </ng-template>
