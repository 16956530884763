<form [formGroup]="quoteForm">

  <div class="flex flex-column gap-2 mb-4">
    <label htmlFor="requestedByDate">Proposal Requested By:</label>
    <p-calendar id="requestedByDate"
                [minDate]="minDate"
                [maxDate]="maxDate"
                dateFormat="dd-M-yy"
                formControlName="requestedByDate">
    </p-calendar>
    <p>
      <small>
        <i>Typical processing time is 2-3 business days.</i>
      </small>
    </p>
  </div>

  <div class="flex flex-column gap-2 mb-4">
    <label htmlFor="rfqComment">Comment</label>
    <textarea type="text"
              pInputTextarea
              [rows]="6"
              [cols]="60"
              [autoResize]="true"
              id="rfqComment"
              formControlName="rfqComment">
    </textarea>
  </div>

  <p-button label="Submit request"
            (onClick)="requestQuote()">

  </p-button>
</form>
