import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { Subject, takeUntil } from 'rxjs';
import { ConfigurationOwner } from 'src/app/configuration-management/interfaces/configuration-owner.interface';
import { UserDataService } from 'src/app/core/services/user-data.service';
import { EditorModeService } from '../services/editor-mode/editor-mode.service';

@Component({
  selector: 'app-editor-panel',
  templateUrl: './editor-panel.component.html',
  styleUrls: ['./editor-panel.component.scss'],
  host: {
    class: 'absolute top-0 left-0 w-full',
  },
})
export class EditorPanelComponent implements OnInit, OnDestroy {
  userInfoShown = false;
  user: ConfigurationOwner;

  isLoading = false;

  private readonly _destroying$ = new Subject<void>();

  constructor(
    private router: Router,
    private editorModeService: EditorModeService,
    private userDataService: UserDataService,
    private messageService: MessageService
  ) {}

  ngOnInit(): void {
    this.router.events.pipe(takeUntil(this._destroying$)).subscribe((event) => {
      if (event instanceof NavigationEnd) {
        const url = event.url.split('/');
        const editingPages = ['projects', 'configuration', 'track-designer'];

        if (!editingPages.some((pageUrl) => pageUrl === url[1])) {
          this.editorModeService.setEditorMode(false);
        }
      }
    });

    this.editorModeService.onBehalfOfUserId$
      .pipe(takeUntil(this._destroying$))
      .subscribe((id: string | null) => {
        if (!id) return;

        const currentUser = this.editorModeService.currentUser;
        if (!currentUser || currentUser.id !== id) {
          this.isLoading = true;
          this.userDataService.getUserById(id).subscribe({
            next: (user) => {
              this.editorModeService.currentUser = user;
              this.isLoading = false;
            },
            error: (error) => {
              this.isLoading = false;
              this.messageService.add({
                severity: 'error',
                summary: 'Error retreiving user.',
                detail: error.status + ': ' + error.title,
              });
            },
          });
        }
      });

    this.editorModeService.onBehalfOfUser$
      .pipe(takeUntil(this._destroying$))
      .subscribe((user) => {
        if (user) {
          this.user = user;
        }
      });
  }

  ngOnDestroy(): void {
    this._destroying$.unsubscribe();
  }

  showUserInfo(): void {
    this.userInfoShown = true;
  }
}
