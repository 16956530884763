import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, map, Observable, tap, throwError } from 'rxjs';
import {
  RoleResponse,
  UserResponse,
} from 'src/app/common/models/user-response/user-response';
import { Role, User } from 'src/app/common/models/user/user';
import { environment } from 'src/environments/environment';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root',
})
export class UserDataService {
  private allUsersUrl = 'https://' + environment.potApiBaseUrl + '/api/users';
  private allRolesUrl = 'https://' + environment.potApiBaseUrl + '/api/roles';
  private userById = 'https://' + environment.potApiBaseUrl + '/api/users/:id';
  private updateUserRolesUrl =
    'https://' + environment.potApiBaseUrl + '/api/users/:id/roles';

  constructor(
    private httpClient: HttpClient,
    private userService: UserService
  ) {}

  loadUsers() {
    return this.httpClient.get<UserResponse[]>(this.allUsersUrl).pipe(
      map((responses: UserResponse[]) => {
        const users: User[] = [];

        responses.forEach((u) => {
          const user = new User(
            u.id,
            u.externalId,
            u.firstName,
            u.lastName,
            u.email,
            u.isActive,
            u.isAts
          );

          u.roles.forEach((r) => {
            user.addRole(new Role(r.id, r.name, r.permissions));
          });
          users.push(user);
        });
        return users;
      }),
      tap((users: User[]) => {
        this.userService.setUsers(users);
      })
    );
  }

  loadRoles() {
    return this.httpClient.get<RoleResponse[]>(this.allRolesUrl).pipe(
      map((responses: RoleResponse[]) => {
        const roles: Role[] = [];

        responses.forEach((r) => {
          const role = new Role(r.id, r.name, r.permissions);

          roles.push(role);
        });
        return roles;
      }),
      tap((roles: Role[]) => {
        this.userService.setRoles(roles);
      })
    );
  }

  getUserById(userId: string): Observable<any> {
    const url = this.userById.replace(':id', userId);

    return this.httpClient.get<any>(url).pipe(
      map((res) => {
        return res;
      }),
      catchError((error) => throwError(() => error))
    );
  }

  updateUserRoles(user: User, roles: Role[]) {
    const url = this.updateUserRolesUrl.replace(':id', user.id);

    return this.httpClient
      .put<RoleResponse[]>(url, roles, {
        responseType: 'json',
      })
      .pipe(
        map((responses: RoleResponse[]) => {
          const roles: Role[] = [];

          responses.forEach((r) => {
            const role = new Role(r.id, r.name, r.permissions);

            roles.push(role);
          });
          return roles;
        }),
        tap((roles: Role[]) => {
          this.userService.setUserRoles(user, roles);
        })
      );
  }
}
