import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { Table, TableLazyLoadEvent } from 'primeng/table';
import { Subject, takeUntil } from 'rxjs';
import { AppRoutes } from 'src/app/app.routes.const';
import {
  getVersionStatusColor,
  getVersionStatusLabel,
} from 'src/app/common/helpers/configuration-version-status';
import { Track } from 'src/app/common/models/track/track';
import { RoleEnum } from 'src/app/common/models/user/user';
import { TrackService } from 'src/app/common/services/track-services/track.service';
import {
  Configuration,
  ConfigurationVersion,
  VersionStatus,
} from 'src/app/configuration/models/configuration.model';
import { AppLoadingService } from 'src/app/core/services/app-loading.service';
import { IdentityService } from 'src/app/core/services/identity.service';
import { AccessScope } from '../../enums/access-scope.enum';
import { convertTableParameters } from '../../helpers/convert-table-parameters';
import { ConfigurationsRequestParameters } from '../../interfaces/configurations-request-parameters.interface';
import { TableParameters } from '../../interfaces/table-parameters.interface';
import { ConfigurationVersionsDataService } from '../../services/configuration-versions/configuration-versions-data.service';
import { ConfigurationsApiService } from '../../services/configurations-api/configurations-api.service';
import { ConfigurationsStorageService } from '../../services/configurations-storage/configurations-storage.service';
import { ConfigurationsListParams } from '../../types/configurations-list-params.type';

@Component({
  selector: 'app-configurations-list',
  templateUrl: './configurations-list.component.html',
  styleUrls: ['./configurations-list.component.scss'],
  host: {
    class: 'flex flex-column flex-grow-1 overflow-auto h-full',
  },
})
export class ConfigurationsListComponent implements OnInit, OnDestroy {
  @ViewChild('configTable') configTable: Table;
  private readonly _destroying$ = new Subject<void>();

  configurations: Configuration[] = [];
  selectedVersion: ConfigurationVersion;
  selectedConfiguration: Configuration;

  isLoading = true;
  exportingParameters = false;

  tracksList: Track[] = [];
  listParams: ConfigurationsListParams;

  showTracks = false;
  loadingTracks = false;
  isConfigIdValid = true;
  isVersionEmpty = false;

  get isAdmin(): boolean {
    return !!this.identityService.currentUser?.hasAnyRole([
      RoleEnum.Admin,
      RoleEnum.SuperAdmin,
    ]);
  }

  constructor(
    private identityService: IdentityService,
    private messageService: MessageService,
    private configurationsStorageService: ConfigurationsStorageService,
    private configurationsApiService: ConfigurationsApiService,
    private configurationVersionService: ConfigurationVersionsDataService,
    private trackService: TrackService,
    private router: Router,
    private appLoadingService: AppLoadingService
  ) {}

  ngOnInit(): void {
    this.configurationsStorageService.configurations
      .pipe(takeUntil(this._destroying$))
      .subscribe({
        next: (configurations: Configuration[]) => {
          this.configurations = configurations;
        },
      });

    this.configurationsStorageService.configurationsListParams
      .pipe(takeUntil(this._destroying$))
      .subscribe({
        next: (listParameters: ConfigurationsListParams | null) => {
          if (listParameters) {
            this.listParams = listParameters;
          }
        },
      });

    this.trackService.tracks$
      .pipe(takeUntil(this._destroying$))
      .subscribe((tracks: Track[]) => {
        this.tracksList = tracks;
        this.isVersionEmpty = !!tracks.length;
      });
  }

  ngOnDestroy(): void {
    this._destroying$.unsubscribe();
  }

  updateConfigurations({
    rows,
    sortField,
    sortOrder,
    first,
    filters,
  }: TableLazyLoadEvent): void {
    const parameters: TableParameters = {
      rows,
      sortField: sortField || 'createdTime',
      sortOrder,
      first,
      filters,
    };

    const tableParameters = convertTableParameters(parameters);
    this.requestConfigurations(tableParameters);
  }

  requestConfigurations(parameters?: ConfigurationsRequestParameters): void {
    this.isLoading = true;
    this.appLoadingService.startLoadingBar();
    this.configurationsApiService
      .getAllConfigurations(AccessScope.Sales, parameters)
      .pipe(takeUntil(this._destroying$))
      .subscribe({
        next: (response) => {
          this.configurationsStorageService.updateConfigurations(response);
        },
        error: (error) => {
          this.isLoading = false;
          this.messageService.add({
            severity: 'error',
            summary: 'Error requesting configurations.',
            detail: error.status + ': ' + error.title,
          });
          console.error('error: ', error);
          this.appLoadingService.stopLoadingBar();
        },
        complete: () => {
          this.isLoading = false;
          this.appLoadingService.stopLoadingBar();
        },
      });
  }

  getStatusColor(rfqStatus: VersionStatus): {
    class: string;
    color: string;
  } {
    return getVersionStatusColor(rfqStatus);
  }

  getStatusLabel(rfqStatus: VersionStatus): string {
    return getVersionStatusLabel(rfqStatus);
  }

  onViewTracks(
    version: ConfigurationVersion,
    configuration: Configuration
  ): void {
    this.selectedVersion = version;
    this.selectedConfiguration = configuration;
    this.showTracks = true;
    this.loadingTracks = true;
    const accessScope = this.identityService.currentUser?.hasAccessScope(
      AccessScope.Sales
    )
      ? AccessScope.Sales
      : AccessScope.User;

    this.trackService
      .getTracksForConfigVersion(version.id, accessScope)
      .pipe(takeUntil(this._destroying$))
      .subscribe({
        next: () => {
          this.loadingTracks = false;
        },
        error: () => {
          this.loadingTracks = false;
        },
        complete: () => {
          this.loadingTracks = false;
        },
      });
  }

  viewTrack(trackId: string): void {
    this.router.navigate(
      [
        AppRoutes.TrackDrilldown.fullPath(
          this.selectedConfiguration.id,
          trackId
        ),
      ],
      {
        queryParams: {
          versionId: this.selectedVersion.id,
        },
      }
    );
  }

  viewConfiguration(): void {
    this.router.navigate(
      [AppRoutes.ProjectDetails.fullPath(this.selectedConfiguration.projectId)],
      {
        queryParams: {
          versionId: this.selectedVersion.id,
        },
      }
    );
  }

  validateId(configId: string): void {
    const uuidPattern =
      /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/;
    this.isConfigIdValid = uuidPattern.test(configId) || !configId.length;
  }

  filterByConfigId(event: any): void {
    this.validateId(event.target.value);
    if (!this.isConfigIdValid) {
      return;
    }
    this.setConfigIdFilter(event.target.value);
    this.configTable._filter();
  }

  setConfigIdFilter(value: string | null): void {
    this.configTable.filters['configurationId'] = {
      value,
    };
  }

  clearField(event: any): void {
    if (!this.isConfigIdValid) {
      event.target.value = '';
      this.setConfigIdFilter(null);
      return;
    }
  }

  defineVersionCreator(
    createdById: string,
    ownerId: string
  ): 'Owner' | 'Sales' {
    return createdById === ownerId ? 'Owner' : 'Sales';
  }

  isVersionOwner(version: ConfigurationVersion): boolean {
    return version.createdById === this.identityService.currentUser?.id;
  }

  exportVersionParameters(configVersionId: string): void {
    this.exportingParameters = true;
    this.appLoadingService.startLoadingBar();

    if (!this.identityService.currentUser?.hasAccessScope(AccessScope.Sales))
      return;

    this.configurationVersionService
      .exportConfigurationVersionParameters(configVersionId)
      .pipe(takeUntil(this._destroying$))
      .subscribe({
        next: (res) => {
          const link = document.createElement('a');
          const disposition = res.headers.get('content-disposition');
          const content = res.body;

          if (!disposition || !content) return;

          const contentType = 'application/vnd.ms-excel';
          const blob = new Blob([res.body], { type: contentType });

          const fileName = disposition
            .split(';')[1]
            .split('filename')[1]
            .split('=')[1]
            .replace(/['"]+/g, '')
            .trim();

          link.href = window.URL.createObjectURL(blob);
          link.download = fileName;
          link.click();
        },
        error: (error) => {
          this.messageService.add({
            severity: 'error',
            summary: 'Error downloading parameters.',
            detail: 'Version was not found',
          });
          console.error(error);
          this.appLoadingService.stopLoadingBar();
          this.exportingParameters = false;
        },
        complete: () => {
          this.exportingParameters = false;
          this.appLoadingService.stopLoadingBar();
        },
      });
  }
}
