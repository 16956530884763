<div class="process-map-template p-card p-component">
  <div class="p-card-header">
    <img [src]="template.imgSrc"
         class="process-map-template__img"
         alt="Prcoess Map Template 1">
  </div>
  <div class="p-card-body">
    <p class="p-card-title mb-0">{{ template.title }}</p>
    <!-- <p class="p-card-subtitle">{{ template.subtitle }}</p> -->
    <div class="p-card-content">
      <p>{{ template.description }}</p>
    </div>
    <div class="p-card-footer text-center">
      <p-button icon="pi pi-file-edit"
                label="Use Template"></p-button>
    </div>
  </div>
</div>
