<p-button icon="pi pi-angle-left"
          (onClick)="close()"
          [outlined]="true">
</p-button>

<form class="flex flex-column flex-grow-1"
      [formGroup]="processForm">

  <div class="flex w-full align-items-center my-3">
    <h2 class="process-details__name mb-0"
        *ngIf="!processForm.controls.name.touched; else editNameTpl">{{ process.name }}</h2>
    <ng-template #editNameTpl>
      <input pInputText
             type="text"
             id="name"
             name="name"
             class="p-inputtext-lg"
             [autocomplete]="false"
             formControlName="name">
    </ng-template>
    <div class="process-details__action ml-2 px-1">
      <p-button [text]="true"
                *ngIf="!processForm.controls.name.touched"
                (onClick)="touchField('name')"
                pTooltip="Edit"
                icon="pi pi-pencil"></p-button>
      <p-button [text]="true"
                *ngIf="processForm.controls.name.touched"
                pTooltip="Reset"
                (onClick)="resetField('name', process.name)"
                icon="pi pi-refresh">
      </p-button>
    </div>
  </div>


  <ul class="process-details flex flex-column gap-2">
    <li class="process-details__item flex w-full align-items-center">
      <p class="process-details__name mb-0">{{ processMap.function.title }}</p>
      <div class="process-details__value flex flex-grow-1 align-items-center justify-content-end">
        <ng-container *ngIf="!processForm.controls.function.touched; else editFunctionTpl">
          <p class="mb-0">{{ process.function }}</p>
          <img [src]="'/assets/icons/' + getIconName(process.function) + '.svg'"
               class="w-1rem ml-2"
               [alt]="process.function" />
        </ng-container>
        <ng-template #editFunctionTpl>
          <p-dropdown formControlName="function"
                      styleClass="w-full"
                      [options]="processFunctionList"
                      optionLabel="label"
                      appendTo="body"
                      optionValue="value"
                      (onChange)="updateSelectedFunction($event)"
                      placeholder="Select a Function">
            <ng-template pTemplate="selectedItem">
              <div class="flex align-items-center gap-2"
                   *ngIf="selectedProcessFunction">
                <img [src]="'/assets/icons/' + selectedProcessFunction.iconName + '.svg'"
                     class="w-1rem"
                     [alt]="selectedProcessFunction.label" />
                <div>{{ selectedProcessFunction.label }}</div>
              </div>
            </ng-template>
            <ng-template let-option
                         pTemplate="item">
              <div class="flex align-items-center gap-2">
                <img class="w-1rem"
                     [alt]="option.label"
                     [src]="'/assets/icons/' + option.iconName + '.svg'" />
                <div>{{ option.label }}</div>
              </div>
            </ng-template>
          </p-dropdown>
        </ng-template>
      </div>
      <div class="process-details__action ml-2 px-1">
        <p-button [text]="true"
                  *ngIf="!processForm.controls.function.touched"
                  (onClick)="touchField('function')"
                  pTooltip="Edit"
                  icon="pi pi-pencil"></p-button>
        <p-button [text]="true"
                  *ngIf="processForm.controls.function.touched"
                  pTooltip="Reset"
                  (onClick)="resetField('function', process.function)"
                  icon="pi pi-refresh">
        </p-button>
      </div>
    </li>

    <li class="process-details__item flex w-full align-items-center">
      <p class="process-details__name mb-0">{{ processMap.color.title }}</p>
      <div class="process-details__value flex flex-grow-1 align-items-center justify-content-end">
        <span class="process-details__color"
              *ngIf="!processForm.controls.color.touched; else editColorTpl"
              [style]="{
                  backgroundColor: process.color
                }">
        </span>
        <ng-template #editColorTpl>
          <div class="flex gap-2">
            <label class="color-sample _dark-blue"
                   [ngClass]="{
                      '_active': processForm.controls.color.value === '#1B4279'
                    }"
                   for="dark-blue"></label>
            <p-radioButton [value]="'#1B4279'"
                           class="hidden"
                           inputId="dark-blue"
                           formControlName="color">
            </p-radioButton>
            <label class="color-sample _medium-blue"
                   [ngClass]="{
                      '_active': processForm.controls.color.value === '#62AAD7'
                    }"
                   for="medium-blue"></label>
            <p-radioButton [value]="'#62AAD7'"
                           class="hidden"
                           inputId="medium-blue"
                           formControlName="color">
            </p-radioButton>
            <label class="color-sample _light-blue"
                   [ngClass]="{
                      '_active': processForm.controls.color.value === '#B3D0E9'
                    }"
                   for="light-blue"></label>
            <p-radioButton [value]="'#B3D0E9'"
                           class="hidden"
                           inputId="light-blue"
                           formControlName="color">
            </p-radioButton>
            <label class="color-sample _orange"
                   [ngClass]="{
                      '_active': processForm.controls.color.value === '#F7941D'
                    }"
                   for="orange"></label>
            <p-radioButton [value]="'#F7941D'"
                           class="hidden"
                           inputId="orange"
                           formControlName="color">
            </p-radioButton>
            <label class="color-sample _light-grey"
                   [ngClass]="{
                      '_active': processForm.controls.color.value === '#AFB3B6'
                    }"
                   for="light-grey"></label>
            <p-radioButton [value]="'#AFB3B6'"
                           class="hidden"
                           inputId="light-grey"
                           formControlName="color">
            </p-radioButton>
          </div>
        </ng-template>
      </div>
      <div class="process-details__action ml-2 px-1">
        <p-button [text]="true"
                  *ngIf="!processForm.controls.color.touched"
                  (onClick)="touchField('color')"
                  pTooltip="Edit"
                  icon="pi pi-pencil"></p-button>
        <p-button [text]="true"
                  *ngIf="processForm.controls.color.touched"
                  pTooltip="Reset"
                  (onClick)="resetField('color', process.color)"
                  icon="pi pi-refresh">
        </p-button>
      </div>
    </li>

    <li class="process-details__item flex w-full align-items-center">
      <p class="process-details__name mb-0">{{ processMap.width.title }}&nbsp;<span
              class="font-normal font-italic">(mm)</span>
      </p>
      <div class="process-details__value flex flex-grow-1 align-items-center justify-content-end">
        <p *ngIf="!processForm.controls.width.touched; else editWidthTpl"
           class="mb-0">{{ process.width }}</p>
        <ng-template #editWidthTpl>
          <p-inputNumber id="width"
                         name="width"
                         styleClass="w-full"
                         [min]="0"
                         [max]="10000"
                         formControlName="width"
                         type="number">
          </p-inputNumber>
        </ng-template>
      </div>
      <div class="process-details__action ml-2 px-1">
        <p-button [text]="true"
                  *ngIf="!processForm.controls.width.touched"
                  (onClick)="touchField('width')"
                  pTooltip="Edit"
                  icon="pi pi-pencil"></p-button>
        <p-button [text]="true"
                  *ngIf="processForm.controls.width.touched"
                  pTooltip="Reset"
                  (onClick)="resetField('width', process.width)"
                  icon="pi pi-refresh">
        </p-button>
      </div>
    </li>

    <li class="process-details__item flex w-full align-items-center">
      <p class="process-details__name mb-0">{{ processMap.distanceToNext.title }}&nbsp;<span
              class="font-normal font-italic">(mm)</span>
      </p>
      <div class="process-details__value flex flex-grow-1 align-items-center justify-content-end">
        <p *ngIf="!processForm.controls.distanceToNext.touched; else editDistanceTpl"
           class="mb-0">{{ process.distanceToNext }}</p>

        <ng-template #editDistanceTpl>
          <p-inputNumber id="distanceToNext"
                         name="distanceToNext"
                         styleClass="w-full"
                         [min]="0"
                         [max]="10000"
                         formControlName="distanceToNext"
                         type="number">
          </p-inputNumber>
        </ng-template>
      </div>
      <div class="process-details__action ml-2 px-1">
        <p-button [text]="true"
                  *ngIf="!processForm.controls.distanceToNext.touched"
                  (onClick)="touchField('distanceToNext')"
                  pTooltip="Edit"
                  icon="pi pi-pencil"></p-button>
        <p-button [text]="true"
                  *ngIf="processForm.controls.distanceToNext.touched"
                  pTooltip="Reset"
                  (onClick)="resetField('distanceToNext', process.distanceToNext)"
                  icon="pi pi-refresh">
        </p-button>
      </div>
    </li>

    <li class="process-details__item flex w-full align-items-center">
      <p class="process-details__name mb-0">{{ processMap.taktTime.title }}&nbsp;<span
              class="font-normal font-italic">(ms)</span>
      </p>
      <div class="process-details__value flex flex-grow-1 align-items-center justify-content-end">
        <p *ngIf="!processForm.controls.taktTime.touched; else editTaktTimeTpl"
           class="mb-0">{{ process.taktTime }}</p>
        <ng-template #editTaktTimeTpl>
          <p-inputNumber id="taktTime"
                         name="taktTime"
                         styleClass="w-full"
                         [min]="0"
                         [max]="100000"
                         formControlName="taktTime"
                         type="number">
          </p-inputNumber>
        </ng-template>
      </div>
      <div class="process-details__action ml-2 px-1">
        <p-button [text]="true"
                  *ngIf="!processForm.controls.taktTime.touched"
                  (onClick)="touchField('taktTime')"
                  pTooltip="Edit"
                  icon="pi pi-pencil"></p-button>
        <p-button [text]="true"
                  *ngIf="processForm.controls.taktTime.touched"
                  pTooltip="Reset"
                  (onClick)="resetField('taktTime', process.taktTime)"
                  icon="pi pi-refresh">
        </p-button>
      </div>
    </li>

    <li class="process-details__item flex w-full align-items-center"
        *ngIf="process.placeProcessInsideTheTrack && process.placeProcessInsideTheTrack !== null">
      <p class="process-details__name mb-0">{{ processMap.placeProcessInsideTheTrack.title }}:</p>
      <div class="process-details__value flex flex-grow-1 align-items-center justify-content-end">
        <i class="pi"
           *ngIf="!processForm.controls.placeProcessInsideTheTrack.touched; else editPlaceInsideTpl"
           [ngClass]="{
              'pi-check text-green-400': process.placeProcessInsideTheTrack,
              'pi-times text-red-400': !process.placeProcessInsideTheTrack
            }">
        </i>
        <ng-template #editPlaceInsideTpl>
          <p-checkbox name="placeProcessInsideTheTrack"
                      [binary]="true"
                      formControlName="placeProcessInsideTheTrack">
          </p-checkbox>
        </ng-template>
      </div>
      <div class="process-details__action ml-2 px-1">
        <p-button [text]="true"
                  *ngIf="!processForm.controls.placeProcessInsideTheTrack.touched"
                  (onClick)="touchField('placeProcessInsideTheTrack')"
                  pTooltip="Edit"
                  icon="pi pi-pencil"></p-button>
        <p-button [text]="true"
                  *ngIf="processForm.controls.placeProcessInsideTheTrack.touched"
                  pTooltip="Reset"
                  (onClick)="resetField('placeProcessInsideTheTrack', process.placeProcessInsideTheTrack)"
                  icon="pi pi-refresh">
        </p-button>
      </div>
    </li>

    <li class="process-details__item flex w-full">
      <div class="flex flex-column flex-grow-1">
        <p class="process-details__name mb-0">{{ processMap.notes.title }}</p>
        <div class="process-details__value overflow-hidden flex-grow-1 align-items-center">
          <p *ngIf="!processForm.controls.notes.touched; else editNotesTpl"
             class="process-details__value-content font-normal text-left mb-0">{{ process.notes || '(empty)' }}</p>
          <ng-template #editNotesTpl>
            <textarea rows="5"
                      cols="30"
                      id="notes"
                      pInputTextarea
                      class="w-full"
                      [autoResize]="true"
                      formControlName="notes">
              </textarea>
          </ng-template>
        </div>
      </div>
      <div class="process-details__action ml-2 px-1">
        <p-button [text]="true"
                  *ngIf="!processForm.controls.notes.touched"
                  (onClick)="touchField('notes')"
                  pTooltip="Edit"
                  icon="pi pi-pencil"></p-button>
        <p-button [text]="true"
                  *ngIf="processForm.controls.notes.touched"
                  pTooltip="Reset"
                  (onClick)="resetField('notes', process.notes)"
                  icon="pi pi-refresh">
        </p-button>
      </div>
    </li>
  </ul>
</form>

<div class="grid w-full"
     *ngIf="processForm.dirty">
  <p-button severity="warning"
            class="form-action-btn col-6"
            styleClass="w-full"
            icon="pi pi-times"
            (onClick)="resetForm()"
            label="Undo changes"></p-button>
  <p-button class="form-action-btn col-6"
            styleClass="w-full"
            icon="pi pi-check"
            (onClick)="saveChanges()"
            label="Apply changes"></p-button>
</div>
