import { Component, EventEmitter, Input, Output } from '@angular/core';
import { processFunctionsList } from '../../constants/process-function-list.const';
import { ProcessFunctionName } from '../../enums/process-function.enum';
import { Process } from '../../types/process.interface';

@Component({
  selector: 'app-process-item',
  templateUrl: './process-item.component.html',
  styleUrls: ['./process-item.component.scss'],
})
export class ProcessItemComponent {
  @Input() process: Process;

  @Output() onShowDetails: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() onDeleteProcess: EventEmitter<boolean> =
    new EventEmitter<boolean>();

  actionsVisible = false;

  constructor() {}

  getIconName(processName: ProcessFunctionName): string | undefined {
    return processFunctionsList.find((fn) => fn.label === processName)
      ?.iconName;
  }

  showProcessDetails(): void {
    this.onShowDetails.emit(true);
  }

  deleteProcess(): void {
    this.onDeleteProcess.emit(true);
  }

  hideProcessDetails(): void {
    this.actionsVisible = false;
  }

  showActions(): void {
    this.actionsVisible = true;
  }
}
