<p-table #configTable
         class="flex-grow-1 w-full h-full overflow-auto mt-3"
         styleClass="configurations-table flex flex-column h-full overflow-auto"
         [paginator]="true"
         [rows]="10"
         [lazy]="true"
         [value]="configurations"
         [loading]="isLoading"
         [rowHover]="true"
         [rowsPerPageOptions]="[5, 10, 25, 50]"
         [totalRecords]="listParams.totalCount"
         [scrollable]="true"
         scrollHeight="flex"
         scrollDirection="both"
         (onLazyLoad)="updateConfigurations($event)"
         dataKey="id">
  <ng-template pTemplate="header">
    <tr class="flex flex-row">
      <th class="configurations-table__th-fixed"></th>
      <th class="configurations-table__th text-center font-semibold text-lg">Configuration Id</th>
      <th class="configurations-table__th text-center font-semibold text-lg"
          pSortableColumn="name">Configuration Name <p-sortIcon field="name"></p-sortIcon>
      </th>
      <th class="configurations-table__th text-center font-semibold text-lg"
          pSortableColumn="projectName">Project Name <p-sortIcon field="projectName"></p-sortIcon></th>
      <th class="configurations-table__th text-center font-semibold text-lg"
          pSortableColumn="userEmail">User Email <p-sortIcon field="userEmail"></p-sortIcon></th>
      <th class="configurations-table__th text-center font-semibold text-lg"
          pSortableColumn="fullName">User Name <p-sortIcon field="fullName"></p-sortIcon></th>
      <th class="configurations-table__th text-center font-semibold text-lg"
          pSortableColumn="companyName">Company Name <p-sortIcon field="companyName"></p-sortIcon></th>
      <th class="configurations-table__th text-center font-semibold text-lg"
          pSortableColumn="createdTime">Date Created <p-sortIcon field="createdTime"></p-sortIcon></th>
      <th class="configurations-table__th text-center font-semibold text-lg"
          pSortableColumn="updatedTime">Date Updated <p-sortIcon field="updatedTime"></p-sortIcon></th>
    </tr>
    <tr class="flex flex-row">
      <th class="configurations-table__th-fixed"></th>
      <th class="configurations-table__th">
        <p-columnFilter field="configurationId"
                        class="w-full"
                        [showMenu]="false"
                        [showClearButton]="false">
          <ng-template pTemplate="filter"
                       let-value
                       let-filter="filterCallback">
            <input type="text"
                   pInputText
                   [ngClass]="{
                      'ng-invalid': !isConfigIdValid,
                      'ng-dirty': !isConfigIdValid
                    }"
                   (keydown.enter)="filterByConfigId($event)"
                   (blur)="clearField($event)"
                   placeholder="Configuration id" />
            <small *ngIf="!isConfigIdValid"
                   class="p-error block">Incorrect id format</small>
          </ng-template>
        </p-columnFilter>
      </th>
      <th class="configurations-table__th">
        <p-columnFilter field="configurationName"
                        class="w-full"
                        placeholder="Configuration Name"
                        [showMenu]="false"
                        [showClearButton]="false">
        </p-columnFilter>
      </th>
      <th class="configurations-table__th">
        <p-columnFilter field="projectName"
                        class="w-full"
                        placeholder="Project Name"
                        [showMenu]="false"
                        [showClearButton]="false">
        </p-columnFilter>
      </th>
      <th class="configurations-table__th">
        <p-columnFilter field="userEmail"
                        class="w-full"
                        placeholder="User Email"
                        [showMenu]="false"
                        [showClearButton]="false">
        </p-columnFilter>
      </th>
      <th class="configurations-table__th">
        <p-columnFilter field="fullName"
                        class="w-full"
                        placeholder="User Name"
                        [showMenu]="false"
                        [showClearButton]="false">
        </p-columnFilter>
      </th>
      <th class="configurations-table__th">
        <p-columnFilter field="companyName"
                        class="w-full"
                        placeholder="Company Name"
                        [showMenu]="false"
                        [showClearButton]="false">
        </p-columnFilter>
      </th>
      <th class="configurations-table__th">
      </th>
      <th class="configurations-table__th">
      </th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body"
               let-expanded="expanded"
               let-configuration>
    <tr class="flex flex-row">
      <td class="configurations-table__td-fixed">
        <button type="button"
                pButton
                pRipple
                [pRowToggler]="configuration"
                class="p-button-text p-button-rounded p-button-plain"
                [icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"></button>
      </td>
      <td class="configurations-table__td text-center">
        {{ configuration.id }}
      </td>
      <td class="configurations-table__td text-center">
        {{ configuration.name }}
      </td>
      <td class="configurations-table__td text-center">
        {{ configuration.projectName }}
      </td>
      <td class="configurations-table__td text-center">
        {{ configuration.owner.email }}
      </td>
      <td class="configurations-table__td text-center">
        {{ configuration.owner.fullName }}
      </td>
      <td class="configurations-table__td text-center justify-content-center flex align-items-center">
        {{ configuration.owner.companyName }}
        <img *ngIf="configuration.owner.isAts"
             class="w-4rem ml-2"
             src="/assets/images/ATS-Corp-320x140-rgb.jpg"
             alt="ATS Logo">
      </td>
      <td class="configurations-table__td text-center">
        {{ configuration.createdTime | date : 'MMMM dd, yyyy' }}
      </td>
      <td class="configurations-table__td text-center">
        {{ configuration.updatedTime | date : 'MMMM dd, yyyy' }}
      </td>
    </tr>

  </ng-template>
  <ng-template pTemplate="rowexpansion"
               datakey="id"
               let-configuration>
    <tr class="expansion-row">
      <td class="flex flex-column w-full">
        <p-table class="w-full"
                 styleClass="versions-table"
                 [value]="configuration.configurationVersions">
          <ng-template pTemplate="header">
    <tr class="flex flex-row">
      <th class="text-center font-semibold text-lg">Version Number</th>
      <th class="text-center font-semibold text-lg">Version Name</th>
      <th class="text-center font-semibold text-lg">Date Created</th>
      <th class="text-center font-semibold text-lg">Created By</th>
      <th class="text-center font-semibold text-lg">Track Type</th>
      <th class="text-center font-semibold text-lg">Status</th>
      <th class="text-center font-semibold text-lg">View</th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body"
               let-version>
    <tr class="flex flex-row">
      <td class="text-center"><p-tag [value]="'Version ' + version.version"
               severity="warning"></p-tag></td>
      <td class="text-center">{{ version.name || '(none)' }}</td>
      <td class="text-center">{{ version.createdTime | date : 'MMMM dd, yyyy' }}</td>
      <td class="text-center">{{ defineVersionCreator(version.createdById, configuration.ownerId) }}</td>
      <td class="text-center">{{ version.productType | productType }}</td>
      <td class="text-center">
        <p-tag [styleClass]="getStatusColor(version.rfqStatus).class"
               [value]="getStatusLabel(version.rfqStatus)"></p-tag>
      </td>
      <td class="text-center">
        <button pButton
                type="button"
                icon="pi pi-eye"
                class="p-button-outlined p-ripple"
                pTooltip="View version"
                tooltipPosition="bottom"
                (click)="onViewTracks(version, configuration)">
        </button>
      </td>
    </tr>
  </ng-template>
</p-table>
</td>
</tr>
</ng-template>

</p-table>

<p-sidebar [(visible)]="showTracks"
           styleClass="surface-ground"
           position="right">
  <app-configuration-version-info *ngIf="selectedVersion"
                                  [configurationStatusSeverity]="getStatusColor(selectedVersion.rfqStatus).color"
                                  [configurationVersion]="selectedVersion"
                                  [exportingParameters]="exportingParameters"
                                  [tracksList]="tracksList"
                                  [configuration]="selectedConfiguration"
                                  [isUserAdmin]="isAdmin"
                                  (onViewConfiguration)="viewConfiguration()"
                                  (onExportParameters)="exportVersionParameters($event)">
  </app-configuration-version-info>
  <app-tracks-list *ngIf="selectedVersion"
                   [loadingTracks]="loadingTracks"
                   [tracksList]="tracksList"
                   [isOwner]="isVersionOwner(selectedVersion)"
                   (onViewTrack)="viewTrack($event)"></app-tracks-list>
</p-sidebar>
