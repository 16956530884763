/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { Component, Input } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';

interface IProjectForm {
  name: FormControl<string>;
  description: FormControl<string>;
  referenceNumber: FormControl<string | null>;
}

@Component({
  selector: 'app-create-project',
  templateUrl: './create-project.component.html',
  styleUrls: ['./create-project.component.scss'],
})
export class CreateProjectComponent {
  @Input() isCreating: boolean;

  createProjectForm: FormGroup<IProjectForm>;

  constructor(private fb: FormBuilder) {
    this.createProjectForm = this.fb.group({
      name: new FormControl<string>('', {
        nonNullable: true,
        validators: [Validators.required, Validators.max(200)],
      }),
      referenceNumber: new FormControl<string | null>(null, {
        validators: [Validators.minLength(1), Validators.maxLength(64)],
      }),
      description: new FormControl<string>('', {
        nonNullable: true,
        validators: [Validators.required, Validators.max(400)],
      }),
    });
  }

  get formInvalidAndDirty(): boolean {
    if (this.createProjectForm.invalid && this.createProjectForm.dirty)
      return true;
    return false;
  }

  get nameTooLong(): boolean {
    const formName = this.createProjectForm.getRawValue().name;
    if (formName != null && formName.length > 200) return true;
    return false;
  }

  get descriptionTooLong(): boolean {
    const formDescription = this.createProjectForm.getRawValue().description;
    if (formDescription != null && formDescription.length > 400) return true;
    return false;
  }
}
