import { AccessScope } from 'src/app/configuration-management/enums/access-scope.enum';

export class User {
  public roles: Role[] = [];
  id: string;
  externalId: string;
  firstName: string;
  lastName: string;
  email: string;
  isActive: boolean;
  isAts: boolean;
  fullName?: string;

  constructor(
    id: string,
    externalId: string,
    firstName: string,
    lastName: string,
    email: string,
    isActive: boolean,
    isAts: boolean,
    fullName?: string
  ) {
    this.id = id;
    this.externalId = externalId;
    this.firstName = firstName;
    this.lastName = lastName;
    this.email = email;
    this.isActive = isActive;
    this.isAts = isAts;

    if (fullName) {
      this.fullName = fullName;
    }
  }

  addRole(role: Role): void {
    this.roles.push(role);
  }

  hasRole(roleName: string | RoleEnum): boolean {
    const role = this.roles.find((role) => role.name === roleName);
    return role !== undefined;
  }

  hasAnyRole(roleNames: string[]): boolean {
    return this.roles.some((role) => roleNames.includes(role.name));
  }

  hasAccessScope(scope: AccessScope): boolean {
    switch (scope) {
      case AccessScope.Sales:
        return this.hasAnyRole([
          RoleEnum.Admin,
          RoleEnum.SuperAdmin,
          RoleEnum.SuperTrakSales,
        ]);
      case AccessScope.User:
        return true;
      default:
        return false;
    }
  }
}

export class Role {
  constructor(
    public id: string,
    public name: string,
    public permissions: string[]
  ) {}
}

export enum RoleEnum {
  SuperAdmin = 'SuperAdmin',
  Admin = 'Admin',
  BasicUser = 'BasicUser',
  SuperTrakSales = 'SuperTrak-Sales',
}
