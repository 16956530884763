<div class="layout-menu-wrapper"
     [ngClass]="{
      '_expanded': isExpanded,
      '_collapsed': isCollapsed
    }">
  <ul class="layout-menu">
    <li app-menuitem
        *ngFor="let item of menuItems; let i = index;"
        [item]="item"
        [sidebarState]="sideBarState"
        [index]="i"
        [root]="true"></li>
  </ul>
  <div class="flex justify-content-center">
    <p-button class="p-button-info"
              icon="pi pi-question"
              (onClick)="showHelp()"
              [raised]="true"
              [outlined]="false"
              [severity]="'secondary'"
              [text]="true"
              ariaLabel="Support"
              [pTooltip]="'Support'">
    </p-button>
  </div>
</div>
