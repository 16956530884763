import { Pipe, PipeTransform } from '@angular/core';
import { Role } from 'src/app/common/models/user/user';

@Pipe({
  name: 'orderBy',
})
export class OrderByPipe implements PipeTransform {
  transform(value: Role[]): Role[] {
    return value.sort(function (a, b) {
      const x = a.name;
      const y = b.name;
      return x < y ? -1 : x > y ? 1 : 0;
    });
  }
}
