<div class="flex justify-content-end gap-2 pt-3">
  <p-button label="Cancel"
            [styleClass]="this.dialogConfig.data.rejectButtonClass"
            (click)="onCancel()"></p-button>
  <p-button icon="pi pi-trash"
            [loading]="this.isLoading"
            label="Delete"
            [styleClass]="this.dialogConfig.data.confirmButtonClass"
            (click)="onConfirm()"></p-button>
</div>
