import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { environment } from 'src/environments/environment';
import { EditUserComponent } from './admin/user-management/edit-user/edit-user.component';
import { EditUserResolver } from './admin/user-management/edit-user/edit-user.resolver';
import { UserManagementComponent } from './admin/user-management/user-management.component';
import { AppRoutes } from './app.routes.const';
import configurationManagementRoutes from './configuration-management/configuration-management.routing';
import { ConfigurationsComponent } from './configuration/configurations/configurations.component';
import { ConfigurationsResolver } from './configuration/configurations/configurations.resolver';
import { DashboardComponent } from './configuration/dashboard/dashboard.component';
import { ProjectsComponent } from './configuration/projects/projects.component';
import { ProjectsResolver } from './configuration/projects/projects.resolver';
import { TrackComponent } from './configuration/track/track.component';
import { TrackResolver } from './configuration/track/track.resolver';
import { ErrorPageComponent } from './core/error-page/error-page.component';
import { MaintenancePageComponent } from './core/maintenance-page/maintenance-page.component';
import { AuthGuard } from './guards/authGuard.guard';
import { devEnvGuard } from './guards/dev-env/dev-env.guard';
import { AppLayoutComponent } from './layout/app.layout/app.layout.component';
import trackDesignerRoutes from './track-designer/track-designer.routing';

const routes: Routes = [
  {
    path: '',
    title: environment.appTitle,
    component: AppLayoutComponent,
    children: [
      { path: '', redirectTo: AppRoutes.Dashboard.path, pathMatch: 'full' },
      { path: AppRoutes.Dashboard.path, component: DashboardComponent },
      {
        path: AppRoutes.UserManagement.path,
        canActivate: [AuthGuard],
        children: [
          {
            path: '',
            pathMatch: 'full',
            component: UserManagementComponent,
          },
          {
            path: AppRoutes.EditUser.path,
            component: EditUserComponent,
            resolve: [EditUserResolver],
          },
        ],
      },
      {
        path: AppRoutes.Projects.path,
        canActivate: [AuthGuard],
        children: [
          {
            path: '',
            pathMatch: 'full',
            component: ProjectsComponent,
            resolve: [ProjectsResolver],
          },
          {
            path: AppRoutes.ProjectDetails.path,
            component: ConfigurationsComponent,
            resolve: [ConfigurationsResolver],
          },
        ],
      },
      {
        path: AppRoutes.TrackDrilldown.path,
        component: TrackComponent,
        resolve: [TrackResolver],
        canActivate: [AuthGuard],
      },
      ...trackDesignerRoutes,
      ...configurationManagementRoutes,
    ],
  },
  {
    path: AppRoutes.TrackCreator.path,
    canActivate: [devEnvGuard],
    loadChildren: () =>
      import('./modules/track-creator/track-creator.module').then(
        (m) => m.TrackCreatorModule
      ),
  },
  {
    path: AppRoutes.Maintenance.path,
    component: MaintenancePageComponent,
  },
  {
    path: '**',
    component: ErrorPageComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
