<p-button icon="pi pi-angle-left"
          (onClick)="close()"
          [outlined]="true">
</p-button>

<h3>Add a new process</h3>

<form [formGroup]="processForm"
      class="flex flex-column flex-grow-1 gap-4">
  <div class="flex flex-column gap-2">
    <label class="font-semibold"
           for="name">{{ processMap.name.title }}</label>
    <input pInputText
           id="name"
           name="name"
           formControlName="name"
           type="text"
           autoComplete="off">
  </div>

  <div class="flex flex-column gap-2">
    <div class="flex align-items-center">
      <p class="font-semibold mb-0"
         htmlFor="function">{{ processMap.function.title }}
      </p>
      <p-button icon="pi pi-info-circle"
                class="ml-2"
                [text]="true"
                (onClick)="showPropertyDescription($event, 'function')">
      </p-button>
    </div>
    <p-dropdown formControlName="function"
                #functionProp
                ariaLabel="Process function"
                styleClass="w-full"
                [options]="processFunctionList"
                optionLabel="label"
                optionValue="value"
                (onChange)="updateSelectedFunction($event)"
                placeholder="Select a Function">
      <ng-template pTemplate="selectedItem">
        <div class="flex align-items-center gap-2"
             *ngIf="selectedProcessFunction">
          <img [src]="'/assets/icons/' + selectedProcessFunction.iconName + '.svg'"
               class="w-1rem"
               [alt]="selectedProcessFunction.label" />
          <div>{{ selectedProcessFunction.label }}</div>
        </div>
      </ng-template>
      <ng-template let-option
                   pTemplate="item">
        <div class="flex align-items-center gap-2">
          <img class="w-1rem"
               [alt]="option.label"
               [src]="'/assets/icons/' + option.iconName + '.svg'" />
          <div>{{ option.label }}</div>
        </div>
      </ng-template>
    </p-dropdown>
  </div>

  <div class="flex flex-column gap-2">
    <p class="font-semibold mb-0">{{ processMap.color.title }}</p>
    <div class="flex gap-2">
      <label class="color-sample _dark-blue"
             [ngClass]="{
                '_active': processForm.controls.color.value === '#1B4279'
              }"
             for="dark-blue"></label>
      <p-radioButton [value]="'#1B4279'"
                     class="hidden"
                     inputId="dark-blue"
                     formControlName="color">
      </p-radioButton>
      <label class="color-sample _medium-blue"
             [ngClass]="{
                '_active': processForm.controls.color.value === '#62AAD7'
              }"
             for="medium-blue"></label>
      <p-radioButton [value]="'#62AAD7'"
                     class="hidden"
                     inputId="medium-blue"
                     formControlName="color">
      </p-radioButton>
      <label class="color-sample _light-blue"
             [ngClass]="{
                '_active': processForm.controls.color.value === '#B3D0E9'
              }"
             for="light-blue"></label>
      <p-radioButton [value]="'#B3D0E9'"
                     class="hidden"
                     inputId="light-blue"
                     formControlName="color">
      </p-radioButton>
      <label class="color-sample _orange"
             [ngClass]="{
                '_active': processForm.controls.color.value === '#F7941D'
              }"
             for="orange"></label>
      <p-radioButton [value]="'#F7941D'"
                     class="hidden"
                     inputId="orange"
                     formControlName="color">
      </p-radioButton>
      <label class="color-sample _light-grey"
             [ngClass]="{
                '_active': processForm.controls.color.value === '#AFB3B6'
              }"
             for="light-grey"></label>
      <p-radioButton [value]="'#AFB3B6'"
                     class="hidden"
                     inputId="light-grey"
                     formControlName="color">
      </p-radioButton>
    </div>
  </div>

  <div class="flex flex-column gap-2">
    <div class="flex align-items-center">
      <label class="font-semibold"
             for="width">{{ processMap.width.title }} <span class="font-normal font-italic">(mm)</span>
      </label>
      <p-button icon="pi pi-info-circle"
                class="ml-2"
                [text]="true"
                (onClick)="showPropertyDescription($event, 'width')">
      </p-button>
    </div>
    <p-inputNumber inputId="width"
                   #widthProp
                   name="width"
                   styleClass="w-full"
                   formControlName="width"
                   [min]="0"
                   [max]="10000"
                   type="number">
    </p-inputNumber>
    <div *ngIf="processForm.controls.width.invalid && processForm.touched"
         class="text-red-500">
      <p *ngIf="processForm.controls.width.errors?.['max']">* Maximum allowed: 10,000</p>
    </div>
  </div>

  <div class="flex flex-column gap-2">
    <div class="flex align-items-center">
      <label class="font-semibold"
             for="distanceToNext">{{ processMap.distanceToNext.title }} <span
              class="font-normal font-italic">(mm)</span></label>
      <p-button icon="pi pi-info-circle"
                class="ml-2"
                [text]="true"
                (onClick)="showPropertyDescription($event, 'distanceToNext')"></p-button>
    </div>
    <p-inputNumber inputId="distanceToNext"
                   name="distanceToNext"
                   #distanceToNextProp
                   styleClass="w-full"
                   [min]="0"
                   [max]="10000"
                   formControlName="distanceToNext"
                   type="number">
    </p-inputNumber>
    <div *ngIf="processForm.controls.distanceToNext.invalid && processForm.touched"
         class="text-red-500">
      <p *ngIf="processForm.controls.distanceToNext.errors?.['max']">* Maximum allowed: 10,000</p>
    </div>
  </div>

  <div class="flex flex-column gap-2">

    <div class="flex align-items-center">
      <label class="font-semibold"
             for="taktTime">{{ processMap.taktTime.title }} <span class="font-normal font-italic">(ms)</span></label>
      <p-button icon="pi pi-info-circle"
                class="ml-2"
                [text]="true"
                (onClick)="showPropertyDescription($event, 'taktTime')"></p-button>
    </div>
    <p-inputNumber inputId="taktTime"
                   name="taktTime"
                   styleClass="w-full"
                   #taktTimeProp
                   formControlName="taktTime"
                   [min]="0"
                   [max]="100000"
                   type="number">
    </p-inputNumber>
    <div *ngIf="processForm.controls.taktTime.invalid && processForm.touched"
         class="text-red-500">
      <p *ngIf="processForm.controls.taktTime.errors?.['max']">* Maximum allowed: 100,000</p>
    </div>
  </div>

  <div class="flex flex-column gap-2">
    <label class="font-semibold"
           for="placeProcessInsideTheTrack">{{ processMap.placeProcessInsideTheTrack.title }}</label>
    <p-checkbox name="placeProcessInsideTheTrack"
                [binary]="true"
                inputId="placeProcessInsideTheTrack"
                formControlName="placeProcessInsideTheTrack">
    </p-checkbox>
  </div>

  <div class="flex flex-column gap-2">
    <label class="font-semibold"
           for="notes">{{ processMap.notes.title }}</label>
    <textarea rows="5"
              cols="30"
              pInputTextarea
              class="w-full"
              [autoResize]="true"
              name="notes"
              id="notes"
              formControlName="notes">
            </textarea>
  </div>
</form>

<p-button styleClass="w-full"
          [disabled]="processForm.invalid"
          (onClick)="createProcess()">
  <span class="flex w-full justify-content-center">
    <i class="pi pi-plus mr-2"></i>
    <span><b>Add process</b></span>
  </span>
</p-button>

<p-overlayPanel #propertyDescriptionOverlay>
  <ng-template pTemplate="content">
    <div class="flex flex-column justify-content-center"
         [style]="{
      maxWidth: '350px'
    }">
      <ng-container *ngIf="processMap[propertyDescriptionToShow].imgSrc">
        <img class="mb-3 mx-auto"
             [class]="processMap[propertyDescriptionToShow].imgFormat === 'tall' ? 'max-h-14rem' : 'w-full'"
             [src]="processMap[propertyDescriptionToShow].imgSrc"
             alt="Origin oint example">
      </ng-container>
      <p [innerHTML]="processMap[propertyDescriptionToShow].description"></p>
    </div>
  </ng-template>
</p-overlayPanel>
