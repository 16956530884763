import { Component, OnDestroy, OnInit } from '@angular/core';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { Subject, takeUntil } from 'rxjs';
import { getTrackTypesList } from 'src/app/common/constants/track-types.const';
import { ETurn } from 'src/app/common/enums/eTurn.enum';
import { environment } from 'src/environments/environment';
import { TrackCreatorService } from '../../services/track-creator/track-creator.service';

@Component({
  selector: 'app-track-type-modal',
  templateUrl: './track-type-modal.component.html',
  styleUrls: ['./track-type-modal.component.scss'],
})
export class TrackTypeModalComponent implements OnInit, OnDestroy {
  trackTypeList: {
    fullLabel: string;
    value: ETurn;
  }[];

  selectedTrack: ETurn;

  private readonly _destroying$ = new Subject<void>();

  constructor(
    private trackCreatorService: TrackCreatorService,
    private dialogRef: DynamicDialogRef
  ) {
    this.trackCreatorService.trackSpecs$
      .pipe(takeUntil(this._destroying$))
      .subscribe((trackSpecs) => {
        if (!trackSpecs) return;

        this.selectedTrack = trackSpecs?.eTurn;
      });
  }

  ngOnInit(): void {
    this.trackTypeList = getTrackTypesList();
  }

  ngOnDestroy(): void {
    this._destroying$.unsubscribe();
  }

  selectType(trackType: ETurn): void {
    this.selectedTrack = trackType;
  }

  changeTrackType(): void {
    this.trackCreatorService.updateTrack({
      eTurn: this.selectedTrack,
    });
    this.closeDialog();
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  getTrackImage(): string {
    return `/assets/images/track-designer/${this.selectedTrack}-stands-pallets.png`;
  }

  isOptionAvailable(trackType: ETurn): boolean {
    if (trackType === ETurn.None) return false;

    return environment.production ? trackType !== ETurn.Curve500Low : true;
  }
}
