import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { MessageService } from 'primeng/api';
import { catchError, forkJoin, map, Observable, throwError } from 'rxjs';
import { AppRoutes } from 'src/app/app.routes.const';
import { TrackService } from 'src/app/common/services/track-services/track.service';
import { AccessScope } from 'src/app/configuration-management/enums/access-scope.enum';
import { TrackDetails } from 'src/app/configuration/models/track-details.interface';
import { IdentityService } from 'src/app/core/services/identity.service';
import { EditorModeService } from '../services/editor-mode/editor-mode.service';
import { ProjectConfigurationService } from '../services/project-configuration/project-configuration.service';

@Injectable({
  providedIn: 'root',
})
export class TrackResolver {
  constructor(
    private trackService: TrackService,
    private projectConfigService: ProjectConfigurationService,
    private identityService: IdentityService,
    private router: Router,
    private messageService: MessageService,
    private editorModeService: EditorModeService
  ) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<TrackDetails> {
    const trackId = route.paramMap.get('trackId');
    const configId = route.paramMap.get('configId');
    const versionId = route.queryParamMap.get('versionId');

    if (!trackId) {
      return throwError(() => 'trackId is null');
    }

    if (!configId) {
      return throwError(() => 'configId is null');
    }

    if (!versionId) {
      return throwError(() => 'versionId is null');
    }

    const accessScope = this.identityService.currentUser?.hasAccessScope(
      AccessScope.Sales
    )
      ? AccessScope.Sales
      : AccessScope.User;

    return forkJoin([
      this.trackService.getTrackDetails(trackId, accessScope),
      this.projectConfigService.requestConfigurationById(configId, accessScope),
    ]).pipe(
      map(([trackDetails, configuration]) => {
        this.editorModeService.initEditorModeByConfig(configuration);
        if (!trackDetails.components.length) {
          this.router.navigate(
            [AppRoutes.TrackDesigner.fullPath(configId, trackDetails.id)],
            {
              queryParams: {
                versionId,
              },
            }
          );
        }
        return trackDetails;
      }),
      catchError((error) => {
        this.handleError();
        return throwError(() => error);
      })
    );
  }

  handleError() {
    this.messageService.add({
      severity: 'error',
      summary: 'Error',
      detail: 'Failed to resolve track',
    });
    this.router.navigate([AppRoutes.Dashboard.fullPath()]);
  }
}
