import { Routes } from '@angular/router';
import { TrackCreatorComponent } from './components/track-creator/track-creator.component';

const trackCreatorRoutes: Routes = [
  {
    path: '',
    component: TrackCreatorComponent,
  },
];

export default trackCreatorRoutes;
