import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Subject, takeUntil } from 'rxjs';
import { ETurn } from '../../../common/enums/eTurn.enum';
import { ControlPanelInterconnectLength } from '../../enums/control-panel-interconnect-length.enum';
import { InterconnectLength } from '../../enums/interconnect-length.enum';
import { Question } from '../../track-designer-questions';

@Component({
  selector: 'app-track-interconnect',
  templateUrl: './track-interconnect.component.html',
  styleUrls: ['./track-interconnect.component.scss'],
})
export class TrackInterconnectComponent implements OnInit, OnDestroy {
  @Input() question: Question | undefined;
  @Input() form: FormGroup;

  interconnectImg: string;

  descriptionVisible = false;

  private readonly _destroying$ = new Subject<void>();

  get getETurnType(): ETurn {
    return this.form.get('eTurn')?.value || ETurn.None;
  }

  get isSymphoniProject(): boolean {
    return this.form.get('isSymphoni')?.value;
  }

  get isMotorPowerSupplyInputPowerCablingAvailable(): boolean {
    return !this.form.get('includeStandardBaseFrames')?.value;
  }

  constructor() {}

  ngOnInit(): void {
    this.form
      .get('controlPanelInterconnectLength')
      ?.valueChanges.pipe(takeUntil(this._destroying$))
      .subscribe((res) => {
        this.setInterconnectImg(res);
      });

    const interconnectField = this.form.get('controlPanelInterconnectLength');

    if (interconnectField?.value) {
      this.setInterconnectImg(interconnectField?.value);
    }

    if (this.getETurnType === ETurn.None) {
      interconnectField?.setValue(
        ControlPanelInterconnectLength.Interconnect1200
      );
    }

    if (!this.isMotorPowerSupplyInputPowerCablingAvailable) {
      this.form.get('includePowerSupplyWiring')?.setValue(null);
    } else {
      if (this.form.get('includePowerSupplyWiring')?.value === null) {
        this.form.get('includePowerSupplyWiring')?.setValue(true);
      }
    }

    if (
      this.getETurnType !== ETurn.None &&
      (interconnectField?.value === null ||
        interconnectField?.value ===
          ControlPanelInterconnectLength.Interconnect1200)
    ) {
      interconnectField?.setValue(
        ControlPanelInterconnectLength.Interconnect2000
      );
    }

    if (this.isSymphoniProject) {
      interconnectField?.setValue(
        ControlPanelInterconnectLength.Interconnect6500
      );
    }
  }

  ngOnDestroy(): void {
    this._destroying$.unsubscribe();
  }

  setInterconnect(fieldName: string, value: InterconnectLength): void {
    this.form.get(fieldName)?.setValue(value);
  }

  setInterconnectImg(imgName: string): void {
    this.interconnectImg = `/assets/images/track-designer/interconnect/${imgName}.png`;
  }

  isInterconnect6500(interconnect: ControlPanelInterconnectLength): boolean {
    return interconnect === ControlPanelInterconnectLength.Interconnect6500;
  }

  disableCheckboxFormValueChange(event: Event): void {
    event.preventDefault();
  }

  showDescription(): void {
    this.descriptionVisible = true;
  }
}
