import { Component, OnDestroy } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { Subject, takeUntil } from 'rxjs';
import { ApiUrlParams } from 'src/app/core/api-url-params';
import { AppLoadingService } from 'src/app/core/services/app-loading.service';
import { ProjectConfigurationService } from '../services/project-configuration/project-configuration.service';

interface QuoteForm {
  requestedByDate: FormControl<Date>;
  rfqComment: FormControl<string | null>;
}

interface QuoteRequest {
  requestedByDate: string;
  rfqComment: null | string;
  configUrl: string;
}

@Component({
  selector: 'app-quote-request',
  templateUrl: './quote-request.component.html',
  styleUrls: ['./quote-request.component.scss'],
})
export class QuoteRequestComponent implements OnDestroy {
  quoteForm: FormGroup;
  minDate = this.getStartDate();
  maxDate = this.getMaxDate();

  private readonly _destroying$ = new Subject<void>();

  constructor(
    private fb: FormBuilder,
    private projectConfigService: ProjectConfigurationService,
    private router: Router,
    private route: ActivatedRoute,
    private messageService: MessageService,
    private appLoadingService: AppLoadingService,
    private dialogRef: DynamicDialogRef
  ) {
    this.quoteForm = this.fb.group<QuoteForm>({
      requestedByDate: new FormControl(this.minDate, {
        nonNullable: true,
        validators: [Validators.required],
      }),
      rfqComment: new FormControl(null),
    });
  }

  ngOnDestroy(): void {
    this._destroying$.unsubscribe();
  }

  getStartDate(): Date {
    const currentDate = new Date();
    currentDate.setDate(currentDate.getDate() + 1);

    return currentDate;
  }

  getMaxDate(): Date {
    const date = this.getStartDate();
    date.setFullYear(this.getStartDate().getFullYear() + 1);
    return date;
  }

  requestQuote(): void {
    const configUrl = this.router.url;
    const versionId =
      this.route.snapshot.queryParamMap.get(ApiUrlParams.VersionId) ?? '';

    const quoteReq: QuoteRequest = {
      requestedByDate: this.convertDateToUTC(
        this.quoteForm.value.requestedByDate
      ),
      rfqComment: this.quoteForm.value.rfqComment,
      configUrl,
    };

    this.appLoadingService.startLoadingBar();
    this.projectConfigService
      .requestConfigurationQuote(versionId, quoteReq)
      .pipe(takeUntil(this._destroying$))
      .subscribe({
        next: () => {
          this.messageService.add({
            severity: 'success',
            summary: 'Quote was succesfully requested.',
          });
        },
        error: (error) => {
          this.appLoadingService.stopLoadingBar();
          this.messageService.add({
            severity: 'error',
            summary: 'Error requesting a quote.',
            detail: error.status + ': ' + error.title,
          });
        },
        complete: () => {
          this.dialogRef.close();
          this.appLoadingService.stopLoadingBar();
        },
      });
  }

  private convertDateToUTC(date: Date): string {
    const dateToTransform = this.isMinDate(date) ? this.getStartDate() : date;

    dateToTransform.toUTCString();

    return dateToTransform.toISOString();
  }

  private isMinDate(date: Date): boolean {
    const currentDate = new Date();

    return (
      date.getDate() === currentDate.getDate() + 1 &&
      date.getMonth() === currentDate.getMonth() &&
      date.getFullYear() === currentDate.getFullYear()
    );
  }
}
