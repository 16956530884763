<div class="flex flex-column gap-3 align-items-center">
  <div class="card-form-container w-full project-body d-grid align-items-center">
    <form [formGroup]="createProjectForm"
          class="d-grid gap-4 justify-content-center">
      <div class="flex flex-column gap-2">
        <label htmlFor="name">Name</label>
        <input pInputText
               id="name"
               name="name"
               formControlName="name"
               type="text"
               autoComplete="off"
               [class.ng-invalid]="createProjectForm.controls.name.invalid"
               [class.ng-dirty]="createProjectForm.controls.name.dirty">
        <div *ngIf="createProjectForm.controls.name.invalid && createProjectForm.touched"
             class="h-1rem text-red-500">
          <p *ngIf="createProjectForm.controls.name.errors?.['required']">* A name is required.</p>
          <p *ngIf="createProjectForm.controls.name.errors?.['maxlength']">* The name is too long. Max 200.
            characters.
          </p>
        </div>
      </div>

      <div class="flex flex-column gap-2">
        <label htmlFor="referenceNumber">Project Reference Number or Opportunity Number<small><i>(optional)</i>
          </small></label>
        <input pInputText
               [maxlength]="64"
               formControlName="referenceNumber"
               name="referenceNumber"
               id="referenceNumber"
               type="text">
      </div>

      <div class="flex flex-column gap-2">
        <label htmlFor="description">Description</label>

        <textarea pInputTextarea
                  style="min-height: 126px;"
                  id="description"
                  formControlName="description"
                  type="text"
                  class="border-round-xl"
                  [rows]="6"
                  [cols]="60"
                  [autoResize]="true"
                  [class.ng-invalid]="createProjectForm.controls.description.invalid"
                  [class.ng-dirty]="createProjectForm.controls.description.dirty"
                  pInputText>
              </textarea>
        <div *ngIf="createProjectForm.controls.description.invalid && createProjectForm.touched"
             class="h-1rem text-red-500">
          <p *ngIf="createProjectForm.controls.description.errors?.['required']">* A description is required.</p>
          <p *ngIf="createProjectForm.controls.description.errors?.['maxlength']">* The name is too long. Max 400.
            characters.
          </p>
        </div>
      </div>
    </form>
  </div>

</div>
