import {
  AfterViewInit,
  Component,
  ElementRef,
  OnDestroy,
  ViewChild,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { Subject, takeUntil } from 'rxjs';
import { ETurn } from 'src/app/common/enums/eTurn.enum';
import {
  AppLayoutService,
  SideBarState,
} from 'src/app/layout/service/app.layout.service';
import { ProcessesStorageService } from '../../services/processes-storage/processes-storage.service';
import {
  TrackConceptDefinition,
  TrackCreatorService,
} from '../../services/track-creator/track-creator.service';
import { Track } from '../../track-viewer/track';
import { TrackViewer } from '../../track-viewer/track-viewer';

interface TrackConceptForm {
  eTurn: FormControl<ETurn>;
  length: FormControl<number>;
  width: FormControl<number | null>;
}

@Component({
  selector: 'app-track-creator',
  templateUrl: './track-creator.component.html',
  styleUrls: ['./track-creator.component.scss'],
  host: {
    class: 'layout-light flex flex-column w-screen h-screen',
  },
})
export class TrackCreatorComponent implements AfterViewInit, OnDestroy {
  @ViewChild('trackContainer') trackContainer: ElementRef;

  processMapOriginPoint = 0;

  controlsVisible = false;
  settingsVisible = false;
  widthAvailable = false;

  trackInfoVisible = false;
  processMapExpanded = true;

  trackConceptForm: FormGroup<TrackConceptForm>;

  // TODO: Should be moved into separate file
  trackTypes: {
    label: string;
    value: ETurn;
  }[] = [
    {
      label: '1200mm+ wide loop with 90deg',
      value: ETurn.Curve90,
    },
    {
      label: '500mm Wide Loop',
      value: ETurn.Curve500,
    },
    {
      label: '800mm Wide Loop',
      value: ETurn.Curve800,
    },
    {
      label: 'Straight SuperTrak only',
      value: ETurn.None,
    },
    {
      label: 'Vertical 500mm Wide Loop',
      value: ETurn.Curve500Low,
    },
  ];

  private sideBarState: SideBarState;
  private trackViewer: TrackViewer;
  private track: Track;
  private readonly _destroying$ = new Subject<void>();

  private resizeObserver: ResizeObserver;

  get eTurn(): ETurn {
    return this.trackConceptForm.controls.eTurn.value;
  }

  get minLength(): number {
    return this.eTurn === ETurn.Curve90 ? 0 : 1;
  }

  get maxLength(): number {
    if (this.eTurn === ETurn.Curve90) {
      return 30 - this.trackWidth;
    }

    if (this.eTurn === ETurn.None) {
      return 64;
    }

    return 31;
  }

  get minWidth(): number {
    return 0;
  }

  get maxWidth(): number {
    return 30 - this.trackLength;
  }

  get trackLength(): number {
    return this.trackConceptForm.controls.length.value;
  }

  get trackWidth(): number {
    return this.trackConceptForm.controls.width.value || 0;
  }

  get trackTypeName(): string {
    return (
      this.trackTypes.find((type) => type.value === this.eTurn)?.label ||
      '(empty)'
    );
  }

  get isSidebarCollapsed(): boolean {
    return this.sideBarState === SideBarState.Collapsed;
  }

  constructor(
    private fb: FormBuilder,
    private layoutService: AppLayoutService,
    private trackCreatorService: TrackCreatorService,
    private processesStorageService: ProcessesStorageService
  ) {
    this.trackConceptForm = this.fb.group({
      eTurn: new FormControl<ETurn>(ETurn.None, {
        nonNullable: true,
        validators: [Validators.required],
      }),
      length: new FormControl<number>(1, {
        nonNullable: true,
        validators: [
          Validators.required,
          Validators.min(1),
          Validators.max(64),
        ],
      }),
      width: new FormControl<number | null>(
        {
          value: null,
          disabled: true,
        },
        {
          validators: [
            Validators.required,
            Validators.min(0),
            Validators.max(64),
          ],
        }
      ),
    });

    document.addEventListener('keydown', (event: KeyboardEvent) => {
      this.moveViewport(event);
    });

    this.layoutService.sidebarState$
      .pipe(takeUntil(this._destroying$))
      .subscribe((state) => {
        this.sideBarState = state;
      });

    this.trackCreatorService.trackSpecs$
      .pipe(takeUntil(this._destroying$))
      .subscribe((trackSpecs: TrackConceptDefinition | null) => {
        if (!trackSpecs) return;

        this.renderTrack(trackSpecs);
      });
  }

  ngAfterViewInit(): void {
    this.trackViewer = new TrackViewer(this.trackContainer, {
      length: 1,
    });

    this.trackViewer.initApplication().then(() => {
      this.renderTrack({
        eTurn: this.trackConceptForm.controls.eTurn.value,
        length: this.trackConceptForm.controls.length.value,
        width: this.trackConceptForm.controls.width.value,
      });
    });

    this.trackConceptForm.controls.eTurn.valueChanges.subscribe(
      (type: ETurn) => {
        this.handleTypeChange(type);
      }
    );

    this.setupResizeListener();
  }

  ngOnDestroy(): void {
    document.removeEventListener('keydown', () => {
      console.log('Event listener removed');
    });

    this._destroying$.unsubscribe();
    this.trackViewer.destroy();

    this.trackCreatorService.clearTrack();
    this.processesStorageService.clearProcesses();
  }

  showSettings(): void {
    this.settingsVisible = true;
  }

  showControls(): void {
    this.controlsVisible = true;
  }

  hideControls(): void {
    this.controlsVisible = false;
  }

  zoom(direction: 'in' | 'out'): void {
    this.trackViewer.zoomViewport(direction);
  }

  resetZoom(): void {
    this.trackViewer.resetZoom();
    this.trackViewer.resetPosition();
  }

  moveViewport(keyEvent: KeyboardEvent): void {
    let direction: 'up' | 'left' | 'right' | 'down';

    //FIXME: Should be refactored eventually
    if (
      keyEvent.key !== 'ArrowUp' &&
      keyEvent.key !== 'ArrowLeft' &&
      keyEvent.key !== 'ArrowRight' &&
      keyEvent.key !== 'ArrowDown'
    )
      return;

    switch (keyEvent.key) {
      case 'ArrowUp':
        direction = 'up';
        break;
      case 'ArrowLeft':
        direction = 'left';
        break;
      case 'ArrowRight':
        direction = 'right';
        break;
      case 'ArrowDown':
        direction = 'down';
        break;
    }

    this.changePosition(direction);
  }

  changePosition(direction: 'up' | 'left' | 'right' | 'down'): void {
    this.trackViewer.moveViewport(direction);
  }

  updateTrack(): void {
    this.trackCreatorService.updateTrack({
      eTurn: this.trackConceptForm.controls.eTurn.value,
      length: this.trackConceptForm.controls.length.value,
      width: this.trackConceptForm.controls.width.value,
    });
  }

  async renderTrack(trackSpecs: TrackConceptDefinition): Promise<void> {
    await this.trackViewer.renderTrack(trackSpecs).then((track) => {
      this.track = track;
      this.track.onSelect$.subscribe(() => {
        this.showTrackInfo();
      });
    });
  }

  handleTypeChange(turnType: ETurn): void {
    this.updateWidthAvailability(turnType);
  }

  updateWidthAvailability(turnType: ETurn): void {
    this.widthAvailable = turnType === ETurn.Curve90;

    this.trackConceptForm.controls.width.setValue(
      this.widthAvailable ? 0 : null
    );
    this.trackConceptForm.controls.width.setValidators(
      this.widthAvailable
        ? [Validators.required, Validators.min(0), Validators.max(30)]
        : []
    );
    if (!this.widthAvailable) {
      this.trackConceptForm.controls.width.disable();
    } else {
      this.trackConceptForm.controls.width.enable();
    }

    this.trackConceptForm.controls.width.updateValueAndValidity();
  }

  setupResizeListener() {
    window.addEventListener('resize', () => {});

    this.resizeObserver = new ResizeObserver(() => {
      this.trackViewer.resize();
    });
    this.resizeObserver.observe(this.trackContainer.nativeElement);
  }

  showTrackInfo(): void {
    this.trackInfoVisible = true;
  }

  toggleSideBar(): void {
    const sidebarState = this.isSidebarCollapsed
      ? SideBarState.Expanded
      : SideBarState.Collapsed;

    this.layoutService.setSidebarState(sidebarState);
  }

  toggleProcessMap(): void {
    this.processMapExpanded = !this.processMapExpanded;
    this.trackViewer.resize();
  }

  setOriginPoint(originPoint: number): void {
    this.processMapOriginPoint = originPoint;
  }
}
