<div class="user-management-container card-container flex flex-row gap-3">
  <p-card>
    <h2>User Management</h2>
    <p-table [value]="users"
             [lazy]="true"
             (onLazyLoad)="loadUsers($event)"
             dataKey="id"
             [rows]=10
             [loading]="loading">
      <ng-template pTemplate="header">
        <tr>
          <th style="width: 4rem"></th>
          <th>Name</th>
          <th>Email</th>
          <th>Roles</th>
          <th>Created Date</th>
          <th></th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body"
                   let-rowIndex="rowIndex"
                   let-user>
        <tr>
          <td>
            <p-tableCheckbox [value]="user"></p-tableCheckbox>
          </td>
          <td>{{user.firstName + " " + user.lastName}}</td>
          <td>{{user.email}}</td>
          <td>
            <p-badge *ngFor="let role of user.roles | orderBy"
                     class="mr-2 text-lg"
                     [value]="getRoleName(role)"
                     [severity]="getRoleSeverity(role.name)"></p-badge>
          </td>
          <td>{{ "" }}</td>
          <td>
            <button pButton
                    *ngIf="isEditAvailable(user)"
                    type="button"
                    icon="pi pi-user-edit"
                    label=""
                    class="p-button-outlined p-button-secondary w-4rem"
                    (click)="editUser(rowIndex)">
            </button>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </p-card>
</div>
