import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { DropdownChangeEvent } from 'primeng/dropdown';
import { processFunctionsList } from '../../constants/process-function-list.const';
import { processMap } from '../../constants/process-map.const';
import { ProcessFunctionName } from '../../enums/process-function.enum';
import { createFormGroupFromProcess } from '../../helpers/process-map-form';
import { ProcessesStorageService } from '../../services/processes-storage/processes-storage.service';
import {
  ProcessFormGroup,
  ProcessMap,
} from '../../types/process-form.interface';
import { ProcessFunction } from '../../types/process-function.interface';
import { Process } from '../../types/process.interface';

@Component({
  selector: 'app-process-details',
  templateUrl: './process-details.component.html',
  styleUrls: ['./process-details.component.scss'],
})
export class ProcessDetailsComponent implements OnInit {
  @Input() process: Process;
  @Input() processStorageIndex: number;

  @Output() onClose: EventEmitter<boolean> = new EventEmitter();

  processForm: FormGroup<ProcessFormGroup>;
  processMap: ProcessMap;

  processFunctionList: ProcessFunction[];
  selectedProcessFunction: ProcessFunction | null;

  constructor(private processesStorageService: ProcessesStorageService) {}

  ngOnInit(): void {
    this.processMap = processMap;

    this.processForm = createFormGroupFromProcess(this.process);

    this.processFunctionList = processFunctionsList;
    this.setSelectedFunctionByName(this.process.function);
  }

  close(): void {
    this.onClose.emit(true);
  }

  getIconName(processName: ProcessFunctionName): string | undefined {
    return processFunctionsList.find((fn) => fn.label === processName)
      ?.iconName;
  }

  touchField(fieldName: string): void {
    this.processForm.get(fieldName)?.markAsTouched();
  }

  resetField(fieldName: string, value: any): void {
    if (fieldName === 'processFunction') {
      this.setSelectedFunctionByName(this.process.function);
    }
    this.processForm.get(fieldName)?.reset();
    this.processForm.get(fieldName)?.setValue(value);
  }

  setSelectedFunctionByName(functionName: ProcessFunctionName): void {
    this.selectedProcessFunction =
      this.processFunctionList.find((item) => item.value === functionName) ||
      null;
  }

  updateSelectedFunction(event: DropdownChangeEvent): void {
    const selectedFunctionName = event.value;

    this.selectedProcessFunction =
      this.processFunctionList.find(
        (item) => item.value === selectedFunctionName
      ) || null;
  }

  resetForm(): void {
    this.processForm.reset();
  }

  saveChanges(): void {
    const updatedProcess = this.processForm.value as Process;

    this.processesStorageService.updateProcess(
      updatedProcess,
      this.processStorageIndex
    );

    this.processForm.markAsUntouched();
    this.processForm.markAsPristine();
    this.process = updatedProcess;
  }
}
