import { Assets } from '@pixi/assets';
import { Texture } from '@pixi/core';
import { Container } from '@pixi/display';
import { Sprite } from '@pixi/sprite';
import { ETurn } from 'src/app/common/enums/eTurn.enum';

export class TrackRenderer {
  private trackContainer: Container;

  curveSectionsContainer: Container;
  straightSectionsContainer: Container;

  constructor(container: Container) {
    this.trackContainer = container;

    this.curveSectionsContainer = new Container();
    this.straightSectionsContainer = new Container();

    this.curveSectionsContainer.name = 'curves';
    this.straightSectionsContainer.name = 'straight';

    this.trackContainer.addChild(this.curveSectionsContainer);
    this.trackContainer.addChild(this.straightSectionsContainer);
  }

  render(
    trackType: ETurn,
    length: number,
    width: number | null
  ): Promise<void> {
    this.clearTrack();

    switch (trackType) {
      case ETurn.None:
        return this.renderStraightSystem(length);
      case ETurn.Curve500:
      case ETurn.Curve800:
      case ETurn.Curve500Low:
        return this.renderTwoSidedSystem(trackType, length);
      case ETurn.Curve90:
        return width !== null
          ? this.renderFourSidedSystem(trackType, length, width)
          : Promise.reject('Track width is null');
    }
  }

  private renderStraightSystem(length: number): Promise<void> {
    return Assets.load('straight').then((resource: Texture) => {
      for (let i = 0; i < length; i++) {
        const straightPart = new Sprite(resource);
        straightPart.anchor.set(0.5, 0.5);
        straightPart.rotation = -Math.PI / 2;
        straightPart.scale.set(0.1, 0.1);

        const startPoint = (-length * straightPart.height) / 2;

        this.straightSectionsContainer.addChild(straightPart);
        straightPart.position.x =
          startPoint + straightPart.height * i + straightPart.height / 2;
      }
    });
  }

  private renderTwoSidedSystem(
    trackType: ETurn,
    length: number
  ): Promise<void> {
    const curveSections: Sprite[] = [];

    return Promise.all([Assets.load(trackType), Assets.load('straight')]).then(
      ([curveTexture, straightTexture]) => {
        // Rendering curved sections
        for (let i = 0; i < 2; i++) {
          const curveSection = new Sprite(curveTexture);
          curveSection.anchor.set(0.5, 0.5);
          curveSection.scale.set(0.1, 0.1);

          curveSections.push(curveSection);
          this.curveSectionsContainer.addChild(curveSection);

          curveSection.rotation = i % 2 === 0 ? -Math.PI / 2 : Math.PI / 2;

          const endPosition = (length * 100) / 2 + curveSection.height / 2;
          const startPoisition = -endPosition;

          curveSection.position.x = i % 2 !== 0 ? startPoisition : endPosition;
        }

        const straightPartsNumber = length * 2;

        // Rendering straight sections
        for (let i = 0; i < straightPartsNumber; i++) {
          const straightPart = new Sprite(straightTexture);
          straightPart.anchor.set(0.5, 0.5);
          straightPart.scale.set(0.1, 0.1);

          straightPart.rotation = i % 2 !== 0 ? Math.PI / 2 : -Math.PI / 2;

          const startPoint = (-length * straightPart.height) / 2;

          this.straightSectionsContainer.addChild(straightPart);

          const startOffsetPositionX =
            straightPart.height * Math.floor(i / 2) + straightPart.height / 2;

          straightPart.position.x = startPoint + startOffsetPositionX;

          straightPart.position.y =
            i % 2 === 0
              ? curveSections[0].width / 2 - straightPart.width / 2
              : -curveSections[0].width / 2 + straightPart.width / 2;
        }
      }
    );
  }

  private renderFourSidedSystem(
    trackType: ETurn,
    length: number,
    width: number
  ): Promise<void> {
    const curveSections: Sprite[] = [];

    return Promise.all([Assets.load(trackType), Assets.load('straight')]).then(
      ([curveTexture, straightTexture]) => {
        // Rendering curved sections
        for (let i = 0; i < 4; i++) {
          const curveSection = new Sprite(curveTexture);
          curveSection.anchor.set(0.5, 0.5);
          curveSection.scale.set(0.1, 0.1);

          curveSections.push(curveSection);
          this.curveSectionsContainer.addChild(curveSection);

          curveSection.rotation = i * ((i < 1 ? Math.PI : -Math.PI) / 2);

          const endPositionHorizontal =
            (length * 100) / 2 + curveSection.height / 2;
          const startPoisitionHorizontal = -endPositionHorizontal;

          const endPositionVertical =
            (width * 100) / 2 + curveSection.height / 2;
          const startPoisitionVertical = -endPositionVertical;

          curveSection.position.x =
            i > 1 ? startPoisitionHorizontal : endPositionHorizontal;
          curveSection.position.y =
            i < 2
              ? i % 2 === 0
                ? endPositionVertical
                : startPoisitionVertical
              : i % 2 === 0
              ? startPoisitionVertical
              : endPositionVertical;
        }

        const straightPartsNumberHorizontal = length * 2;
        const straightPartsNumberVertical = width * 2;

        // Rendering straight sections
        for (let i = 0; i < straightPartsNumberHorizontal; i++) {
          const straightPart = new Sprite(straightTexture);
          straightPart.anchor.set(0.5, 0.5);
          straightPart.scale.set(0.1, 0.1);

          straightPart.rotation = i % 2 !== 0 ? Math.PI / 2 : -Math.PI / 2;

          const startPoint = (-length * straightPart.height) / 2;

          this.straightSectionsContainer.addChild(straightPart);

          const startOffsetPositionX =
            straightPart.height * Math.floor(i / 2) + straightPart.height / 2;

          straightPart.position.x = startPoint + startOffsetPositionX;

          straightPart.position.y =
            i % 2 !== 0
              ? -curveSections[0].width +
                straightPart.width / 2 -
                (straightPart.height * width) / 2
              : curveSections[0].width -
                straightPart.width / 2 +
                (straightPart.height * width) / 2;
        }

        for (let i = 0; i < straightPartsNumberVertical; i++) {
          const straightPart = new Sprite(straightTexture);
          straightPart.anchor.set(0.5, 0.5);
          straightPart.scale.set(0.1, 0.1);

          straightPart.rotation = i % 2 === 0 ? 2 * Math.PI : Math.PI;

          const startPoint = (-width * straightPart.height) / 2;

          this.straightSectionsContainer.addChild(straightPart);

          const startOffsetPositionY =
            straightPart.height * Math.floor(i / 2) + straightPart.height / 2;

          straightPart.position.y = startPoint + startOffsetPositionY;

          straightPart.position.x =
            i % 2 === 0
              ? -curveSections[0].width +
                straightPart.width / 2 -
                (straightPart.height * length) / 2
              : curveSections[0].width -
                straightPart.width / 2 +
                (straightPart.height * length) / 2;
        }
      }
    );
  }

  private clearTrack(): void {
    this.straightSectionsContainer.removeChildren();
    this.curveSectionsContainer.removeChildren();
  }
}
