import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  BehaviorSubject,
  catchError,
  map,
  Observable,
  of,
  throwError,
} from 'rxjs';
import { AccessScope } from 'src/app/configuration-management/enums/access-scope.enum';
import { ApiUrls, FormatApiUrlParam } from 'src/app/core/api-helper';
import { ApiUrlParams } from 'src/app/core/api-url-params';
import { IApiResponse } from 'src/app/core/iapi-response';
import { IdentityService } from 'src/app/core/services/identity.service';
import { ConfigurationRequest } from '../../models/configuration-request.model';
import { ConfigurationVersionRequest } from '../../models/configuration-version-reqest.model';
import { ConfigurationVersionRfqResponse } from '../../models/configuration-version-rfq-response.interface';
import {
  Configuration,
  ConfigurationVersion,
  VersionStatus,
} from '../../models/configuration.model';
import { ProductType } from '../../models/product-type';
import { EditorModeService } from '../editor-mode/editor-mode.service';

@Injectable({
  providedIn: 'root',
})
export class ProjectConfigurationService {
  private configurations: Configuration[] = [];

  configurations$ = new BehaviorSubject<Configuration[]>([]);

  constructor(
    private httpClient: HttpClient,
    private identityService: IdentityService,
    private editorModeService: EditorModeService
  ) {}

  getAllUserConfigurations(
    projectId: string,
    accessScope = AccessScope.User
  ): Observable<Configuration[]> {
    const url = ApiUrls.getConfigurationsByProjectId.replace(
      FormatApiUrlParam(ApiUrlParams.ProjectId),
      projectId
    );

    return this.httpClient
      .get<IApiResponse<Configuration[]>>(url, {
        params: {
          accessScope: accessScope,
        },
      })
      .pipe(
        map((response: IApiResponse<Configuration[]>) => {
          this.configurations = response.value;
          this.configurations$.next(this.configurations.slice());
          return response.value;
        }),
        catchError((error) => {
          return throwError(() => error);
        })
      );
  }

  requestConfigVersionById(
    versionId: string
  ): Observable<ConfigurationVersion> {
    const url = ApiUrls.configVersionById.replace(':versionId', versionId);

    return this.httpClient.get<IApiResponse<ConfigurationVersion>>(url).pipe(
      map((response) => {
        return response.value;
      }),
      catchError((error) => {
        console.error(error);
        return throwError(() => error);
      })
    );
  }

  requestConfigurationById(
    configId: string,
    accessScope = AccessScope.User
  ): Observable<Configuration> {
    const url = ApiUrls.getConfigurationByIdUrl.replace(
      FormatApiUrlParam(ApiUrlParams.ConfigurationId),
      configId
    );

    const existingConfiguration = this.configurations$.value.find(
      (configuration) => configuration.id === configId
    );

    if (existingConfiguration) {
      return of(existingConfiguration);
    }

    return this.httpClient
      .get<IApiResponse<Configuration>>(url, {
        params: {
          accessScope,
        },
      })
      .pipe(
        map((response) => {
          const index = this.configurations.findIndex(
            (configuration) => configuration.id === configId
          );
          if (index < 0) {
            this.configurations.push(response.value);
          } else {
            this.configurations[index] = response.value;
          }
          this.configurations$.next(this.configurations);
          return response.value;
        }),
        catchError((error) => {
          console.error(error);
          return throwError(() => error);
        })
      );
  }

  getConfigurationVersion(id: string): ConfigurationVersion | undefined {
    let currentConfigurationVersion;

    this.configurations$.value.forEach((curConfig: Configuration) => {
      const configuration = curConfig.configurationVersions.find(
        (config: ConfigurationVersion) => config.id === id
      );

      if (configuration) {
        currentConfigurationVersion = configuration;
      }
    });

    return currentConfigurationVersion;
  }

  getConfiguration(id: string): Configuration | undefined {
    return this.configurations$.value.find((config) => config.id === id);
  }

  createNewConfiguration(
    projectId: string,
    newConfiguration: ConfigurationRequest
  ) {
    const url = ApiUrls.createNewConfigurationUrl.replace(
      FormatApiUrlParam(ApiUrlParams.ProjectId),
      projectId
    );

    return this.httpClient
      .post<IApiResponse<Configuration>>(url, newConfiguration)
      .pipe(
        map((response) => {
          this.configurations.push(response.value);
          this.configurations$.next(this.configurations);
          return response.value;
        }),
        catchError((error) => {
          console.error(error);
          return throwError(() => error);
        })
      );
  }

  createNewVersion(
    configId: string,
    versionId?: string
  ): Observable<ConfigurationVersion> {
    const url = ApiUrls.createNewVersionUrl.replace(
      FormatApiUrlParam(ApiUrlParams.ConfigurationId),
      configId
    );
    const newVersionRequest = new ConfigurationVersionRequest(
      ProductType.SuperTrakGen3,
      versionId
    );

    return this.httpClient
      .post<IApiResponse<ConfigurationVersion>>(url, newVersionRequest)
      .pipe(
        map((res) => {
          const updatedConfiguration = this.configurations.find(
            (config) => config.id === configId
          );

          if (updatedConfiguration) {
            updatedConfiguration.configurationVersions.push(res.value);
          }

          return res.value;
        }),
        catchError((error) => {
          console.error(error);
          return throwError(() => error);
        })
      );
  }

  deleteConfigurationById(configurationId: string) {
    const url = ApiUrls.deleteConfiguration.replace(
      FormatApiUrlParam(ApiUrlParams.ConfigurationId),
      configurationId
    );

    return this.httpClient.delete(url).pipe(
      map(() => {
        this.configurations.splice(
          this.configurations.findIndex(
            (configuration) => configuration.id === configurationId
          ),
          1
        );
        this.configurations$.next(this.configurations.slice());
        return true;
      }),
      catchError((error) => {
        console.error(error);
        return throwError(() => error);
      })
    );
  }

  deleteConfigVersionById(versionId: string): Observable<string | undefined> {
    const url = ApiUrls.configVersionById.replace(
      FormatApiUrlParam(ApiUrlParams.VersionId),
      versionId
    );

    return this.httpClient.delete(url).pipe(
      map(() => {
        let versionIdToSelect;
        this.configurations.forEach((configuration) => {
          const versionIndex = configuration.configurationVersions.findIndex(
            (version) => version.id === versionId
          );

          if (versionIndex >= 0) {
            configuration.configurationVersions.splice(versionIndex, 1);
            versionIdToSelect =
              configuration.configurationVersions[versionIndex - 1]?.id ||
              configuration.configurationVersions[versionIndex]?.id;
          }
        });
        this.configurations$.next(this.configurations.slice());
        return versionIdToSelect;
      }),
      catchError((error) => {
        console.error(error);
        return throwError(() => error);
      })
    );
  }

  requestConfigurationQuote(
    configVersionId: string,
    quote: {
      requestedByDate: string;
      rfqComment: null | string;
      configUrl: string;
    }
  ): Observable<any> {
    const url = ApiUrls.requestConfigQuote.replace(
      FormatApiUrlParam(ApiUrlParams.VersionId),
      configVersionId
    );
    return this.httpClient
      .post(url, {
        rfqStatus: VersionStatus.Submitted,
        ...quote,
      })
      .pipe(
        map(() => {
          this.configurations.forEach((configuration: Configuration) => {
            const config = configuration.configurationVersions.find(
              (config: ConfigurationVersion) => config.id === configVersionId
            );

            if (config) {
              config.rfqStatus = VersionStatus.Submitted;
              config.requestedByDate = quote.requestedByDate;
              config.rfqComment = quote.rfqComment || undefined;
            }
          });
          this.configurations$.next(this.configurations);
        })
      );
  }

  changeVersionRfqStatus(
    configVersionId: string,
    quote: {
      rfqStatus: VersionStatus;
      requestedByDate: string | undefined;
      rfqComment: null | string | undefined;
      configUrl: string;
    }
  ): Observable<ConfigurationVersionRfqResponse> {
    const url = ApiUrls.requestConfigQuote.replace(
      FormatApiUrlParam(ApiUrlParams.VersionId),
      configVersionId
    );

    return this.httpClient
      .put<IApiResponse<ConfigurationVersionRfqResponse>>(url, quote)
      .pipe(
        map((response: IApiResponse<ConfigurationVersionRfqResponse>) => {
          this.updateExistingVersion(configVersionId, response.value);
          return response.value;
        }),
        catchError((error) => {
          console.error(error);
          return throwError(() => error);
        })
      );
  }

  updateConfigurationQuoteByDate(
    configVersionId: string,
    date: string
  ): Observable<any> {
    const url = ApiUrls.requestConfigQuote.replace(
      FormatApiUrlParam(ApiUrlParams.VersionId),
      configVersionId
    );

    return this.httpClient
      .put(url, {
        requestedByDate: date,
      })
      .pipe(
        map(() => {
          this.configurations.forEach((configuration: Configuration) => {
            const config = configuration.configurationVersions.find(
              (config: ConfigurationVersion) => config.id === configVersionId
            );

            if (config) {
              config.requestedByDate = date;
            }
          });
          this.configurations$.next(this.configurations);
        })
      );
  }

  updateConfigurationVersion(
    versionId: string,
    updatedVersion: Partial<ConfigurationVersion>
  ): Observable<ConfigurationVersion> {
    const url = ApiUrls.configVersionById.replace(':versionId', versionId);

    const existingVersion = this.getConfigurationVersion(versionId);

    if (!existingVersion)
      return throwError(() => 'Configuration Version not found');

    const versionToUpdate: ConfigurationVersion = {
      ...existingVersion,
      ...updatedVersion,
    };

    return this.httpClient
      .put<IApiResponse<ConfigurationVersion>>(url, versionToUpdate)
      .pipe(
        map((response) => {
          const configurations = this.configurations$.value;
          configurations.map((config) => {
            const indexToReplace = config.configurationVersions.findIndex(
              (version) => version.id === versionId
            );

            if (indexToReplace >= 0) {
              config.configurationVersions[indexToReplace] = response.value;
            }
          });

          this.configurations$.next(configurations);
          return response.value;
        }),
        catchError((error) => {
          return throwError(() => error);
        })
      );
  }

  private updateExistingVersion(
    configVersionId: string,
    quote: {
      rfqStatus: VersionStatus;
      requestedByDate: string;
      rfqComment: null | string;
      configUrl: string;
    }
  ): void {
    this.configurations.forEach((configuration: Configuration) => {
      const config = configuration.configurationVersions.find(
        (config: ConfigurationVersion) => config.id === configVersionId
      );

      if (config) {
        config.rfqStatus = quote.rfqStatus;
        config.requestedByDate = quote.requestedByDate;
        config.rfqComment = quote.rfqComment || undefined;
      }
    });
    this.configurations$.next(this.configurations);
  }
}
