import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { combineLatest, Subject, takeUntil } from 'rxjs';
import { ETurn } from 'src/app/common/enums/eTurn.enum';
import {
  getTrakBrandName,
  getTrakLabel,
} from 'src/app/common/helpers/track-type';
import { ProcessMapState } from '../../enums/process-map-layout.enum';
import { ProcessesStorageService } from '../../services/processes-storage/processes-storage.service';
import {
  TrackConceptDefinition,
  TrackCreatorService,
} from '../../services/track-creator/track-creator.service';
import { Process } from '../../types/process.interface';
import { ProcessMapCreatorComponent } from '../process-map-creator/process-map-creator.component';
import { TrackTypeModalComponent } from '../track-type-modal/track-type-modal.component';

@Component({
  selector: 'app-process-map',
  templateUrl: './process-map.component.html',
  styleUrls: ['./process-map.component.scss'],
  host: {
    class: 'flex flex-column flex-grow-1 h-full bg-white',
  },
})
export class ProcessMapComponent implements OnInit, OnDestroy {
  @Output() onOriginPointChange: EventEmitter<number> =
    new EventEmitter<number>();
  @Input() collapsed: boolean;
  @Input() originPoint: number;

  trackSpecs: TrackConceptDefinition | null;
  isTrackFullfilled: boolean;

  originPointModel: number;
  processIndexToShow: number;

  processList: Process[] = [];

  processMapState = ProcessMapState;
  screenState: ProcessMapState;

  originPointEditing = false;

  get processToShow(): Process {
    return this.processList[this.processIndexToShow];
  }

  private readonly _destroying$ = new Subject<void>();

  private trakTypeModalRef: DynamicDialogRef | undefined;

  constructor(
    private dialogService: DialogService,
    private trackCreatorService: TrackCreatorService,
    private processesStorageService: ProcessesStorageService
  ) {}

  ngOnInit(): void {
    this.originPointModel = this.originPoint;

    combineLatest([
      this.processesStorageService.processes$,
      this.trackCreatorService.trackSpecs$,
    ])
      .pipe(takeUntil(this._destroying$))
      .subscribe(([processList, trackSpecs]) => {
        this.processList = processList;
        this.trackSpecs = trackSpecs;

        if (!this.trackSpecs || !this.processList.length) {
          this.setSCreen();
        }
      });
  }

  ngOnDestroy(): void {
    this._destroying$.unsubscribe();
  }

  openProcessMapCreation(): void {
    this.dialogService.open(ProcessMapCreatorComponent, {
      header: 'Concept a System',
      style: {
        width: '70vw',
        maxWidth: '1400px',
      },
      contentStyle: {
        backgroundImage: 'url(/assets/images/supertrak-bg.png)',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: '100% 100%',
        backgroundSize: 'contain',
      },
    });
  }

  startNewProcess(): void {
    this.screenState = ProcessMapState.NewProcess;
  }

  showProcessMap(): void {
    this.screenState = ProcessMapState.ProcessList;
  }

  showDetails(processId: number): void {
    this.processIndexToShow = processId;
    this.screenState = ProcessMapState.ProcessDetails;
  }

  resetOriginPoint(): void {
    this.originPointModel = this.originPoint;
    this.hideOriginPointEditing();
  }

  saveOriginPoint(): void {
    this.onOriginPointChange.emit(this.originPointModel);
    this.hideOriginPointEditing();
  }

  hideOriginPointEditing(): void {
    this.originPointEditing = false;
  }

  getTrackLabel(eTurn: ETurn): string | undefined {
    return getTrakLabel(eTurn);
  }

  getTrackBrandName(eTurn: ETurn): string | undefined {
    return getTrakBrandName(eTurn);
  }

  openTrackTypeModal(): void {
    this.trakTypeModalRef = this.dialogService.open(TrackTypeModalComponent, {
      header: 'Select a SuperTrak CONVEYANCE™ configuration',
      width: '60vw',
      style: {
        'max-width': '1200px',
      },
    });
  }

  private setSCreen(state?: ProcessMapState): void {
    if (state) {
      this.screenState = state;
      return;
    }

    if (this.trackSpecs) {
      this.screenState = ProcessMapState.ProcessList;
    }
  }
}
