<div class="flex flex-column justify-content-center align-items-center col-6">
  <p-image class="option-item__image"
           [preview]="true"
           [src]="trackImageSrc"
           (onImageError)="setPlaceholderImage()">
  </p-image>
</div>

<div class="col-1">
  <p-divider [layout]="'vertical'"></p-divider>
</div>

<form class="flex flex-column justify-content-center col-5"
      [formGroup]="form">

  <ng-container *ngIf="isOverUnder; else framesSelectionTpl">
    <p class="text-2xl text-center">The vertical configuration is delivered assembled on standard
      base
      plates - there
      is no
      option to purchase the
      vertical configuration by single modules</p>
  </ng-container>

  <ng-template #framesSelectionTpl>
    <ng-container *ngFor="let question of frameQuestion">
      <ng-container *ngIf="question.isEnabled ? question.isEnabled(isOverUnder) : true">
        <p-divider align="center">
          <h5 class="question m-0">{{ question.questionText }}</h5>
        </p-divider>
        <ul class="options-list _center">
          <li *ngFor="let option of question.options"
              class="option-item _bordered w-6"
              [ngClass]="{
         '_disabled': isSymphoniProject
       }">
            <label class="option-item__inner align-items-center _inline">
              <p-radioButton [formControlName]="option.fieldName || ''"
                             [value]="option.value">
              </p-radioButton>
              <p class="option-item__label text-base w-full mb-0 text-center ml-3">{{ option.label }}</p>

              <i *ngIf="isSymphoniProject && !isBaseFrameIncludedOpt(option.value)"
                 class="pi pi-info-circle ml-3"
                 style="color: #f7941d"></i>
            </label>
          </li>
        </ul>
      </ng-container>
    </ng-container>


    <div *ngIf="eTurnNumberQuestion.isEnabled ? eTurnNumberQuestion.isEnabled(isFrameIncluded, trackType) : true">
      <p-divider align="center">
        <h5 class="question m-0">{{ eTurnNumberQuestion.questionText }}</h5>
      </p-divider>
      <ul class="options-list _center">
        <li class="option-item _bordered w-4"
            [ngClass]="{
              '_disabled': isSymphoniProject
            }"
            *ngFor="let option of eTurnNumberQuestion.options">
          <label class="option-item__inner align-items-center _inline">
            <p-radioButton [formControlName]="eTurnNumberQuestion.fieldName"
                           [value]="option.value">
            </p-radioButton>
            <p class="option-item__label text-base w-full mb-0 text-center">{{ option.label }}</p>

            <i *ngIf="isSymphoniProject && !isShortEturnsNone(option.value)"
               class="pi pi-info-circle ml-3"
               style="color: #f7941d"></i>
          </label>
        </li>
      </ul>
    </div>

    <div
         *ngIf="trackStandsAndLevellingKitQuestion.isEnabled ? trackStandsAndLevellingKitQuestion.isEnabled(isFrameIncluded, trackType, isInternalUser) : true">
      <p-divider align="center">
        <h5 class="question m-0">{{ trackStandsAndLevellingKitQuestion.questionText }}</h5>
      </p-divider>
      <div class="option-item flex relative overflow-hidden _bordered mx-auto lg:w-10 xl:w-8">
        <label class="option-item__inner align-items-center _inline">
          <p-checkbox [binary]="true"
                      (click)="disableCheckboxFormValueChange($event)"
                      [formControlName]="trackStandsAndLevellingKitQuestion.fieldName"></p-checkbox>
          <p class="option-item__label text-base w-full m-0 text-center ml-3">{{
            trackStandsAndLevellingKitQuestion.options[0].label }}
          </p>
        </label>
        <div class="hidden-info flex align-items-center justify-content-end w-6rem">
          <p-button [rounded]="true"
                    severity="warning"
                    ariaLabel="Description"
                    (click)="showDescription()"
                    icon="pi pi-info">
          </p-button>
        </div>
      </div>
    </div>

    <ng-container *ngIf="isSymphoniProject">
      <p class="text-center font-italic">*Base frames for the e-turns will not be included.</p>
      <p class="text-center font-italic"><i class="pi pi-info-circle"
           style="color: #f7941d"></i> This option is not available for Symphoni
        enabled systems.
      </p>
    </ng-container>
  </ng-template>

</form>

<p-dialog [(visible)]="descriptionVisible"
          [modal]="true"
          [closeOnEscape]="false"
          [draggable]="false"
          class="description-dialog"
          [resizable]="false">
  <ng-template pTemplate="header">
    <h3 class="m-0">Track Section Stands and Levelling Kit</h3>
  </ng-template>

  <ng-template pTemplate="content">
    <p-image [preview]="true"
             [appendTo]="'body'"
             src="/assets/images/track-designer/stands-and-levelling-kit.png"></p-image>
    <p class="m-0 text-base text-justify">
      The track stands provide a means to support the straight section. The track levelling assembly provides a
      convenient method of adjusting the height of the straight section relative to the track stand. Every straight
      section needs two track stands and two levelling kits.
    </p>
  </ng-template>
</p-dialog>
