import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { MsalService } from '@azure/msal-angular';
import { switchMap, tap } from 'rxjs';
import { RoleEnum } from 'src/app/common/models/user/user';
import { IdentityService } from 'src/app/core/services/identity.service';
import { environment } from 'src/environments/environment';

export const devEnvGuard: CanActivateFn = (route, state) => {
  const router = inject(Router);
  const identityService = inject(IdentityService);
  const msalService = inject(MsalService);

  return msalService.initialize().pipe(
    switchMap(() => identityService.checkToken()),
    tap((isAuthenticated: boolean) => {
      // If user is already authenticated, they can proceed to target route
      if (isAuthenticated) {
        localStorage.removeItem('ATS-location');
        return true;
      }
      if (
        environment.production ||
        !identityService.currentUser?.hasRole(RoleEnum.SuperAdmin)
      ) {
        router.navigate(['/404']);
        return false;
      }
      return true;
    })
  );
};
