import { Component, EventEmitter, Input, Output } from '@angular/core';
import {
  getVersionStatusColor,
  getVersionStatusLabel,
} from 'src/app/common/helpers/configuration-version-status';
import { Track } from 'src/app/common/models/track/track';
import {
  Configuration,
  ConfigurationVersion,
  VersionStatus,
} from 'src/app/configuration/models/configuration.model';

@Component({
  selector: 'app-configuration-version-info',
  templateUrl: './configuration-version-info.component.html',
  styleUrls: ['./configuration-version-info.component.scss'],
})
export class ConfigurationVersionInfoComponent {
  @Input() configurationVersion: ConfigurationVersion;
  @Input() configuration: Configuration;
  @Input() configurationStatusSeverity: string;
  @Input() exportingParameters = false;
  @Input() tracksList: Track[];
  @Input() isUserAdmin = false;

  @Output() onExportParameters: EventEmitter<string> = new EventEmitter();
  @Output() onViewConfiguration: EventEmitter<boolean> = new EventEmitter();

  constructor() {}

  exportParameters(): void {
    this.onExportParameters.emit(this.configurationVersion.id);
  }

  viewConfiguration(): void {
    this.onViewConfiguration.emit(true);
  }

  getVersionStatusLabel(status: VersionStatus): string {
    return getVersionStatusLabel(status);
  }

  getVersionStatusColor(status: VersionStatus): {
    class: string;
    color: string;
  } {
    return getVersionStatusColor(status);
  }
}
