import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { MessageService } from 'primeng/api';
import { catchError, forkJoin, map, Observable, throwError } from 'rxjs';
import { AppRoutes } from 'src/app/app.routes.const';
import { TrackService } from 'src/app/common/services/track-services/track.service';
import { Track } from '../common/models/track/track';
import { AccessScope } from '../configuration-management/enums/access-scope.enum';
import { Configuration } from '../configuration/models/configuration.model';
import { EditorModeService } from '../configuration/services/editor-mode/editor-mode.service';
import { ProjectConfigurationService } from '../configuration/services/project-configuration/project-configuration.service';
import { IdentityService } from '../core/services/identity.service';

@Injectable({
  providedIn: 'root',
})
export class TrackDesignerResolver {
  constructor(
    private trackService: TrackService,
    private projectConfigService: ProjectConfigurationService,
    private identityService: IdentityService,
    private router: Router,
    private messageService: MessageService,
    private editorModeService: EditorModeService
  ) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<Track> {
    const trackId = route.paramMap.get('trackId');
    const configId = route.paramMap.get('configId');
    const versionId = route.queryParamMap.get('versionId');

    if (!trackId) {
      return throwError(() => 'trackId is null');
    }

    if (!configId) {
      return throwError(() => 'trackId is null');
    }

    const accessScope = this.identityService.currentUser?.hasAccessScope(
      AccessScope.Sales
    )
      ? AccessScope.Sales
      : AccessScope.User;

    return forkJoin([
      this.trackService.getTrackById(trackId, accessScope),
      this.projectConfigService.requestConfigurationById(configId, accessScope),
    ]).pipe(
      map(([trackDetails, configuration]) => {
        this.editorModeService.initEditorModeByConfig(configuration);
        if (!versionId || !this.isPageAccessible(configuration, versionId)) {
          this.handleError(
            'track designer is not accessible until the track is fulfilled by its owner'
          );
        }
        return trackDetails;
      }),
      catchError((error) => {
        this.handleError('Failed to resolve track');
        return throwError(() => error);
      })
    );
  }

  isPageAccessible(configuration: Configuration, versionId: string): boolean {
    const version = configuration.configurationVersions.find(
      (version) => version.id === versionId
    );

    if (!version || !configuration) return false;

    const currentUser = this.identityService.currentUser;

    return (
      version.createdById !== configuration.owner?.id ||
      currentUser?.id === configuration.owner.id
    );

    return true;
  }

  handleError(message: string) {
    this.messageService.add({
      severity: 'error',
      summary: 'Error',
      detail: 'Failed to resolve track',
    });
    this.router.navigate([AppRoutes.Projects.fullPath()]);
  }
}
