import { Injectable } from '@angular/core';
import { DialogService, DynamicDialogConfig } from 'primeng/dynamicdialog';
import { Observable, of } from 'rxjs';
import { AppConfirmationDialogComponent } from '../app-confirmation-dialog/app-confirmation-dialog/app-confirmation-dialog.component';

/**
 * The AppConfirmationDialogService is a custom dialog service that allows the user to perform
 * async tasks with a confirmation.
 * Please use this when you want to confirm an action with an async function.
 */
@Injectable({
  providedIn: 'root',
})
export class AppConfirmationDialogService {
  constructor(private dialogService: DialogService) {}

  open(request: AppConfirmationDialogRequest) {
    this.dialogService.open(AppConfirmationDialogComponent, {
      header: request.header,
      modal: request.modal,
      position: request.position ?? 'top',
      style: { width: '50vw' },
      resizable: false,
      draggable: false,
      data: request,
    } as DynamicDialogConfig);
  }
}

export class AppConfirmationDialogRequest {
  /**
   * This is the observable that will be subscribed to before the dialog is
   * closed. The dialog will only close when the observable completes. You can tap
   * into this observable if you need to accomplish anything before the observable
   * completes.
   */
  onBeforeClose: Observable<boolean> = of(true);
  header?: string | undefined;
  modal?: boolean | undefined;
  position?: string;
  confirmButtonClass? = 'p-button p-button-danger p-button-raised';
  rejectButtonClass? = 'p-button-outlined p-button-secondary w-8rem';

  public constructor(init?: Partial<AppConfirmationDialogRequest>) {
    Object.assign(this, init);
  }
}
