import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Configuration } from 'src/app/configuration/models/configuration.model';
import { ConfigurationsSales } from '../../interfaces/configurations-sales.interface';
import { ConfigurationsListParams } from '../../types/configurations-list-params.type';

@Injectable({
  providedIn: 'root',
})
export class ConfigurationsStorageService {
  // Configrations storage
  private configurations$: BehaviorSubject<Configuration[]> =
    new BehaviorSubject<Configuration[]>([]);
  // Configurations list parameters storage (pagination, filters and etc.)
  private configurationsListParams$: BehaviorSubject<ConfigurationsListParams | null> =
    new BehaviorSubject<ConfigurationsListParams | null>(null);

  public configurations = this.configurations$.asObservable();
  public configurationsListParams =
    this.configurationsListParams$.asObservable();

  get getConfigurations(): Configuration[] {
    return this.configurations$.value;
  }

  set setConfigurations(configurations: Configuration[]) {
    this.configurations$.next(configurations);
  }

  get getConfigurationsListParams(): ConfigurationsListParams | null {
    return this.configurationsListParams$.value;
  }

  set setConfigurationsListParams(params: ConfigurationsListParams) {
    this.configurationsListParams$.next(params);
  }

  constructor() {}

  updateConfigurations({ items, ...rest }: ConfigurationsSales): void {
    this.configurations$.next(items);
    this.configurationsListParams$.next(rest);
  }
}
