<p-card>
  <h2>Edit User</h2>
  <div class="card-form-container">
    <p-panel header="Roles">
      <form id="roleForm"
            (ngSubmit)="submitRoles()"
            #roleForm="ngForm">
        <div *ngFor="let role of roles; index as i"
             class="field-checkbox">
          <p-checkbox name="roles"
                      [value]="role"
                      value="role"
                      [disabled]="!isInternalUser && isProduction"
                      [inputId]="i.toString()"
                      [(ngModel)]="selectedRoles">
          </p-checkbox>
          <label [for]="i.toString()">{{role.name}}</label>
        </div>
      </form>
      <div class="flex justify-content-end card-form-button-row p-buttonset">
        <button pButton
                type="button"
                label="Cancel"
                class="p-button-outlined p-button-primary"
                (click)="cancel()"
                [disabled]="isSaving"></button>
        <button pButton
                type="button"
                form="ngForm"
                label="Save"
                icon="pi pi-check"
                iconPos="right"
                [loading]="isSaving"
                (click)="roleForm.ngSubmit.emit()"></button>
      </div>
    </p-panel>
    <p-panel header="Company Override (Development Testing)"
             styleClass="mt-4"
             *ngIf="isCurrentUser && !isProduction">
      <p-dropdown styleClass="w-3"
                  [options]="companiesList"
                  [(ngModel)]="selectedCompany"
                  optionLabel="name"
                  optionValue="value">
      </p-dropdown>
      <div class="flex justify-content-end card-form-button-row p-buttonset">
        <button pButton
                type="button"
                label="Reset"
                class="p-button-outlined p-button-primary"
                (click)="resetCompanyName()"
                [disabled]="isSaving"></button>
        <button pButton
                type="button"
                label="Save"
                icon="pi pi-check"
                iconPos="right"
                [loading]="isSaving"
                (click)="saveCompanyName()"></button>
      </div>
    </p-panel>
  </div>
</p-card>
