import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { ConfirmationService } from 'primeng/api';
import { OrderList } from 'primeng/orderlist';
import { processFunctionsList } from '../../constants/process-function-list.const';
import { ProcessFunctionName } from '../../enums/process-function.enum';
import { ProcessesStorageService } from '../../services/processes-storage/processes-storage.service';
import { Process } from '../../types/process.interface';

@Component({
  selector: 'app-process-list',
  templateUrl: './process-list.component.html',
  styleUrls: ['./process-list.component.scss'],
})
export class ProcessListComponent {
  @ViewChild('orderList') orderList: OrderList;

  @Input() processList: Process[];
  @Output() onShowDetails: EventEmitter<number> = new EventEmitter<number>();

  actionsVisibleFor: number | null;

  constructor(
    private processesStorageService: ProcessesStorageService,
    private confirmationService: ConfirmationService
  ) {}

  getIconName(processName: ProcessFunctionName): string | undefined {
    return processFunctionsList.find((fn) => fn.label === processName)
      ?.iconName;
  }

  showProcessDetails(processId: number): void {
    this.onShowDetails.emit(processId);
  }

  deleteProcess(processId: number): void {
    this.confirmationService.confirm({
      header: 'Delete Process',
      icon: 'pi pi-exclamation-triangle',
      message: 'Are you sure you want to delete the process?',
      accept: () => {
        this.processesStorageService.deleteProcess(processId);
      },
    });
  }

  reorderProcessMap(processList: any): void {
    const updateProcessList = processList as Process[];
    this.processesStorageService.updateProcessListOrder(updateProcessList);
  }
}
