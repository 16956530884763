<div class="flex flex-column p-card p-3 bg-white mb-4">
  <h5 class="flex-grow-1 m-0 mb-2">Configuration</h5>

  <ul class="configuration-info flex flex-column list-none p-0 m-0">
    <li class="configuration-info__item flex justify-content-between align-items-center py-2 border-bottom-1">
      <span>Id:</span>
      <span>{{ configuration.id }}</span>
    </li>
    <li class="configuration-info__item flex justify-content-between align-items-center py-2 border-bottom-1">
      <span>Name:</span>
      <span>{{ configuration.name }}</span>
    </li>
    <li class="configuration-info__item flex justify-content-between align-items-center py-2 border-bottom-1">
      <span>Project Name:</span>
      <span>{{ configuration.projectName }}</span>
    </li>
    <li class="configuration-info__item flex justify-content-between align-items-center py-2 border-bottom-1">
      <span>User Email:</span>
      <span>{{ configuration.owner?.email }}</span>
    </li>
    <li class="configuration-info__item flex justify-content-between align-items-center py-2 border-bottom-1">
      <span>User Name:</span>
      <span>{{ configuration.owner?.fullName }}</span>
    </li>
    <li class="configuration-info__item flex justify-content-between align-items-center py-2 border-bottom-1">
      <span>Company Name:</span>
      <span>{{ configuration.owner?.companyName }}</span>
    </li>
  </ul>

  <div class="configuration-info__item flex justify-content-end align-items-center pt-2">
    <button pButton
            pRipple
            type="button"
            icon="pi pi-eye"
            pTooltip="View Configuration"
            class="p-button-outlined p-button-info"
            (click)="viewConfiguration()">
    </button>
  </div>

</div>
<div class="flex flex-column p-card p-3 bg-white mb-4">
  <h5 class="mb-2">Version Info</h5>
  <ul class="configuration-info flex flex-column list-none p-0 m-0">
    <li *ngIf="isUserAdmin"
        class="configuration-info__item flex justify-content-between align-items-center py-2 border-bottom-1">
      <span>Id:</span>
      <span>{{ configurationVersion.id || '(none)' }}</span>
    </li>
    <li class="configuration-info__item flex justify-content-between align-items-center py-2 border-bottom-1">
      <span>Version Number:</span>
      <p-tag [value]="'Version ' + configurationVersion.version"
             severity="warning"></p-tag>
    </li>
    <li class="configuration-info__item flex justify-content-between align-items-center py-2 border-bottom-1">
      <span>Name:</span>
      <span>{{ configurationVersion.name || '(none)' }}</span>
    </li>
    <li class="configuration-info__item flex justify-content-between align-items-center py-2 border-bottom-1">
      <span>Product Type:</span>
      <span>{{ configurationVersion.productType | productType }}</span>
    </li>
    <li class="configuration-info__item flex justify-content-between align-items-center py-2 border-bottom-1">
      <span>Status:</span>
      <p-tag [styleClass]="getVersionStatusColor(configurationVersion.rfqStatus).class"
             [value]="getVersionStatusLabel(configurationVersion.rfqStatus)"></p-tag>
    </li>
    <li class="configuration-info__item flex justify-content-end align-items-center pt-2">
      <button pButton
              pRipple
              *ngIf="tracksList.length > 0"
              type="button"
              icon="pi pi-file-export"
              pTooltip="Export"
              class="p-button-outlined p-button-info"
              [loading]="exportingParameters"
              (click)="exportParameters()">
      </button>
    </li>
  </ul>
</div>
