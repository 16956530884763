import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiUrls } from '../../api-helper';

@Injectable({
  providedIn: 'root',
})
export class MaintenanceService {
  constructor(private http: HttpClient) {}

  checkMaintenanceStatus(): Observable<any> {
    const url = ApiUrls.maintenanceEndpoint;

    return this.http.get(url);
  }
}
