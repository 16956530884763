<div class="flex flex-column justify-content-center align-items-center col-6"
     *ngIf="isWelcomeMessageVisible; else orientationSection">
  <h1>Track Designer</h1>
  <p>Welcome to our SuperTrak GEN3™ designer. This tool will generate a full list of SuperTrak GEN3™ sections and
    parts based on your entry.</p>
</div>

<ng-template #orientationSection>
  <div class="flex flex-column justify-content-center align-items-center col-6">
    <p-image class="option-item__image"
             [preview]="true"
             (onImageError)="setPlaceholderImage()"
             [src]="trackTypeImg"></p-image>
  </div>
</ng-template>

<div class="col-1">
  <p-divider [layout]="'vertical'"></p-divider>
</div>

<div class="types flex flex-column overflow-auto col-5"
     [formGroup]="form">
  <h5 class="question mt-2 text-center">{{ question?.questionText }}</h5>
  <ul class="options-list">
    <ng-container *ngFor="let option of question?.options; index as i">
      <li class="option-item flex _bordered">
        <label class="option-item__inner"
               (click)="showImage(option.value)">
          <div class="option-item__actions">
            <p-radioButton [name]="question?.fieldName || ''"
                           [formControlName]="question?.fieldName || ''"
                           [value]="option.value">
            </p-radioButton>
          </div>
          <p-image class="option-item__image"
                   [src]="getImageUrl(option.value)"
                   [alt]='option.value'></p-image>
          <p class="option-item__label text-lg">{{ option.label }}</p>
        </label>
      </li>
    </ng-container>
  </ul>
  <div class="option-item flex p-3 my-0 _bordered"
       *ngIf="isSymphoniAvailable">
    <p class="flex-grow-1 m-0">Is this a Symphoni project?</p>
    <p-checkbox [binary]="true"
                name="isSymphoni"
                inputId="isSymphoni"
                formControlName="isSymphoni"
                class="ml-3">
    </p-checkbox>
  </div>
</div>
