import { VersionStatus } from 'src/app/configuration/models/configuration.model';

export function getVersionStatusColor(rfqStatus: VersionStatus): {
  class: string;
  color: string;
} {
  const severity: {
    class: string;
    color: string;
  } = {
    color: '#2196F3',
    class: '_blue',
  };

  switch (rfqStatus) {
    case VersionStatus.Submitted:
      severity.color = '#2196F3';
      severity.class = '_blue';
      break;

    case VersionStatus.Draft:
      severity.color = '#BDBDBD';
      severity.class = '_grey';
      break;

    case VersionStatus.Cancelled:
      severity.color = '#EF4444';
      severity.class = '_red';
      break;

    case VersionStatus.InProgress:
      severity.color = '#F59E0B';
      severity.class = '_orange';
      break;

    case VersionStatus.ProposalSent:
      severity.color = '#22C55E';
      severity.class = '_green';
      break;

    default:
      break;
  }

  return severity;
}

export function getVersionStatusLabel(rfqStatus: VersionStatus): string {
  switch (rfqStatus) {
    case VersionStatus.Submitted:
      return 'Submitted';

    case VersionStatus.Cancelled:
      return 'Cancelled';

    case VersionStatus.InProgress:
      return 'In Progress';

    case VersionStatus.ProposalSent:
      return 'Proposal Sent';

    default:
      return 'Draft';
  }
}
