import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AppLoadingService {
  private isLoadingSubject$ = new BehaviorSubject<boolean>(false);
  public isLoading$: Observable<boolean> = this.isLoadingSubject$;

  private isLoadingBarSubject$ = new BehaviorSubject<boolean>(false);
  public isLoadingBar$: Observable<boolean> = this.isLoadingBarSubject$;

  constructor() {}

  startLoading() {
    this.isLoadingSubject$.next(true);
  }

  stopLoading() {
    this.isLoadingSubject$.next(false);
  }

  startLoadingBar() {
    this.isLoadingBarSubject$.next(true);
  }

  stopLoadingBar() {
    this.isLoadingBarSubject$.next(false);
  }
}
