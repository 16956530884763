import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { MessageService } from 'primeng/api';
import { forkJoin, map, Observable, tap, throwError } from 'rxjs';
import { AppRoutes } from 'src/app/app.routes.const';
import { AccessScope } from 'src/app/configuration-management/enums/access-scope.enum';
import { IdentityService } from 'src/app/core/services/identity.service';
import { ProjectService } from '../projects/project.service';
import { EditorModeService } from '../services/editor-mode/editor-mode.service';
import { ProjectConfigurationService } from '../services/project-configuration/project-configuration.service';

@Injectable({
  providedIn: 'root',
})
export class ConfigurationsResolver {
  constructor(
    private configService: ProjectConfigurationService,
    private projectService: ProjectService,
    private identityService: IdentityService,
    private router: Router,
    private editorModeService: EditorModeService,
    private messageService: MessageService
  ) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    const projectId = route.paramMap.get('projectId');

    if (projectId === null) {
      return throwError(() => 'ProjectId is null');
    }

    const accessScope = this.identityService.currentUser?.hasAccessScope(
      AccessScope.Sales
    )
      ? AccessScope.Sales
      : AccessScope.User;

    return forkJoin([
      this.configService.getAllUserConfigurations(projectId, accessScope),
      this.projectService.getProjectById(projectId, accessScope),
    ]).pipe(
      map(([configurations, project]) => {
        this.editorModeService.initEditorModeByProject(project);
        return true;
      }),
      tap({
        next: () => {
          return true;
        },
        error: () => {
          this.handleError();
          return false;
        },
      })
    );
  }

  handleError() {
    this.messageService.add({
      severity: 'error',
      summary: 'Error',
      detail: 'Failed to resolve configurations',
    });
    this.router.navigate([AppRoutes.Projects.fullPath()]);
  }
}
