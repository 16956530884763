import { Pipe, PipeTransform } from '@angular/core';
import { ProductType } from 'src/app/configuration/models/product-type';

@Pipe({
  name: 'productType',
})
export class ProductTypePipe implements PipeTransform {
  transform(value: string, ...args: unknown[]): string {
    switch (value) {
      case ProductType.SuperTrakGen3:
        return 'SuperTrak GEN3™';
        break;
      default:
        return value;
    }
  }
}
