<div class="flex flex-column justify-content-center align-items-center col-6">
  <h4>Interconnect Graphics</h4>
  <p-image class="option-item__image"
           [preview]="true"
           [src]="interconnectImg"
           alt='Shuttle Magnets graphics'></p-image>
</div>

<div class="col-1">
  <p-divider [layout]="'vertical'"></p-divider>
</div>

<div class="col-5 flex flex-column justify-content-center align-items-center"
     [formGroup]="form">
  <h5 class="question mt-4 text-center">{{ question?.questionText }}</h5>
  <ul class="options-list flex justify-content-center">
    <ng-container *ngFor="let option of question?.options">
      <li class="option-item m-0"
          [ngClass]="{
          '_disabled': isSymphoniProject
          }"
          *ngIf="option.isEnabled ? option.isEnabled(getETurnType) : true">
        <label
               class="option-item__inner align-items-center _inline p-0 mb-4 mx-4 justify-content-center align-items-center">
          <p-radioButton [formControlName]="question?.fieldName || ''"
                         [value]="option.value"
                         class="mr-3"></p-radioButton>
          <p class="option-item__label text-base mb-0">{{ option.label }}</p>
          <i *ngIf="isSymphoniProject && !isInterconnect6500(option.value)"
             class="pi pi-info-circle ml-3"
             style="color: #f7941d"></i>
        </label>
      </li>
    </ng-container>
  </ul>
  <p class="text-center font-italic"
     *ngIf="isSymphoniProject; else descTpl"><i class="pi pi-info-circle"
       style="color: #f7941d"></i> This option is not available for Symphoni
    enabled systems.
  </p>
  <ng-template #descTpl>
    <p class="font-italic">*If the controller is placed further than 2m away from the e-turn, choose a 6.5m
      interconnect.</p>
  </ng-template>
  <div class="flex flex-column w-full"
       *ngIf="isMotorPowerSupplyInputPowerCablingAvailable">
    <p-divider align="center"
               layout="horizontal">
      <h5 class="question m-0">Motor Power Supply Input Power Cabling</h5>
    </p-divider>
    <div class="option-item flex relative overflow-hidden _bordered mx-auto lg:w-10 xl:w-8">
      <label class="option-item__inner align-items-center _inline">
        <p-checkbox [binary]="true"
                    (click)="disableCheckboxFormValueChange($event)"
                    formControlName="includePowerSupplyWiring"></p-checkbox>
        <p class="option-item__label text-base w-full m-0 text-center ml-3">Include Motor Power Supply input power
          cabling (16 AWG/3c) (meters)</p>
      </label>
      <div class="hidden-info flex align-items-center justify-content-end w-6rem">
        <p-button [rounded]="true"
                  severity="warning"
                  ariaLabel="Description"
                  (click)="showDescription()"
                  icon="pi pi-info">
        </p-button>
      </div>
    </div>
  </div>
</div>


<p-dialog [(visible)]="descriptionVisible"
          [modal]="true"
          [closeOnEscape]="false"
          [draggable]="false"
          class="description-dialog"
          [resizable]="false">
  <ng-template pTemplate="header">
    <h3 class="m-0">Motor Power Supply input power cabling (16 AWG/3c) (meters)</h3>
  </ng-template>

  <ng-template pTemplate="content">
    <p-image [preview]="true"
             [appendTo]="'body'"
             src="/assets/images/track-designer/interconnect/motorPowerSupplyInputPowerCabling.png"></p-image>
    <p class="m-0 text-base text-justify">
      The motor power supply input power cabling is required to connect the motor power supply to the incoming power
      which comes via the SuperTrak supplied control panel or the integrator supplied control panel. This cabling is
      estimated in length which may be split into 2 or more pieces depending on the total length.
    </p>
  </ng-template>
</p-dialog>
