import { Component, OnDestroy } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { IdentityService } from 'src/app/core/services/identity.service';

@Component({
  selector: 'app-login-gateway',
  templateUrl: './login-gateway.component.html',
  styleUrls: ['./login-gateway.component.scss'],
})
export class LoginGatewayComponent implements OnDestroy {
  isExternalUser = false;
  userEmail: string;

  loginForm = this.fb.group({
    email: ['', [Validators.email, Validators.required]],
  });

  get isInternalDomain(): boolean {
    const domain = this.loginForm.get('email')?.value?.split('@')[1];

    return (
      domain === 'atsautomation.com' || domain == 'supertrakconveyance.com'
    );
  }

  private readonly _destroying$ = new Subject<void>();

  constructor(
    private router: Router,
    private identityService: IdentityService,
    private fb: FormBuilder
  ) {}

  ngOnDestroy(): void {
    this._destroying$.unsubscribe();
  }

  onLoginClick(): void {
    if (this.loginForm.valid && this.userEmail.length) {
      this.identityService.login(this.userEmail);
    }
  }

  onNewUserClick(): void {
    this.identityService.signup(this.userEmail);
  }

  startSignIn(): void {
    if (this.loginForm.invalid) return;

    this.userEmail = this.loginForm.get('email')?.value || '';

    if (!this.userEmail) return;

    const internalDomain = this.defineInternalDomain(this.userEmail);
    this.isExternalUser = !internalDomain;

    if (!this.isExternalUser && internalDomain) {
      this.identityService.ATSSignUpLogin(this.userEmail, internalDomain);
      return;
    }
  }

  defineInternalDomain(
    email: string
  ): 'supertrakconveyance.com' | 'atsautomation.com' | null {
    const domain = email.split('@')[1];

    return domain === 'atsautomation.com' || domain == 'supertrakconveyance.com'
      ? domain
      : null;
  }
}
