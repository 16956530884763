import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-process-map-template',
  templateUrl: './process-map-template.component.html',
  styleUrls: ['./process-map-template.component.scss'],
})
export class ProcessMapTemplateComponent {
  @Input() template: any;
}
