import { Component, OnInit } from '@angular/core';
import {
  NavigationCancel,
  NavigationEnd,
  NavigationError,
  NavigationStart,
  Router,
} from '@angular/router';
import { debounce, Subject, takeUntil, timer } from 'rxjs';
import { AppLoadingService } from 'src/app/core/services/app-loading.service';

@Component({
  selector: 'app-progress-bar',
  templateUrl: './progress-bar.component.html',
  styleUrls: ['./progress-bar.component.scss'],
})
export class ProgressBarComponent implements OnInit {
  isLoadingBar = false;
  private readonly TIMEOUT = 1000;
  private readonly _destroying$ = new Subject<void>();

  constructor(
    private router: Router,
    private appLoadingService: AppLoadingService
  ) {}

  ngOnInit(): void {
    this.appLoadingService.isLoadingBar$
      .pipe(
        takeUntil(this._destroying$),
        debounce(() => {
          return timer(this.TIMEOUT);
        })
      )
      .subscribe((res: boolean) => {
        this.isLoadingBar = res;
      });

    this.router.events.pipe(takeUntil(this._destroying$)).subscribe({
      next: (e) => {
        if (e instanceof NavigationStart) {
          this.appLoadingService.startLoadingBar();
        } else if (
          e instanceof NavigationEnd ||
          e instanceof NavigationError ||
          e instanceof NavigationCancel
        ) {
          this.appLoadingService.stopLoadingBar();
        }
      },
      error: (err) => {
        console.error(err);
        this.appLoadingService.stopLoadingBar();
      },
    });
  }

  ngOnDestroy(): void {
    this._destroying$.unsubscribe();
  }
}
